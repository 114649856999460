import React from 'react';
import classNames from 'classnames';

import styles from './responsive-image.module.scss';
import { useResponsiveImageContext } from './responsive-image.context';
import * as Scrivito from 'scrivito';
import { ImageWidgetBubbleColor } from '../../../../widgets/image-widget/image-widget-definitions';

import { ReactComponent as IconSearch } from '../../../../assets/icons/icon-search.svg';
import { useImageScalableContext } from './responsive-image-scalable';

import { ReactComponent as IconClose } from '../../../../assets/icons/icon-close.svg';
import { useViewportWidth } from '../../../../utils/hooks.utils';

const IMAGE_ZOOM_MIN_VIEWPORT_WIDTH = 720;

export interface ResponsiveImageSizeProps {
  sources?: Array<{ url: string; width: number }>;
  caption?: string | React.ReactElement;
  width?: number;
  meta?: { width: number; height: number };
  link?: Scrivito.Link;
  bubble?: ImageWidgetBubbleColor;
  scalable?: boolean;
}

export interface ImageWrapperProps {
  image: React.ReactElement;
}

export type ResponsiveImageProps = React.ImgHTMLAttributes<HTMLImageElement> &
  Omit<ImageWrapperProps, 'image'> &
  ResponsiveImageSizeProps;

/**
 * Creates the src image and the string for srcSet from the given source array.
 * @param sources The source array to convert
 */
export const convertSourceSet = (sources?: Array<{ url: string; width: number }>): { src?: string; srcSet: string } => {
  let smallestImage = undefined;
  let srcSetString = '';
  sources?.forEach((source) => {
    smallestImage = source.url;
    if (srcSetString.length > 0) {
      srcSetString = `${source.url} ${source.width}w, ${srcSetString}`;
    } else {
      srcSetString = `${source.url} ${source.width}w`;
    }
  });
  return { src: smallestImage, srcSet: srcSetString };
};

/**
 * Img Element Control which takes position and ratio as extra attributes different from an img tag
 * and passes everything else down
 *
 * @param rest Any attribute that can be passed down to an img tag
 * @param sources for responsive images - the source image URL for x-small
 * @param width The breakpoint width
 * @param bubble Bubble type for displaying the bubble tip
 * @param meta Meta information about the size of the image
 * @param link A link to which the image points
 * @constructor
 */
export const ResponsiveImageComponent: React.FC<ResponsiveImageProps> = ({
  bubble,
  sources,
  width,
  meta,
  link,
  ...rest
}) => {
  const imageContext = useResponsiveImageContext();
  const { setScaledImage } = useImageScalableContext();
  const viewportWidth = useViewportWidth() ?? 0;
  const { src, alt, title, caption, className } = rest;
  const { src: smallestImage, srcSet: srcSetString } = convertSourceSet(sources);
  const imgWidth = meta?.width;
  const imgHeight = meta?.height;
  const scalable = (imageContext.forceScalable || rest.scalable) && viewportWidth >= IMAGE_ZOOM_MIN_VIEWPORT_WIDTH;

  //create the image object if there is a srcset to be included
  let imageTag =
    srcSetString !== '' ? (
      <img
        src={smallestImage}
        srcSet={srcSetString}
        sizes={'100vw'}
        alt={alt}
        title={title}
        style={{ width }}
        className={classNames(className, styles.imageEl)}
        width={imgWidth}
        height={imgHeight}
        loading={imageContext.loading}
      />
    ) : (
      <img
        src={src}
        alt={alt}
        className={classNames(className, styles.imageEl)}
        width={imgWidth}
        height={imgHeight}
        loading={imageContext.loading}
      />
    );

  if (scalable && !link) {
    let baseImageTag = imageTag;

    baseImageTag = (
      <div className={styles.ScaledImageColumn}>
        <div className={styles.ButtonImageWrapper} onClick={(e): void => e.stopPropagation()}>
          <button className={styles.ScaledImageCloseButton} onClick={(): void => setScaledImage(null)}>
            <IconClose />
          </button>
          {imageTag}
        </div>
      </div>
    );

    imageTag = (
      <button className={styles.Scalable} onClick={(): void => setScaledImage(baseImageTag)}>
        {imageTag}
        <div className={styles.Overlay} />
        <div className={styles.OverlayText}>
          <IconSearch />
        </div>
      </button>
    );
  }

  if (link) {
    imageTag = (
      <Scrivito.LinkTag to={link} draggable="false" className={styles.ImageLink}>
        {imageTag}
        <div className={styles.Overlay} />
        <div className={styles.OverlayText}>{link?.title()}</div>
      </Scrivito.LinkTag>
    );
  }

  const isBubble = bubble && bubble !== ImageWidgetBubbleColor.NO_COLOR;
  const bubbleClass = isBubble ? styles[bubble] : undefined;

  if (caption) {
    const captionEl = <figcaption className={classNames({ [styles.Centered]: width })}>{caption}</figcaption>;

    // Case 1: caption and bubble
    // Case 2: caption and no bubble
    return (
      <figure
        className={classNames(styles.ResponsiveImage, bubbleClass, {
          [styles.Bubble]: isBubble,
        })}
      >
        {imageTag}
        {captionEl}
      </figure>
    );
  }

  if (isBubble) {
    // Case 3: no caption and bubble
    return <div className={classNames(styles.ResponsiveImage, styles.Bubble, bubbleClass)}>{imageTag}</div>;
  }

  // Case 4: no caption and no bubble
  return imageTag;
};
