import truncate from 'lodash.truncate';
import * as Scrivito from 'scrivito';
import {
  DownloadObjectAttributes,
  DownloadObjectId,
  ImageObjectId,
  SynonymObjectAttributes,
  VideoObjectId,
} from '../../objects';
import { BasePageAttributes } from '../../pages/base-page';
import { ContentPageId } from '../../pages/content-page';
import { HomePageId } from '../../pages/home-page';
import { SearchAttributes } from '../../pages/home-page/home-page-search';
import { downloadFile, fetchBlob } from '../../utils/general.utils';
import { HeadlineWidgetTag } from '../../widgets/headline-widget';
import { DownloadBoxComponent, DownloadBoxObject } from '../building-blocks/download-box/download-box';
import { SearchResultComponent } from '../controls/search-result/search-result';
import React from 'react';
import { PressReleaseId } from '../../pages/press-release';
import { SimpleChoice } from '../../utils/objects/enums.utils';
import { FaqPageId } from '../../pages/faq-page';
import { isWidgetVisible, SchedulingWidget, SchedulingWidgetId } from '../../widgets/scheduling-widget';
import { getText } from '../../utils/scrivito/widget.utils';
import { SearchResult } from './search-function';

export const DEFAULT_SEARCH_RESULT_LIMIT = 10;
export const DEFAULT_DOWNLOAD_RESULT_LIMIT = 6;
export const SEARCH_PAGE_OBJECTS = [HomePageId, ContentPageId, PressReleaseId, FaqPageId];
export const SEARCH_MEDIA_OBJECTS = [DownloadObjectId, ImageObjectId, VideoObjectId];
export const SEARCH_INCLUDES = [...SEARCH_PAGE_OBJECTS, ...SEARCH_MEDIA_OBJECTS];
export const SEARCH_RESULT_TEXT_LENGTH = 250;
export const MAX_SEARCH_SUGGESTIONS = 5;
export const MIN_SEARCH_SUGGESTIONS_INPUT_LENGTH = 3;

// Special filter for SEARCH_MEDIA_OBJECTS
export const FILE_FILTER = 'File';

// get synonym list if the query word is included in one of them
export const getSynonymListForQuery = (query: string): string[] => {
  return (Scrivito.Obj.root()?.get(SearchAttributes.SYNONYMS) as Scrivito.Obj[])
    .map((item) => item.get(SynonymObjectAttributes.SYNONYMS) as string[])
    .filter((singleSynonymList) => {
      return singleSynonymList.some((item) => {
        return item.toLowerCase() === query.toLowerCase();
      });
    })[0];
};

// execute the scrivito search command
export const buildScrivitoSearchQuery = (
  searchTerms: string | string[],
  searchableObjects: string[],
  tags: string[]
): Scrivito.ObjSearch => {
  let query = Scrivito.Obj.where('*', 'containsPrefix', searchTerms)
    .and('_objClass', 'equals', searchableObjects)
    .and('_restriction', 'equals', null)
    .andNot('visibleFrom', 'isGreaterThan', new Date())
    .andNot('visibleUntil', 'isLessThan', new Date())
    .andNot('_publishedAt', 'equals', null)
    .and('noIndex', 'equals', false)
    .and('visibleInSearch', 'equals', [SimpleChoice.YES, null]);

  if (tags.length > 0) {
    // add each tag separately to query to make sure that it is an 'and' condition
    tags.forEach((tag) => {
      query = query.and('tags', 'equals', tag);
    });
  }

  return query;
};

// main query for suggestions in autocomplete
export const getAutocompleteSuggestions = (query: string, limit: number): string[] => {
  if (query.length < MIN_SEARCH_SUGGESTIONS_INPUT_LENGTH) {
    return [];
  }

  return Scrivito.Obj.all().suggest(query, { limit });
};

export const isHomePage = (page: Scrivito.Obj): boolean => {
  return page.objClass() === HomePageId;
};

export const isContentPage = (page: Scrivito.Obj): boolean => {
  return page.objClass() === ContentPageId;
};

export const isDownloadObject = (page: Scrivito.Obj): boolean => {
  return page.objClass() === DownloadObjectId;
};

export const isImageObject = (page: Scrivito.Obj): boolean => {
  return page.objClass() === ImageObjectId;
};

export const isVideoObject = (page: Scrivito.Obj): boolean => {
  return page.objClass() === VideoObjectId;
};

export const isMediaFile = (page: Scrivito.Obj): boolean => {
  return isDownloadObject(page) || isImageObject(page) || isVideoObject(page);
};

export const getSearchResultTitle = (page: Scrivito.Obj): string => {
  if (isMediaFile(page)) {
    const blob = page.get(DownloadObjectAttributes.BLOB) as Scrivito.Binary;
    const downloadObjectTitle = page.get(DownloadObjectAttributes.TITLE) as string;

    const fileName = blob.filename();

    return downloadObjectTitle !== '' ? downloadObjectTitle : fileName;
  }
  return (page.get(BasePageAttributes.TITLE) as string) ?? '';
};

export const getSearchResultLinkText = (page: Scrivito.Obj): string => {
  if (SEARCH_INCLUDES.includes(page.objClass())) {
    if (isContentPage(page)) {
      return 'search.toPage';
    }
    if (isHomePage(page)) {
      return 'search.toHome';
    }
    if (isMediaFile(page)) {
      return '';
    }
  }
  return 'search.toResult';
};

export const renderSingleSearchResult = (
  page: Scrivito.Obj,
  searchTerms: string[],
  index: number
): React.ReactElement => {
  const isPageHighlight = (page.get(BasePageAttributes.SEARCH_HIGHLIGHT) as boolean) ?? false;

  // gets headline depending on objClass of page
  const headline = getSearchResultTitle(page);

  // gets text and removes non-UTF8 characters
  let extractedText = Scrivito.extractText(page).replace(/[^\x00-\xFF]/g, '');

  // Check if there are scheduling widgets on the page that are currently not visible
  const invisibleScheduledWidgets = page
    .widgets()
    .filter(
      (widget) => widget.objClass().trim() === SchedulingWidgetId.trim() && !isWidgetVisible(widget as SchedulingWidget)
    );

  // Remove the text that is not visible
  invisibleScheduledWidgets.forEach((invisibleWidget) => {
    extractedText = extractedText.replace(getText(invisibleWidget), '');
  });

  // find all first occurrences of the search term including synonyms
  const searchTermIndices = searchTerms
    .filter((searchTerm) => {
      return extractedText.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
    })
    .map((searchTerm) => extractedText.toLowerCase().indexOf(searchTerm.toLowerCase()));

  // check if search terms only appear in the title and not in the body
  const isSearchTermOnlyInTitle = searchTermIndices.length === 0;

  // choose first occurrence of the search terms in the texts
  const firstSearchTermIndex = searchTermIndices.length > 0 ? Math.min(...searchTermIndices) : 0;

  // always creates the same offset for each page individually at the beginning of search results text
  const offsetAtBeginning = Number(page.id().replace(/\D/gi, '')) % 40;
  // cuts beginning of extracted text in a smart way
  const smartBeginningTruncatedText =
    // whole text should longer than the part which is cut at the beginning
    extractedText.length > offsetAtBeginning &&
    // after beginning of text is cut, there should still be enough characters to display
    firstSearchTermIndex - offsetAtBeginning > 0 &&
    extractedText.substring(firstSearchTermIndex - offsetAtBeginning).length > SEARCH_RESULT_TEXT_LENGTH &&
    // search term not only appears in the title
    !isSearchTermOnlyInTitle &&
    // search term should not be the first word of the cut text
    firstSearchTermIndex !== 0
      ? '...' + extractedText.substring(firstSearchTermIndex - offsetAtBeginning)
      : extractedText;

  // truncates end of text
  const textToHighlight = truncate(smartBeginningTruncatedText, {
    length: SEARCH_RESULT_TEXT_LENGTH,
    separator: /,? +/,
    omission: ' ...',
  });

  // sets link text depending on object type of the result page
  const linkText = getSearchResultLinkText(page);

  const handleFileClick = (): void => {
    const blob = page.get('blob') as Scrivito.Binary;
    const fileName = blob.filename();
    fetchBlob(blob.url()).then((data) => downloadFile(data, fileName));
  };

  // Check if there are still search results after removing the scheduling content
  const searchEmpty = searchTermIndices.length === 0;

  return (
    <SearchResultComponent
      key={`${page.id()}-${index}`}
      headline={headline}
      text={searchEmpty ? '' : textToHighlight}
      highlight={searchEmpty ? false : isPageHighlight}
      keywords={searchEmpty ? [] : searchTerms}
      link={page}
      linkText={linkText}
      isMediaFile={isMediaFile(page)}
      fileClick={isMediaFile(page) && !searchEmpty ? handleFileClick : undefined}
    />
  );
};

export const searchResultToDownloadBoxObjects = (result: SearchResult): DownloadBoxObject[] => {
  const download: DownloadBoxObject[] = [];

  for (const page of result.items) {
    if (isMediaFile(page)) {
      const blob = page.get(DownloadObjectAttributes.BLOB) as Scrivito.Binary;
      const fileName = blob.filename();

      download.push({
        name: fileName,
        onClick: async (event: React.MouseEvent): Promise<void> => {
          // TODO check why some files are downloaded multiple times
          event.preventDefault();
          await Scrivito.load(() => {
            fetchBlob(blob.url()).then((data) => {
              downloadFile(data, fileName);
            });
          });
        },
      });

      if (download.length >= DEFAULT_DOWNLOAD_RESULT_LIMIT) {
        break;
      }
    }
  }

  return download;
};

export const renderDownloadBox = (query: string, result: SearchResult): React.ReactNode => {
  const download = searchResultToDownloadBoxObjects(result);

  if (!download.length) {
    return null;
  }

  return (
    <DownloadBoxComponent
      key={`download-box`}
      className={''}
      headline={<>Nützliche Informationen zum Herunterladen</>}
      headlineComponent={HeadlineWidgetTag.H3}
      downloadObjects={download}
      onButtonClick={(): void => {
        Scrivito.navigateTo(() => Scrivito.currentPage(), {
          params: { q: query, file: 'true' },
        });
      }}
      buttonText={<>Alle anzeigen</>}
      enableSingleFileDownload
    />
  );
};

/**
 * Executes the search with the given URL params.
 *
 * @param query The search query
 * @param limit Number of search results
 * @param currentSearchObjects The current search objects
 * @param tags The current tags to filter
 */
export const executeSearch = async (
  query = '',
  limit: number,
  currentSearchObjects: string[],
  tags: string[]
): Promise<SearchResult> => {
  return Scrivito.load(() => {
    if (!query || query === '') {
      return {
        items: [],
        total: 0,
        tags: [],
      };
    }

    const currentSynonymList = getSynonymListForQuery(query);
    const search = buildScrivitoSearchQuery(currentSynonymList ?? query, currentSearchObjects, tags);
    const items = search.take(limit);

    return {
      items,
      total: search.count(),
    };
  });
};

/**
 * Checks if the query with file filter and/or tags has results
 * @param query The query to execute
 * @param fileFilter Whether the file filter should be added to the query
 * @param tags Tags that should be added to the query
 */
export const hasResults = async (query = '', fileFilter: boolean, tags: string[]): Promise<boolean> => {
  // When searching without filter, search for page objects only (media objects are separate)
  let currentSearchObjects = SEARCH_PAGE_OBJECTS;
  // Searching with file filter will only search for media objects
  if (fileFilter) {
    currentSearchObjects = SEARCH_MEDIA_OBJECTS;
  } else if (tags.length > 0) {
    // When searching with tag filters, include all results
    currentSearchObjects = SEARCH_INCLUDES;
  }

  const result = await executeSearch(query, DEFAULT_SEARCH_RESULT_LIMIT, currentSearchObjects, tags);
  return result.total > 0;
};

/**
 * Creates the params for the url depending on the given options and redirects to it
 * @param q The query
 * @param filter The active filters
 * @param file The file filter
 */
export const navigateWithFilters = (q: string, filter: string | null, file?: string): void => {
  let params = { q };
  if (filter) {
    params = Object.assign(params, { filter });
  }
  if (file) {
    params = Object.assign(params, { file });
  }
  Scrivito.navigateTo(() => Scrivito.currentPage(), {
    params,
  });
};

/**
 * Creates a filter query string separated by commas.
 * If the current filters already have the given filter, it will be removed
 * otherwise it will be added.
 * @param currentFilters The current active url filters
 * @param filter The filter to add or remove
 */
export const addOrRemoveFilter = (currentFilters: string[], filter: string): string => {
  let filterQuery = currentFilters;
  if (filterQuery.indexOf(filter) === -1) {
    filterQuery.push(filter);
  } else {
    filterQuery = filterQuery.filter((item) => item !== filter);
  }
  return filterQuery.join(',');
};
