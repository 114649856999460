import * as Scrivito from 'scrivito';
import {
  PressReleaseButtonWidgetAttributes,
  PressReleaseButtonWidgetId,
} from './press-release-button-widget-definitions';
import { ButtonWidgetIcons } from '../../../widgets/button-widget';
import { PressReleaseId } from '../press-release-definitions';

export const PressReleaseButtonWidgetClass = Scrivito.provideWidgetClass(PressReleaseButtonWidgetId, {
  attributes: {
    [PressReleaseButtonWidgetAttributes.LINK]: 'link',
    [PressReleaseButtonWidgetAttributes.ICON]: [
      'enum',
      {
        values: Object.values(ButtonWidgetIcons),
      },
    ],
  },
  onlyInside: [PressReleaseId],
});

export type PressReleaseButtonWidget = InstanceType<typeof PressReleaseButtonWidgetClass>;
