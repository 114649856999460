import * as Scrivito from 'scrivito';
import {
  FormInteractiveEmailWidgetAttributes,
  FormInteractiveEmailWidgetId,
} from './form-interactive-email-widget-definitions';
import { FormWidgetId } from '../form-widget/form-widget-definitions';

export const FormInteractiveEmailWidgetClass = Scrivito.provideWidgetClass(FormInteractiveEmailWidgetId, {
  attributes: {
    [FormInteractiveEmailWidgetAttributes.FORM_CONTROL_NAME]: 'string',
    [FormInteractiveEmailWidgetAttributes.FORM_CONTROL_VALUE]: 'string',
    [FormInteractiveEmailWidgetAttributes.EMAIL]: 'string',
  },
  onlyInside: [FormWidgetId],
});

export type FormInteractiveEmailWidget = InstanceType<typeof FormInteractiveEmailWidgetClass>;
