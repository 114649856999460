export const WagonSelectionItemWidgetId = 'WagonSelectionItemWidget';

export enum WagonSelectionItemWidgetAttributes {
  NAME = 'wagonSelectionItemName',
  VALUE = 'wagonSelectionITemValue',
  YEAR = 'wagonSelectionItemYear',
  CAPACITY = 'wagonSelectionItemCapacity',
  DURATION = 'wagonSelectionItemDuration',
  PRICE = 'wagonSelectionItemPrice',
  CONTENT = 'wagonSelectionItemContent',
  IMAGES = 'wagonSelectionItemImages',
  DISABLED = 'wagonSelectionItemDisabled',
}
