import * as Scrivito from 'scrivito';
import { ColorBoxWidgetAttributes, ColorBoxWidgetColors, ColorBoxWidgetId } from './color-box-widget-definitions';
import { spacingAttributes, spacingContent, spacingGroup } from '../../utils/scrivito/spacing-definitions';
import { HelperText, Message, WidgetTitle } from '../../utils/translations';
import { ValidationInfo } from '../../utils/type.utils';
import Thumbnail from '../../assets/thumbnails/fullscreen_hintergrundfarbe.svg';

Scrivito.provideEditingConfig(ColorBoxWidgetId, {
  title: WidgetTitle.COLOR_BOX_WIDGET,
  thumbnail: Thumbnail,
  properties: Object.values(ColorBoxWidgetAttributes),
  propertiesGroups: [spacingGroup],
  attributes: {
    [ColorBoxWidgetAttributes.COLOR]: {
      title: 'Farbe',
      values: [
        { value: ColorBoxWidgetColors.DARK_GREEN, title: 'dunkelgrün' },
        { value: ColorBoxWidgetColors.DARK_RED, title: 'dunkelrot' },
        { value: ColorBoxWidgetColors.VIOLET, title: 'lila' },
        { value: ColorBoxWidgetColors.RED, title: 'rot' },
        { value: ColorBoxWidgetColors.GRAY, title: 'grau' },
      ],
    },
    [ColorBoxWidgetAttributes.ELEMENTS]: {
      title: 'Elemente',
    },
    [ColorBoxWidgetAttributes.ELEMENT_ID]: {
      title: HelperText.ELEMENT_ID,
      description: HelperText.ELEMENT_ID_DESCRIPTION,
    },
    ...spacingAttributes,
  },
  validations: [
    [
      ColorBoxWidgetAttributes.COLOR,
      (value: string | null): ValidationInfo => {
        if (!value) {
          return {
            severity: 'error',
            message: Message.COLOR_MISSING,
          };
        }
        return undefined;
      },
    ],
  ],
  initialContent: {
    [ColorBoxWidgetAttributes.COLOR]: ColorBoxWidgetColors.DARK_GREEN,
    ...spacingContent,
  },
});
