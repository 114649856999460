import * as Scrivito from 'scrivito';
import React from 'react';
import { ReactComponent as RheinbahnLogoImageOnly } from '../../../../../assets/img/rheinbahn-logo-image-only.svg';
import { ReactComponent as RheinbahnLogoTextOnly } from '../../../../../assets/img/rheinbahn-logo-text-only.svg';
import { ReactComponent as IconSearch } from '../../../../../assets/icons/icon-search.svg';
import { ReactComponent as IconSearchDot } from '../../../../../assets/icons/icon-search-dot.svg';
import { ReactComponent as IconBurger } from '../../../../../assets/icons/icon-burger.svg';
import { NavigationAttributes, NavigationEntryAttrs } from '../../../../../pages/home-page/home-page-navigation';
import classNames from 'classnames';
import styles from './navigation-header.module.scss';
import { NavigationHeaderLink } from './navigation-header-link';
import { NavigationHeaderMeta } from './navigation-header-meta';
import { SearchAttributes } from '../../../../../pages/home-page/home-page-search';
import { useTranslation } from 'react-i18next';

interface NavigationHeaderProps {
  selected: Scrivito.Widget | undefined;
  small: boolean;
  onSelection: (selection: Scrivito.Widget | undefined) => void;
  onBurgerButton: () => void;
}

export const NavigationHeader: React.FC<NavigationHeaderProps> = Scrivito.connect(
  ({ selected, small, onSelection, onBurgerButton }) => {
    const root = Scrivito.Obj.root();
    const navElements = (root?.get(NavigationAttributes.NAVIGATION) as Scrivito.Widget[]) ?? [];
    const selectedLabel = (selected?.get(NavigationEntryAttrs.LABEL) as string) ?? undefined;
    const searchPageLink = root?.get(SearchAttributes.SEARCH_PAGE) as Scrivito.Link;
    const { t } = useTranslation();

    return (
      <>
        <NavigationHeaderMeta small={small} afterClick={(): void => onSelection(undefined)} />
        <div className={classNames(styles.NavigationHeader, { [styles.Small]: small })}>
          <div className={styles.LogoArea}>
            <Scrivito.LinkTag to={root} aria-label={t('component.navigation.logoTitle')}>
              <div className={styles.LogoContainer}>
                <RheinbahnLogoImageOnly />
                <RheinbahnLogoTextOnly className={styles.Text} />
              </div>
            </Scrivito.LinkTag>
          </div>
          <nav className={styles.LinksArea}>
            {navElements.map((element, index) => (
              <NavigationHeaderLink
                key={`nav-link-${index}`}
                element={element}
                selected={selectedLabel}
                small={small}
                setSelected={onSelection}
              />
            ))}
          </nav>
          <div className={styles.IconsArea}>
            <Scrivito.LinkTag
              to={searchPageLink}
              className={styles.White}
              aria-label={t('component.navigation.searchButtonTitle')}
            >
              <IconSearch className={styles.NotHover} />
              <IconSearchDot className={styles.HoverOnly} />
            </Scrivito.LinkTag>
            <button
              className={classNames(styles.White, styles.MobileOnly)}
              onClick={onBurgerButton}
              aria-label={t('component.navigation.menuButtonTitle')}
            >
              <IconBurger />
            </button>
          </div>
        </div>
      </>
    );
  }
);
