import * as Scrivito from 'scrivito';
import { checkCurrency, checkDuration, checkValue } from '../../utils/scrivito/validation';
import { HelperText, Message, WidgetTitle } from '../../utils/translations';
import { ValidationInfo } from '../../utils/type.utils';
import {
  WagonSelectionItemWidgetAttributes,
  WagonSelectionItemWidgetId,
} from './wagon-selection-item-widget-definitions';

Scrivito.provideEditingConfig(WagonSelectionItemWidgetId, {
  title: WidgetTitle.WAGON_SELECTION_ITEM,
  titleForContent: (obj) => obj.get(WagonSelectionItemWidgetAttributes.NAME) as string,
  properties: Object.values(WagonSelectionItemWidgetAttributes),
  attributes: {
    [WagonSelectionItemWidgetAttributes.NAME]: {
      title: HelperText.NAME,
    },
    [WagonSelectionItemWidgetAttributes.VALUE]: {
      title: HelperText.FORM_VALUE,
    },
    [WagonSelectionItemWidgetAttributes.YEAR]: {
      title: HelperText.YEAR,
    },
    [WagonSelectionItemWidgetAttributes.CAPACITY]: {
      title: HelperText.CAPACITY,
    },
    [WagonSelectionItemWidgetAttributes.PRICE]: {
      title: HelperText.PRICE,
    },
    [WagonSelectionItemWidgetAttributes.DURATION]: {
      title: HelperText.DURATION,
    },
    [WagonSelectionItemWidgetAttributes.CONTENT]: {
      title: HelperText.CONTENT,
      options: {
        showHtmlSource: false,
        toolbar: ['bulletList', 'subscript', 'superscript'],
      },
    },
    [WagonSelectionItemWidgetAttributes.IMAGES]: {
      title: HelperText.IMAGES,
    },
    [WagonSelectionItemWidgetAttributes.DISABLED]: {
      title: HelperText.DISABLED,
    },
  },
  validations: [
    [
      WagonSelectionItemWidgetAttributes.NAME,
      (value: string): ValidationInfo => checkValue(value, Message.VALUE_NAME_MISSING),
    ],
    [
      WagonSelectionItemWidgetAttributes.VALUE,
      (value: string): ValidationInfo => checkValue(value, Message.VALUE_FIELD_MISSING),
    ],
    [
      WagonSelectionItemWidgetAttributes.PRICE,
      (value: string): ValidationInfo => checkValue(value, Message.VALUE_PRICE_MISSING),
    ],
    [
      WagonSelectionItemWidgetAttributes.PRICE,
      (value: string): ValidationInfo => checkCurrency(value, Message.VALUE_PRICE_ERROR),
    ],
    [
      WagonSelectionItemWidgetAttributes.DURATION,
      (value: string): ValidationInfo => checkDuration(value, Message.VALUE_DURATION_ERROR),
    ],
  ],
  initialContent: {},
});
