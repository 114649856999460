import { TooltipObjectId } from '../../objects/tooltip-object';

export const tooltipPattern = /(\[[a-z0-9_-]+\])/g;

export enum TooltipAttributes {
  ITEMS = 'tooltipItems',
}

export const tooltipAttributes = {
  [TooltipAttributes.ITEMS]: {
    title: 'Tooltips',
  },
};

export const tooltipClassAttributes = {
  [TooltipAttributes.ITEMS]: ['referencelist', { only: [TooltipObjectId] }],
};
