import * as Scrivito from 'scrivito';
import { ImageObjectId } from '../../objects';
import { ValidationInfo } from '../../utils/type.utils';
import { checkValue } from '../../utils/scrivito/validation';
import { Message } from '../../utils/translations';
import { HomePageId } from './home-page-definitions';

export const NavigationLinkId = 'NavigationLink';
export const NavigationEntryId = 'NavigationEntry';
export const SubNavigationEntryId = 'SubNavigationEntryId';

export enum NavigationAttributes {
  SHOW_LANGUAGE_SWITCHER = 'showLanguageSwitcher',
  META_NAVIGATION = 'metaNavigation',
  NAVIGATION = 'navigation',
}

export const navigationAttributes = {
  [NavigationAttributes.SHOW_LANGUAGE_SWITCHER]: {
    title: 'Sprachwechsler anzeigen',
  },
  [NavigationAttributes.META_NAVIGATION]: {
    title: 'Meta-Navigation',
  },
};

export const navigationInitialContent = {
  [NavigationAttributes.SHOW_LANGUAGE_SWITCHER]: true,
};

export const navigationClassAttributes = {
  [NavigationAttributes.SHOW_LANGUAGE_SWITCHER]: 'boolean',
  [NavigationAttributes.META_NAVIGATION]: 'linklist',
  [NavigationAttributes.NAVIGATION]: ['widgetlist', { only: [NavigationEntryId, NavigationLinkId] }],
};

export enum NavigationEntryAttrs {
  LABEL = 'label',
  LINK = 'link',
  IMAGE = 'image',
  IMAGE_POSITION_H = 'imagePositionHorizontal',
  IMAGE_POSITION_V = 'imagePositionVertical',
  CHILDREN = 'children',
  CHILDREN1 = 'children1',
  CHILDREN2 = 'children2',
  CHILDREN3 = 'children3',
}

export enum NavigationImagePositions {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom',
}

Scrivito.provideWidgetClass(NavigationLinkId, {
  attributes: {
    [NavigationEntryAttrs.LINK]: 'link',
  },
  onlyInside: [HomePageId, SubNavigationEntryId],
});

Scrivito.provideWidgetClass(NavigationEntryId, {
  attributes: {
    [NavigationEntryAttrs.LABEL]: 'string',
    [NavigationEntryAttrs.IMAGE]: ['reference', { only: [ImageObjectId] }],
    [NavigationEntryAttrs.IMAGE_POSITION_H]: [
      'enum',
      {
        values: [NavigationImagePositions.LEFT, NavigationImagePositions.CENTER, NavigationImagePositions.RIGHT],
      },
    ],
    [NavigationEntryAttrs.IMAGE_POSITION_V]: [
      'enum',
      {
        values: [NavigationImagePositions.TOP, NavigationImagePositions.CENTER, NavigationImagePositions.BOTTOM],
      },
    ],
    [NavigationEntryAttrs.CHILDREN1]: ['widgetlist', { only: [SubNavigationEntryId] }],
    [NavigationEntryAttrs.CHILDREN2]: ['widgetlist', { only: [SubNavigationEntryId] }],
    [NavigationEntryAttrs.CHILDREN3]: ['widgetlist', { only: [SubNavigationEntryId] }],
  },
  extractTextAttributes: [NavigationEntryAttrs.LABEL],
  onlyInside: [HomePageId],
});

Scrivito.provideWidgetClass(SubNavigationEntryId, {
  attributes: {
    [NavigationEntryAttrs.LABEL]: 'string',
    [NavigationEntryAttrs.LINK]: 'link',
    [NavigationEntryAttrs.CHILDREN]: ['widgetlist', { only: [NavigationLinkId] }],
  },
  extractTextAttributes: [NavigationEntryAttrs.LABEL],
  onlyInside: [NavigationEntryId],
});

Scrivito.provideEditingConfig(NavigationLinkId, {
  title: 'Navigationslink',
  properties: [NavigationEntryAttrs.LINK],
  attributes: {
    [NavigationEntryAttrs.LINK]: { title: 'Link' },
  },
  initialContent: {
    [NavigationEntryAttrs.LINK]: new Scrivito.Link({
      url: '#',
      title: 'Link Title',
    }),
  },
  validations: [
    [NavigationEntryAttrs.LINK, (value: string | null): ValidationInfo => checkValue(value, Message.LINK_MISSING)],
    (widget): ValidationInfo => {
      const link = widget.get(NavigationEntryAttrs.LINK) as Scrivito.Link;
      if (!link?.title()) {
        return { severity: 'error', message: Message.LINK_MISSING };
      }
      return undefined;
    },
  ],
  titleForContent: (obj) => {
    const link = obj.get(NavigationEntryAttrs.LINK) as Scrivito.Link;
    const title = link?.title() as string;
    return title || 'Navigationslink';
  },
});

Scrivito.provideEditingConfig(NavigationEntryId, {
  title: 'Navigationspunkt',
  properties: [
    NavigationEntryAttrs.LABEL,
    NavigationEntryAttrs.IMAGE,
    NavigationEntryAttrs.IMAGE_POSITION_H,
    NavigationEntryAttrs.IMAGE_POSITION_V,
    NavigationEntryAttrs.CHILDREN1,
    NavigationEntryAttrs.CHILDREN2,
    NavigationEntryAttrs.CHILDREN3,
  ],
  attributes: {
    [NavigationEntryAttrs.LABEL]: { title: 'Label' },
    [NavigationEntryAttrs.IMAGE]: { title: 'Bild' },
    [NavigationEntryAttrs.IMAGE_POSITION_H]: {
      title: 'Horizontaler Bild-Fokus',
      values: [
        { value: NavigationImagePositions.LEFT, title: 'Links' },
        { value: NavigationImagePositions.CENTER, title: 'Mittig' },
        { value: NavigationImagePositions.RIGHT, title: 'Rechts' },
      ],
    },
    [NavigationEntryAttrs.IMAGE_POSITION_V]: {
      title: 'Vertikaler Bild-Fokus',
      values: [
        { value: NavigationImagePositions.TOP, title: 'Oben' },
        { value: NavigationImagePositions.CENTER, title: 'Mittig' },
        { value: NavigationImagePositions.BOTTOM, title: 'Unten' },
      ],
    },
    [NavigationEntryAttrs.CHILDREN1]: { title: 'Spalte 1' },
    [NavigationEntryAttrs.CHILDREN2]: { title: 'Spalte 2' },
    [NavigationEntryAttrs.CHILDREN3]: { title: 'Spalte 3' },
  },
  initialContent: {
    [NavigationEntryAttrs.IMAGE_POSITION_H]: NavigationImagePositions.CENTER,
    [NavigationEntryAttrs.IMAGE_POSITION_V]: NavigationImagePositions.CENTER,
  },
  titleForContent: (obj) => {
    return obj.get(NavigationEntryAttrs.LABEL) as string;
  },
  validations: [
    [
      NavigationEntryAttrs.LABEL,
      (value: string | null): ValidationInfo => checkValue(value, Message.LABEL_FIELD_MISSING),
    ],
  ],
});

Scrivito.provideEditingConfig(SubNavigationEntryId, {
  title: 'Navigationsunterpunkt',
  properties: [NavigationEntryAttrs.LABEL, NavigationEntryAttrs.LINK, NavigationEntryAttrs.CHILDREN],
  attributes: {
    [NavigationEntryAttrs.LABEL]: { title: 'Label' },
    [NavigationEntryAttrs.LINK]: { title: 'Link (optional)' },
    [NavigationEntryAttrs.CHILDREN]: { title: 'Kindelemente' },
  },
  titleForContent: (obj) => {
    return obj.get(NavigationEntryAttrs.LABEL) as string;
  },
  validations: [
    [
      NavigationEntryAttrs.LABEL,
      (value: string | null): ValidationInfo => checkValue(value, Message.LABEL_FIELD_MISSING),
    ],
  ],
});
