import * as Scrivito from 'scrivito';
import { HomePageId } from '../../pages/home-page/home-page-definitions';
import { LandingPageId } from '../../pages/landing-page/landing-page-definitions';
import { LinkListWidgetAttributes, LinkListWidgetId } from './link-list-widget-definitions';

export const LinkListWidgetClass = Scrivito.provideWidgetClass(LinkListWidgetId, {
  attributes: {
    [LinkListWidgetAttributes.TITLE]: 'string',
    [LinkListWidgetAttributes.LINKS]: 'linklist',
  },
  onlyInside: [HomePageId, LandingPageId],
});

export type LinkListWidget = InstanceType<typeof LinkListWidgetClass>;
