import * as Scrivito from 'scrivito';
import React, { MouseEventHandler } from 'react';
import styles from './navigation-header-meta.module.scss';
import { NavigationAttributes } from '../../../../../pages/home-page/home-page-navigation';
import classNames from 'classnames';
import { useLanguageSwitcherInfo } from '../../../../../utils/scrivito/language-versions';

interface NavigationHeaderMetaProps {
  small: boolean;
  afterClick: () => void;
}

export const NavigationHeaderMeta: React.FC<NavigationHeaderMetaProps> = Scrivito.connect(({ small, afterClick }) => {
  const languageSwitcher = useLanguageSwitcherInfo();
  const root = Scrivito.Obj.root();
  const links = (root?.get(NavigationAttributes.META_NAVIGATION) as Scrivito.Link[]) ?? [];
  const showLanguageSwitcher = !!root?.get(NavigationAttributes.SHOW_LANGUAGE_SWITCHER);

  const onClick: MouseEventHandler = (e): void => {
    const pointerEvent = e.nativeEvent as PointerEvent;
    const isMouse = pointerEvent.pointerId > 0;
    if (isMouse) {
      const element = e.target as HTMLElement;
      if (element.tagName === 'A') {
        element?.blur();
      } else {
        element?.parentElement?.blur();
      }
    }
    afterClick();
  };

  return (
    <div className={classNames(styles.NavigationHeaderMeta, { [styles.Small]: small })}>
      <div className={styles.MetaLinksContainer}>
        {links.map((link) => {
          const label = link.title() as string;
          return (
            <Scrivito.LinkTag key={label} to={link} title={label} onClick={onClick}>
              <span className={styles.MetaLabel}>{label}</span>
              <span className={styles.MetaLabelPlaceholder}>{label}</span>
            </Scrivito.LinkTag>
          );
        })}
        {showLanguageSwitcher && languageSwitcher && (
          <Scrivito.LinkTag to={languageSwitcher.link} title={languageSwitcher.label} onClick={onClick}>
            <span className={styles.MetaLabel}>{languageSwitcher.label}</span>
            <span className={styles.MetaLabelPlaceholder}>{languageSwitcher.label}</span>
          </Scrivito.LinkTag>
        )}
      </div>
    </div>
  );
});
