import * as Scrivito from 'scrivito';
import classNames from 'classnames';
import styles from './landing-navigation-header-link.module.scss';
import { linkStripHash } from '../../../../../utils/scrivito';

interface NavigationHeaderLinkProps {
  element: Scrivito.Link;
}

export const LandingNavigationHeaderLink: React.FC<NavigationHeaderLinkProps> = Scrivito.connect(({ element }) => {
  const page = Scrivito.currentPage();
  const to = linkStripHash(page, element);

  // Navigation link
  const label = element.title() || 'not_set';

  return (
    <Scrivito.LinkTag key={label} to={to} title={label} className={classNames(styles.LandingNavigationHeaderLink)}>
      <span title={label}>{label}</span>
    </Scrivito.LinkTag>
  );
});
