import * as Scrivito from 'scrivito';
import { basePageEditingConfig } from '../base-page';
import { ContentPageId } from './content-page-definitions';
import { heroAttributes, heroProperties } from '../properties/hero';
import { scheduleAttributes, scheduleProperties } from '../properties/schedule';

Scrivito.provideEditingConfig(ContentPageId, {
  ...basePageEditingConfig,
  title: 'Inhaltsseite',
  propertiesGroups: [
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(basePageEditingConfig.propertiesGroups as Array<any>),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(heroProperties as Array<any>),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(scheduleProperties as Array<any>),
  ],
  attributes: {
    ...basePageEditingConfig.attributes,
    ...(heroAttributes as object),
    ...scheduleAttributes,
  },
  hideInSelectionDialogs: false,
});
