import { SimpleChoice } from './../../utils/objects/enums.utils';
import * as Scrivito from 'scrivito';
import { VideoObjectAttributes, VideoObjectId } from './video-object-definitions';

export const VideoObjectClass = Scrivito.provideObjClass(VideoObjectId, {
  attributes: {
    [VideoObjectAttributes.TITLE]: 'string',
    [VideoObjectAttributes.TAGS]: 'stringlist',
    [VideoObjectAttributes.BLOB]: 'binary',
    [VideoObjectAttributes.VISIBLE_IN_SEARCH]: [
      'enum',
      {
        values: Object.values(SimpleChoice),
      },
    ],
  },
});

export type VideoObject = InstanceType<typeof VideoObjectClass>;
