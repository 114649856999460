import * as Scrivito from 'scrivito';
import {
  VideoWidgetAttributes,
  VideoWidgetBubbleColor,
  VideoWidgetId,
  VideoWidgetOrientation,
  VideoWidgetPosition,
} from './video-widget-definitions';
import { spacingClassAttributes } from '../../utils/scrivito/spacing-definitions';
import { VideoObjectId } from '../../objects';

export const VideoWidgetClass = Scrivito.provideWidgetClass(VideoWidgetId, {
  attributes: {
    [VideoWidgetAttributes.VIDEO]: ['reference', { only: [VideoObjectId] }],
    [VideoWidgetAttributes.URL]: 'string',
    [VideoWidgetAttributes.CAPTION]: 'html',
    [VideoWidgetAttributes.POSITION]: ['enum', { values: Object.values(VideoWidgetPosition) }],
    [VideoWidgetAttributes.ORIENTATION]: ['enum', { values: Object.values(VideoWidgetOrientation) }],
    [VideoWidgetAttributes.COOKIE_CONSENT]: 'boolean',
    [VideoWidgetAttributes.BUBBLE]: ['enum', { values: Object.values(VideoWidgetBubbleColor) }],
    ...spacingClassAttributes(),
  },
});

export type VideoWidget = InstanceType<typeof VideoWidgetClass>;
