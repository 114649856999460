import { FormGroupWidgetId } from './../form-group-widget/form-group-widget-definitions';
import * as Scrivito from 'scrivito';
import { FormWidgetId, FormWidgetAttributes } from './form-widget-definitions';
import { spacingClassAttributes } from '../../utils/scrivito/spacing-definitions';
import { FormActionId } from '../../objects';
import { FormSpecialSubmissionErrorWidgetId } from '../form-special-submission-error-widget';
import { FormInteractiveLogicWidgetId } from '../form-interactive-logic-widget';
import { FormInteractiveEmailWidgetId } from '../form-interactive-email-widget';

export const FormWidgetClass = Scrivito.provideWidgetClass(FormWidgetId, {
  attributes: {
    [FormWidgetAttributes.FORM_ACTION]: ['reference', { only: FormActionId }],
    [FormWidgetAttributes.SUBMIT_BUTTON_TEXT]: 'string',
    [FormWidgetAttributes.SUBMIT_BUTTON_ENABLED]: 'boolean',
    [FormWidgetAttributes.ELEMENTS]: ['widgetlist', { only: FormGroupWidgetId }],
    [FormWidgetAttributes.SUCCESS_PAGE]: 'link',
    [FormWidgetAttributes.ERROR_MESSAGE]: 'string',
    [FormWidgetAttributes.SUBMISSION_ERROR]: 'string',
    [FormWidgetAttributes.SPECIAL_SUBMISSION_ERRORS]: ['widgetlist', { only: FormSpecialSubmissionErrorWidgetId }],
    [FormWidgetAttributes.URL_PARAMETERS_TO_SEND]: 'stringlist',
    [FormWidgetAttributes.INTERACTIVE_LOGIC]: [
      'widgetlist',
      { only: [FormInteractiveLogicWidgetId, FormInteractiveEmailWidgetId] },
    ],
    [FormWidgetAttributes.HIDDEN_FORM_DATA]: 'string',
    [FormWidgetAttributes.ELEMENT_ID]: 'string',
    ...spacingClassAttributes(),
  },
  extractTextAttributes: [FormWidgetAttributes.ELEMENTS],
});

export type SectionWidget = InstanceType<typeof FormWidgetClass>;
