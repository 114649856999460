import React from 'react';
import * as Scrivito from 'scrivito';
import { breakpoints } from '../../../../utils/scrivito/breakpoints';
import { ImageWidgetBubbleColor } from '../../../../widgets/image-widget';
import { ResponsiveImageComponent } from './responsive-image';

interface ResponsiveImageSizeProps {
  binary: Scrivito.Binary;
  caption?: string | React.ReactElement;
  width?: number;
  meta?: { width: number; height: number };
  link?: Scrivito.Link;
  bubble?: ImageWidgetBubbleColor;
  scalable?: boolean;
}

export interface ImageWrapperProps {
  image: React.ReactElement;
}

export type ResponsiveImageProps = React.ImgHTMLAttributes<HTMLImageElement> &
  Omit<ImageWrapperProps, 'image'> &
  ResponsiveImageSizeProps;

/**
 * Img Element Control which takes position and ratio as extra attributes different from an img tag
 * and passes everything else down. It optimizes the image for the current screen size.
 *
 * @param binary The Scrivito.Binary of the image
 * @param rest Any attribute that can be passed down to an img tag
 */
export const ResponsiveImage: React.FC<ResponsiveImageProps> = Scrivito.connect(
  ({ binary, caption, width, meta, ...rest }) => {
    const sources: Array<{ url: string; width: number }> = [];
    Object.values(breakpoints).forEach((breakpoint) => {
      sources.push({ url: binary.optimizeFor({ width: breakpoint }).url(), width: breakpoint });
    });

    // Return figure with caption
    return (
      <ResponsiveImageComponent
        alt={rest.alt}
        title={rest.title}
        sources={sources}
        caption={caption}
        width={width}
        meta={meta}
        link={rest.link}
        bubble={rest.bubble}
        scalable={rest.scalable}
      />
    );
  }
);
