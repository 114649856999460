import * as Scrivito from 'scrivito';
import { NavigationAttributes, NavigationEntryAttrs } from '../../pages/home-page/home-page-navigation';
import { useMemo } from 'react';
import { Hierarchy } from './scrivito-link-definitions';

/**
 * Returns the link title for the given link object.
 * If the title()-Field is filled, it returns the content of the title field regardless if the link
 * is an internal or external link.
 * If the link is internal and the title field is empty, the method returns the title of the linked Obj.
 * If a fallback is provided and the above did not return anything, the method returns the fallback string
 * or if the fallback is not provided, it returns the URL for external links.
 * If no URL or Object has been defined as link target, the fallback will be returned if provided.
 * Otherwise, returns the string '<Undefined>'.
 * @param link The Scrivito.Link Object.
 * @param fallback The fallback to use if the link title can not be determined
 * @returns The link title or a fallback.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getLinkTitle(link: Scrivito.Link | null, fallback?: string): any {
  if (link) {
    // Title field is filled - return the title in any case.
    const title = link.title();
    if (title && title !== '') {
      return title;
    }

    // Internal links - Title of the linked object/page is returned
    if (link.isInternal()) {
      const linkObj = link.obj();

      if (linkObj) {
        const linkObjTitle = linkObj.get('title');

        if (linkObjTitle && linkObjTitle !== '') {
          return linkObjTitle;
        }
      }
    }

    // Return the fallback or the link url if the fallback is not set.
    return fallback ? fallback : link.url() || '';
  }

  return fallback;
}

/**
 * This functions takes the current page and a link and checks if the link points to the current page.
 * ATTENTION: This function needs to be run within a Scrivito context.
 * Scrivito.load is not used, so it keeps a synchronous function, which can be used within the render function
 * of a component, e.g. to set a CSS class.
 * @param currentPage Return value of Scrivito.currentPage()
 * @param link The link to check of type Scrivito.Link
 */
export function isLinkCurrentPage(currentPage: Scrivito.Obj | null, link: Scrivito.Link | undefined): boolean {
  if (!link) {
    return false;
  }

  if (link.isInternal()) {
    if (link.hash() && window.location.hash && '#' + link.hash() !== window.location.hash) {
      return false;
    }

    const pageId = currentPage?.id();
    const linkPageId = link.obj()?.id();
    if (linkPageId === pageId) {
      if (link.hash()) {
        const linkHash = '#' + link.hash();
        const currentHash = window.location.hash;
        return linkHash === currentHash;
      } else {
        return !window.location.hash;
      }
    }
    return false;
  }

  const httpParts = window.location.href.split(/https?:\/\//);
  const fullUrl = '/' + httpParts[httpParts.length - 1].split('/').splice(1).join('/');
  const linkPath = link.url();
  return !!fullUrl && fullUrl === linkPath;
}

/**
 * This page returns a new link just with the hash part of the url, when the link is on the current page.
 * E.g. when the link is /example#hash, and you are on /example, it will return a link just with #hash.
 * The reason for using this method is, a link with the page in it causes following faulty behaviour:
 * you click on a link, it scrolls to this section, then when you scroll and click on the link again, it won't work.
 * @param currentPage Scrivito.currentPage()
 * @param link The link, where the hash part should be stripped of.
 */
export function linkStripHash(currentPage: Scrivito.Obj | null, link: Scrivito.Link): Scrivito.Link {
  if (link.isInternal()) {
    const pageID = currentPage?.id();
    const linkID = link.obj()?.id();
    const linkHash = link.hash();
    if (pageID && linkHash && pageID === linkID) {
      return new Scrivito.Link({ url: '#' + linkHash });
    }
  } else {
    const currentUrl = window.location.pathname;
    const linkUrl = link.url() as string;
    const match = /^(.+)(#.+)$/.exec(linkUrl);
    if (match && match[1] === currentUrl) {
      return new Scrivito.Link({ url: match[2] });
    }
  }

  return link;
}

/**
 * getChildrenFromWidget receives a Navigation Widget and extracts its children, a list of Navigation Widgets.
 * It doesn't matter if its a NavigationWidget or a SubNavigationWidget.
 * It always returns a list.
 * @param widget A navigation widget
 */
function getChildrenFromWidget(widget: Scrivito.Widget): Scrivito.Widget[] {
  const children = widget.get(NavigationEntryAttrs.CHILDREN) ?? [];
  const children1 = widget.get(NavigationEntryAttrs.CHILDREN1) ?? [];
  const children2 = widget.get(NavigationEntryAttrs.CHILDREN2) ?? [];
  const children3 = widget.get(NavigationEntryAttrs.CHILDREN3) ?? [];

  return [
    ...(children as Scrivito.Widget[]),
    ...(children1 as Scrivito.Widget[]),
    ...(children2 as Scrivito.Widget[]),
    ...(children3 as Scrivito.Widget[]),
  ];
}

/**
 * This function returns an array with the navigation elements hierarchically sorted by the current page.
 * e.g. if your on "Service > Overview > Information", you get a list of 3 widgets:
 * [Scrivito.Widget("Service"), Scrivito.Widget("Overview"), Scrivito.Widget("Information")]
 * @param currentPage Instance of Scrivito.currentPage()
 * @param navigation A list of the top navigation widgets
 * @param parents The parents from the navigation, those will be added to the result
 */
function getCurrentNavigationHierarchy(
  currentPage: Scrivito.Obj | null,
  navigation: Scrivito.Widget[],
  parents: Scrivito.Widget[] = []
): Scrivito.Widget[] {
  if (!currentPage) {
    return [];
  }

  for (const nav of navigation) {
    const link = nav.get(NavigationEntryAttrs.LINK) as Scrivito.Link;
    const children = getChildrenFromWidget(nav);

    if (children.length) {
      const ret = getCurrentNavigationHierarchy(currentPage, children, [...parents, nav]);
      if (ret.length) {
        return ret;
      }
    }

    if (isLinkCurrentPage(currentPage, link)) {
      return [...parents, nav];
    }
  }

  return [];
}

/**
 * A react hook which returns an object with the navigation elements hierarchically sorted by the current page.
 * e.g. if your on "Service > Overview > Information", you get a list of 3 widgets:
 * [Scrivito.Widget("Service"), Scrivito.Widget("Overview"), Scrivito.Widget("Information")]
 * The widget list is stored in the attribute "widgets", while the function "contains" helps to check if a link
 * is contained in the list
 */
export function useHierarchy(): Hierarchy {
  const currentPage = Scrivito.currentPage();
  const currentPageId = currentPage?.id();
  const root = Scrivito.Obj.root();
  const navigation = root?.get(NavigationAttributes.NAVIGATION) as Scrivito.Widget[];
  const navAvailable = !!navigation;

  /*
   * Optimization decision
   * This use Memo only has currentPageId as dependency for optimization reasons.
   * When changing the page, the currentPageId changes and triggers the recalculation of the hierarchy.
   * Don't use currentPage, because it always triggers the recalculation (unnecessarily).
   * Neither use navigation, because it is an array of re-fetched objects, which also triggers the recalculation.
   * The case "currentPageId keeps the same, but navigation changes" only happens, when the editor is changing
   * the navigation while a user browses the same page. This is an edge case which doesn't require immediate update.
   */
  return useMemo(() => {
    const widgets = getCurrentNavigationHierarchy(currentPage, navigation ?? []);

    const contains = (widget: Scrivito.Widget | undefined): boolean => {
      if (widget) {
        for (const navWidget of widgets) {
          if (widget.id() === navWidget.id()) {
            return true;
          }
        }
      }
      return false;
    };

    return { widgets, contains };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [currentPageId, navAvailable, window.location.href]);
}

/**
 * Returns an external Scrivito.Link to the given URL with the given title.
 * @param url The url for the link
 * @param title The title for the link
 */
export const createExternalLink = (url: string, title: string): Scrivito.Link => {
  return new Scrivito.Link({
    rel: 'noopener',
    target: '_blank',
    title,
    url,
  });
};

/**
 * Returns a link URL from the given Scrivito.Link
 * @param link The Scrivito.Link
 */
export const getLinkUrl = (link: Scrivito.Link): string | null => {
  if (link) {
    if (link.isExternal() && link.url()) {
      return link.url() as string;
    } else {
      return Scrivito.urlFor(link);
    }
  }
  return null;
};
