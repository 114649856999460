import * as Scrivito from 'scrivito';
import { HomePageId } from '../../pages/home-page/home-page-definitions';
import { LandingPageId } from '../../pages/landing-page/landing-page-definitions';
import { ClosingLineWidgetAttributes, ClosingLineWidgetId } from './closing-line-widget-definitions';

export const ClosingLineWidgetClass = Scrivito.provideWidgetClass(ClosingLineWidgetId, {
  attributes: {
    [ClosingLineWidgetAttributes.INFO_LINE]: 'string',
    [ClosingLineWidgetAttributes.LIST_NORMAL]: 'linklist',
    [ClosingLineWidgetAttributes.LIST_IMPORTANT]: 'linklist',
  },
  onlyInside: [HomePageId, LandingPageId],
});

export type ClosingLineWidget = InstanceType<typeof ClosingLineWidgetClass>;
