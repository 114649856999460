import {
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableProps,
  TableRow,
} from '@mui/material';
import classNames from 'classnames';
import styles from './table.module.scss';

export type TableCellAlignment = TableCellProps['align'];
export type TableCellVariant = TableCellProps['variant'];

export interface TableCellComponentProps {
  key: string;
  content: JSX.Element;
  width?: number;
  align: TableCellAlignment;
  variant: TableCellVariant;
}

export interface TableRowComponentProps {
  id: string;
  cells: Array<TableCellComponentProps>;
}

export interface TableComponentProps extends TableProps {
  headers: Array<TableCellComponentProps>;
  // Whether header row is visible, default is visible
  showHeader?: boolean;
  rows?: Array<TableRowComponentProps>;
  widthPercentages?: number[];
  label?: string;
  fullWidth?: boolean;
  manualHyphens?: boolean;
}

export const TableComponent: React.FC<TableComponentProps> = ({
  label,
  rows = [],
  headers,
  widthPercentages,
  fullWidth,
  showHeader,
  manualHyphens,
}) => {
  const width = (index: number): string | undefined => (widthPercentages ? `${widthPercentages[index]}%` : undefined);

  return (
    <TableContainer
      className={classNames(styles.TableContainer, {
        [styles.MaxWidth]: !fullWidth,
        [styles.SeparateHeader]: showHeader,
        [styles.ManualHyphens]: manualHyphens,
      })}
    >
      <Table aria-label={label}>
        {showHeader ? (
          <TableHead>
            <TableRow>
              {headers.map((cell, index) => {
                return (
                  <TableCell key={cell.key} align={cell.align} style={{ width: width(index) }}>
                    {cell.content}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : undefined}
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              {row.cells.map((cell, index) => (
                <TableCell key={cell.key} align={cell.align} style={{ width: width(index) }}>
                  {cell.content}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
