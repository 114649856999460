import { AccordionElementWidget } from '../accordion-element-widget/accordion-element-widget-class';
import * as Scrivito from 'scrivito';
import { AccordionWidgetAttributes, AccordionWidgetId } from './accordion-widget-definitions';
import { spacingAttributes, spacingContent, spacingGroup } from '../../utils/scrivito/spacing-definitions';
import { WidgetTitle } from '../../utils/translations';
import AccordionThumbnail from '../../assets/thumbnails/accordion.svg';

Scrivito.provideEditingConfig(AccordionWidgetId, {
  title: WidgetTitle.ACCORDION,
  thumbnail: AccordionThumbnail,
  properties: [AccordionWidgetAttributes.ELEMENTS],
  propertiesGroups: [spacingGroup],
  attributes: {
    [AccordionWidgetAttributes.ELEMENTS]: {
      title: 'Elemente',
    },
    ...spacingAttributes,
  },
  initialContent: {
    [AccordionWidgetAttributes.ELEMENTS]: [
      new AccordionElementWidget({
        headline: 'Überschrift für ein Akkordeon-Element',
        text: 'Platzhalter-Text für ein Akkordeon-Element',
      }),
    ],
    ...spacingContent,
  },
});
