import * as Scrivito from 'scrivito';
import { DropdownWidgetAttributes } from './dropdown-widget-definitions';
import { DropdownWidget, DropdownWidgetClass } from './dropdown-widget-class';
import { DropdownItemWidgetAttributes } from '../dropdown-item-widget/dropdown-item-widget-definitions';
import { DropdownComponent } from '../../components/controls/dropdown/dropdown';
import { FormElementBaseAttributes } from '../form-widget';
import { useField, useFormikContext } from 'formik';
import { invalidMessageForWidget } from '../shared/form/form-controls-helper-methods';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from '../form-widget/FormContext';

/**
 * Properties for a single dropdown item
 */
export interface DropdownItemProps {
  value: string;
  label: string;
}

export const DropdownWidgetComponent: React.FC<{ widget: DropdownWidget }> = ({ widget }) => {
  const disabled = widget.get(DropdownWidgetAttributes.DISABLED) as boolean;
  const placeholder = widget.get(DropdownWidgetAttributes.PLACEHOLDER) ?? '';
  const name = widget.get(FormElementBaseAttributes.NAME);
  const optional = widget.get(FormElementBaseAttributes.OPTIONAL) ?? '';
  const label = widget.get(DropdownWidgetAttributes.LABEL);

  const { setFieldValue, getFieldMeta, handleBlur } = useFormikContext();
  const formContext = useFormContext();
  const [field, meta] = useField(name);

  const [formattedItemArray, setFormattedItemArray] = useState<DropdownItemProps[]>([]);

  const defaultValueWidget = useMemo(() => {
    const items: Scrivito.Widget[] = widget.get(DropdownWidgetAttributes.ITEMS);
    const defaultValue = widget.get(DropdownWidgetAttributes.DEFAULT_VALUE);

    let defaultValueWidget;
    const tmpFormattedItemArray: DropdownItemProps[] = [];

    for (const item of items) {
      const itemValue = item.get(DropdownItemWidgetAttributes.VALUE) as string;
      const itemLabel = item.get(DropdownItemWidgetAttributes.LABEL) as string;

      const itemProps = { value: itemValue, label: itemLabel };
      tmpFormattedItemArray.push(itemProps);

      if (itemValue === defaultValue) {
        defaultValueWidget = itemProps;
      }
    }

    setFormattedItemArray(tmpFormattedItemArray);

    return defaultValueWidget;
  }, [widget]);

  useEffect(() => {
    if (defaultValueWidget) {
      setFieldValue(field.name, defaultValueWidget.value);
    }
  }, [defaultValueWidget, field.name, setFieldValue]);

  if (formContext.isHiddenElement(name)) {
    return null;
  }

  const onChange = (data: DropdownItemProps): void => {
    setFieldValue(field.name, data.value);
  };

  return (
    <Scrivito.WidgetTag style={{ paddingTop: 0, paddingBottom: 0 }}>
      <DropdownComponent
        onValueChange={onChange}
        name={name}
        items={formattedItemArray}
        disabled={disabled}
        placeholder={placeholder}
        defaultValue={defaultValueWidget}
        label={label}
        error={meta.touched && !getFieldMeta(field.name).value}
        errorMessage={invalidMessageForWidget(widget)}
        onBlur={handleBlur}
        optional={optional}
      />
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(DropdownWidgetClass, DropdownWidgetComponent);
