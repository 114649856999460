import * as Scrivito from 'scrivito';
import { SocialMediaWidget, SocialMediaWidgetClass } from './social-media-widget-class';
import React from 'react';
import { SocialMediaWidgetAttributes } from './social-media-widget-definitions';
import { SocialMediaLinkComponent } from '../../components/controls/social-media-link/social-media-link';
import styles from './social-media-widget.module.scss';
import classNames from 'classnames';
import { spacingClassName } from '../../utils/scrivito/spacing-definitions';

export const SocialMediaWidgetComponent: React.FC<{ widget: SocialMediaWidget }> = ({ widget }) => {
  const links = widget.get(SocialMediaWidgetAttributes.TYPE);

  return (
    <Scrivito.WidgetTag>
      <div className={classNames(styles.SocialMediaWidget, spacingClassName(widget))}>
        {links.map((link: SocialMediaWidgetAttributes, index: number) => {
          const scrivitoLink = (widget.get(link) as Scrivito.Link) || null;
          return <SocialMediaLinkComponent key={index} type={link} link={scrivitoLink} />;
        })}
      </div>
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(SocialMediaWidgetClass, SocialMediaWidgetComponent);
