import * as Scrivito from 'scrivito';
import { SwitchPage, SwitchPageObjectClass } from './switch-page-class';
import { SwitchPageAttributes } from './switch-page-definitions';
import { PageTemplate } from '../../components/pages/page-template/page-template';
import { ReactComponent as Rocket } from '../../assets/img/rheinbahn-rocket.svg';
import React, { useEffect } from 'react';
import styles from './switch-page.module.scss';
import { getLinkUrl } from '../../utils/scrivito';
import { useTranslation } from 'react-i18next';

export const SwitchPageComponent: React.FC<{ page: SwitchPage }> = ({ page }) => {
  const links = page.get(SwitchPageAttributes.LINKS) as Scrivito.Link[];
  const defaultLink = page.get(SwitchPageAttributes.DEFAULT) as Scrivito.Link;
  const { t } = useTranslation();

  useEffect(() => {
    let redirected = false;
    if (defaultLink !== null) {
      // Check if the user agent matches the device list
      for (const link of links) {
        const title = link.title();
        const url = getLinkUrl(link);
        if (title && url !== null) {
          const regex = new RegExp(title, 'i');
          if (regex.test(navigator.userAgent)) {
            window.location.href = `${url}`;
            redirected = true;
            break;
          }
        }
      }

      // Fallback when no user agent matches
      if (!Scrivito.isInPlaceEditingActive() && !redirected) {
        const url = getLinkUrl(defaultLink);
        if (url !== null) {
          window.location.href = `${url}`;
          redirected = true;
        }
      }

      // If no redirection works, redirect to the home page
      if (!redirected) {
        window.location.href = '/';
      }
    }
  }, [defaultLink, links]);

  return (
    <PageTemplate className={styles.SwitchPage}>
      <h1>{t('redirect.redirectText')}</h1>
      <Rocket />
    </PageTemplate>
  );
};

Scrivito.provideComponent(SwitchPageObjectClass, SwitchPageComponent);
