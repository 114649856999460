import * as Scrivito from 'scrivito';
import { SearchPageAttributes, SearchResultPageId } from './search-result-page-definitions';
import { BasePageAttributes, basePageEditingConfig } from '../base-page';

Scrivito.provideEditingConfig(SearchResultPageId, {
  ...basePageEditingConfig,
  title: 'Suchergebnisse',
  hideInSelectionDialogs: true,
  properties: [BasePageAttributes.TITLE, SearchPageAttributes.FILTERS],
  attributes: {
    [BasePageAttributes.TITLE]: {
      title: 'Titel',
      description: 'Seitentitel',
    },
    [SearchPageAttributes.FILTERS]: {
      title: 'Such-Filter',
      description: 'Filter für die Suche',
    },
  },
});
