import * as Scrivito from 'scrivito';
import { WizardWidgetAttributes, WizardWidgetId } from './wizard-widget-definitions';
import { HelperText, WidgetTitle } from '../../utils/translations';
import Thumbnail from '../../assets/thumbnails/wizard.svg';
import { WizardPageWidgetAttributes, WizardPageWidgetClass } from '../wizard-page-widget';

const minRequiredItems = 2;

Scrivito.provideEditingConfig(WizardWidgetId, {
  title: WidgetTitle.WIZARD,
  thumbnail: Thumbnail,
  properties: Object.values(WizardWidgetAttributes),
  attributes: {
    [WizardWidgetAttributes.PAGES]: {
      title: 'Seiten',
    },
  },
  validations: [],
  initialContent: {
    [WizardWidgetAttributes.PAGES]: [...Array(minRequiredItems)].map(
      (_, index) =>
        new WizardPageWidgetClass({
          [WizardPageWidgetAttributes.TITLE]: `${HelperText.WIZARD_LABEL_INITIAL_VALUE} ${index + 1}`,
        })
    ),
  },
});
