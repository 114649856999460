import * as Scrivito from 'scrivito';
import { CountdownUnits, CountdownWidgetAttributes, CountdownWidgetId } from './countdown-widget-definitions';
import { spacingClassAttributes } from '../../utils/scrivito/spacing-definitions';

export const CountdownWidgetClass = Scrivito.provideWidgetClass(CountdownWidgetId, {
  attributes: {
    [CountdownWidgetAttributes.COUNTDOWN_TITLE]: 'string',
    [CountdownWidgetAttributes.COUNTDOWN_TITLE_FINISHED]: 'string',
    [CountdownWidgetAttributes.COUNTDOWN_DATE]: 'date',
    [CountdownWidgetAttributes.COUNTDOWN_UNIT]: [
      'enum',
      {
        values: Object.values(CountdownUnits),
      },
    ],
    [CountdownWidgetAttributes.IMAGE]: ['reference', { only: 'Image' }],
    [CountdownWidgetAttributes.IMAGE_ALT]: 'string',
    [CountdownWidgetAttributes.IMAGE_TITLE]: 'string',
    [CountdownWidgetAttributes.BUTTON_TOGGLE]: 'boolean',
    [CountdownWidgetAttributes.BUTTON_LINK]: 'link',
    [CountdownWidgetAttributes.ID]: 'string',
    ...spacingClassAttributes(),
  },
});

export type CountdownWidget = InstanceType<typeof CountdownWidgetClass>;
