import * as Scrivito from 'scrivito';
import { BasePage } from '../base-page';
import { LandingPageAttributes, LandingPageId } from './landing-page-definitions';
import { RootPageAttributes } from '../base-page/root-page-definitions';
import { navigationClassAttributes } from './landing-page-navigation';
import { ImageObjectId, VideoObjectId } from '../../objects';
import { LandingSubPageId } from '../landing-sub-page/landing-sub-page-definitions';
import { error404ClassAttributes } from '../home-page/home-page-404';
import { scheduleClassAttributes } from '../properties/schedule';
import { footerWidgetClassAttributes } from '../home-page/home-page-footer';

export const LandingPageObjectClass = Scrivito.provideObjClass(LandingPageId, {
  extend: BasePage,
  onlyAsRoot: true,
  onlyChildren: [LandingSubPageId],
  attributes: {
    [RootPageAttributes.PATH]: 'string',
    [RootPageAttributes.BASE_SITE_ID]: 'string',
    [LandingPageAttributes.DOWNLOAD_BUTTON_TEXT]: 'string',
    [LandingPageAttributes.DOWNLOAD_BUTTON_TEXT_SHORT]: 'string',
    [LandingPageAttributes.DOWNLOAD_BUTTON_LINK]: 'link',
    [LandingPageAttributes.HERO_MEDIA]: ['reference', { only: [VideoObjectId, ImageObjectId] }],
    [LandingPageAttributes.HERO_MEDIA_ALT]: 'string',
    [LandingPageAttributes.HERO_MEDIA_TITLE]: 'string',
    ...(navigationClassAttributes as object),
    ...(scheduleClassAttributes as object),
    ...(error404ClassAttributes as object),
    ...(footerWidgetClassAttributes as object),
  },
  extractTextAttributes: [],
});

export type LandingPage = InstanceType<typeof LandingPageObjectClass>;
