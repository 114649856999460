import * as Scrivito from 'scrivito';
import { columnCountClassAttributes } from '../../utils/scrivito/column-count-definitions';
import { CheckboxWidgetId } from '../checkbox-widget/checkbox-widget-definitions';
import { FormColumnWidgetId } from '../form-column-widget';
import { FormGroupWidgetId } from '../form-group-widget';
import {
  getFormElementBaseClassAttributes,
  groupLabelFormElementBaseClassAttributes,
} from '../form-widget/form-widget-definitions';

import { CheckboxGroupWidgetAttributes, CheckboxGroupWidgetId } from './checkbox-group-widget-definitions';
import { WizardPageWidgetId } from '../wizard-page-widget';

export const CheckboxGroupWidgetClass = Scrivito.provideWidgetClass(CheckboxGroupWidgetId, {
  attributes: {
    ...(groupLabelFormElementBaseClassAttributes as object),
    [CheckboxGroupWidgetAttributes.ITEMS]: ['widgetlist', { only: CheckboxWidgetId }],
    ...(columnCountClassAttributes as object),
    ...getFormElementBaseClassAttributes(),
  },
  onlyInside: [FormGroupWidgetId, FormColumnWidgetId, WizardPageWidgetId],
});

export type CheckboxGroupWidget = InstanceType<typeof CheckboxGroupWidgetClass>;
