import classNames from 'classnames';
import { ReactElement, useEffect, useRef, useState } from 'react';
import * as Scrivito from 'scrivito';
import { HeroColor } from '../../../pages/properties/hero';
import { hasManualHyphens, removeHtmlTagsExceptBreaks } from '../../../utils/general.utils';
import { JourneyPlanner } from '../../efa/journey-planner/journey-planner';
import styles from './hero.module.scss';
import { Typography, TypographyVariant } from '@mui/material';
import { ResponsiveImageContextProvider } from '../../controls/image/responsive-image/responsive-image.context';
import { removeIds } from '../../../utils/string';

/**
 * Properties for the hero component
 */
export interface HeroProps {
  title: string;
  titleElement?: TypographyVariant;
  subtitle?: string;
  linkText?: string;
  link?: Scrivito.Link;
  image: ReactElement | null;
  imageHideMobile?: boolean;
  color: HeroColor;
  breadcrumb: ReactElement;
  showJourneyPlanner: boolean;
  showJourneyText?: boolean;
  smallFont?: boolean;
}

/**
 * Renders a hero component
 */
export const HeroComponent: React.FC<HeroProps> = (props) => {
  const [focused, setFocused] = useState<boolean>(false);
  const heroRef = useRef<HTMLElement | null>(null);
  // remove tags from `html` attributes
  const titleSanitized = removeIds(removeHtmlTagsExceptBreaks(props.title));
  const subTitleSanitized = props.subtitle && removeIds(removeHtmlTagsExceptBreaks(props.subtitle));
  const [journeyPlanner, setJourneyPlanner] = useState(false);

  // Load journey planner first when all material ui components are loaded
  useEffect(() => {
    if (!journeyPlanner) {
      setJourneyPlanner(true);
    }
  }, [journeyPlanner, setJourneyPlanner]);

  const heroText = (
    <>
      <div className={classNames(styles.TextWrapper, { [styles.Small]: props.smallFont })}>
        <Typography
          variant={props.titleElement ?? 'h2'}
          className={classNames({ ManualHyphens: hasManualHyphens(titleSanitized) })}
          dangerouslySetInnerHTML={{ __html: titleSanitized }}
        />
        {subTitleSanitized && (
          <p
            className={classNames({ ManualHyphens: hasManualHyphens(subTitleSanitized) })}
            dangerouslySetInnerHTML={{ __html: subTitleSanitized }}
          />
        )}
      </div>
      {props.link && props.linkText && (
        <Scrivito.LinkTag className={styles.Link} to={props.link}>
          {props.linkText}
        </Scrivito.LinkTag>
      )}
    </>
  );

  let heroImage = <div className={styles.HeroImage}>{props.image}</div>;
  if (props.link && props.linkText) {
    heroImage = (
      <Scrivito.LinkTag className={classNames(styles.HeroImage, styles.Clickable)} tabIndex="-1" to={props.link}>
        {props.image}
      </Scrivito.LinkTag>
    );
  }

  return (
    <section
      className={classNames(styles.Hero, {
        [styles.MobilePlannerAbove]: props.showJourneyPlanner && props.showJourneyText,
      })}
      data-test-id={`hero`}
      data-testid={`hero`}
      ref={heroRef}
    >
      <div
        id="heroSpeechBubble"
        className={classNames([styles.SpeechBubble, styles[props.color]], {
          [styles.JourneyPlanner]: props.showJourneyPlanner,
          [styles.ShowJourneyText]: props.showJourneyText,
        })}
      >
        {!props.showJourneyPlanner && heroText}
        {props.showJourneyPlanner && journeyPlanner && (
          <JourneyPlanner onDropdownOpen={(value: boolean): void => setFocused(value)} />
        )}
      </div>
      <div
        data-testid={'heroImage'}
        className={classNames(styles.HeroImageColumn, {
          [styles.IsFocused]: focused,
          [styles.HideMobile]: props.imageHideMobile,
          [styles.ShowJourneyText]: props.showJourneyText,
        })}
      >
        <ResponsiveImageContextProvider loading="eager">{heroImage}</ResponsiveImageContextProvider>
        {props.showJourneyText && (
          <div className={classNames(styles.SpeechBubble, styles.HeroImageInfo, styles[props.color])}>{heroText}</div>
        )}
      </div>
      {props.breadcrumb}
    </section>
  );
};
