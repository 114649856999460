import * as Scrivito from 'scrivito';
import { useTranslation } from 'react-i18next';

export interface LanguageInfo {
  label: string;
  short: string;
  link: Scrivito.Link;
}

/**
 * This function extracts the language information of the given page.
 * @param page The page, where you want the language information from
 * @param t t from the useTranslation hook
 */
function getLanguageInfoFromPage(page: Scrivito.Obj, t: (key: string) => string): LanguageInfo {
  const languageText = page.language() === 'en' ? t('languageSwitcher.english') : t('languageSwitcher.german');
  const languageShort = page.language() ?? 'de';
  return {
    label: languageText,
    short: languageShort,
    link: new Scrivito.Link({ obj: page }),
  };
}

/**
 * This function searches a language variant from the given page and returns its language information.
 * If no variant cant be found, this function returns undefined.
 * @param page The page, where you want the language variant information from
 * @param t t from the useTranslation hook
 */
function getLanguageInfoFromVariant(page: Scrivito.Obj, t: (key: string) => string): LanguageInfo | undefined {
  const versions = page.versionsOnAllSites() ?? [];
  for (const version of versions) {
    if (version.id() !== page.id()) {
      return getLanguageInfoFromPage(version, t);
    }
  }
  return undefined;
}

/**
 * This function builds a list of language variants for the current page.
 * The result includes the current pages language information.
 */
export function useLanguageVersions(): LanguageInfo[] {
  const { t } = useTranslation();
  const page = Scrivito.currentPage() ?? Scrivito.Obj.root();
  const result: LanguageInfo[] = [];

  if (page) {
    result.push(getLanguageInfoFromPage(page, t));
    const variant = getLanguageInfoFromVariant(page, t);
    if (variant) {
      result.push(variant);
    }
  }

  return result;
}

/**
 * This hook builds the information for a language switcher button.
 * It looks up if there is a version of the current page in a different language.
 * If not, it gives a link to the home page of the other language.
 */
export function useLanguageSwitcherInfo(): LanguageInfo | undefined {
  const { t } = useTranslation();
  const page = Scrivito.currentPage();
  const root = Scrivito.Obj.root();

  if (page) {
    const result = getLanguageInfoFromVariant(page, t);
    if (result) {
      return result;
    }
  }

  if (root) {
    const result = getLanguageInfoFromVariant(root, t);
    if (result) {
      return result;
    }
  }

  // In this case, neither the current page, nor the root page has a different version
  return undefined;
}
