import * as Scrivito from 'scrivito';
import { BasePage, BasePageAttributes } from '../base-page';
import { OverviewPageAttributes, OverviewPageId } from './overview-page-definitions';
import { heroClassAttributes } from '../properties/hero';
import { scheduleClassAttributes } from '../properties/schedule';
import { PressReleaseId } from '../press-release';

export const OverviewPageObjectClass = Scrivito.provideObjClass(OverviewPageId, {
  extend: BasePage,
  attributes: {
    [OverviewPageAttributes.HEADLINE]: 'string',
    [OverviewPageAttributes.ELEMENTS]: ['enum', { values: [PressReleaseId] }],
    [OverviewPageAttributes.FILTERS]: 'stringlist',
    ...(heroClassAttributes as object),
    ...(scheduleClassAttributes as object),
  },
  extractTextAttributes: [BasePageAttributes.BODY, OverviewPageAttributes.HEADLINE],
});

export type OverviewPage = InstanceType<typeof OverviewPageObjectClass>;
