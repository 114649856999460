import * as Scrivito from 'scrivito';
import { SchedulingWidgetAttributes, SchedulingWidgetId } from './scheduling-widget-definitions';
import { AccordionWidgetId } from '../accordion-widget';
import { BigTeaserWidgetId } from '../big-teaser-widget';
import { CircleTeaserGroupWidgetId } from '../circle-teaser-group-widget';
import { DownloadWidgetId } from '../download-widget';
import { FormWidgetId } from '../form-widget';
import { ImageWidgetId } from '../image-widget';
import { InfoTeaserGroupWidgetId } from '../info-teaser-group-widget';
import { SliderWidgetId } from '../slider-widget';
import { ColumnWidgetId } from '../column-widget';
import { TableWidgetId } from '../table-widget';
import { AppointmentWidgetId } from '../appointment-widget';
import { TextWidgetId } from '../text-widget';
import { VideoWidgetId } from '../video-widget';
import { HeadlineWidgetId } from '../headline-widget';
import { IntroTextWidgetId } from '../intro-text-widget';
import { TextTeaserGroupWidgetId } from '../text-teaser-group-widget';

const SchedulingChildWidgetIds: string[] = [
  AccordionWidgetId,
  BigTeaserWidgetId,
  CircleTeaserGroupWidgetId,
  DownloadWidgetId,
  FormWidgetId,
  ImageWidgetId,
  InfoTeaserGroupWidgetId,
  IntroTextWidgetId,
  SliderWidgetId,
  ColumnWidgetId,
  TableWidgetId,
  AppointmentWidgetId,
  TextWidgetId,
  TextTeaserGroupWidgetId,
  VideoWidgetId,
  HeadlineWidgetId,
];

export const SchedulingWidgetClass = Scrivito.provideWidgetClass(SchedulingWidgetId, {
  attributes: {
    [SchedulingWidgetAttributes.VISIBLE_FROM]: 'date',
    [SchedulingWidgetAttributes.VISIBLE_UNTIL]: 'date',
    [SchedulingWidgetAttributes.CHILDREN]: ['widgetlist', { only: SchedulingChildWidgetIds }],
  },
  extractTextAttributes: [SchedulingWidgetAttributes.CHILDREN],
});

export type SchedulingWidget = InstanceType<typeof SchedulingWidgetClass>;
