import * as Scrivito from 'scrivito';
import { basePageEditingConfig } from '../base-page';
import { LandingPageAttributes, LandingPageId } from './landing-page-definitions';
import { RootPageAttributes, rootPageInitialContent } from '../base-page/root-page-definitions';
import { HelperText, Message } from '../../utils/translations';
import { navigationAttributes, NavigationAttributes } from './landing-page-navigation';
import { ValidationInfo } from '../../utils/type.utils';
import {
  FooterWidgetAttributes,
  footerWidgetAttributesDefinition,
  footerWidgetInitialContent,
} from '../home-page/home-page-footer';
import { Error404Attributes, error404Attributes, error404InitialContent } from '../home-page/home-page-404';
import { scheduleAttributes, scheduleProperties } from '../properties/schedule';

Scrivito.provideEditingConfig(LandingPageId, {
  ...basePageEditingConfig,
  title: 'Landing Page',
  hideInSelectionDialogs: false,
  properties: [
    RootPageAttributes.PATH,
    RootPageAttributes.BASE_SITE_ID,
    ...(basePageEditingConfig.properties as string[]),
  ],
  propertiesGroups: [
    ...(basePageEditingConfig.propertiesGroups as Array<never>),
    {
      title: 'Navigation',
      key: 'page-navigation',
      properties: [
        LandingPageAttributes.DOWNLOAD_BUTTON_TEXT,
        LandingPageAttributes.DOWNLOAD_BUTTON_TEXT_SHORT,
        LandingPageAttributes.DOWNLOAD_BUTTON_LINK,
        ...Object.values(NavigationAttributes),
      ],
    },
    {
      title: 'Footer',
      key: 'page-footer',
      properties: Object.values(FooterWidgetAttributes),
    },
    {
      title: 'Hero',
      key: 'page-hero',
      properties: [
        LandingPageAttributes.HERO_MEDIA,
        LandingPageAttributes.HERO_MEDIA_ALT,
        LandingPageAttributes.HERO_MEDIA_TITLE,
      ],
    },
    ...(scheduleProperties as Array<never>),
    {
      title: 'Fehlerseite',
      key: 'page-errors',
      properties: Object.values(Error404Attributes),
    },
  ],
  attributes: {
    [RootPageAttributes.PATH]: {
      title: 'Pfad',
      description: HelperText.ROOT_PAGE_PATH,
    },
    [RootPageAttributes.BASE_SITE_ID]: {
      title: 'SiteId',
      description: HelperText.ROOT_PAGE_BASE_SITE_ID,
    },
    [LandingPageAttributes.HERO_MEDIA]: {
      title: 'Hero (Bild oder Video)',
    },
    [LandingPageAttributes.HERO_MEDIA_ALT]: {
      title: 'Alternativ-Text',
    },
    [LandingPageAttributes.HERO_MEDIA_TITLE]: {
      title: 'Titel (optional)',
    },
    [LandingPageAttributes.DOWNLOAD_BUTTON_TEXT]: {
      title: 'Buttontext',
    },
    [LandingPageAttributes.DOWNLOAD_BUTTON_TEXT_SHORT]: {
      title: 'Kürzerer Buttontext für mobile Ansicht',
    },
    [LandingPageAttributes.DOWNLOAD_BUTTON_LINK]: {
      title: 'Link',
    },
    ...basePageEditingConfig.attributes,
    ...navigationAttributes,
    ...scheduleAttributes,
    ...error404Attributes,
    ...footerWidgetAttributesDefinition,
  },
  validations: [
    [
      LandingPageAttributes.HERO_MEDIA_ALT,
      (value: string, { obj }: { obj: Scrivito.Obj }): ValidationInfo => {
        const hero = obj.get(LandingPageAttributes.HERO_MEDIA);
        const heroIsSet = !!hero;
        const heroAltIsNotSet = !value;

        if (heroIsSet && heroAltIsNotSet) {
          return {
            severity: 'error',
            message: Message.ALT_TEXT_MISSING,
          };
        }
        return undefined;
      },
    ],
  ],

  initialContent: {
    ...rootPageInitialContent,
    ...basePageEditingConfig.initialContent,
    ...error404InitialContent,
    ...footerWidgetInitialContent,
  },
});
