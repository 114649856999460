import * as Scrivito from 'scrivito';
import { ColorBoxWidgetAttributes, ColorBoxWidgetColors, ColorBoxWidgetId } from './color-box-widget-definitions';
import { ColumnWidgetId } from '../column-widget';
import { TextWidgetId } from '../text-widget';
import { ImageWidgetId } from '../image-widget';
import { spacingClassAttributes } from '../../utils/scrivito/spacing-definitions';
import { IconListWidgetId } from '../icon-list-widget';
import { TableWidgetId } from '../table-widget';
import { VideoWidgetId } from '../video-widget';

export const ColorBoxWidgetClass = Scrivito.provideWidgetClass(ColorBoxWidgetId, {
  attributes: {
    [ColorBoxWidgetAttributes.COLOR]: ['enum', { values: Object.values(ColorBoxWidgetColors) }],
    [ColorBoxWidgetAttributes.ELEMENTS]: [
      'widgetlist',
      {
        only: [ColumnWidgetId, TextWidgetId, ImageWidgetId, IconListWidgetId, TableWidgetId, VideoWidgetId],
      },
    ],
    [ColorBoxWidgetAttributes.ELEMENT_ID]: 'string',
    ...spacingClassAttributes(),
  },
});

export type ColorBoxWidget = InstanceType<typeof ColorBoxWidgetClass>;
