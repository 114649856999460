import * as Scrivito from 'scrivito';
import { RadioButtonWidgetClass } from './radio-button-widget-class';

/**
 * Placeholder Radio Button Widget (used as Radio Group Widget)
 */
export const RadioButtonWidgetComponent: React.FC<Scrivito.WidgetComponentProps> = () => {
  return <Scrivito.WidgetTag />;
};

Scrivito.provideComponent(RadioButtonWidgetClass, RadioButtonWidgetComponent);
