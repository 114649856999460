import React, { FormEvent, ReactElement, useState } from 'react';
import styles from './journey-planner.module.scss';
import { AutoSuggestComponent } from '../autosuggest/autosuggest';
import { DropdownComponent } from '../../controls/dropdown/dropdown';
import { Collapse } from '@mui/material';
import { ReactComponent as IconChevron } from '../../../assets/icons/icon-chevron-left.svg';
import { ButtonComponent } from '../../controls/button/button';
import classNames from 'classnames';
import { DropdownItemProps } from '../../../widgets/dropdown-widget';
import { EfaStopSuggestion } from '../api/efa-api.utils';
import { ReactComponent as IconArrows } from '../../../assets/icons/icon-arrows.svg';
import { useTranslation } from 'react-i18next';

interface TripPlannerProps {
  showOptions: boolean;
  setShowOptions: (value: boolean) => void;
  setFrom: (value: string | EfaStopSuggestion) => void;
  from: string | EfaStopSuggestion;
  setTo: (value: string | EfaStopSuggestion) => void;
  to: string | EfaStopSuggestion;
  dropdownItems: DropdownItemProps[];
  dropdownValue: DropdownItemProps;
  setDropdownValue: (value: DropdownItemProps) => void;
  setAccessible: (value: boolean) => void;
  onSubmit: (event: FormEvent<HTMLFormElement> | React.MouseEvent | React.KeyboardEvent) => void;
  options: ReactElement;
}

export const TripPlanner: React.FC<TripPlannerProps> = ({
  showOptions,
  setShowOptions,
  setFrom,
  from,
  setTo,
  to,
  dropdownItems,
  dropdownValue,
  setDropdownValue,
  // TODO will be used later with app.rheinbahn.de
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setAccessible,
  onSubmit,
  options,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string>('');

  return (
    <form onSubmit={onSubmit}>
      <div className={styles.FromToContainer}>
        <AutoSuggestComponent
          className={'JourneyPlannerFrom'}
          id={'journey-planner-from'}
          label={t('journeyPlanner.from')}
          locationFinder={true}
          onFocus={(): void => {
            setShowOptions(true);
            setError('');
          }}
          onChange={(value): void => {
            setFrom(value);
            setError('');
          }}
          value={from as EfaStopSuggestion}
          onError={(error): void => setError(error)}
          scrollIntoView={true}
        />
        <AutoSuggestComponent
          className={'JourneyPlannerTo'}
          id={'journey-planner-to'}
          label={t('journeyPlanner.to')}
          onFocus={(): void => setShowOptions(true)}
          onChange={(value): void => setTo(value)}
          value={to as EfaStopSuggestion}
          scrollIntoView={true}
        />
        <button
          type={'button'}
          className={styles.SwitchButton}
          onClick={(): void => {
            setFrom(to);
            setTo(from);
          }}
          title={t('journeyPlanner.switchTitle')}
        >
          <IconArrows />
        </button>
      </div>
      {error && (
        <p data-testid="errorMessage" className={styles.ErrorMessage}>
          {error}
        </p>
      )}
      <Collapse in={showOptions} className={classNames(styles.Options)}>
        <DropdownComponent
          hiddenLabel={t('journeyPlanner.travelTime')}
          onValueChange={(value): void => setDropdownValue(value)}
          defaultValue={dropdownValue}
          items={dropdownItems}
          className={'DateTimeSelector'}
        />
        {options}
        <div className={styles.JourneyPlannerOptions} data-testid={'options'}>
          {/* TODO will be used later with app.rheinbahn.de */}
          {/*<CheckboxComponent*/}
          {/*  label={t('journeyPlanner.accessible')}*/}
          {/*  hideOptionalLabel={true}*/}
          {/*  onChange={(_, checked): void => setAccessible(checked)}*/}
          {/*/>*/}
          <a
            role={'button'}
            onClick={onSubmit}
            onKeyDown={(event: React.KeyboardEvent): void => {
              if (event.key === 'Enter') {
                onSubmit(event);
              }
            }}
            tabIndex={0}
          >
            {t('journeyPlanner.detailSearch')}
            <IconChevron />
          </a>
        </div>
      </Collapse>
      <ButtonComponent
        component="button"
        href={null}
        color={'info'}
        small={false}
        content={t('journeyPlanner.showTrips')}
      />
    </form>
  );
};
