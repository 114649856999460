import * as Scrivito from 'scrivito';
import React, { MouseEventHandler } from 'react';
import { NavigationEntryAttrs } from '../../../../../pages/home-page/home-page-navigation';
import classNames from 'classnames';
import styles from './navigation-header-link.module.scss';

interface NavigationHeaderLinkProps {
  element: Scrivito.Widget;
  selected: string;
  small: boolean;
  setSelected: (selection: Scrivito.Widget | undefined) => void;
}

export const NavigationHeaderLink: React.FC<NavigationHeaderLinkProps> = Scrivito.connect(
  ({ element, selected, small, setSelected }) => {
    const link = element.get(NavigationEntryAttrs.LINK) as Scrivito.Link | undefined;

    // Navigation link
    if (link) {
      const label = link.title() || 'not_set';

      return (
        <Scrivito.LinkTag
          key={label}
          to={link}
          title={label}
          className={classNames(styles.NavigationHeaderLink, {
            [styles.Selected]: label === selected,
            [styles.Small]: small,
          })}
          onClick={(): void => setSelected(undefined)}
        >
          <span title={label}>{label}</span>
        </Scrivito.LinkTag>
      );
    }

    // Navigation with children
    const label = element.get(NavigationEntryAttrs.LABEL) as string;

    const onClick: MouseEventHandler = (e): void => {
      e.preventDefault();
      setSelected(element);
    };

    return (
      <a
        key={label}
        href="#"
        className={classNames(styles.NavigationHeaderLink, {
          [styles.Selected]: label === selected,
          [styles.Small]: small,
        })}
        onClick={onClick}
      >
        <span title={label}>{label}</span>
      </a>
    );
  }
);
