import * as Scrivito from 'scrivito';
import { DropdownWidgetId } from '../dropdown-widget/dropdown-widget-definitions';
import { DropdownItemWidgetAttributes, DropdownItemWidgetId } from './dropdown-item-widget-definitions';

export const DropdownItemWidget = Scrivito.provideWidgetClass(DropdownItemWidgetId, {
  attributes: {
    [DropdownItemWidgetAttributes.VALUE]: 'string',
    [DropdownItemWidgetAttributes.LABEL]: 'string',
  },
  onlyInside: [DropdownWidgetId],
  extractTextAttributes: [DropdownItemWidgetAttributes.VALUE, DropdownItemWidgetAttributes.LABEL],
});

export default DropdownItemWidget;
