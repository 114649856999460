import * as Scrivito from 'scrivito';
import { ButtonGroupWidgetAttributes, ButtonGroupWidgetId } from './button-group-widget-definitions';
import { spacingAttributes, spacingContent, spacingGroup } from '../../utils/scrivito/spacing-definitions';
import { WidgetTitle } from '../../utils/translations';
import ButtonGroupThumbnail from '../../assets/thumbnails/button-group.svg';
import {
  ButtonWidgetAttributes,
  ButtonWidgetClass,
  ButtonWidgetIcons,
  ButtonWidgetKinds,
  ButtonWidgetSizes,
} from '../button-widget';

const minRequiredItems = 2;

Scrivito.provideEditingConfig(ButtonGroupWidgetId, {
  title: WidgetTitle.BUTTON_GROUP,
  thumbnail: ButtonGroupThumbnail,
  properties: [ButtonGroupWidgetAttributes.BUTTONS],
  propertiesGroups: [spacingGroup],
  attributes: {
    [ButtonGroupWidgetAttributes.BUTTONS]: {
      title: 'Buttons',
    },
    ...spacingAttributes,
  },
  validations: [],
  initialContent: {
    [ButtonGroupWidgetAttributes.BUTTONS]: [...Array(minRequiredItems)].map(
      (_, index) =>
        new ButtonWidgetClass({
          [ButtonWidgetAttributes.LINK]: new Scrivito.Link({
            url: '#',
            title: `Button ${index + 1}`,
          }),
          [ButtonWidgetAttributes.KIND]: ButtonWidgetKinds.PRIMARY,
          [ButtonWidgetAttributes.SIZE]: ButtonWidgetSizes.LARGE,
          [ButtonWidgetAttributes.ICON]: ButtonWidgetIcons.NONE,
        })
    ),
    ...spacingContent,
  },
});
