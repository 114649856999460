import * as Scrivito from 'scrivito';
import { AccordionWidgetId } from '../accordion-widget/accordion-widget-definitions';
import { AccordionElementWidgetAttributes, AccordionElementWidgetId } from './accordion-element-widget-definitions';

export const AccordionElementWidget = Scrivito.provideWidgetClass(AccordionElementWidgetId, {
  attributes: {
    [AccordionElementWidgetAttributes.HEADLINE]: 'string',
    [AccordionElementWidgetAttributes.TEXT]: 'html',
    [AccordionElementWidgetAttributes.ID]: 'string',
  },
  onlyInside: [AccordionWidgetId],
  extractTextAttributes: [AccordionElementWidgetAttributes.HEADLINE, AccordionElementWidgetAttributes.TEXT],
});

export default AccordionElementWidget;
