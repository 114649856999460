import React, { ElementType, ReactElement } from 'react';
import styles from './download-box.module.scss';

import { Button, Typography } from '@mui/material';
import { ReactComponent as IconDownload } from '../../../assets/icons/icon-download.svg';
import classNames from 'classnames';
import { splitFileName } from '../../../utils/general.utils';
import { renderFileIcon } from '../../../utils/icons.utils';
import { HeadlineWidgetVariant } from '../../../widgets/headline-widget';
import { LoadingButton } from '../../controls/loading-button';

export interface DownloadBoxObject {
  name: string;
  onClick: (event: React.MouseEvent) => void;
}

export interface DownloadBoxProps {
  className: string;
  headline: ReactElement;
  headlineComponent: ElementType;
  downloadObjects: DownloadBoxObject[];
  onButtonClick: () => void;
  buttonText: ReactElement;
  enableSingleFileDownload?: boolean;
  id?: string;
  loading?: boolean;
  done?: boolean;
}

export const DownloadBoxComponent: React.FC<DownloadBoxProps> = (props) => {
  const fileList: ReactElement[] = [];
  // Add all files to the fileList and create a html <li> item
  if (props.downloadObjects.length > 0) {
    props.downloadObjects.forEach((file: DownloadBoxObject, index: number) => {
      const fileName = file.name;
      const fileNameSplit = splitFileName(fileName);
      // Render when file name is loaded
      if (fileNameSplit.name) {
        fileList.push(
          <li key={index}>
            <Button
              disabled={!props.enableSingleFileDownload && props.downloadObjects.length === 1}
              title={fileName}
              className={styles.DownloadItem}
              type={'button'}
              onClick={file.onClick}
            >
              <span className={styles.FileIcon}>{renderFileIcon(fileName)}</span>
              <span className={styles.FileName} tabIndex={-1}>
                {fileNameSplit.name}
              </span>
              <span className={styles.FileExtension}>.{fileNameSplit.extension}</span>
              {(props.enableSingleFileDownload || props.downloadObjects.length) !== 1 && (
                <span className={styles.DownloadIcon}>
                  <IconDownload />
                </span>
              )}
            </Button>
          </li>
        );
      }
    });
  }

  return (
    <div className={classNames(styles.DownloadWidget, props.className)} id={props.id}>
      <Typography variant={HeadlineWidgetVariant.L} component={props.headlineComponent as ElementType}>
        {props.headline}
      </Typography>
      {fileList.length > 0 && (
        <ul className={classNames({ [styles.OnlyChild]: fileList.length === 1 })}>
          {fileList.map((file) => {
            return file;
          })}
        </ul>
      )}
      <LoadingButton
        loading={props.loading || false}
        content={<>{props.buttonText}</>}
        small={false}
        color={'info'}
        onClick={props.onButtonClick}
        disabled={props.downloadObjects.length < 1}
        type="button"
        done={props.done || false}
      />
    </div>
  );
};
