export const CountdownWidgetId = 'CountdownWidget';

export enum CountdownWidgetAttributes {
  COUNTDOWN_TITLE = 'countdownTitle',
  COUNTDOWN_TITLE_FINISHED = 'countdownTitleFinished',
  COUNTDOWN_DATE = 'countdownDate',
  COUNTDOWN_UNIT = 'countdownUnit',
  IMAGE = 'image',
  IMAGE_ALT = 'imageAltText',
  IMAGE_TITLE = 'imageTitle',
  BUTTON_TOGGLE = 'buttonToggle',
  BUTTON_LINK = 'buttonLink',
  ID = 'countdownId',
}

export enum CountdownUnits {
  TAGE = 'Tage',
  TAGEN = 'Tagen',
}
