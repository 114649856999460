import * as Scrivito from 'scrivito';
import { BasePageAttributes, basePageEditingConfig } from '../base-page';
import { PressReleaseAttributes, PressReleaseId } from './press-release-definitions';
import { HelperText, PageTitle } from '../../utils/translations';
import { HeadlineWidgetTag } from '../../widgets/headline-widget';
import { scheduleProperties } from '../properties/schedule';

Scrivito.provideEditingConfig(PressReleaseId, {
  ...basePageEditingConfig,
  title: PageTitle.PRESS_RELEASE,
  propertiesGroups: [
    {
      title: 'Pressemitteilung',
      key: 'page-press-release',
      properties: Object.values(PressReleaseAttributes),
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(basePageEditingConfig.propertiesGroups as Array<any>),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(scheduleProperties as Array<any>),
  ],
  properties: [...(basePageEditingConfig.properties as string[])],
  attributes: {
    [PressReleaseAttributes.DATE]: {
      title: 'Datum',
    },
    [PressReleaseAttributes.SUBLINE]: {
      title: 'Sublinie',
      description: 'Beschreibung oberhalb der Überschrift',
    },
    [PressReleaseAttributes.HEADLINE]: {
      title: 'Überschrift',
    },
    [PressReleaseAttributes.HEADLINE_ELEMENT]: {
      title: HelperText.HEADLINE_HIERARCHY,
      values: [
        { value: HeadlineWidgetTag.H1, title: '<h1>' },
        { value: HeadlineWidgetTag.H2, title: '<h2>' },
      ],
    },
    [PressReleaseAttributes.BUTTONS]: {
      title: 'Buttons',
    },
  },
  initialContent: {
    [PressReleaseAttributes.DATE]: new Date(),
    [PressReleaseAttributes.HEADLINE_ELEMENT]: HeadlineWidgetTag.H1,
    [BasePageAttributes.TAGS]: ['Presse'],
  },
  hideInSelectionDialogs: false,
});
