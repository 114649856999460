export const ButtonWidgetId = 'ButtonWidget';

export enum ButtonWidgetAttributes {
  LINK = 'link',
  KIND = 'kind',
  SIZE = 'size',
  ICON = 'icon',
}

export enum ButtonWidgetKinds {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  CTA = 'cta',
}

export enum ButtonWidgetSizes {
  LARGE = 'large',
  SMALL = 'small',
}

// Icons must be copied from the Icons enum (icons.utils.tsx),
// since typescript does not allow using computed string values in enums.
// See https://github.com/microsoft/TypeScript/issues/40793
export enum ButtonWidgetIcons {
  NONE = 'none',
  FILE = 'file',
  FILE_ARCHIVE = 'file-archive',
  FILE_PDF = 'file-pdf',
  FILE_PPT = 'file-ppt',
  FILE_VIDEO = 'file-video',
  FILE_IMAGE = 'file-img',
  FILE_ZIP = 'file-zip',
  SHOPPING_CART = 'shopping-cart',
  TICKET = 'ticket',
  INFO = 'info',
  MAP = 'map',
  TIP = 'tip',
}
