import * as Scrivito from 'scrivito';
import {
  SliderWidgetAttributes,
  SliderWidgetId,
  SlideWidgetAttribute,
  SlideWidgetId,
} from './slider-widget-definitions';
import { TextWidgetId } from '../text-widget';
import { ImageWidgetId } from '../image-widget';
import { VideoWidgetId } from '../video-widget';
import { ColumnWidgetId } from '../column-widget';
import { ButtonWidgetId } from '../button-widget';
import { spacingClassAttributes } from '../../utils/scrivito/spacing-definitions';

export const SliderWidgetClass = Scrivito.provideWidgetClass(SliderWidgetId, {
  attributes: {
    [SliderWidgetAttributes.ARIA_DESCRIPTION]: 'string',
    [SliderWidgetAttributes.SLIDES]: ['widgetlist', { only: [SlideWidgetId] }],
    ...spacingClassAttributes(),
  },
});

export const SlideWidgetClass = Scrivito.provideWidgetClass(SlideWidgetId, {
  attributes: {
    [SlideWidgetAttribute.ARIA_LABEL]: 'string',
    [SlideWidgetAttribute.WIDGETS]: [
      'widgetlist',
      { only: [TextWidgetId, ImageWidgetId, VideoWidgetId, ColumnWidgetId, ButtonWidgetId] },
    ],
  },
  onlyInside: [SliderWidgetId],
});

export type SliderWidget = InstanceType<typeof SliderWidgetClass>;
