import { SimpleChoice } from './../../utils/objects/enums.utils';
import * as Scrivito from 'scrivito';
import { ImageObjectAttributes, ImageObjectId } from './image-object-definitions';
import { ValidationInfo } from '../../utils/type.utils';
import { checkValue } from '../../utils/scrivito/validation';
import { HelperText, Message } from '../../utils/translations';

Scrivito.provideEditingConfig(ImageObjectId, {
  title: 'Bild',
  description: 'Bild mit Metadaten',
  hideInSelectionDialogs: true,
  properties: [
    ImageObjectAttributes.ALTERNATIVE_TEXT,
    ImageObjectAttributes.TITLE,
    ImageObjectAttributes.VISIBLE_IN_SEARCH,
    ImageObjectAttributes.TAGS,
  ],
  attributes: {
    [ImageObjectAttributes.TAGS]: {
      title: 'Tags',
    },
    [ImageObjectAttributes.ALTERNATIVE_TEXT]: {
      title: 'Alternativer Text',
      description: HelperText.IMAGE_ALT,
    },
    [ImageObjectAttributes.TITLE]: {
      title: 'Titel',
      description: HelperText.IMAGE_TITLE,
    },
    [ImageObjectAttributes.VISIBLE_IN_SEARCH]: {
      title: 'Bild über die Suche auffindbar',
      description: HelperText.IMAGE_VISIBLE_IN_SEARCH,
    },
  },
  initialContent: { [ImageObjectAttributes.VISIBLE_IN_SEARCH]: SimpleChoice.NO },
  validations: [
    [
      ImageObjectAttributes.ALTERNATIVE_TEXT,
      (value: string): ValidationInfo => checkValue(value, Message.ALT_TEXT_MISSING, 'warning'),
    ],
  ],
});
