import React, { FormEvent, ReactElement, useState } from 'react';
import styles from './journey-planner.module.scss';
import { AutoSuggestComponent } from '../autosuggest/autosuggest';
import { DropdownComponent } from '../../controls/dropdown/dropdown';
import { ButtonComponent } from '../../controls/button/button';
import { DropdownItemProps } from '../../../widgets/dropdown-widget';
import { EfaStopSuggestion } from '../api/efa-api.utils';
import { useTranslation } from 'react-i18next';

interface DeparturePlannerProps {
  dropdownItems: DropdownItemProps[];
  dropdownValue: DropdownItemProps;
  setDropdownValue: (value: DropdownItemProps) => void;
  setFrom: (value: string | EfaStopSuggestion) => void;
  from: string | EfaStopSuggestion;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  options: ReactElement;
}

export const DepartureFinder: React.FC<DeparturePlannerProps> = ({
  dropdownItems,
  dropdownValue,
  setDropdownValue,
  setFrom,
  from,
  options,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string>('');

  return (
    <form onSubmit={onSubmit}>
      <AutoSuggestComponent
        className={'JourneyPlannerFrom'}
        id={'journey-planner-from-dep'}
        label={t('journeyPlanner.from')}
        locationFinder={true}
        value={from as EfaStopSuggestion}
        onFocus={(): void => setError('')}
        onChange={(value): void => {
          setFrom(value);
          setError('');
        }}
        onError={(error): void => setError(error)}
        scrollIntoView={true}
      />
      {error && (
        <p data-testid="errorMessage" className={styles.ErrorMessage}>
          {error}
        </p>
      )}
      <DropdownComponent
        defaultValue={dropdownValue}
        items={dropdownItems}
        onValueChange={(value): void => setDropdownValue(value)}
        className={'DateTimeSelector'}
      />
      {options}
      <ButtonComponent
        component="button"
        className={'DepartureButton'}
        href={null}
        color={'info'}
        small={false}
        content={t('journeyPlanner.showTrips')}
      />
    </form>
  );
};
