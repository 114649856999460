export const DownloadWidgetId = 'DownloadWidget';

export enum DownloadWidgetAttributes {
  HEADLINE = 'headline',
  HEADLINE_COMPONENT = 'headlineComponent',
  FILES = 'files',
  DOWNLOAD_BUTTON_TEXT = 'downloadButtonText',
  DOWNLOAD_ZIP_NAME = 'downloadZipName',
  ID = 'downloadWidgetId',
}
