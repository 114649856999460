import * as Scrivito from 'scrivito';
import { BasePage, BasePageAttributes } from '../base-page';
import { SearchPageAttributes, SearchResultPageId } from './search-result-page-definitions';

export const SearchResultPageObjectClass = Scrivito.provideObjClass(SearchResultPageId, {
  extend: BasePage,
  attributes: {
    [SearchPageAttributes.FILTERS]: 'stringlist',
    [BasePageAttributes.TITLE]: 'string',
  },
});

export type SearchResultPage = InstanceType<typeof SearchResultPageObjectClass>;
