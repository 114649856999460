import * as Scrivito from 'scrivito';
import { PageTemplate } from '../../components/pages/page-template/page-template';
import { SearchResultPage, SearchResultPageObjectClass } from './search-result-page-class';
import { Search } from '../../components/search';
import { SearchPageAttributes } from './search-result-page-definitions';
import styles from './search-result-page.module.scss';

export const SearchResultPageComponent: React.FC<{ page: SearchResultPage }> = ({ page }) => {
  const urlSearchParams = new URLSearchParams(document.location.search);
  const filterList = page.get(SearchPageAttributes.FILTERS) as string[];
  const params = { q: urlSearchParams.get('q') ?? '', filterList: Array.from(filterList) };

  return (
    <PageTemplate className={styles.SearchResultPageBase}>
      <Search params={params} />
    </PageTemplate>
  );
};

Scrivito.provideComponent(SearchResultPageObjectClass, SearchResultPageComponent);
