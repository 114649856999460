import * as Scrivito from 'scrivito';
import { IconObjectAttributes, IconObjectId } from './icon-object-definitions';

export const IconObjectClass = Scrivito.provideObjClass(IconObjectId, {
  attributes: {
    [IconObjectAttributes.TITLE]: 'string',
    [IconObjectAttributes.TAGS]: 'stringlist',
    [IconObjectAttributes.BLOB]: 'binary',
  },
  extractTextAttributes: [IconObjectAttributes.TITLE],
});

export type IconObject = InstanceType<typeof IconObjectClass>;
