import * as Scrivito from 'scrivito';
import React from 'react';
import { ModalComponent } from './modal';
import { ResponsiveImage } from '../../controls/image/responsive-image/responsive-image.scrivito';
import styles from './modal.module.scss';
import { ModalAttributes } from '../../../pages/home-page/home-page-modal';
import { isDateTimeNow } from '../../../utils/general.utils';

export interface ModalProps {
  /**
   * Root Obj of page.
   */
  root: Scrivito.Obj;
  open: boolean;
  onClose: () => void;
}

export const Modal: React.FC<ModalProps> = Scrivito.connect(({ root, open, onClose }) => {
  const link = root.get(ModalAttributes.LINK) as Scrivito.Link;
  const linkText = link?.title() ?? 'Button';
  const image = root.get(ModalAttributes.IMAGE) as Scrivito.Obj;
  const type = root.get(ModalAttributes.TYPE) as 'info' | 'warning';
  const visibleFrom = (root.get(ModalAttributes.VISIBLE_FROM) as Date) ?? null;
  const visibleUntil = (root.get(ModalAttributes.VISIBLE_UNTIL) as Date) ?? null;
  const isVisible = isDateTimeNow(visibleFrom, visibleUntil);

  let img = null;
  if (image) {
    // Get the binary of the selected image.
    const binary = image.get('blob') as Scrivito.Binary;
    const imgHeight = image.metadata().get('height') as number;
    const imgWidth = image.metadata().get('width') as number;
    img = (
      <ResponsiveImage
        alt={'altText'}
        title={'titleText'}
        binary={binary}
        meta={imgWidth && imgHeight ? { width: imgWidth, height: imgHeight } : undefined}
      />
    );
  }

  if (!isVisible) {
    // Hiding the modal backdrop.
    // It can't be handled in the modal itself, because it is not rendered anymore.
    document.body.classList.remove('modalOpen');
    document.body.classList.remove('modalWarningOpen');
    return <></>;
  }

  return (
    isVisible && (
      <ModalComponent
        open={open}
        title={
          <Scrivito.ContentTag className={styles.Title} content={root} tag={'p'} attribute={ModalAttributes.TITLE} />
        }
        headline={
          <Scrivito.ContentTag
            className={styles.Headline}
            content={root}
            tag={'h2'}
            attribute={ModalAttributes.HEADLINE}
          />
        }
        text={<Scrivito.ContentTag content={root} tag={'p'} attribute={ModalAttributes.TEXT} />}
        linkText={linkText}
        link={link}
        type={type}
        image={img}
        onClose={onClose}
      />
    )
  );
});
