import * as Scrivito from 'scrivito';
import styles from './landing-page-template.module.scss';
import { LandingNavigation } from '../../building-blocks/navigation/landing-navigation/landing-navigation';
import React, { useRef } from 'react';
import classNames from 'classnames';
import { useScrollManager } from '../utils/scroll-manager';
import { LandingPageAttributes } from '../../../pages/landing-page/landing-page-definitions';
import { ImageObjectId } from '../../../objects';
import { LandingPageHero } from '../../building-blocks/landing-page-hero/landing-page-hero';
import { PageMetaData } from '../page-metadata/page-metadata';
import { renderScheduleInfo, ScheduleInfo } from '../../../utils/scrivito/schedule-validation';
import { Footer } from '../../building-blocks/footer/footer.scrivito';

export const LandingPageTemplate: React.FC<{
  children: React.ReactNode;
  scheduleInfo?: ScheduleInfo;
  className?: string;
}> = Scrivito.connect(({ children, scheduleInfo, className }) => {
  const headerRef = useRef<HTMLElement>(null);
  const { noTransitionHeader, reducedHeader, fixedPositionHeader } = useScrollManager(96, headerRef);

  const editMode = Scrivito.isInPlaceEditingActive();
  const page = Scrivito.currentPage();
  let heroComponent: React.ReactNode = null;
  const hero = page?.get(LandingPageAttributes.HERO_MEDIA) as Scrivito.Obj | undefined;
  const heroAlt = page?.get(LandingPageAttributes.HERO_MEDIA_ALT) as string | undefined;
  const heroTitle = page?.get(LandingPageAttributes.HERO_MEDIA_TITLE) as string | undefined;

  if (hero && heroAlt) {
    const url = hero.contentUrl();
    const type: 'image' | 'video' = hero.objClass() === ImageObjectId ? 'image' : 'video';
    heroComponent = <LandingPageHero url={url} type={type} alt={heroAlt} title={heroTitle ?? heroAlt} />;
  }

  return (
    <div className={styles.PageWrapper}>
      <header
        ref={headerRef}
        className={classNames(styles.PageHeader, {
          [styles.Small]: reducedHeader,
          [styles.NoTransition]: noTransitionHeader,
          [styles.PositionFixed]: fixedPositionHeader,
        })}
      >
        <LandingNavigation small={reducedHeader} />
      </header>
      <main className={styles.PageMain}>
        {(editMode || !scheduleInfo || scheduleInfo.visible) && heroComponent}
        {editMode && scheduleInfo && !scheduleInfo.visible && renderScheduleInfo(scheduleInfo)}
        <article className={className}>{children}</article>
      </main>
      <Footer id="skip-link-footer" />
      <PageMetaData />
    </div>
  );
});
