import ToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton';
import styles from './filter-tag.module.scss';

export interface FilterTagComponentProps extends ToggleButtonProps {
  label: string;
  disabled?: boolean;
}

export const FilterTagComponent: React.FC<FilterTagComponentProps> = (props) => {
  return (
    <ToggleButton {...props} className={styles.FilterTag} aria-label={props.label} disabled={props.disabled}>
      <span className={styles.Label}>{props.label}</span>
    </ToggleButton>
  );
};
