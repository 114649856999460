import * as Scrivito from 'scrivito';
import { HelperText, Message, WidgetTitle } from '../../utils/translations';
import { ValidationInfo } from '../../utils/type.utils';
import { AppointmentWidgetAttributes, AppointmentWidgetId } from './appointment-widget-definitions';

Scrivito.provideEditingConfig(AppointmentWidgetId, {
  title: WidgetTitle.APPOINTMENT,
  titleForContent: (widget) => widget.get(AppointmentWidgetAttributes.LABEL) as string,
  properties: Object.values(AppointmentWidgetAttributes),
  attributes: {
    [AppointmentWidgetAttributes.LABEL]: {
      title: HelperText.LABEL,
    },
    [AppointmentWidgetAttributes.LINK]: {
      title: HelperText.LINK,
    },
  },
  validations: [
    [
      AppointmentWidgetAttributes.LABEL,
      (value: string): ValidationInfo => {
        if (value?.length <= 0) {
          return {
            message: Message.LABEL_FIELD_MISSING,
            severity: 'error',
          };
        }
        return undefined;
      },
    ],
    [
      AppointmentWidgetAttributes.LINK,
      (link: Scrivito.Link): ValidationInfo => {
        if (!link) {
          return {
            message: Message.LINK_MISSING,
            severity: 'error',
          };
        }
        if (!link.title() && !link.obj()?.get('title')) {
          return {
            message: Message.LINK_TITLE_MISSING,
            severity: 'error',
          };
        }
        return undefined;
      },
    ],
  ],
  initialContent: {
    [AppointmentWidgetAttributes.LABEL]: HelperText.APPOINTMENT_DEFAULT_LABEL,
    [AppointmentWidgetAttributes.LINK]: new Scrivito.Link({
      url: '#',
      title: HelperText.APPOINTMENT_DEFAULT_LINK_TITLE,
    }),
  },
});
