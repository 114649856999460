import * as Scrivito from 'scrivito';
import { Navigation } from '../../building-blocks/navigation/homepage-navigation/navigation';
import { SkipLinkNavigation } from '../../controls/skip-link-navigation/skip-link-navigation';
import { PageMetaData } from '../page-metadata/page-metadata';
import styles from './page-template.module.scss';
import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Hero } from '../../building-blocks/hero/hero.scrivito';
import { Modal } from '../../building-blocks/modal/modal.scrivito';
import { Footer } from '../../building-blocks/footer/footer.scrivito';
import { renderScheduleInfo, ScheduleInfo } from '../../../utils/scrivito/schedule-validation';
import { PageBanner, PageBannerSpacer } from '../../building-blocks/page-banner/page-banner';
import { useSessionStorage } from '../../../utils/session-storage';
import { usePageBannerInfo } from '../../building-blocks/page-banner/page-banner.helper';
import { ModalAttributes } from '../../../pages/home-page/home-page-modal';
import { useScrollManager } from '../utils/scroll-manager';
import { usePageTracker } from '../../../utils/page-tracker';
import { checkAppView } from '../../../utils/general.utils';

/*
Page structure:
body / the html body of the page
  skip-link-navigation / skip link
  page-wrapper / wraps the content and has a max with of 2400px, bg white
    page-header
      nav-drawer / The drawer for mobile navigation
      nav / The mega menu, bg red
      flyout / When selected a navigation link, bg white
      flyout-backdrop / prevents using the background
    content / the content of the page, bg transparent
      section / for the hero section, bg transparent
      article / content of the page, bg white
    nav-page-drop / for overlap effect, bg red

 */

export const PageTemplate: React.FC<{
  children: React.ReactNode;
  hideBreadcrumb?: boolean;
  scheduleInfo?: ScheduleInfo;
  className?: string;
}> = Scrivito.connect(({ children, hideBreadcrumb, scheduleInfo, className }) => {
  usePageTracker();

  // check if the "appview" parameter is provided in the url or as session cookie
  const isAppView = checkAppView();
  const headerRef = useRef<HTMLElement>(null);
  const [isModalOpen, setModalOpen] = useSessionStorage('is-open');
  const [bannerVisible] = usePageBannerInfo();
  const { noTransitionHeader, reducedHeader, fixedPositionHeader } = useScrollManager(96, headerRef);
  // get root obj of page
  const root = Scrivito.Obj.root();
  // get current page obj
  const currentPage = Scrivito.currentPage();

  // Set appview state in the session storage to keep the view after clicking a link
  useEffect(() => {
    if (isAppView) {
      sessionStorage.setItem('appview', 'true');
    }
  }, [isAppView]);

  useEffect(() => {
    if (root && isModalOpen === null) {
      const hideInitially: boolean = (root?.get(ModalAttributes.HIDE_INITIALLY) as boolean) ?? false;
      setModalOpen(!hideInitially);
    }
  }, [root, isModalOpen, setModalOpen]);

  // App view with reduced page view
  if (isAppView) {
    // add class to body for global adjustments
    document.body.classList.add('AppView');
    return (
      <>
        <main className={classNames(styles.PageMain, styles.PageMainAppView)}>
          <article className={className}>{children}</article>
        </main>
        <PageMetaData />
      </>
    );
  }

  return (
    <>
      {root && <Modal root={root} open={!!isModalOpen} onClose={(): void => setModalOpen(false)} />}
      <SkipLinkNavigation />
      <div className={styles.PageWrapper}>
        <header
          ref={headerRef}
          className={classNames(styles.PageHeader, {
            [styles.Small]: reducedHeader,
            [styles.NoTransition]: noTransitionHeader,
            [styles.PositionFixed]: fixedPositionHeader,
          })}
        >
          <PageBanner hidden={reducedHeader} onClick={(): void => setModalOpen(true)} />
          <Navigation small={reducedHeader} />
        </header>
        <main id="skip-link-main" className={styles.PageMain}>
          <PageBannerSpacer />
          {scheduleInfo && !scheduleInfo.visible && renderScheduleInfo(scheduleInfo)}
          {currentPage && !hideBreadcrumb && <Hero currentPage={currentPage} root={root} />}
          <article className={className}>{children}</article>
        </main>
      </div>
      <Footer id="skip-link-footer" />
      <div
        className={classNames(styles.PageHeaderDrop, {
          [styles.Small]: reducedHeader,
          [styles.NoTransition]: noTransitionHeader,
          [styles.PositionFixed]: fixedPositionHeader,
          [styles.BannerMargin]: bannerVisible,
        })}
      />
      <PageMetaData />
    </>
  );
});
