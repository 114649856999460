import { ThemeOptions } from '@mui/material';
import theme from '../assets/scss/exports.module.scss';

/**
 * Default theme for material ui
 */
export const defaultThemeOptions: ThemeOptions = {
  palette: {
    text: {
      primary: theme.textDefault,
    },
    error: {
      main: theme.interactiveError,
    },
    success: {
      main: theme.interactiveSuccess,
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  shape: {
    borderRadius: parseInt(theme.borderRadius.replace('px', '')),
  },
};
