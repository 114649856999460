import * as Scrivito from 'scrivito';
import { DownloadWidgetAttributes, DownloadWidgetId } from './download-widget-definitions';
import { DownloadObjectId, ImageObjectId, VideoObjectId } from '../../objects';
import { HeadlineWidgetTag } from '../headline-widget';
import { spacingClassAttributes } from '../../utils/scrivito/spacing-definitions';

export const DownloadWidgetClass = Scrivito.provideWidgetClass(DownloadWidgetId, {
  attributes: {
    [DownloadWidgetAttributes.FILES]: ['referencelist', { only: [DownloadObjectId, ImageObjectId, VideoObjectId] }],
    [DownloadWidgetAttributes.HEADLINE]: 'string',
    [DownloadWidgetAttributes.HEADLINE_COMPONENT]: ['enum', { values: Object.values(HeadlineWidgetTag) }],
    [DownloadWidgetAttributes.DOWNLOAD_ZIP_NAME]: 'string',
    [DownloadWidgetAttributes.DOWNLOAD_BUTTON_TEXT]: 'string',
    [DownloadWidgetAttributes.ID]: 'string',
    ...spacingClassAttributes(),
  },
});

export type DownloadWidget = InstanceType<typeof DownloadWidgetClass>;
