export const ColorBoxWidgetId = 'ColorBoxWidget';

export enum ColorBoxWidgetAttributes {
  COLOR = 'color',
  ELEMENTS = 'elements',
  ELEMENT_ID = 'elementId',
}

export enum ColorBoxWidgetColors {
  DARK_GREEN = 'darkGreen',
  DARK_RED = 'darkRed',
  VIOLET = 'violet',
  RED = 'red',
  GRAY = 'gray',
}
