import { FormColumnWidgetId } from './../form-column-widget/form-column-widget-definitions';
import * as Scrivito from 'scrivito';
import { DropdownItemWidgetId } from '../dropdown-item-widget/dropdown-item-widget-definitions';
import { FormGroupWidgetId } from '../form-group-widget/form-group-widget-definitions';
import { DropdownWidgetAttributes, DropdownWidgetId } from './dropdown-widget-definitions';
import { getFormElementBaseClassAttributes } from '../form-widget/form-widget-definitions';
import { WizardPageWidgetId } from '../wizard-page-widget';

export const DropdownWidgetClass = Scrivito.provideWidgetClass(DropdownWidgetId, {
  attributes: {
    [DropdownWidgetAttributes.LABEL]: 'string',
    [DropdownWidgetAttributes.ITEMS]: ['widgetlist', { only: DropdownItemWidgetId }],
    [DropdownWidgetAttributes.DISABLED]: 'boolean',
    [DropdownWidgetAttributes.PLACEHOLDER]: 'string',
    [DropdownWidgetAttributes.DEFAULT_VALUE]: 'string',
    ...getFormElementBaseClassAttributes(),
  },
  onlyInside: [FormGroupWidgetId, FormColumnWidgetId, WizardPageWidgetId],
});

export type DropdownWidget = InstanceType<typeof DropdownWidgetClass>;
