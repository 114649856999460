import * as Scrivito from 'scrivito';
import { HelperText, Message, WidgetTitle } from '../../utils/translations';
import { ValidationInfo } from '../../utils/type.utils';
import { CircleTeaserWidgetAttributes, CircleTeaserWidgetId } from './circle-teaser-widget-definitions';

Scrivito.provideEditingConfig(CircleTeaserWidgetId, {
  title: WidgetTitle.CIRCLE_TEASER,
  properties: Object.values(CircleTeaserWidgetAttributes),
  attributes: {
    [CircleTeaserWidgetAttributes.LINK]: {
      title: HelperText.CIRCLE_TEASER_LINK,
    },
    [CircleTeaserWidgetAttributes.IMAGE]: {
      title: HelperText.CIRCLE_TEASER_IMAGE,
    },
    [CircleTeaserWidgetAttributes.HOVER_TEXT]: {
      title: HelperText.CIRCLE_TEASER_HOVER_TEXT,
    },
  },
  validations: [
    [
      CircleTeaserWidgetAttributes.LINK,
      (link: Scrivito.Obj): ValidationInfo => {
        if (!link) {
          return {
            message: Message.CIRCLE_TEASER_LINK_REQUIRED,
            severity: 'error',
          };
        }
        return undefined;
      },
    ],
    [
      CircleTeaserWidgetAttributes.IMAGE,
      (image: Scrivito.Obj): ValidationInfo => {
        if (!image) {
          return {
            message: Message.CIRCLE_TEASER_IMAGE_REQUIRED,
            severity: 'error',
          };
        }
        return undefined;
      },
    ],
  ],
  initialContent: {
    [CircleTeaserWidgetAttributes.LINK]: new Scrivito.Link({
      url: '#',
      title: 'Überschrift',
    }),
    [CircleTeaserWidgetAttributes.HOVER_TEXT]: HelperText.TEASER_HOVER_TEXT_DEFAULT,
  },
  titleForContent: (obj) => {
    const link = obj.get(CircleTeaserWidgetAttributes.LINK) as Scrivito.Link;
    return link?.title() || 'Circle-Teaser';
  },
});
