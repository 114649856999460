import { useField, useFormikContext } from 'formik';
import * as Scrivito from 'scrivito';
import { UploadButtonFileComponentProps } from '../../components/controls/upload-button-file/upload-button-file';
import { UploadButtonComponent } from '../../components/controls/upload-button/upload-button';
import { getFormikErrorMessage } from '../../utils/formik/get-error';
import { FormElementBaseAttributes } from '../form-widget/form-widget-definitions';
import {
  UploadButtonWidget,
  uploadButtonWidgetAcceptedFileTypesDefault,
  UploadButtonWidgetClass,
} from './upload-button-widget-class';
import { UploadButtonWidgetAttributes, UploadButtonWidgetId } from './upload-button-widget-definitions';
import { FormsAttributes } from '../../pages/home-page/home-page-forms';
import { MAX_SIZE_MULTI_FILES } from '../../utils/general.utils';
import { useFormContext } from '../form-widget/FormContext';

export const UploadButtonWidgetComponent: React.FC<{ widget: UploadButtonWidget }> = ({ widget }) => {
  const accept =
    widget.get(UploadButtonWidgetAttributes.ACCEPT).join(',') || uploadButtonWidgetAcceptedFileTypesDefault;
  const multiple = widget.get(UploadButtonWidgetAttributes.MULTIPLE) || undefined;
  const optional = widget.get(FormElementBaseAttributes.OPTIONAL);
  const label = <Scrivito.ContentTag attribute={UploadButtonWidgetAttributes.LABEL_GROUP} content={widget} />;
  const name = widget.get(FormElementBaseAttributes.NAME);
  const helperText = widget.get(UploadButtonWidgetAttributes.HELPER_TEXT) ?? '';
  // Get the api key from the global forms configuration
  const apiKey = (Scrivito.Obj.root()?.get(FormsAttributes.API_KEY) as string) || '';

  const id = widget.id();

  // use same size as for multiple files
  const maxSize = MAX_SIZE_MULTI_FILES;

  const formContext = useFormContext();
  const { setFieldValue, validateField } = useFormikContext();
  const [, meta] = useField(name);

  if (formContext.isHiddenElement(name)) {
    return null;
  }

  const onChange = (fileMap: Map<string, UploadButtonFileComponentProps>): void => {
    const fileListAsArray = Array.from<UploadButtonFileComponentProps>(fileMap.values()).map((item) => ({
      name: item.hashedName,
      size: item.file.size,
      status: item.status,
    }));

    setFieldValue(name, fileListAsArray);

    // validate again when file list changed
    validateField(name);
  };

  return (
    <Scrivito.WidgetTag>
      <UploadButtonComponent
        id={`${UploadButtonWidgetId}-${id}`}
        label={label}
        required={!optional}
        labelVisible={true}
        error={meta.touched && !!meta.error}
        errorMessage={getFormikErrorMessage(meta.error)}
        name={name}
        maxSize={maxSize}
        onChange={onChange}
        accept={accept}
        multiple={multiple}
        helperText={helperText}
        multipleFileSize={MAX_SIZE_MULTI_FILES}
        apiKey={apiKey}
      />
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(UploadButtonWidgetClass, UploadButtonWidgetComponent);
