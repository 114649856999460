import * as Scrivito from 'scrivito';
import { TextTeaserGroupWidgetId } from '../text-teaser-group-widget/text-teaser-group-widget-definitions';
import { TextTeaserWidgetAttributes, TextTeaserWidgetId } from './text-teaser-widget-definitions';

export const TextTeaserWidgetClass = Scrivito.provideWidgetClass(TextTeaserWidgetId, {
  attributes: {
    [TextTeaserWidgetAttributes.LINK]: 'link',
    [TextTeaserWidgetAttributes.IMAGE]: ['reference', { only: 'Image' }],
    [TextTeaserWidgetAttributes.SUBJECT]: 'html',
    [TextTeaserWidgetAttributes.IMAGE_ALT]: 'string',
    [TextTeaserWidgetAttributes.IMAGE_TITLE]: 'string',
  },
  onlyInside: [TextTeaserGroupWidgetId],
  extractTextAttributes: [TextTeaserWidgetAttributes.SUBJECT],
});

export type TextTeaserWidget = InstanceType<typeof TextTeaserWidgetClass>;
