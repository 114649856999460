export function isCurrentPage(pageId: number, currentId: number): boolean {
  return pageId === currentId;
}

export function isDonePage(pageId: number, currentId: number): boolean {
  return pageId < currentId;
}

export function getNextButtonText(text: string, lastPage: boolean, t: (key: string) => string): string {
  if (text.trim()) {
    return text;
  }
  if (lastPage) {
    return t('component.wizard.buttonFinish');
  }
  return t('component.wizard.buttonNext');
}

export function progressString(currentIndex: number, count: number): string {
  const progress = ((currentIndex + 1) / (count || 1)) * 100;
  return `${progress}%`;
}

export function progressAria(currentIndex: number, count: number): number {
  const progress = ((currentIndex + 1) / (count || 1)) * 100;
  return Math.floor(progress);
}
