import { Message } from '../translations';
import { ValidationInfo, ValidationSeverityLevel, ValidationValue } from '../type.utils';
import * as Scrivito from 'scrivito';
import dayjs from 'dayjs';
import { DATE_FORMAT_INT } from '../general.utils';

export const idAndNameRegex = /^[A-Za-z][A-Za-z0-9-_:.]+$/;

/**
 * Validation function of `linklist` to ensure title is available.
 * For internal links, the internal title is a valid title, too.
 *
 * @param list
 * @returns
 */
export const validateLinkListRequiredTitle = (list: Array<Scrivito.Link>): ValidationInfo => {
  for (const link of list) {
    if (!link) {
      return {
        message: Message.LINK_MISSING,
        severity: 'error',
      };
    }
    if (!link.title() && !link.obj()?.get('title')) {
      return {
        message: Message.LINK_TITLE_MISSING,
        severity: 'error',
      };
    }
  }

  return undefined;
};

/**
 * Checks the value and returns an object or undefined
 * @param value The value to check
 * @param message The error message
 * @param severity The severity level (optional), this is 'error' by default
 */
export const checkValue = (
  value: ValidationValue,
  message: Message,
  severity?: ValidationSeverityLevel
): ValidationInfo => {
  if (!value) {
    return {
      message,
      severity: severity || 'error',
    };
  }
  return undefined;
};

/**
 * Checks the value to match price or duration and returns an object or undefined
 * @param value The value to check
 * @param message The error message
 * @param severity The severity level (optional), this is 'error' by default
 */
export const checkCurrency = (
  value: string,
  message: Message,
  severity: ValidationSeverityLevel = 'error'
): ValidationInfo => {
  if (value) {
    const regex = /^\d+([,.]\d{2})?$/gm;
    if (!regex.test(value)) {
      return {
        message,
        severity,
      };
    }
  }
  return undefined;
};

/**
 * Checks the value to match price or duration and returns an object or undefined
 * @param value The value to check
 * @param message The error message
 * @param severity The severity level (optional), this is 'error' by default
 */
export const checkDuration = (
  value: string,
  message: Message,
  severity: ValidationSeverityLevel = 'error'
): ValidationInfo => {
  if (value) {
    const regex = /^\d+([,.]\d{1,2})?$/gm;
    if (!regex.test(value)) {
      return {
        message,
        severity,
      };
    }
  }
  return undefined;
};

/**
 * Checks if the current value is a valid year
 * @param value The value to check
 * @param message The error message
 * @param severity The severity level (optional), this is 'error' by default
 */
export const checkYear = (
  value: string,
  message: Message,
  severity: ValidationSeverityLevel = 'error'
): ValidationInfo => {
  if (value?.length !== 4 || !dayjs(value, 'YYYY').isValid()) {
    return {
      message,
      severity,
    };
  }
  return undefined;
};

/**
 * Checks if the current value is a valid date
 * @param value The value to check
 * @param message The error message
 * @param severity The severity level (optional), this is 'error' by default
 */
export const checkDate = (
  value: string,
  message: Message,
  severity: ValidationSeverityLevel = 'error'
): ValidationInfo => {
  if (!dayjs(value, DATE_FORMAT_INT).isValid() && value.length > 0) {
    return {
      message,
      severity,
    };
  }
  return undefined;
};

/**
 * Checks if the current value is a valid date
 * @param value The value to check
 * @param message The error message
 * @param severity The severity level (optional), this is 'error' by default
 */
export const checkDateWithin969Days = (
  value: string,
  message: Message,
  severity: ValidationSeverityLevel = 'error'
): ValidationInfo => {
  const dateInvalid = checkDate(value, message, severity);

  if (dateInvalid) {
    return {
      message: Message.DATE_MISSING,
      severity: 'error',
    };
  }

  // selected date is in the past
  if (Math.ceil(dayjs(value).diff(dayjs(), 'days', true)) < 0) {
    return {
      message: Message.DATE_IN_PAST,
      severity: 'warning',
    };
  }

  // calculates countdown days like in the implementation of the actual widget
  const countdownDays = Math.max(Math.ceil(dayjs(value).diff(dayjs(), 'days', true)), 0);

  if (countdownDays > 969) {
    return {
      message: Message.DATE_NOT_WITHIN_969_DAYS,
      severity: 'error',
    };
  }

  return undefined;
};

export function formElementIdAttributeValidation(idField: string): [string, object] {
  return [
    idField,
    (value: string | null): ValidationInfo => {
      if (value && !idAndNameRegex.test(value)) {
        return {
          severity: 'error',
          message: Message.INVALID_INPUT,
        };
      }
      return undefined;
    },
  ];
}
