import * as Scrivito from 'scrivito';
import { validateLinkListRequiredTitle } from '../../utils/scrivito/validation';
import { HelperText, WidgetTitle } from '../../utils/translations';
import { ClosingLineWidgetAttributes, ClosingLineWidgetId } from './closing-line-widget-definitions';

Scrivito.provideEditingConfig(ClosingLineWidgetId, {
  title: WidgetTitle.CLOSING_LINE,
  properties: Object.values(ClosingLineWidgetAttributes),
  attributes: {
    [ClosingLineWidgetAttributes.INFO_LINE]: {
      title: HelperText.INFO_LINE,
    },
    [ClosingLineWidgetAttributes.LIST_NORMAL]: {
      title: HelperText.CLOSING_LINE_LIST_NORMAL,
      description: HelperText.CLOSING_LINE_LIST_NORMAL_DESCRIPTION,
    },
    [ClosingLineWidgetAttributes.LIST_IMPORTANT]: {
      title: HelperText.CLOSING_LINE_LIST_IMPORTANT,
      description: HelperText.CLOSING_LINE_LIST_IMPORTANT_DESCRIPTION,
    },
  },
  initialContent: {
    [ClosingLineWidgetAttributes.INFO_LINE]: '© 2022 Rheinbahn AG',
  },
  validations: [
    [ClosingLineWidgetAttributes.LIST_NORMAL, validateLinkListRequiredTitle],
    [ClosingLineWidgetAttributes.LIST_IMPORTANT, validateLinkListRequiredTitle],
  ],
});
