import React, { ReactElement, useEffect } from 'react';
import styles from './kpi-tile.module.scss';
import { ResponsiveImageContextProvider } from '../image/responsive-image/responsive-image.context';
import classNames from 'classnames';
import CountUp from 'react-countup';

/**
 * Properties for the KPI tile component
 */
export interface KpiTileProps {
  text?: string;
  counterStart?: number;
  counterEnd?: number;
  asset: ReactElement | null;
  backgroundColor?: string;
}

/**
 * Creates a KPI tile component
 */
export const KpiTileComponent: React.FC<KpiTileProps> = (props) => {
  const host = React.createRef<HTMLDivElement>();

  /**
   * Intersection observer of video.
   */
  let observer: IntersectionObserver | undefined = undefined;

  /**
   * Add intersection observer to video.
   * @param video
   */
  const addIntersectionObserver = (video: HTMLVideoElement): void => {
    // We can only control playback without interaction if video is mute
    video.muted = true;

    observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // play video only one time completely
            if (video.paused && !video.ended) {
              video.play();
            }
          } else {
            // stop when video is not visible anymore
            if (!video.paused) {
              video.pause();
            }
          }
        });
      },
      // 80% visibility of video
      { threshold: 0.8 }
    );
    observer.observe(video);
  };

  /**
   * Start video automatically
   */
  const startVideoAutomatically = (): void => {
    // find video
    const video = host.current?.querySelector('video');

    if (video) {
      addIntersectionObserver(video);
    }
  };

  useEffect(() => {
    // set up video auto start
    startVideoAutomatically();

    return () => {
      // remove observer
      if (observer) {
        observer.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [host]);

  return (
    <div ref={host} className={classNames(styles.KpiTile, props.backgroundColor)}>
      <div className={classNames(styles.KpiTileContent, { hasAsset: props.asset })}>
        <div className={styles.KpiTileText}>{props.text}</div>
        {props.counterEnd && (
          <div className={styles.KpiTileCounter}>
            <CountUp
              start={props.counterStart}
              end={props.counterEnd}
              decimals={props.counterEnd % 1 !== 0 ? 2 : undefined}
              decimal={','}
              separator={'.'}
              enableScrollSpy={true}
              scrollSpyOnce={true}
            />
          </div>
        )}
      </div>
      {props.asset && (
        <ResponsiveImageContextProvider loading="eager">
          <div className={styles.KpiTileImageWrapper}>{props.asset}</div>
        </ResponsiveImageContextProvider>
      )}
    </div>
  );
};
