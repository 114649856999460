import * as Scrivito from 'scrivito';
import { ColumnWidget, ColumnWidgetClass } from './column-widget-class';
import React from 'react';
import { ColumnWidgetAttributes } from './column-widget-definitions';
import styles from './column-widget.module.scss';
import classNames from 'classnames';

export const ColumnWidgetComponent: React.FC<{ widget: ColumnWidget }> = ({ widget }) => {
  return (
    <Scrivito.WidgetTag>
      <Scrivito.ContentTag
        className={classNames('Column', styles.ColumnWidget)}
        content={widget}
        attribute={ColumnWidgetAttributes.ELEMENTS}
      />
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(ColumnWidgetClass, ColumnWidgetComponent);
