import * as Scrivito from 'scrivito';
import {
  VideoWidgetAttributes,
  VideoWidgetBubbleColor,
  VideoWidgetId,
  VideoWidgetOrientation,
  VideoWidgetPosition,
} from './video-widget-definitions';
import { ValidationInfo } from '../../utils/type.utils';
import { HelperText, Message, WidgetTitle } from '../../utils/translations';
import { spacingAttributes, spacingContent, spacingGroup } from '../../utils/scrivito/spacing-definitions';
import Thumbnail from '../../assets/thumbnails/video.svg';
import { ImageWidgetBubbleColor } from '../image-widget';

type AttributeValidationCallback = { widget: Scrivito.Widget };

const urlValidationPatterns = [
  /^https:\/\/www.youtube.com\/watch/,
  /^https:\/\/youtu.be\//,
  /^https:\/\/vimeo.com\/\d+$/,
];

Scrivito.provideEditingConfig(VideoWidgetId, {
  title: WidgetTitle.VIDEO,
  thumbnail: Thumbnail,
  properties: [
    VideoWidgetAttributes.VIDEO,
    VideoWidgetAttributes.URL,
    VideoWidgetAttributes.CAPTION,
    VideoWidgetAttributes.POSITION,
    VideoWidgetAttributes.ORIENTATION,
    VideoWidgetAttributes.COOKIE_CONSENT,
    VideoWidgetAttributes.BUBBLE,
  ],
  propertiesGroups: [spacingGroup],
  attributes: {
    [VideoWidgetAttributes.VIDEO]: {
      title: 'Video-Datei',
    },
    [VideoWidgetAttributes.URL]: {
      title: 'Video URL',
      description: HelperText.VIDEO_URL_DESCRIPTION,
    },
    [VideoWidgetAttributes.CAPTION]: {
      title: 'Video-Unterschrift (optional)',
      options: { toolbar: ['bold', 'subscript', 'superscript'] },
    },
    [VideoWidgetAttributes.POSITION]: {
      title: 'Video-Position',
      values: [
        { value: VideoWidgetPosition.LEFT, title: 'Links' },
        { value: VideoWidgetPosition.CENTER, title: 'Mittig' },
        { value: VideoWidgetPosition.RIGHT, title: 'Rechts' },
      ],
    },
    [VideoWidgetAttributes.ORIENTATION]: {
      title: 'Video-Ausrichtung',
      values: [
        { value: VideoWidgetOrientation.LANDSCAPE, title: 'Querformat' },
        { value: VideoWidgetOrientation.PORTRAIT, title: 'Hochformat' },
      ],
    },
    [VideoWidgetAttributes.COOKIE_CONSENT]: {
      title: 'Cookies akzeptiert',
    },
    [VideoWidgetAttributes.BUBBLE]: {
      title: HelperText.BUBBLE_TITLE,
      description: HelperText.BUBBLE_DESCRIPTION,
      values: [
        { value: ImageWidgetBubbleColor.NO_COLOR, title: 'Keine' },
        { value: ImageWidgetBubbleColor.LIGHT_BLUE, title: 'Hellblau' },
        { value: ImageWidgetBubbleColor.DARK_GREEN, title: 'Dunkelgrün' },
        { value: ImageWidgetBubbleColor.VIOLET, title: 'Lila' },
        { value: ImageWidgetBubbleColor.PINK, title: 'Pink' },
      ],
    },
    ...spacingAttributes,
  },
  validations: [
    [
      VideoWidgetAttributes.VIDEO,
      (value: Scrivito.Obj, { widget }: AttributeValidationCallback): ValidationInfo => {
        const url = widget.get(VideoWidgetAttributes.URL);
        if (url && value) {
          return {
            message: Message.URL_AND_VIDEO,
            severity: 'error',
          };
        } else if (!url && !value) {
          return {
            message: Message.VIDEO_URL_MISSING,
            severity: 'error',
          };
        }
        return undefined;
      },
    ],
    [
      VideoWidgetAttributes.URL,
      (value: string, { widget }: AttributeValidationCallback): ValidationInfo => {
        const video = widget.get(VideoWidgetAttributes.VIDEO);
        if (video) {
          if (value) {
            return {
              message: Message.URL_AND_VIDEO,
              severity: 'error',
            };
          }
        } else {
          if (!value) {
            return {
              message: Message.VIDEO_URL_MISSING,
              severity: 'error',
            };
          }

          for (const pattern of urlValidationPatterns) {
            if (pattern.exec(value)) {
              return undefined;
            }
          }

          return {
            message: Message.URL_INVALID,
            severity: 'error',
          };
        }
        return undefined;
      },
    ],
    [
      VideoWidgetAttributes.BUBBLE,
      (value: VideoWidgetBubbleColor | null): ValidationInfo => {
        if (!value) {
          return {
            severity: 'error',
            message: Message.BUBBLE_MISSING,
          };
        }

        return undefined;
      },
    ],
  ],
  initialContent: {
    [VideoWidgetAttributes.POSITION]: VideoWidgetPosition.CENTER,
    [VideoWidgetAttributes.ORIENTATION]: VideoWidgetOrientation.LANDSCAPE,
    [VideoWidgetAttributes.COOKIE_CONSENT]: true,
    [VideoWidgetAttributes.BUBBLE]: VideoWidgetBubbleColor.NO_COLOR,
    ...spacingContent,
  },
});
