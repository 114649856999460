import * as Scrivito from 'scrivito';
import { TextWidgetAttributes, TextWidgetId } from './text-widget-definitions';
import { spacingClassAttributes } from '../../utils/scrivito/spacing-definitions';

export const TextWidgetClass = Scrivito.provideWidgetClass(TextWidgetId, {
  attributes: {
    [TextWidgetAttributes.TEXT]: 'html',
    [TextWidgetAttributes.ID]: 'string',
    [TextWidgetAttributes.GRAY]: 'boolean',
    ...spacingClassAttributes(),
  },
  extractTextAttributes: [TextWidgetAttributes.TEXT],
});

export type TextWidget = InstanceType<typeof TextWidgetClass>;
