import * as Scrivito from 'scrivito';
import {
  SliderWidgetAttributes,
  SliderWidgetId,
  SlideWidgetAttribute,
  SlideWidgetId,
} from './slider-widget-definitions';
import { ValidationInfo } from '../../utils/type.utils';
import { Message, WidgetTitle } from '../../utils/translations';
import Thumbnail from '../../assets/thumbnails/slideshow.svg';
import { SlideWidgetClass } from './slider-widget-class';
import { spacingAttributes, spacingContent, spacingGroup } from '../../utils/scrivito/spacing-definitions';

const minRequiredItems = 3;

Scrivito.provideEditingConfig(SliderWidgetId, {
  title: WidgetTitle.SLIDER,
  thumbnail: Thumbnail,
  properties: Object.values(SliderWidgetAttributes),
  attributes: {
    [SliderWidgetAttributes.ARIA_DESCRIPTION]: {
      title: 'Beschreibung für die Barrierefreiheit',
    },
    [SliderWidgetAttributes.SLIDES]: {
      title: 'Slides',
      description: Message.SLIDER_SLIDES_DESCRIPTION,
    },
    ...spacingAttributes,
  },
  propertiesGroups: [spacingGroup],
  validations: [
    [
      SliderWidgetAttributes.SLIDES,
      (value): ValidationInfo => {
        const slideCount = (value as Scrivito.Widget[]).length;
        if (slideCount < 3) {
          return {
            severity: 'error',
            message: Message.SLIDER_TOO_SMALL,
          };
        }
        if (slideCount > 7) {
          return {
            severity: 'error',
            message: Message.SLIDER_TOO_LARGE,
          };
        }
        return undefined;
      },
    ],
  ],
  initialContent: {
    [SliderWidgetAttributes.SLIDES]: [...Array(minRequiredItems)].map(() => new SlideWidgetClass()),
    ...spacingContent,
  },
});

Scrivito.provideEditingConfig(SlideWidgetId, {
  title: WidgetTitle.SLIDE,
  properties: Object.values(SlideWidgetAttribute),
  attributes: {
    [SlideWidgetAttribute.ARIA_LABEL]: {
      title: 'Beschreibung für die Barrierefreiheit',
    },
  },
});
