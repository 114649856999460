import * as Scrivito from 'scrivito';
import {
  HighlightTeaserGroupWidgetAttributes,
  HighlightTeaserGroupWidgetId,
} from './highlight-teaser-group-widget-definitions';
import { spacingClassAttributes } from '../../utils/scrivito/spacing-definitions';
import { HighlightTeaserWidgetId } from '../highlight-teaser-widget';

export const HighlightTeaserGroupWidgetClass = Scrivito.provideWidgetClass(HighlightTeaserGroupWidgetId, {
  attributes: {
    [HighlightTeaserGroupWidgetAttributes.ELEMENT_ID]: 'string',
    [HighlightTeaserGroupWidgetAttributes.HIGHLIGHT_TEASER]: ['widgetlist', { only: HighlightTeaserWidgetId }],
    ...spacingClassAttributes(),
  },
});

export type HighlightTeaserGroupWidget = InstanceType<typeof HighlightTeaserGroupWidgetClass>;
