import React, { useCallback, useEffect, useState } from 'react';
import styles from './navigation.module.scss';
import classNames from 'classnames';
import * as Scrivito from 'scrivito';
import { NavigationDrawer } from './drawer/navigation-drawer';
import { NavigationAttributes } from '../../../../pages/home-page/home-page-navigation';
import { NavigationFlyout } from './flyout/navigation-flyout';
import { NavigationHeader } from './header/navigation-header';

interface NavigationComponentProps {
  small?: boolean;
}

const NavigationComponent: React.FC<NavigationComponentProps> = (props) => {
  const [selected, setSelected] = useState<Scrivito.Widget | undefined>();
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState<boolean>(false);

  const root = Scrivito.Obj.root();
  const metaLinks = (root?.get(NavigationAttributes.META_NAVIGATION) as Scrivito.Link[]) ?? [];
  const navElements = (root?.get(NavigationAttributes.NAVIGATION) as Scrivito.Widget[]) ?? [];

  const onEscapeHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        setSelected(undefined);
      }
    },
    [setSelected]
  );

  const onSelection = (newSelected: Scrivito.Widget | undefined): void => {
    if (newSelected && newSelected?.id() === selected?.id()) {
      setSelected(undefined);
      return;
    }
    setSelected(newSelected);
  };

  useEffect(() => {
    document.addEventListener('keydown', onEscapeHandler);
    return () => document.removeEventListener('keydown', onEscapeHandler);
  }, [onEscapeHandler]);

  return (
    <>
      <div id="skip-link-nav" tabIndex={-1} className={classNames(styles.Navigation, { [styles.Small]: props.small })}>
        {/* Mega menu navigation area */}
        <NavigationHeader
          selected={selected}
          small={!!props.small}
          onSelection={onSelection}
          onBurgerButton={(): void => setMobileDrawerOpen(true)}
        />

        <NavigationDrawer
          navigation={navElements}
          open={mobileDrawerOpen}
          onClose={(): void => setMobileDrawerOpen(false)}
          meta={metaLinks}
        />

        {/* Flyout with sub navigation */}
        <NavigationFlyout nav={selected} small={!!props.small} close={(): void => setSelected(undefined)} />
      </div>
      {selected && (
        <div
          className={classNames(styles.Backdrop, { [styles.Small]: props.small })}
          onClick={(): void => setSelected(undefined)}
        />
      )}
    </>
  );
};

export const Navigation = Scrivito.connect(NavigationComponent);
