import * as Scrivito from 'scrivito';
import {
  PressReleaseButtonIcons,
  PressReleaseButtonWidgetAttributes,
  PressReleaseButtonWidgetId,
} from './press-release-button-widget-definitions';

Scrivito.provideEditingConfig(PressReleaseButtonWidgetId, {
  title: 'Pressemitteilung-Button',
  properties: Object.values(PressReleaseButtonWidgetAttributes),
  attributes: {
    [PressReleaseButtonWidgetAttributes.LINK]: {
      title: 'Link',
    },
    [PressReleaseButtonWidgetAttributes.ICON]: {
      title: 'ICON',
      values: [
        { value: PressReleaseButtonIcons.NONE, title: 'ohne' },
        { value: PressReleaseButtonIcons.FILE, title: 'Datei generisch' },
        { value: PressReleaseButtonIcons.FILE_PDF, title: 'PDF' },
        { value: PressReleaseButtonIcons.FILE_PPT, title: 'PPT' },
        { value: PressReleaseButtonIcons.FILE_ARCHIVE, title: 'Archiv generisch' },
        { value: PressReleaseButtonIcons.FILE_ZIP, title: 'ZIP' },
        { value: PressReleaseButtonIcons.FILE_VIDEO, title: 'Video' },
        { value: PressReleaseButtonIcons.FILE_IMAGE, title: 'Bild' },
        { value: PressReleaseButtonIcons.SHOPPING_CART, title: 'Einkaufswagen' },
        { value: PressReleaseButtonIcons.TICKET, title: 'Ticket' },
        { value: PressReleaseButtonIcons.INFO, title: 'Info' },
        { value: PressReleaseButtonIcons.MAP, title: 'Landkarte' },
        { value: PressReleaseButtonIcons.TIP, title: 'Tipp' },
      ],
    },
  },
  validations: [],
  initialContent: {
    [PressReleaseButtonWidgetAttributes.LINK]: new Scrivito.Link({
      url: '#',
      title: 'Button Text',
    }),
    [PressReleaseButtonWidgetAttributes.ICON]: PressReleaseButtonIcons.NONE,
  },
});
