import * as Scrivito from 'scrivito';
import { FormWidgetClass, SectionWidget } from './form-widget-class';
import styles from './form-widget.module.scss';
import React, { useEffect, useRef, useState } from 'react';
import { FormWidgetAttributes } from './form-widget-definitions';
import { spacingClassName } from '../../utils/scrivito/spacing-definitions';
import { ButtonComponent } from '../../components/controls/button/button';
import { buttonColors, ButtonWidgetKinds } from '../button-widget';
import { Formik, FormikErrors } from 'formik';
import { FormContextData, FormContextProvider } from './FormContext';
import { generateInitialValues, generateValidationSchema, validateUploadFiles } from './formik-helper';
import { generateOnSubmit } from './form-submit-handler';
import { FormSpecialSubmissionErrorWidgetAttributes } from '../form-special-submission-error-widget';
import { Alert } from '@mui/material';
import { ReactComponent as AlertCircleIcon } from '../../assets/icons/icon-alert-circle.svg';
import { useTranslation } from 'react-i18next';
import { FormInteractiveLogicWidgetAttributes, FormInteractiveLogicWidgetId } from '../form-interactive-logic-widget';
import { compareFormValue, getFieldsFromForm } from './form-widget-helpers';
import { FormInteractiveEmailWidgetAttributes, FormInteractiveEmailWidgetId } from '../form-interactive-email-widget';
import { LoadingButton } from '../../components/controls/loading-button';
import classNames from 'classnames';

function extendListWhenValueMatches(
  formikValues: { [key: string]: never },
  formControlName: string,
  formGroupName: string,
  valueExpected: string,
  shouldHide: boolean,
  res: string[]
): string[] {
  const valueIs = formikValues[formControlName] as never;
  const equal = compareFormValue(valueExpected, valueIs);
  if (equal === shouldHide) {
    return [...res, formGroupName];
  }
  return res;
}

function getSubmissionErrorMessage(widget: Scrivito.Widget, submissionErrorDetails: string | undefined): string {
  const defaultError = (widget.get(FormWidgetAttributes.SUBMISSION_ERROR) as string) || 'Ein Fehler ist aufgetreten.';

  if (!submissionErrorDetails) {
    return defaultError;
  }

  const specialErrors = widget.get(FormWidgetAttributes.SPECIAL_SUBMISSION_ERRORS) as Scrivito.Widget[] | null;
  if (!specialErrors?.length) {
    return defaultError;
  }

  const messageSpecialErrorPairs: { [key: string]: string | null } = specialErrors.reduce((accept, v) => {
    const triggeringMessage = v.get(FormSpecialSubmissionErrorWidgetAttributes.TRIGGERING_ERROR_MESSAGE) as string;
    const specialError = v.get(FormSpecialSubmissionErrorWidgetAttributes.RESULTING_SPECIAL_ERROR);

    if (!triggeringMessage) {
      return accept;
    }

    return { ...accept, [triggeringMessage]: specialError };
  }, {});

  const hasMatchingSpecialError = Object.keys(messageSpecialErrorPairs).includes(submissionErrorDetails);
  if (hasMatchingSpecialError) {
    return messageSpecialErrorPairs[submissionErrorDetails] || defaultError;
  }

  return defaultError;
}

export const FormWidgetComponent: React.FC<{ widget: SectionWidget }> = ({ widget }) => {
  const [submissionErrorMessage, setSubmissionErrorMessage] = useState<string>('Ein Fehler ist aufgetreten');
  const [submissionErrorDetails, setSubmissionErrorDetails] = useState<string | undefined>(undefined);
  const [isShowingErrorDialog, setIsShowingErrorDialog] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formikValues, setFormikValues] = useState<{ [key: string]: any }>({});
  const [inputValues, setInputValues] = useState<{ [ref: string]: string }>({});
  const [customErrorMessages, setCustomErrorMessages] = useState<{ [ref: string]: string }>({});
  const [submitButtonVisible, setSubmitButtonVisible] = useState(true);
  const [hiddenElements, setHiddenElements] = useState<string[]>([]);
  const [interactiveIds, setInteractiveIds] = useState<{ [objId: string]: string[] }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(false);

  const buttonContent = <Scrivito.ContentTag content={widget} attribute={FormWidgetAttributes.SUBMIT_BUTTON_TEXT} />;
  const submitButtonEnabled = widget.get(FormWidgetAttributes.SUBMIT_BUTTON_ENABLED) && submitButtonVisible;
  const elementID = widget.get(FormWidgetAttributes.ELEMENT_ID) as string;
  const formID = elementID ? elementID.replace(/^#+/, '') : undefined;

  const formRef = useRef<HTMLFormElement | null>(null);

  const { t } = useTranslation();

  useEffect((): void => {
    const interactiveWidgets = widget.get(FormWidgetAttributes.INTERACTIVE_LOGIC) as Scrivito.Widget[];
    const interactiveLogicWidgets = interactiveWidgets.filter(
      (widget) => widget.objClass() === FormInteractiveLogicWidgetId
    );
    const interactiveEmailWidgets = interactiveWidgets.filter(
      (widget) => widget.objClass() === FormInteractiveEmailWidgetId
    );

    const groupsToHide = interactiveLogicWidgets.reduce((res, interactiveLogic) => {
      const formGroupName = interactiveLogic.get(FormInteractiveLogicWidgetAttributes.TARGET_FORM_GROUP) as string;
      const formControlName = interactiveLogic.get(FormInteractiveLogicWidgetAttributes.FORM_CONTROL_NAME) as string;
      const valueExpected = interactiveLogic.get(FormInteractiveLogicWidgetAttributes.FORM_CONTROL_VALUE) as string;
      const shouldHide = interactiveLogic.get(
        FormInteractiveLogicWidgetAttributes.HIDE_FROM_GROUP_WHEN_VALUE_MATCHES
      ) as boolean;
      return extendListWhenValueMatches(
        formikValues as { [key: string]: never },
        formControlName,
        formGroupName,
        valueExpected,
        shouldHide,
        res
      );
    }, [] as string[]);
    setHiddenElements(groupsToHide);

    const activeInteractiveIds = interactiveEmailWidgets.reduce((res, interactiveLogic) => {
      const objId = interactiveLogic.obj().id();
      const widgetId = interactiveLogic.id();
      const formControlName = interactiveLogic.get(FormInteractiveEmailWidgetAttributes.FORM_CONTROL_NAME) as string;
      const valueExpected = interactiveLogic.get(FormInteractiveEmailWidgetAttributes.FORM_CONTROL_VALUE) as string;
      const valueIs = formikValues[formControlName] as never;
      if (compareFormValue(valueExpected, valueIs)) {
        const list = res[objId] ?? [];
        res[objId] = [...list, widgetId];
      }
      return res;
    }, {} as { [objId: string]: string[] });
    setInteractiveIds(activeInteractiveIds);
  }, [formikValues, widget]);

  /**
   * This message is shown in the dialog as a headline
   *
   * In the form configuration, the editor can specify the default error message
   *  but also special error messages that override the default, if a specific
   *  error text is returned by the backend.
   */
  useEffect(() => {
    const submissionErrorMessage = getSubmissionErrorMessage(widget, submissionErrorDetails);
    setSubmissionErrorMessage(submissionErrorMessage);
  }, [submissionErrorDetails, widget]);

  const formElements = getFieldsFromForm(widget, hiddenElements);

  // Form context provides communication functionalities for between form elements.
  const formContext: FormContextData = {
    setInputValue(reference: string, value: string) {
      const newValues = Object.assign(inputValues, { [reference]: value });
      setInputValues(newValues);
    },
    getInputValue(reference: string): string {
      return inputValues[reference] ?? '';
    },
    setCustomError(reference: string, error: string) {
      const newValues = Object.assign(customErrorMessages, { [reference]: error });
      setCustomErrorMessages(newValues);
    },
    getCustomError(reference: string): string {
      return customErrorMessages[reference] ?? '';
    },
    getCustomErrorRefs(): string[] {
      return Object.keys(customErrorMessages);
    },
    resetCustomError(reference: string) {
      const copy = Object.assign(inputValues);
      delete copy[reference];
      setCustomErrorMessages(copy);
    },
    resetAllCustomErrors() {
      setCustomErrorMessages({});
    },
    setSubmitButton: setSubmitButtonVisible,
    setInteractiveIds,
    interactiveIds,
    setHiddenElements,
    isHiddenElement(name: string): boolean {
      if (Scrivito.isInPlaceEditingActive()) {
        return false;
      }
      return !!name && hiddenElements.indexOf(name) >= 0;
    },
    hiddenElements,
    setIsLoading,
    isLoading,
    setIsDone,
    isDone,
  };

  return (
    <Scrivito.WidgetTag>
      <div className={spacingClassName(widget)}>
        <FormContextProvider value={formContext}>
          <Formik
            initialValues={generateInitialValues(formElements)}
            validationSchema={generateValidationSchema(formElements)}
            validateOnChange={false}
            validate={(values): FormikErrors<{ [key: string]: string }> => validateUploadFiles(values, t)}
            onSubmit={generateOnSubmit(
              widget,
              formRef,
              setSubmissionErrorDetails,
              setIsShowingErrorDialog,
              formElements,
              formContext
            )}
          >
            {(formik): JSX.Element => {
              // useEffect prevents the following error:
              //   Cannot update a component (`connectedComponent`) while rendering a different component (`Formik`).
              // eslint-disable-next-line react-hooks/rules-of-hooks
              useEffect(() => {
                setFormikValues(formik.values);
              }, [formik.values]);

              return (
                <form
                  id={formID}
                  ref={formRef}
                  onSubmit={formik.handleSubmit}
                  className={classNames(styles.FormWidget, {
                    [styles.FormEditMode]: Scrivito.isInPlaceEditingActive(),
                  })}
                  noValidate
                >
                  <Scrivito.ContentTag content={widget} attribute={FormWidgetAttributes.ELEMENTS} />
                  {isShowingErrorDialog && !formik.isSubmitting && (
                    <Alert
                      iconMapping={{
                        error: <AlertCircleIcon fontSize="inherit" />,
                      }}
                      className={styles.Alert}
                      severity="error"
                      action={
                        submitButtonEnabled && (
                          <ButtonComponent
                            href={null}
                            color={buttonColors[ButtonWidgetKinds.CTA]}
                            small={false}
                            content={t('component.forms.retry')}
                            className={styles.ErrorButton}
                          />
                        )
                      }
                    >
                      {submissionErrorMessage}
                    </Alert>
                  )}
                  {submitButtonEnabled && (
                    <LoadingButton
                      content={buttonContent}
                      loading={isLoading}
                      done={isDone}
                      buttonClassName={styles.SubmitButton}
                    />
                  )}
                </form>
              );
            }}
          </Formik>
        </FormContextProvider>
      </div>
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(FormWidgetClass, FormWidgetComponent);
