import * as Scrivito from 'scrivito';
import { IconListItemWidget, IconListItemWidgetClass } from './icon-list-item-widget-class';
import { IconListItemWidgetAttributes } from './icon-list-item-widget-definitions';
import { useIconListContext } from '../icon-list-widget/icon-list-widget.context';
import { ReactComponent as IconCheck } from '../../assets/icons/icon-list-check.svg';
import { ReactComponent as IconCross } from '../../assets/icons/icon-list-cross.svg';
import { ReactComponent as IconStar } from '../../assets/icons/icon-list-star.svg';
import { IconListWidgetIcons } from '../icon-list-widget';
import styles from './icon-list-item-widget.module.scss';
import { contentTagOrHtml } from '../../utils/scrivito/widget.utils';

const IconListIcon: React.FC<{ icon: string }> = (props) => {
  switch (props.icon) {
    case IconListWidgetIcons.CROSS:
      return <IconCross />;
    case IconListWidgetIcons.STAR:
      return <IconStar />;
    default:
      return <IconCheck />;
  }
};

export const IconListItemWidgetComponent: React.FC<{ widget: IconListItemWidget }> = ({ widget }) => {
  const { getIconType } = useIconListContext();
  const itemIcon = widget.get(IconListItemWidgetAttributes.ICON) as string;
  const icon = getIconType(itemIcon);

  return (
    <Scrivito.WidgetTag tag="li" className={styles.IconListItem}>
      <IconListIcon icon={icon} />
      {contentTagOrHtml(widget, IconListItemWidgetAttributes.TEXT, 'span')}
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(IconListItemWidgetClass, IconListItemWidgetComponent);
