import * as Scrivito from 'scrivito';
import { FormGroupWidget, FormGroupWidgetClass } from './form-group-widget-class';
import styles from './form-group-widget.module.scss';
import React from 'react';
import { FormGroupWidgetAttributes, FormGroupWidgetColors } from './form-group-widget-definitions';
import classNames from 'classnames';
import { FormGroupContextProvider } from './form-group-widget-context';
import { useFormContext } from '../form-widget/FormContext';

export const FormGroupWidgetComponent: React.FC<{ widget: FormGroupWidget }> = ({ widget }) => {
  const backgroundColor = widget.get(FormGroupWidgetAttributes.BACKGROUND_COLOR) || FormGroupWidgetColors.WHITE;
  const label = widget.get(FormGroupWidgetAttributes.LABEL);
  const name = widget.get(FormGroupWidgetAttributes.NAME);
  const hasLabel = label?.length > 0;
  const formContext = useFormContext();
  const hideElement = name && formContext.hiddenElements.indexOf(name) >= 0;

  return (
    <Scrivito.WidgetTag
      className={classNames(styles.FormGroupWidget, styles[`color-${backgroundColor.toLowerCase()}`], {
        [styles.Empty]: hideElement, // render empty element to make sure that the padding is set correctly
      })}
    >
      {!hideElement && (
        <FormGroupContextProvider value={{ backgroundColor }}>
          <fieldset className={styles.FormFieldset}>
            {(hasLabel || Scrivito.isInPlaceEditingActive()) && (
              <legend className={styles.Legend}>
                <Scrivito.ContentTag content={widget} tag={'span'} attribute={FormGroupWidgetAttributes.LABEL} />
              </legend>
            )}
            <Scrivito.ContentTag content={widget} attribute={FormGroupWidgetAttributes.ELEMENTS} />
          </fieldset>
        </FormGroupContextProvider>
      )}
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(FormGroupWidgetClass, FormGroupWidgetComponent);
