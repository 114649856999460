import * as Scrivito from 'scrivito';
import styles from './navigation-drawer-pager.module.scss';
import { NavigationDrawerPage } from './navigation-drawer-page';
import React from 'react';
import classNames from 'classnames';

interface NavigationDrawerPagerProps {
  nav: Scrivito.Widget[];
  subNav: Scrivito.Widget[];
  subSubNav: Scrivito.Widget[];
  selectedNav?: Scrivito.Widget;
  setSelectedNav: (widget: Scrivito.Widget) => void;
  selectedSubNav?: Scrivito.Widget;
  setSelectedSubNav: (widget: Scrivito.Widget) => void;
  close: () => void;
}

export const NavigationDrawerPager: React.FC<NavigationDrawerPagerProps> = Scrivito.connect((props) => {
  return (
    <div
      className={classNames(styles.NavigationDrawerPager, {
        [styles.Sub]: !!props.selectedNav,
        [styles.SubSub]: !!props.selectedSubNav,
      })}
    >
      <NavigationDrawerPage
        widgets={props.nav}
        onClick={props.setSelectedNav}
        afterNav={props.close}
        disabled={!!props.selectedNav}
      />
      <NavigationDrawerPage
        sub
        widgets={props.subNav}
        onClick={props.setSelectedSubNav}
        afterNav={props.close}
        disabled={!!props.selectedSubNav}
      />
      <NavigationDrawerPage subSub widgets={props.subSubNav} overview={props.selectedSubNav} afterNav={props.close} />
    </div>
  );
});
