import * as Scrivito from 'scrivito';
import { ValidationInfo } from '../../utils/type.utils';
import {
  FormElementBaseAttributes,
  FormElementBaseGroupLabelAttributes,
  formElementBaseAttributesDefinition,
  formElementBaseAttributesValidation,
  groupLabelFormElementBaseClassAttributesDefinition,
  groupLabelFormElementBaseClassAttributesValidation,
} from '../form-widget/form-widget-definitions';
import { ToggleButtonWidgetAttributes } from '../toggle-button-widget';
import { ToggleButtonWidgetClass } from '../toggle-button-widget/toggle-button-widget-class';
import { HelperText, Message, WidgetTitle } from './../../utils/translations';
import { ToggleButtonGroupWidgetAttributes, ToggleButtonGroupWidgetId } from './toggle-button-group-widget-definitions';
import Thumbnail from '../../assets/thumbnails/toggle-button.svg';

const minRequiredItems = 2;

Scrivito.provideEditingConfig(ToggleButtonGroupWidgetId, {
  title: WidgetTitle.TOGGLE_BUTTON_GROUP,
  titleForContent: (obj) =>
    `${WidgetTitle.TOGGLE_BUTTON_GROUP}: ${
      (obj.get(FormElementBaseAttributes.NAME) as string) || Message.FORM_INPUT_NAME_MISSING
    }`,
  thumbnail: Thumbnail,
  properties: [
    ...Object.values(FormElementBaseGroupLabelAttributes),
    ...Object.values(ToggleButtonGroupWidgetAttributes),
  ],
  propertiesGroups: [{ title: 'Validation', properties: [FormElementBaseAttributes.NAME] }],

  attributes: {
    ...(groupLabelFormElementBaseClassAttributesDefinition as object),
    [ToggleButtonGroupWidgetAttributes.VALUE]: {
      title: HelperText.TOGGLE_BUTTON_GROUP_DEFAULT_VALUE,
    },

    [ToggleButtonGroupWidgetAttributes.DISABLED]: {
      title: HelperText.TOGGLE_BUTTON_GROUP_DISABLED,
    },
    ...(formElementBaseAttributesDefinition as object),
  },
  initialContent: {
    [ToggleButtonGroupWidgetAttributes.VALUE]: '0',
    [ToggleButtonGroupWidgetAttributes.ITEMS]: [...Array(minRequiredItems)].map(
      (_, index) =>
        new ToggleButtonWidgetClass({
          [ToggleButtonWidgetAttributes.LABEL]: `${HelperText.TOGGLE_BUTTON_LABEL_INITIAL_VALUE} ${index + 1}`,
          [ToggleButtonWidgetAttributes.VALUE]: String(index),
        })
    ),
  },
  validations: [
    [
      ToggleButtonGroupWidgetAttributes.ITEMS,
      (radios: Array<Scrivito.Widget>): ValidationInfo => {
        if (radios?.length < minRequiredItems) {
          return {
            message: Message.TOGGLE_BUTTON_GROUP_MIN_ITEM_COUNT_REQUIRED,
            severity: 'error',
          };
        }
        return undefined;
      },
    ],
    [
      ToggleButtonGroupWidgetAttributes.VALUE,
      (value: string, { widget }: { widget: Scrivito.Widget }): ValidationInfo => {
        const toggleButtonValues = (widget.get(ToggleButtonGroupWidgetAttributes.ITEMS) as Scrivito.Widget[]).map(
          (item) => item.get(ToggleButtonWidgetAttributes.VALUE)
        );
        if (!toggleButtonValues.includes(value)) {
          return {
            message: Message.TOGGLE_GROUP_DEFAULT_VALUE_REQUIRED,
            severity: 'error',
          };
        }
        return undefined;
      },
    ],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(formElementBaseAttributesValidation as Array<any>),
    ...groupLabelFormElementBaseClassAttributesValidation,
  ],
});
