import * as Scrivito from 'scrivito';
import { checkValue } from '../../utils/scrivito/validation';
import { HelperText, Message } from '../../utils/translations';
import { ValidationInfo } from '../../utils/type.utils';
import { heroAttributes, heroInitialContent, heroValidations } from '../properties/hero';
import { BasePageAttributes } from './base-page-definitions';

type ValidationFunction<T> = (value: T, { obj }: { obj: Scrivito.Obj }) => ValidationInfo;

function validateAppBanner(message: Message): ValidationFunction<Scrivito.Obj> {
  return (value, { obj }) => {
    if (!value) {
      const iconIsSet = !!obj.get(BasePageAttributes.APP_BANNER_ICON);
      const titleIsSet = !!obj.get(BasePageAttributes.APP_BANNER_TITLE);
      const textIsSet = !!obj.get(BasePageAttributes.APP_BANNER_TEXT);
      const googleUrlIsSet = !!obj.get(BasePageAttributes.APP_BANNER_URL_GOOGLE);
      const appleUrlIsSet = !!obj.get(BasePageAttributes.APP_BANNER_URL_APPLE);

      const anySet = iconIsSet || titleIsSet || textIsSet || googleUrlIsSet || appleUrlIsSet;

      if (anySet) {
        return {
          severity: 'error',
          message,
        };
      }
    }

    return undefined;
  };
}

function validateAppBannerUrl(otherUrl: BasePageAttributes): ValidationFunction<string> {
  return (value, { obj }) => {
    if (!value) {
      const iconIsSet = !!obj.get(BasePageAttributes.APP_BANNER_ICON);
      const titleIsSet = !!obj.get(BasePageAttributes.APP_BANNER_TITLE);
      const textIsSet = !!obj.get(BasePageAttributes.APP_BANNER_TEXT);
      const otherUrlIsSet = !!obj.get(otherUrl);

      if ((iconIsSet || titleIsSet || textIsSet) && !otherUrlIsSet) {
        return {
          severity: 'error',
          message: Message.APP_URL_MISSING,
        };
      }
    }

    return undefined;
  };
}

export const basePageEditingConfig: Scrivito.ObjEditingConfig = {
  title: 'Base Page',
  hideInSelectionDialogs: true,
  properties: [
    BasePageAttributes.TITLE,
    BasePageAttributes.TAGS,
    BasePageAttributes.SEARCH_HIGHLIGHT,
    BasePageAttributes.CANONICAL_URL,
    BasePageAttributes.NO_INDEX,
  ],
  propertiesGroups: [
    {
      title: 'Meta-Elemente',
      key: 'page-meta-data',
      properties: [BasePageAttributes.META_TITLE, BasePageAttributes.META_DESCRIPTION, BasePageAttributes.META_TAGS],
    },
    {
      title: 'Feature Snippets',
      key: 'page-feature-snippets',
      properties: [BasePageAttributes.FEATURE_SNIPPETS_RAW],
    },
    {
      title: 'Soziale Medien',
      key: 'page-social-media',
      properties: [
        BasePageAttributes.SOCIAL_MEDIA_TITLE,
        BasePageAttributes.SOCIAL_MEDIA_DESCRIPTION,
        BasePageAttributes.SOCIAL_MEDIA_IMAGE,
      ],
    },
    {
      title: 'App Banner',
      key: 'page-app-banner',
      properties: [
        BasePageAttributes.APP_BANNER_ICON,
        BasePageAttributes.APP_BANNER_TITLE,
        BasePageAttributes.APP_BANNER_TEXT,
        BasePageAttributes.APP_BANNER_URL_GOOGLE,
        BasePageAttributes.APP_BANNER_URL_APPLE,
      ],
    },
  ],
  attributes: {
    [BasePageAttributes.TITLE]: {
      title: 'Titel',
      description: 'Seitentitel',
    },
    [BasePageAttributes.NO_INDEX]: {
      title: 'No Index',
      description: 'Indizierung für Suchmaschinen unterbinden',
    },
    [BasePageAttributes.TAGS]: {
      title: 'Tags',
      description: 'Hilfreich zum Suchen und Filtern',
    },
    [BasePageAttributes.CANONICAL_URL]: {
      title: 'Canonical URL',
      description: 'Canonical Link überschreiben',
    },
    [BasePageAttributes.SEARCH_HIGHLIGHT]: {
      title: 'Highlight in der Suche',
      description: HelperText.SEARCH_HIGHLIGHT_DESCRIPTION,
    },
    [BasePageAttributes.META_TITLE]: {
      title: 'Titel',
    },
    [BasePageAttributes.META_DESCRIPTION]: {
      title: 'Seitenbeschreibung',
      description: HelperText.META_DESCRIPTION,
      options: {
        showHtmlSource: false,
        toolbar: ['clean'],
      },
    },
    [BasePageAttributes.META_TAGS]: {
      title: 'Stichwörter',
    },
    [BasePageAttributes.FEATURE_SNIPPETS_RAW]: {
      title: 'Feature Snippet',
    },
    [BasePageAttributes.SOCIAL_MEDIA_TITLE]: {
      title: 'Titel',
      description: HelperText.SOCIAL_MEDIA_TITLE,
    },
    [BasePageAttributes.SOCIAL_MEDIA_DESCRIPTION]: {
      title: 'Text',
      description: HelperText.SOCIAL_MEDIA_DESCRIPTION,
      options: {
        showHtmlSource: false,
        toolbar: ['clean'],
      },
    },
    [BasePageAttributes.SOCIAL_MEDIA_IMAGE]: {
      title: 'Bild',
      description: HelperText.SOCIAL_MEDIA_PREVIEW,
    },
    [BasePageAttributes.APP_BANNER_ICON]: {
      title: 'Icon',
      description: 'Das Icon, welches im App-Banner dargestellt werden soll',
    },
    [BasePageAttributes.APP_BANNER_TITLE]: {
      title: 'Titel',
      description: 'Der Titel für den App-Banner',
    },
    [BasePageAttributes.APP_BANNER_TEXT]: {
      title: 'Text',
      description: 'Der Beschreibungstext für den App-Banner',
    },
    [BasePageAttributes.APP_BANNER_URL_GOOGLE]: {
      title: 'Link zum Play Store',
      description: 'Der Link zur App im Google Play Store',
    },
    [BasePageAttributes.APP_BANNER_URL_APPLE]: {
      title: 'Link zum App Store',
      description: 'Der Link zur App im Apple App Store',
    },
    ...heroAttributes,
  },
  validations: [
    [BasePageAttributes.TITLE, (value: string | null): ValidationInfo => checkValue(value, Message.PAGE_TITEL_MISSING)],
    [BasePageAttributes.APP_BANNER_ICON, validateAppBanner(Message.APP_ICON_MISSING)],
    [BasePageAttributes.APP_BANNER_TITLE, validateAppBanner(Message.APP_TITLE_MISSING)],
    [BasePageAttributes.APP_BANNER_TEXT, validateAppBanner(Message.APP_TEXT_MISSING)],
    [BasePageAttributes.APP_BANNER_URL_GOOGLE, validateAppBannerUrl(BasePageAttributes.APP_BANNER_URL_APPLE)],
    [BasePageAttributes.APP_BANNER_URL_APPLE, validateAppBannerUrl(BasePageAttributes.APP_BANNER_URL_GOOGLE)],
    ...(heroValidations as never[]),
  ],
  initialContent: {
    ...heroInitialContent,
  },
};
