import * as Scrivito from 'scrivito';
import { RedirectObjectAttributes, RedirectObjectId } from './redirect-object-definitions';

export const RedirectObjectClass = Scrivito.provideObjClass(RedirectObjectId, {
  attributes: {
    [RedirectObjectAttributes.TARGET]: 'string',
  },
});

export type RedirectObject = InstanceType<typeof RedirectObjectClass>;
