import { SimpleChoice } from './../../utils/objects/enums.utils';
import * as Scrivito from 'scrivito';
import { HelperText } from '../../utils/translations';
import { IconObjectAttributes } from '../icon-object/icon-object-definitions';
import { DownloadObjectAttributes, DownloadObjectId } from './download-object-definitions';

Scrivito.provideEditingConfig(DownloadObjectId, {
  title: 'Download',
  description: 'Download with meta data',
  properties: [
    DownloadObjectAttributes.TITLE,
    DownloadObjectAttributes.VISIBLE_IN_SEARCH,
    DownloadObjectAttributes.TAGS,
  ],
  attributes: {
    [DownloadObjectAttributes.TITLE]: {
      title: 'Title',
      description: 'A descriptive title for the download',
    },
    [IconObjectAttributes.TAGS]: {
      title: 'Tags',
      description: 'Additional search keywords',
    },
    [DownloadObjectAttributes.VISIBLE_IN_SEARCH]: {
      title: 'Datei über die Suche auffindbar',
      description: HelperText.FILE_VISIBLE_IN_SEARCH,
    },
  },
  initialContent: { [DownloadObjectAttributes.VISIBLE_IN_SEARCH]: SimpleChoice.YES },
});
