import styles from './modal.module.scss';
import * as Scrivito from 'scrivito';
import { ReactElement } from 'react';
import { Button, Modal } from '@mui/material';
import { ButtonComponent } from '../../controls/button/button';
import { ReactComponent as IconClose } from '../../../assets/icons/icon-close.svg';
import { ReactComponent as IconInfo } from '../../../assets/icons/icon-info-i.svg';
import classNames from 'classnames';

/**
 * Properties for the modal component
 */
export interface ModalProps {
  open: boolean;
  title?: ReactElement;
  headline?: ReactElement;
  text?: ReactElement;
  linkText?: string;
  link?: Scrivito.Link;
  image?: ReactElement | null;
  type: 'info' | 'warning';
  onClose: () => void;
}

/**
 * Renders a modal component
 */
export const ModalComponent: React.FC<ModalProps> = (props) => {
  // Add/Remove class to body to apply the mix-blend-mode for the overlay depending on type
  document.body.classList.remove('modalOpen');
  document.body.classList.remove('modalWarningOpen');
  const open = props.open;

  if (open) {
    document.body.classList.add(props.type === 'warning' ? 'modalWarningOpen' : 'modalOpen');
  }

  return (
    <Modal
      className={classNames(styles.Modal, props.type)}
      data-test-id={`modal`}
      hideBackdrop={true}
      open={open}
      onClose={(_, reason): void => {
        if (reason !== 'backdropClick') {
          props.onClose();
        }
      }}
    >
      <div className={styles.ModalContentWrapper}>
        <Button className={styles.CloseButton} onClick={(): void => props.onClose()}>
          <IconClose />
        </Button>
        <div className={styles.ModalContent}>
          <div className={styles.ModalImage}>{props.image || <IconInfo />}</div>
          <div className={styles.ModalInfo}>
            {props.title}
            {props.headline}
            {props.text}
            {props.link && props.linkText && (
              <ButtonComponent
                onClick={(): void => props.onClose()}
                color={props.type === 'info' ? 'info' : 'primary'}
                href={props.link}
                title={props.link?.title() ?? undefined}
                small={false}
                content={<>{props.linkText}</>}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
