import * as Scrivito from 'scrivito';
import { ButtonGroupWidget, ButtonGroupWidgetClass } from './button-group-widget-class';
import { ButtonGroupWidgetAttributes } from './button-group-widget-definitions';
import { spacingClassName } from '../../utils/scrivito/spacing-definitions';
import styles from './button-group.module.scss';
import classNames from 'classnames';

export const ButtonGroupWidgetComponent: React.FC<{ widget: ButtonGroupWidget }> = ({ widget }) => {
  return (
    <Scrivito.WidgetTag>
      <div className={classNames(styles.ButtonGroup, spacingClassName(widget))}>
        <Scrivito.ContentTag
          className={styles.ButtonGroupWrapper}
          content={widget}
          attribute={ButtonGroupWidgetAttributes.BUTTONS}
        />
      </div>
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(ButtonGroupWidgetClass, ButtonGroupWidgetComponent);
