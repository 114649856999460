import { HelperText, WidgetTitle } from './../../utils/translations';
import * as Scrivito from 'scrivito';
import { TableCellWidgetAttributes, TableCellWidgetId } from './table-cell-widget-definitions';

Scrivito.provideEditingConfig(TableCellWidgetId, {
  title: WidgetTitle.TABLE_CELL,
  properties: Object.values(TableCellWidgetAttributes),
  attributes: {
    [TableCellWidgetAttributes.CONTENT]: {
      title: HelperText.TABLE_CELL_CONTENT,
      options: {
        toolbar: ['bold', 'italic', 'link', 'subscript', 'superscript'],
      },
    },
  },
  initialContent: {
    [TableCellWidgetAttributes.WIDTH]: '1',
  },
});
