import { Button, ButtonBaseProps } from '@mui/material';
import * as Scrivito from 'scrivito';
import React, { ReactElement } from 'react';
import { CustomScrivitoLink } from '../../../utils/type.utils';
import classNames from 'classnames';
import styles from './button.module.scss';
import { linkStripHash } from '../../../utils/scrivito';

/**
 * Properties for the button component
 */
export interface ButtonProps extends Omit<ButtonBaseProps, 'content'> {
  href: Scrivito.Link | null;
  color: Color;
  small: boolean;
  content: ReactElement | string;
  icon?: ReactElement;
  component?: React.ElementType;
}

// MUI button variants
export type Color = 'primary' | 'secondary' | 'info';

/**
 * Creates a button component
 */
export const ButtonComponent: React.FC<ButtonProps> = ({ href, color, small, content, icon, component, ...props }) => {
  const onClickHandler = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>): void => {
    props.onClick && props.onClick(event as React.MouseEvent<HTMLButtonElement>);
  };

  return (
    <Button
      {...props}
      data-test-id="Button"
      component={component ? component : href ? CustomScrivitoLink : Button}
      to={href && linkStripHash(Scrivito.currentPage(), href)}
      type={!href && !props.type ? 'submit' : props.type}
      color={color}
      onClick={onClickHandler}
      target={href?.target()}
      className={classNames(styles.ButtonWidget, props.className, {
        [styles.Small]: small,
      })}
    >
      {icon && <span className={styles.Icon}>{icon}</span>}
      {content}
    </Button>
  );
};
