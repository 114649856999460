import styles from './app-banner.module.scss';
import { ButtonComponent } from '../../controls/button/button';
import * as Scrivito from 'scrivito';
import { ReactComponent as IconClose } from '../../../assets/icons/icon-close.svg';
import { useState } from 'react';
import { useViewportWidth } from '../../../utils/hooks.utils';
import { breakpoints } from '../../../utils/scrivito/breakpoints';

const localStorageEntryName = (id: string): string => `closedAppBanner-${id}`;
function detectPlatform(): 'android' | 'ios' | undefined {
  const maxTouchPoints = window.navigator.maxTouchPoints;
  const userAgent = window.navigator.userAgent;

  if (/Android/i.test(userAgent)) {
    return 'android';
  }

  // maxTouchPoints is the only effective method to detect iPad iOS 13+
  // FMI https://developer.apple.com/forums/thread/119186
  const hasMSStream = (window as unknown as { MSStream: unknown }).MSStream;
  const isLinux = /X11|Linux/i.test(userAgent);
  const isIPhone = /iPhone|iPad|iPod/i.test(userAgent);

  if ((!hasMSStream && !isLinux && maxTouchPoints && maxTouchPoints > 0) || isIPhone) {
    return 'ios';
  }
}

export interface AppBannerData {
  icon: React.ReactNode;
  title: string;
  text: string;
  googleLink: Scrivito.Link | undefined;
  appleLink: Scrivito.Link | undefined;
  pageId: string;
  hash: string;
}

export const AppBannerComponent: React.FC<{ data: AppBannerData }> = ({ data }) => {
  const [closedBanners, setClosedBanners] = useState<string[]>([]);
  const viewportWidth = useViewportWidth();

  if (!viewportWidth || viewportWidth >= breakpoints.MOBILE_XL) {
    return null;
  }

  const closedByStorage = localStorage.getItem(localStorageEntryName(data.pageId)) === data.hash;
  const closedByState = closedBanners.indexOf(data.pageId) >= 0;

  /**
   * closedByStorage is true, when the banner of the page was already closed and stored in the
   * local storage.
   * closedByState is true, when the banner has been closed at this moment.
   */
  if (closedByStorage || closedByState) {
    return null;
  }

  const platform = detectPlatform();
  let link: Scrivito.Link | undefined = undefined;
  if (platform === 'ios' && data.appleLink) {
    link = data.appleLink;
  } else if (platform === 'android' && data.googleLink) {
    link = data.googleLink;
  }

  if (!link) {
    return null;
  }

  function onClose(): void {
    localStorage.setItem(localStorageEntryName(data.pageId), data.hash);
    setClosedBanners([...closedBanners, data.pageId]);
  }

  return (
    <div className={styles.AppBanner} role="complementary">
      <div className={styles.Logo}>{data.icon}</div>
      <div className={styles.Title}>{data.title}</div>
      <div className={styles.Text}>{data.text}</div>
      <div className={styles.Close}>
        <button onClick={onClose}>
          <IconClose />
        </button>
      </div>
      <div className={styles.Button}>
        <ButtonComponent small href={link} color="info" content="Rheinbahn-App installieren" />
      </div>
    </div>
  );
};
