import React from 'react';
import * as Scrivito from 'scrivito';
import {
  ToggleButtonComponent,
  ToggleButtonComponentProps,
} from '../../components/controls/toggle-button/toggle-button';
import { ToggleButtonWidgetClass } from './toggle-button-widget-class';
import { ToggleButtonWidgetAttributes } from './toggle-button-widget-definitions';

export const ToggleButtonWidgetComponent: React.FC<Scrivito.WidgetComponentProps> = ({ widget }) => {
  const props: ToggleButtonComponentProps = {
    name: widget.get(ToggleButtonWidgetAttributes.VALUE) as string,
    label: widget.get(ToggleButtonWidgetAttributes.LABEL) as string,
    value: widget.get(ToggleButtonWidgetAttributes.VALUE) as string,
    disabled: widget.get(ToggleButtonWidgetAttributes.DISABLED) as boolean,
  };

  const [selected, setSelected] = React.useState(false);

  const onChange = (): void => {
    setSelected(!selected);
  };

  return (
    <Scrivito.WidgetTag>
      <ToggleButtonComponent selected={selected} onChange={onChange} {...props} />
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(ToggleButtonWidgetClass, ToggleButtonWidgetComponent);
