import { FormColumnWidgetId } from './../form-column-widget/form-column-widget-definitions';
import * as Scrivito from 'scrivito';
import { FormGroupWidgetId } from '../form-group-widget/form-group-widget-definitions';
import { getFormElementBaseClassAttributes } from '../form-widget';
import { TextInputWidgetAttributes, TextInputWidgetId, TextInputWidgetTypes } from './text-input-widget-definitions';
import { WizardPageWidgetId } from '../wizard-page-widget';

export const TextInputWidgetClass = Scrivito.provideWidgetClass(TextInputWidgetId, {
  attributes: {
    [TextInputWidgetAttributes.LABEL]: 'string',
    [TextInputWidgetAttributes.PLACEHOLDER]: 'string',
    [TextInputWidgetAttributes.VALUE]: 'string',
    [TextInputWidgetAttributes.TYPE]: ['enum', { values: Object.values(TextInputWidgetTypes) }],
    [TextInputWidgetAttributes.HELPER_TEXT]: 'string',
    [TextInputWidgetAttributes.DISABLED]: 'boolean',
    [TextInputWidgetAttributes.READ_ONLY]: 'boolean',
    [TextInputWidgetAttributes.MULTILINE]: 'boolean',
    [TextInputWidgetAttributes.REGEXP]: 'string',
    ...getFormElementBaseClassAttributes(),
  },
  onlyInside: [FormGroupWidgetId, FormColumnWidgetId, WizardPageWidgetId],
});

export type TextInputWidget = InstanceType<typeof TextInputWidgetClass>;
