import React, { ElementType } from 'react';
import * as Scrivito from 'scrivito';
import { Message } from './translations';

export type ValidationSeverityLevel = 'error' | 'warning' | 'info';

export interface ValidationType {
  message: Message;
  severity: ValidationSeverityLevel;
}
export type ValidationInfo = ValidationType | undefined;

export type ValidationValue = string | Scrivito.Obj | Scrivito.Link | null;

// Workaround to wrap the Scrivito.LinkTag into a Ref-Element that is compiled by the MuiButton correctly
// eslint-disable-next-line react/display-name, @typescript-eslint/no-unused-vars
export const CustomScrivitoLink = React.forwardRef((props, ref) => <Scrivito.LinkTag {...props} />) as ElementType;

export type Optional<T> = T | null | undefined;
