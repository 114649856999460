import * as Scrivito from 'scrivito';
import { SchedulingWidget, SchedulingWidgetClass } from './scheduling-widget-class';
import { SchedulingWidgetAttributes } from './scheduling-widget-definitions';
import { isSchedulingContentVisible } from '../../utils/general.utils';
import { renderScheduleInfo } from '../../utils/scrivito/schedule-validation';

/**
 * Checks if the given scheduling widget has visible content
 * @param widget The scheduling widget to check
 */
export const isWidgetVisible = (widget: SchedulingWidget): boolean => {
  const visibleFrom = widget.get(SchedulingWidgetAttributes.VISIBLE_FROM) as Date | null;
  const visibleUntil = widget.get(SchedulingWidgetAttributes.VISIBLE_UNTIL) as Date | null;
  return isSchedulingContentVisible(visibleFrom, visibleUntil);
};

export const SchedulingWidgetComponent: React.FC<{ widget: SchedulingWidget }> = ({ widget }) => {
  const visibleFrom = widget.get(SchedulingWidgetAttributes.VISIBLE_FROM) as Date | null;
  const visibleUntil = widget.get(SchedulingWidgetAttributes.VISIBLE_UNTIL) as Date | null;
  const editMode = Scrivito.isInPlaceEditingActive();
  const visible = isSchedulingContentVisible(visibleFrom, visibleUntil);

  if (!editMode && !visible) {
    return null;
  }

  return (
    <Scrivito.WidgetTag>
      {editMode && renderScheduleInfo({ visible, visibleFrom, visibleUntil })}
      <Scrivito.ContentTag content={widget} attribute={SchedulingWidgetAttributes.CHILDREN} />
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(SchedulingWidgetClass, SchedulingWidgetComponent);
