import { HelperText, WidgetTitle } from './../../utils/translations';
import * as Scrivito from 'scrivito';
import { FormSpecialSubmissionErrorWidgetId } from './form-special-submission-error-widget-id';
import { FormSpecialSubmissionErrorWidgetAttributes } from './form-special-submission-error-widget-definitions';
import { ValidationInfo } from '../../utils/type.utils';
import { checkValue } from '../../utils/scrivito/validation';
import { Message } from '../../utils/translations';

Scrivito.provideEditingConfig(FormSpecialSubmissionErrorWidgetId, {
  title: WidgetTitle.FORM_SPECIAL_SUBMISSION_ERROR,
  titleForContent: (widget) => {
    const triggeringErrorMessage = ((): string => {
      const message = widget.get(FormSpecialSubmissionErrorWidgetAttributes.TRIGGERING_ERROR_MESSAGE) as string | null;

      if (!message?.length) {
        return Message.NO_MESSAGE_SPECIFIED;
      }
      return message;
    })();

    const resultingSpecialError = ((): string => {
      const specialError = widget.get(FormSpecialSubmissionErrorWidgetAttributes.RESULTING_SPECIAL_ERROR) as
        | string
        | null;

      if (!specialError?.length) {
        return Message.NO_MESSAGE_SPECIFIED;
      }
      return specialError;
    })();

    return `"${resultingSpecialError}" ${Message.WHEN} "${triggeringErrorMessage}"`;
  },
  attributes: {
    [FormSpecialSubmissionErrorWidgetAttributes.TRIGGERING_ERROR_MESSAGE]: {
      title: 'Triggering error message',
      description: HelperText.TRIGGERING_ERROR_MESSAGE_DESCRIPTION,
    },
    [FormSpecialSubmissionErrorWidgetAttributes.RESULTING_SPECIAL_ERROR]: {
      title: 'Resulting special error',
      description: HelperText.RESULTING_SPECIAL_ERROR_DESCRIPTION,
    },
  },
  properties: Object.values(FormSpecialSubmissionErrorWidgetAttributes),
  initialContent: {
    [FormSpecialSubmissionErrorWidgetAttributes.TRIGGERING_ERROR_MESSAGE]: 'Die E-Mail-Adresse ist bereits eingetragen',
    [FormSpecialSubmissionErrorWidgetAttributes.RESULTING_SPECIAL_ERROR]: 'Hinweis',
  },
  validations: [
    [
      FormSpecialSubmissionErrorWidgetAttributes.TRIGGERING_ERROR_MESSAGE,
      (value: string): ValidationInfo => checkValue(value, Message.INVALID_MESSAGE_MISSING),
    ],
    [
      FormSpecialSubmissionErrorWidgetAttributes.RESULTING_SPECIAL_ERROR,
      (value: string): ValidationInfo => checkValue(value, Message.INVALID_MESSAGE_MISSING),
    ],
  ],
});
