export const SliderWidgetId = 'SliderWidget';
export const SlideWidgetId = 'SlideWidget';

export enum SliderWidgetAttributes {
  ARIA_DESCRIPTION = 'ariaDescription',
  SLIDES = 'slides',
}

export enum SlideWidgetAttribute {
  ARIA_LABEL = 'ariaLabel',
  WIDGETS = 'widgets',
}
