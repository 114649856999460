import { Dispatch, useEffect, useState } from 'react';

export const useSessionStorage = (
  storageKey: string,
  initState: boolean | null = null
): [boolean | null, Dispatch<boolean | null>] => {
  const item = sessionStorage.getItem(storageKey);
  const [value, setValue] = useState(item ? JSON.parse(item) : initState);

  useEffect(() => {
    if (value !== null) {
      sessionStorage.setItem(storageKey, JSON.stringify(value));
    }
  }, [value, storageKey]);

  return [value, setValue];
};
