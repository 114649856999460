import React, { useEffect, useState } from 'react';
import * as Scrivito from 'scrivito';
import { NavigationEntryAttrs } from '../../../../../pages/home-page/home-page-navigation';
import styles from './navigation-drawer.module.scss';
import { Drawer } from '@mui/material';
import { useHierarchy } from '../../../../../utils/scrivito';
import { NavigationDrawerHeader } from './navigation-drawer-header';
import { NavigationDrawerMeta } from './navigation-drawer-meta';
import { NavigationDrawerPager } from './navigation-drawer-pager';

export const NavigationDrawer: React.FC<{
  navigation: Scrivito.Widget[];
  open: boolean;
  onClose: () => void;
  meta: Scrivito.Link[];
}> = Scrivito.connect(({ navigation, open, onClose, meta }) => {
  const [selectedNav, setSelectedNav] = useState<Scrivito.Widget | undefined>();
  const [selectedSubNav, setSelectedSubNav] = useState<Scrivito.Widget | undefined>();
  const hierarchy = useHierarchy();
  const subNav = [
    ...((selectedNav?.get(NavigationEntryAttrs.CHILDREN1) as Scrivito.Widget[]) ?? []),
    ...((selectedNav?.get(NavigationEntryAttrs.CHILDREN2) as Scrivito.Widget[]) ?? []),
    ...((selectedNav?.get(NavigationEntryAttrs.CHILDREN3) as Scrivito.Widget[]) ?? []),
  ];
  const subSubNav = (selectedSubNav?.get(NavigationEntryAttrs.CHILDREN) as Scrivito.Widget[]) ?? [];
  const subNavTitle = (selectedNav?.get(NavigationEntryAttrs.LABEL) as string) ?? '';
  const subSubNavTitle = (selectedSubNav?.get(NavigationEntryAttrs.LABEL) as string) ?? '';

  const closeAll = (): void => {
    setSelectedNav(undefined);
    setSelectedSubNav(undefined);
    onClose();
  };

  const selectionBack = (): void => {
    if (selectedSubNav) {
      setSelectedSubNav(undefined);
    } else if (selectedNav) {
      setSelectedNav(undefined);
    }
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    switch (hierarchy.widgets.length) {
      case 3:
        // Third layer is selected, so jump to third layer
        setSelectedNav(hierarchy.widgets[0]);
        setSelectedSubNav(hierarchy.widgets[1]);
        break;
      case 2:
        // Second layer is selected,
        // either it has no children, and is on the second layer
        // or has children and is "Overview" in the third layer
        const children = hierarchy.widgets[1].get(NavigationEntryAttrs.CHILDREN) as Scrivito.Widget[];
        setSelectedNav(hierarchy.widgets[0]);
        if (children.length > 0) {
          setSelectedSubNav(hierarchy.widgets[1]);
        }
        break;
      case 1:
        // Thirst layer is selected, so keep here
        break;
    }
  }, [open, hierarchy]);

  return (
    <Drawer anchor="left" open={open} onClose={closeAll} className={styles.NavigationDrawer}>
      <NavigationDrawerHeader
        title={subSubNavTitle || subNavTitle || ''}
        onClick={selectionBack}
        onButtonClose={closeAll}
      />

      <NavigationDrawerPager
        nav={navigation}
        subNav={subNav}
        subSubNav={subSubNav}
        selectedNav={selectedNav}
        setSelectedNav={setSelectedNav}
        selectedSubNav={selectedSubNav}
        setSelectedSubNav={setSelectedSubNav}
        close={closeAll}
      />

      <NavigationDrawerMeta meta={meta} sub={!!selectedNav} afterNavigation={closeAll} />
    </Drawer>
  );
});
