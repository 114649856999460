import { FilledTextFieldProps, FormHelperText, TextField, TextFieldProps } from '@mui/material';
import classNames from 'classnames';
import React, { KeyboardEventHandler, ReactElement, useEffect, useState } from 'react';
import { ReactComponent as IconCalendarDate } from '../../../assets/icons/icon-calendar-date.svg';
import { ReactComponent as IconCalendarTime } from '../../../assets/icons/icon-calendar-time.svg';

import { useTranslation } from 'react-i18next';
import { TextInputWidgetTypes } from '../../../widgets/text-input-widget';
import styles from './text-input.module.scss';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { DateTimeWidgetTypes } from '../../../widgets/date-time-widget';
import { DATE_FORMAT, DATE_FORMAT_INT, TIME_FORMAT } from '../../../utils/general.utils';

let uuid = 0;

export interface TextInputComponentProps extends Omit<FilledTextFieldProps, 'variant'> {
  errorMessage?: string;
  success?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  inEditMode?: boolean;
  hideOptionalLabel?: boolean;
  optional?: boolean;
  modifierClass?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (event: any) => void;
  // the props below are for date & time picker only
  onClose?: () => void;
  isNow?: boolean;
  maxDate?: Date;
  minDate?: Date;
}

export const TextInputComponent: React.FC<TextInputComponentProps> = ({
  errorMessage,
  success,
  readOnly,
  label,
  type,
  inEditMode,
  hideOptionalLabel,
  optional,
  helperText,
  modifierClass,
  isNow,
  onClose,
  maxDate,
  minDate,
  ...props
}) => {
  const { t } = useTranslation();
  // Set the default date here if available
  const dateObjValue = props.value
    ? dayjs(props.value.toString(), type === DateTimeWidgetTypes.DATE ? DATE_FORMAT_INT : TIME_FORMAT)
    : null;
  const [dateTime, setDateTime] = useState<Dayjs | null>(dateObjValue);

  // Ensure id is set and unique
  let id: string | undefined = props?.id;
  if (!id) {
    id = 'input-text-' + uuid;
    uuid = uuid + 1;
  }

  const optionalLabel = (
    <>
      {label}
      <span className={styles.Optional}>{t('component.forms.optional')}</span>
    </>
  );

  /**
   * Handle key press for input type number to prevent add illegal non-number characters.
   * @param event
   */
  const onKeyDownNumber: KeyboardEventHandler<HTMLInputElement> = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    // event.target.value === undefined if it contains invalid character
    // check if new value is valid number
    if (
      !parseInt(event.key) &&
      event.key !== 'Backspace' &&
      event.key !== 'Tab' &&
      event.key !== 'ArrowUp' &&
      event.key !== 'ArrowDown' &&
      event.key !== 'Delete' &&
      event.key !== '0'
    ) {
      event.preventDefault();
    }
  };

  const renderMuiDatepicker = type === DateTimeWidgetTypes.DATE && window.matchMedia('(pointer: fine)').matches;

  const renderMuiTimePicker = type === DateTimeWidgetTypes.TIME && window.matchMedia('(pointer: fine)').matches;

  useEffect(() => {
    if (isNow && (renderMuiDatepicker || renderMuiTimePicker)) {
      setDateTime(dayjs());
    }
  }, [isNow, renderMuiDatepicker, renderMuiTimePicker]);

  // The text field wrapper including helper text and error message
  const textFieldWrapper = (children: ReactElement): ReactElement => (
    <div
      className={classNames(styles.TextInputComponentWrapper, modifierClass, {
        [styles.Hidden]: type === TextInputWidgetTypes.HIDDEN,
        [styles.DateTime]: type === DateTimeWidgetTypes.TIME || type === DateTimeWidgetTypes.DATE,
        [styles.MuiDatePicker]: renderMuiDatepicker,
      })}
    >
      {children}
      {helperText && !props.error && (
        <FormHelperText className={styles.HelperText} id={`helper-${id}`}>
          {helperText}
        </FormHelperText>
      )}
      {errorMessage && props.error && !optional && (
        <FormHelperText className={styles.ErrorText}>{errorMessage}</FormHelperText>
      )}
    </div>
  );

  const minMaxDateValue = (date?: Date): string | undefined => {
    return type === DateTimeWidgetTypes.DATE && date ? dayjs(date).format('YYYY-MM-DD') : undefined;
  };

  // The material ui text field
  const textField = (props: TextFieldProps): ReactElement => (
    <TextField
      {...props}
      className={classNames(styles.TextInputComponent, styles.TextAreaWidget, {
        [styles.Disabled]: props.disabled,
        [styles.ReadOnly]: readOnly,
        [styles.Error]: props.error,
        [styles.Success]: success && !props.error,
        [styles.Multiline]: props.multiline,
        [styles.Filled]: !!props.value,
        [styles.InEditMode]: inEditMode,
      })}
      label={!optional || hideOptionalLabel ? label : optionalLabel}
      data-test-id="TextInput"
      variant="filled"
      type={type === TextInputWidgetTypes.EMAIL ? 'text' : type}
      disabled={props.disabled || readOnly}
      inputProps={{
        'aria-describedby': helperText ? `helper-${id}` : undefined,
        min: type === TextInputWidgetTypes.NUMBER ? 0 : minMaxDateValue(minDate),
        max: minMaxDateValue(maxDate),
        autoComplete: renderMuiTimePicker || renderMuiDatepicker ? 'off' : props.autoComplete || undefined,
        ...props.inputProps,
        placeholder: renderMuiDatepicker ? t('component.textInput.placeholderDate') : props.placeholder,
      }}
      onKeyDown={type === TextInputWidgetTypes.NUMBER ? onKeyDownNumber : undefined}
    />
  );

  // If input type is time and the device is a desktop, render the material ui time picker
  if (renderMuiTimePicker) {
    return textFieldWrapper(
      <TimePicker
        ampm={false}
        disableOpenPicker={true}
        onChange={(newValue: Dayjs | null): void => {
          const timeValue = newValue !== null ? newValue.format(TIME_FORMAT) : '';
          setDateTime(newValue);
          props.onChange && props.onChange(timeValue);
        }}
        value={dateTime}
        renderInput={(timeProps): ReactElement => {
          const textInputProps = {
            ...timeProps,
            ...props,
          };
          return textField(textInputProps);
        }}
        readOnly={readOnly}
        disabled={props.disabled}
      />
    );
  }

  // If input type is date and the device is a desktop, render the material ui date picker
  if (renderMuiDatepicker) {
    return textFieldWrapper(
      <DatePicker
        onChange={(newValue: Dayjs | null): void => {
          const dateValue = newValue !== null ? newValue.format(DATE_FORMAT) : '';
          setDateTime(newValue);
          props.onChange && props.onChange(dateValue);
        }}
        value={dateTime}
        renderInput={(dateProps): ReactElement => {
          const textInputProps = {
            ...dateProps,
            ...props,
          };
          return textField(textInputProps);
        }}
        PopperProps={{
          placement: 'bottom-end',
        }}
        components={{
          OpenPickerIcon: IconCalendarDate,
        }}
        onClose={onClose}
        disableOpenPicker={readOnly || props.disabled}
        readOnly={readOnly}
        disabled={props.disabled}
        minDate={minDate ? dayjs(minDate) : undefined}
        maxDate={maxDate ? dayjs(maxDate) : undefined}
      />
    );
  }

  // Return the default text input field for mobile and other input types
  return textFieldWrapper(
    <>
      {textField(props)}
      {type === DateTimeWidgetTypes.DATE && (
        <div className={styles.TextFieldIcon}>
          <IconCalendarDate />
        </div>
      )}
      {type === DateTimeWidgetTypes.TIME && (
        <div className={styles.TextFieldIcon}>
          <IconCalendarTime />
        </div>
      )}
    </>
  );
};
