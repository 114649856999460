import * as Scrivito from 'scrivito';
import { AppointmentWidgetAttributes, AppointmentWidgetId } from './appointment-widget-definitions';
import { HomePageId } from '../../pages/home-page';
import { LandingPageId } from '../../pages/landing-page/landing-page-definitions';

export const AppointmentWidgetClass = Scrivito.provideWidgetClass(AppointmentWidgetId, {
  attributes: {
    [AppointmentWidgetAttributes.LABEL]: 'string',
    [AppointmentWidgetAttributes.LINK]: 'link',
  },
  onlyInside: [HomePageId, LandingPageId],
});

export type AppointmentWidget = InstanceType<typeof AppointmentWidgetClass>;
