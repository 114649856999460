import { HelperText, WidgetTitle } from './../../utils/translations';
import * as Scrivito from 'scrivito';
import { Message } from '../../utils/translations';
import { ValidationInfo } from '../../utils/type.utils';
import { ToggleButtonWidgetAttributes, ToggleButtonWidgetId } from './toggle-button-widget-definitions';
import { checkValue } from '../../utils/scrivito/validation';

Scrivito.provideEditingConfig(ToggleButtonWidgetId, {
  title: WidgetTitle.TOGGLE_BUTTON,
  properties: Object.values(ToggleButtonWidgetAttributes),
  attributes: {
    [ToggleButtonWidgetAttributes.LABEL]: {
      title: HelperText.TOGGLE_BUTTON_LABEL,
    },
    [ToggleButtonWidgetAttributes.VALUE]: {
      title: HelperText.TOGGLE_BUTTON_VALUE,
    },
    [ToggleButtonWidgetAttributes.DISABLED]: {
      title: HelperText.TOGGLE_BUTTON_DISABLED,
    },
  },
  validations: [
    [
      ToggleButtonWidgetAttributes.LABEL,
      (value: string): ValidationInfo => checkValue(value, Message.LABEL_FIELD_MISSING),
    ],
    [
      ToggleButtonWidgetAttributes.VALUE,
      (value: string): ValidationInfo => checkValue(value, Message.BUTTON_VALUE_REQUIRED),
    ],
  ],
  initialContent: {
    [ToggleButtonWidgetAttributes.LABEL]: HelperText.TOGGLE_BUTTON_LABEL_INITIAL_VALUE,
  },
});
