import * as Scrivito from 'scrivito';
import { SwitchPageId, SwitchPageAttributes } from './switch-page-definitions';
import { BasePage } from '../base-page';

export const SwitchPageObjectClass = Scrivito.provideObjClass(SwitchPageId, {
  extend: BasePage,
  attributes: {
    [SwitchPageAttributes.LINKS]: 'linklist',
    [SwitchPageAttributes.DEFAULT]: 'link',
  },
});

export type SwitchPage = InstanceType<typeof SwitchPageObjectClass>;
