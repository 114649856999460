import React, { ReactElement, useState } from 'react';
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary } from '@mui/material';
import styles from './collapsible-tabs.module.scss';

interface CollapsibleTabsProps extends Omit<AccordionProps, 'children' | 'onChange'> {
  value: number;
  onChange: (value: number) => void;
  tabs: Array<{
    label: string;
    content: ReactElement;
  }>;
}

/**
 * Renders accordions
 * @param value The current opened tab
 * @param props The collapsible tabs component props
 */
export const CollapsibleTabsComponent: React.FC<CollapsibleTabsProps> = ({ value, ...props }) => {
  const { tabs, ...other } = props;
  const [openId, setOpenId] = useState<number>(value);

  const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    const id = isExpanded ? panel : 0;
    props.onChange(id);
    setOpenId(id);
  };

  return (
    <div className={styles.CollapsibleTabs}>
      {tabs.map((tab, index) => {
        return (
          <Accordion
            {...other}
            key={index}
            disableGutters
            square
            expanded={openId === index}
            disabled={openId === index}
            onChange={handleChange(index)}
          >
            <AccordionSummary aria-controls={`panel${index}-content`} id={`panel${index}-header`}>
              {tab.label}
            </AccordionSummary>
            <AccordionDetails>{tab.content}</AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};
