import { HelperText, Message, WidgetTitle } from '../../utils/translations';
import * as Scrivito from 'scrivito';
import { ValidationInfo } from '../../utils/type.utils';
import { KpiTileGroupWidgetAttributes, KpiTileGroupWidgetId } from './kpi-tile-group-widget-definitions';
import { spacingAttributes, spacingContent, spacingGroup } from '../../utils/scrivito/spacing-definitions';
import Thumbnail from '../../assets/thumbnails/kpi_teaser.svg';
import { KpiTileWidgetClass } from '../kpi-tile-widget/kpi-tile-widget-class';

const minRequiredTeaser = 2;
const maxAllowedTeaser = 6;

Scrivito.provideEditingConfig(KpiTileGroupWidgetId, {
  title: WidgetTitle.KPI_TILE_GROUP,
  thumbnail: Thumbnail,
  properties: Object.values(KpiTileGroupWidgetAttributes),
  attributes: {
    [KpiTileGroupWidgetAttributes.ID]: {
      title: HelperText.ELEMENT_ID,
      description: HelperText.ELEMENT_ID_DESCRIPTION,
    },
    [KpiTileGroupWidgetAttributes.KPI_TILE]: {
      title: HelperText.TEXT_TEASER_GROUP_TEASER,
    },
    ...spacingAttributes,
  },
  propertiesGroups: [spacingGroup],
  validations: [
    [
      KpiTileGroupWidgetAttributes.KPI_TILE,
      (widgets: Array<Scrivito.Widget>): ValidationInfo => {
        if (widgets && widgets.length < minRequiredTeaser) {
          return {
            message: Message.KPI_TILE_GROUP_MIN_ITEMS,
            severity: 'error',
          };
        }
        if (widgets && widgets.length > maxAllowedTeaser) {
          return {
            message: Message.TEXT_TEASER_GROUP_MAX_ITEMS,
            severity: 'error',
          };
        }

        return undefined;
      },
    ],
  ],
  initialContent: {
    [KpiTileGroupWidgetAttributes.KPI_TILE]: Array.from({ length: minRequiredTeaser }, () => new KpiTileWidgetClass()),
    ...spacingContent,
  },
});
