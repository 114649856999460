import * as Scrivito from 'scrivito';
import {
  SocialMediaWidgetAttributes,
  SocialMediaWidgetType,
  SocialMediaWidgetId,
} from './social-media-widget-definitions';
import { spacingClassAttributes } from '../../utils/scrivito/spacing-definitions';

export const SocialMediaWidgetClass = Scrivito.provideWidgetClass(SocialMediaWidgetId, {
  attributes: {
    [SocialMediaWidgetAttributes.TYPE]: [
      'multienum',
      {
        values: Object.values(SocialMediaWidgetType),
      },
    ],
    [SocialMediaWidgetAttributes.FACEBOOK]: 'link',
    [SocialMediaWidgetAttributes.INSTAGRAM]: 'link',
    [SocialMediaWidgetAttributes.TWITTER]: 'link',
    [SocialMediaWidgetAttributes.YOUTUBE]: 'link',
    [SocialMediaWidgetAttributes.LINKEDIN]: 'link',
    [SocialMediaWidgetAttributes.BLOG]: 'link',
    ...spacingClassAttributes(),
  },
});

export type SocialMediaWidget = InstanceType<typeof SocialMediaWidgetClass>;
