import { createContext, useContext } from 'react';

export interface FormContextData {
  setInputValue(reference: string, value: string): void;
  getInputValue(reference: string): string;
  setCustomError(reference: string, error: string): void;
  getCustomError(reference: string): string;
  getCustomErrorRefs(): string[];
  resetCustomError(reference: string): void;
  resetAllCustomErrors(): void;
  setSubmitButton(visible: boolean): void;
  setInteractiveIds(addresses: { [objId: string]: string[] }): void;
  interactiveIds: { [objId: string]: string[] };
  setHiddenElements(hiddenElements: string[]): void;
  isHiddenElement(name: string): boolean;
  hiddenElements: string[];
  setIsLoading(loading: boolean): void;
  isLoading: boolean;
  setIsDone(done: boolean): void;
  isDone: boolean;
}

const FormContext = createContext<FormContextData>({
  setInputValue: () => undefined,
  getInputValue: () => '',
  setCustomError: () => undefined,
  getCustomError: () => '',
  getCustomErrorRefs: () => [],
  resetCustomError: () => undefined,
  resetAllCustomErrors: () => undefined,
  setSubmitButton: () => undefined,
  setInteractiveIds: () => undefined,
  interactiveIds: {},
  setHiddenElements: () => undefined,
  isHiddenElement: () => false,
  hiddenElements: [],
  setIsLoading: () => undefined,
  isLoading: false,
  setIsDone: () => undefined,
  isDone: false,
});

export const FormContextProvider = FormContext.Provider;

export function useFormContext(): FormContextData {
  return useContext<FormContextData>(FormContext);
}
