import * as Scrivito from 'scrivito';
import { SwitchPageAttributes, SwitchPageId } from './switch-page-definitions';
import { ValidationInfo } from '../../utils/type.utils';
import { checkValue } from '../../utils/scrivito/validation';
import { Message } from '../../utils/translations';
import { basePageEditingConfig } from '../base-page';

Scrivito.provideEditingConfig(SwitchPageId, {
  ...basePageEditingConfig,
  title: 'Weichen-Seite',
  propertiesGroups: [
    {
      title: 'Weiche',
      key: 'page-switch',
      properties: Object.values(SwitchPageAttributes),
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(basePageEditingConfig.propertiesGroups as Array<any>),
  ],
  properties: [...(basePageEditingConfig.properties as string[])],
  attributes: {
    [SwitchPageAttributes.LINKS]: {
      title: 'Weichen',
    },
    [SwitchPageAttributes.DEFAULT]: {
      title: 'Weiche (default)',
    },
  },
  initialContent: {
    [SwitchPageAttributes.LINKS]: [
      new Scrivito.Link({
        url: '#',
        title: 'Android',
      }),
      new Scrivito.Link({
        url: '#',
        title: 'iPhone|iPad|iPod',
      }),
    ],
    [SwitchPageAttributes.DEFAULT]: new Scrivito.Link({
      url: '#',
      title: 'Fallback',
    }),
  },
  validations: [
    [
      SwitchPageAttributes.DEFAULT,
      (value: string | null): ValidationInfo => checkValue(value, Message.SWITCH_PAGE_REQUIRED),
    ],
  ],
  hideInSelectionDialogs: false,
});
