import * as Scrivito from 'scrivito';
import { PressReleaseButtonWidget, PressReleaseButtonWidgetClass } from './press-release-button-widget-class';
import { ButtonComponent } from '../../../components/controls/button/button';
import React from 'react';
import { PressReleaseButtonIcons, PressReleaseButtonWidgetAttributes } from './press-release-button-widget-definitions';
import { Icon, renderIcon } from '../../../utils/icons.utils';

export const PressReleaseButtonWidgetComponent: React.FC<{ widget: PressReleaseButtonWidget }> = ({ widget }) => {
  const linkObj = widget.get(PressReleaseButtonWidgetAttributes.LINK);
  const content = <>{linkObj?.title() || 'Button'}</>;
  const buttonIcon = widget.get(PressReleaseButtonWidgetAttributes.ICON);
  const icon = buttonIcon === PressReleaseButtonIcons.NONE ? undefined : renderIcon(buttonIcon as Icon);

  return (
    <Scrivito.WidgetTag>
      <ButtonComponent small href={linkObj} color="secondary" content={content} icon={icon} />
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(PressReleaseButtonWidgetClass, PressReleaseButtonWidgetComponent);
