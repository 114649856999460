import { HelperText, WidgetTitle } from './../../utils/translations';
import * as Scrivito from 'scrivito';
import { TableEditorId } from '../../extensions/table-editor/table-editorId';
import { spacingAttributes, spacingContent, spacingGroup } from '../../utils/scrivito/spacing-definitions';
import { TableCellWidgetClass } from './../table-cell-widget/table-cell-widget-class';
import { TableCellWidgetAttributes } from './../table-cell-widget/table-cell-widget-definitions';
import { TableRowWidgetClass } from './../table-row-widget/table-row-widget-class';
import { TableRowWidgetAttributes } from './../table-row-widget/table-row-widget-definitions';
import { TableWidgetAttributes, TableWidgetId } from './table-widget-definitions';
import Thumbnail from '../../assets/thumbnails/table.svg';

const minColSize = 2;
const minRowSize = 2;

Scrivito.provideEditingConfig(TableWidgetId, {
  title: WidgetTitle.TABLE,
  thumbnail: Thumbnail,
  propertiesGroups: [
    { title: HelperText.TABLE_EDITOR_TITLE, component: TableEditorId },
    {
      title: HelperText.TABLE_SECTION_WIDTH,
      key: 'table-full-width',
      properties: [TableWidgetAttributes.FULL_WIDTH],
    },
    spacingGroup,
  ],
  attributes: {
    ...spacingAttributes,
    [TableWidgetAttributes.HEADER_CELLS]: {
      title: HelperText.TABLE_HEADER_TITLE,
    },
    [TableWidgetAttributes.ROWS]: {
      title: HelperText.TABLE_ROWS_TITLE,
    },
    [TableWidgetAttributes.FULL_WIDTH]: {
      title: HelperText.TABLE_FULL_WIDTH,
      description: HelperText.TABLE_FULL_WIDTH_DESCRIPTION,
    },
    [TableWidgetAttributes.SHOW_HEADER]: {
      title: HelperText.TABLE_SHOW_HEADER,
    },
    [TableWidgetAttributes.MANUAL_HYPHEN]: {
      title: HelperText.TABLE_MANUAL_HYPHEN,
    },
  },
  validations: [],
  initialContent: {
    ...spacingContent,
    [TableWidgetAttributes.HEADER_CELLS]: Array.from(
      { length: minColSize },
      (_, index) =>
        new TableCellWidgetClass({
          [TableCellWidgetAttributes.CONTENT]: `<strong>Spaltenüberschrift ${index + 1}</strong>`,
        })
    ),
    [TableWidgetAttributes.ROWS]: Array.from(
      { length: minRowSize },
      () =>
        new TableRowWidgetClass({
          [TableRowWidgetAttributes.CELLS]: Array.from({ length: minColSize }, () => new TableCellWidgetClass()),
        })
    ),
    [TableWidgetAttributes.SHOW_HEADER]: true,
  },
});
