import React from 'react';
import * as Scrivito from 'scrivito';
import { ReactComponent as IconSearch } from '../../../../../assets/icons/icon-search.svg';
import { ReactComponent as IconSearchDot } from '../../../../../assets/icons/icon-search-dot.svg';
import { ReactComponent as IconClose } from '../../../../../assets/icons/icon-close.svg';
import styles from './navigation-drawer-header.module.scss';
import { SearchAttributes } from '../../../../../pages/home-page/home-page-search';

interface NavigationDrawerHeaderProps {
  title?: string;
  onClick: () => void;
  onButtonClose: () => void;
}

export const NavigationDrawerHeader: React.FC<NavigationDrawerHeaderProps> = Scrivito.connect((props) => {
  const root = Scrivito.Obj.root();
  const searchPageLink = root?.get(SearchAttributes.SEARCH_PAGE);
  return (
    <div className={styles.NavigationDrawerHeader}>
      <div className={styles.NavigationDrawerTitle} onClick={props.onClick}>
        <span>{props.title}</span>
      </div>
      <div className={styles.IconsArea}>
        <Scrivito.LinkTag to={searchPageLink as Scrivito.Link} className={styles.Red}>
          <IconSearch className={styles.NotHover} />
          <IconSearchDot className={styles.HoverOnly} />
        </Scrivito.LinkTag>
        <button onClick={props.onButtonClose}>
          <IconClose />
        </button>
      </div>
    </div>
  );
});
