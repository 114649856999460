import * as Scrivito from 'scrivito';
import { BasePageAttributes } from './base-page-definitions';
import { ImageObjectId } from '../../objects';

export const BasePage = Scrivito.createObjClass({
  attributes: {
    [BasePageAttributes.TITLE]: 'string',
    [BasePageAttributes.TAGS]: 'stringlist',
    [BasePageAttributes.BODY]: 'widgetlist',
    [BasePageAttributes.CANONICAL_URL]: 'link',
    [BasePageAttributes.NO_INDEX]: 'boolean',
    [BasePageAttributes.META_TITLE]: 'string',
    [BasePageAttributes.META_DESCRIPTION]: 'html',
    [BasePageAttributes.META_TAGS]: 'stringlist',
    [BasePageAttributes.SOCIAL_MEDIA_TITLE]: 'string',
    [BasePageAttributes.SOCIAL_MEDIA_DESCRIPTION]: 'html',
    [BasePageAttributes.SOCIAL_MEDIA_IMAGE]: ['reference', { only: ImageObjectId }],
    [BasePageAttributes.SEARCH_HIGHLIGHT]: 'boolean',
    [BasePageAttributes.FEATURE_SNIPPETS_RAW]: 'string',
    [BasePageAttributes.APP_BANNER_ICON]: ['reference', { only: ImageObjectId }],
    [BasePageAttributes.APP_BANNER_TITLE]: 'string',
    [BasePageAttributes.APP_BANNER_TEXT]: 'string',
    [BasePageAttributes.APP_BANNER_URL_GOOGLE]: 'link',
    [BasePageAttributes.APP_BANNER_URL_APPLE]: 'link',
  },
  extractTextAttributes: [BasePageAttributes.TITLE, BasePageAttributes.BODY],
});
