import * as Scrivito from 'scrivito';
import { IFrameWidget, IFrameWidgetClass } from './iframe-widget-class';
import React, { useEffect, useRef } from 'react';
import { IFrameWidgetAttributes } from './iframe-widget-definitions';
import styles from './iframe-widget.module.scss';
import { useTranslation } from 'react-i18next';

export const IFrameWidgetComponent: React.FC<{ widget: IFrameWidget }> = ({ widget }) => {
  const iFrameRef = useRef<HTMLDivElement>(null);
  const url = (widget.get(IFrameWidgetAttributes.URL) as string) || null;
  const { t } = useTranslation();

  // Update height depending on window size
  useEffect(() => {
    const scaleIFrameListener = (): void => {
      const frame = iFrameRef.current;
      if (frame) {
        frame.style.height = `${window.innerHeight}px`;
      }
    };

    // Set height initially
    scaleIFrameListener();
    // Reset height on resize
    window.addEventListener('resize', scaleIFrameListener);

    // Remove listener when component is unmounted
    return () => window.removeEventListener('resize', scaleIFrameListener);
  }, []);

  return (
    <Scrivito.WidgetTag>
      <div className={styles.IFrameWidget} ref={iFrameRef}>
        {url && <iframe src={widget.get(IFrameWidgetAttributes.URL)} />}
        {!url && <h3>{t('component.iframe.urlMissing')}</h3>}
      </div>
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(IFrameWidgetClass, IFrameWidgetComponent);
