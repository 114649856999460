import classNames from 'classnames';
import * as Scrivito from 'scrivito';
import { getAttributeOrFallback } from '../../utils/scrivito/image.utils';
import { ImageWidgetAttributes } from '../image-widget';
import { HighlightTeaserGroupWidget, HighlightTeaserGroupWidgetClass } from './highlight-teaser-group-widget-class';
import { HighlightTeaserGroupWidgetAttributes } from './highlight-teaser-group-widget-definitions';
import styles from './highlight-teaser-group-widget.module.scss';
import { spacingClassName } from '../../utils/scrivito/spacing-definitions';
import { HighlightTeaserComponent } from '../../components/controls/highlight-teaser/highlight-teaser';
import { HighlightTeaserWidget, HighlightTeaserWidgetAttributes } from '../highlight-teaser-widget';
import { ResponsiveImage } from '../../components/controls/image/responsive-image/responsive-image.scrivito';
import React from 'react';

export const HighlightTeaserGroupWidgetComponent: React.FC<{ widget: HighlightTeaserGroupWidget }> = ({ widget }) => {
  const elementId = (widget.get(HighlightTeaserGroupWidgetAttributes.ELEMENT_ID) as string) || undefined;
  const teasers = widget.get(HighlightTeaserGroupWidgetAttributes.HIGHLIGHT_TEASER) || [];

  return (
    <Scrivito.WidgetTag className={'FullWidth'}>
      <div id={elementId} className={classNames(styles.HighlightTeaserGroup, spacingClassName(widget))}>
        <div
          className={classNames(styles.HighlightTeaserGroupGrid, {
            [styles['teaser-count-even']]: teasers.length % 2 === 0,
          })}
        >
          {teasers.map((teaser: HighlightTeaserWidget, index: number) => {
            const to = teaser.get(HighlightTeaserWidgetAttributes.LINK);
            const subject = teaser.get(HighlightTeaserWidgetAttributes.SUBJECT) as string;
            const text = teaser.get(HighlightTeaserWidgetAttributes.TEXT) as string;
            const innerShadow = teaser.get(HighlightTeaserWidgetAttributes.INNER_SHADOW) as boolean;

            const image = getAttributeOrFallback(teaser, ImageWidgetAttributes.IMAGE);
            let img = null;
            if (image) {
              // Get the binary of the selected image.
              const binary = image.get('blob') as Scrivito.Binary;
              const imgHeight = image.metadata().get('height') as number;
              const imgWidth = image.metadata().get('width') as number;
              const altText =
                teaser.get(HighlightTeaserWidgetAttributes.IMAGE_ALT) || (image.get('alternativeText') as string);
              const titleText =
                teaser.get(HighlightTeaserWidgetAttributes.IMAGE_TITLE) || (image.get('title') as string);
              img = (
                <ResponsiveImage
                  alt={altText}
                  title={titleText}
                  binary={binary}
                  meta={imgWidth && imgHeight ? { width: imgWidth, height: imgHeight } : undefined}
                />
              );
            }

            return (
              <HighlightTeaserComponent
                key={index}
                href={to}
                subject={subject}
                text={text}
                innerShadow={innerShadow}
                image={img}
                isLarge={index === 0}
              />
            );
          })}
        </div>
      </div>
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(HighlightTeaserGroupWidgetClass, HighlightTeaserGroupWidgetComponent);
