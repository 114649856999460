export const TimelineWidgetId = 'TimelineWidget';
export const TimelineEntryWidgetId = 'TimelineEntryWidget';

export enum TimelineWidgetAttributes {
  TITLE = 'title',
  ELEMENT_ID = 'elementId',
  ENTRIES = 'entries',
}

export enum TimelineEntryWidgetAttributes {
  TITLE = 'title',
  DATE = 'date',
  IMAGE = 'image',
  SHORT_TEXT = 'shortText',
  TEXT = 'text',
}
