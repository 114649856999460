import * as Scrivito from 'scrivito';
import { TooltipObjectAttributes, TooltipObjectId } from './tooltip-object-definitions';

export const TooltipObjectClass = Scrivito.provideObjClass(TooltipObjectId, {
  attributes: {
    [TooltipObjectAttributes.NAME]: 'string',
    [TooltipObjectAttributes.TEXT]: 'string',
  },
});

export type GlossaryItemObject = InstanceType<typeof TooltipObjectClass>;
