import * as Scrivito from 'scrivito';
import { WindowWithMatomo } from '../../../utils/matomo';

const matomoDE =
  'var _mtm=window._mtm=window._mtm||[];' +
  "_mtm.push({'mtm.startTime':(new Date().getTime()),'event':'mtm.Start'});" +
  "var d=document,g=d.createElement('script'),s=d.getElementsByTagName('script')[0];g.async=true;" +
  "g.src='https://use.rheinbahn.de/js/container_YopMHGc0.js';" +
  's.parentNode.insertBefore(g,s);';

const matomoEN =
  'var _mtm=window._mtm=window._mtm||[];' +
  "_mtm.push({'mtm.startTime':(new Date().getTime()),'event':'mtm.Start'});" +
  "var d=document,g=d.createElement('script'),s=d.getElementsByTagName('script')[0];g.async=true;" +
  "g.src='https://use.rheinbahn.com/js/container_aWl4aoeP.js';" +
  's.parentNode.insertBefore(g,s);';

function onlyOnce(content: string): string {
  return `if (!window._mtm) { ${content} }`;
}

export function useMatomoTagManager(): React.ReactNode {
  // temporary enable matomo
  // return null;

  const rootLang = Scrivito.Obj.root()?.language();
  const prodDE = process.env.REACT_APP_ORIGIN_DE === 'https://www.rheinbahn.de';
  const prodEN = process.env.REACT_APP_ORIGIN_EN === 'https://www.rheinbahn.com';

  if (rootLang === 'de' && prodDE) {
    return <script>{onlyOnce(matomoDE)}</script>;
  }

  if (rootLang === 'en' && prodEN) {
    return <script>{onlyOnce(matomoEN)}</script>;
  }

  const windowMatomo = window as unknown as WindowWithMatomo;
  windowMatomo._mtm = null;

  return null;
}
