export const DateTimeWidgetId = 'DateTimeWidget';

export enum DateTimeWidgetAttributes {
  LABEL = 'label',
  VALUE = 'value',
  NOW = 'now',
  TYPE = 'type',
  HELPER_TEXT = 'helperText',
  DISABLED = 'disabled',
  READ_ONLY = 'readOnly',
  MIN_DATE_NOW = 'minDateNow',
  MIN_DATE = 'minDate',
  MAX_DATE = 'maxDate',
}

export enum DateTimeWidgetTypes {
  DATE = 'date',
  TIME = 'time',
}
