export function stripHtml(html: string): string {
  const doc = new DOMParser().parseFromString(html, 'text/html');

  // un-ordered lists
  Object.values(doc.getElementsByTagName('ul')).forEach((ul) => {
    const content = Object.values(ul.getElementsByTagName('li'))
      .map((li) => li.innerHTML)
      .join(', ');
    const replacement = document.createElement('span');
    replacement.innerHTML = ` ${content} `;
    ul.parentNode?.replaceChild(replacement, ul);
  });

  // ordered lists
  Object.values(doc.getElementsByTagName('ol')).forEach((ul) => {
    const content = Object.values(ul.getElementsByTagName('li'))
      .map((li, index) => `${index + 1}. ${li.innerHTML}`)
      .join(', ');
    const replacement = document.createElement('span');
    replacement.innerHTML = ` ${content} `;
    ul.parentNode?.replaceChild(replacement, ul);
  });

  return doc.body.textContent?.normalize() || '';
}

/**
 * Removes the "isPasted" id from the given html string
 * @param html The html string
 */
export function removeIds(html: string): string {
  return html.replace(/ id="isPasted"/g, '');
}

/**
 * This function receives a text from a widget and prepares it for a preview (e.g. in titleForContent).
 * It receives a string, a default text and if text is html.
 * It will return the default text, when text is empty.
 * If isHtml is true, it will use stripHtml.
 * If the string is too long, it will shorten it and append "..." on it.
 * The length is set to 100 characters, because it still looks good on the Scrivito UI on a laptop screen.
 * @param text The text from the widget
 * @param defaultText A text, which should be displayed, when text is empty
 * @param isHtml Whether text is html or not
 */
export function makeTextPreview(text: string, defaultText: string, isHtml = false): string {
  if (!text) {
    return defaultText;
  }
  const cleanText = isHtml ? stripHtml(text) : text;
  if (cleanText.length > 100) {
    return cleanText.slice(0, 100) + '...';
  }
  return cleanText;
}
