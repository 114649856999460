import { Message, WidgetTitle } from './../../utils/translations';
import * as Scrivito from 'scrivito';
import { checkValue } from '../../utils/scrivito/validation';
import { ValidationInfo } from '../../utils/type.utils';
import { DropdownItemWidgetAttributes, DropdownItemWidgetId } from './dropdown-item-widget-definitions';

Scrivito.provideEditingConfig(DropdownItemWidgetId, {
  title: WidgetTitle.DROPDOWN_ITEM,
  titleForContent: (obj) => obj.get(DropdownItemWidgetAttributes.LABEL) as string,
  attributes: {
    [DropdownItemWidgetAttributes.VALUE]: {
      title: 'Wert',
    },
    [DropdownItemWidgetAttributes.LABEL]: {
      title: 'Label',
    },
  },
  properties: [DropdownItemWidgetAttributes.VALUE, DropdownItemWidgetAttributes.LABEL],
  initialContent: {
    [DropdownItemWidgetAttributes.VALUE]: 'value',
    [DropdownItemWidgetAttributes.LABEL]: 'Label',
  },
  validations: [
    [
      DropdownItemWidgetAttributes.VALUE,
      (value: string | null): ValidationInfo => checkValue(value, Message.VALUE_FIELD_MISSING),
    ],
    [
      DropdownItemWidgetAttributes.LABEL,
      (value: string | null): ValidationInfo => checkValue(value, Message.LABEL_FIELD_MISSING),
    ],
  ],
});
