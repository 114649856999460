import * as Scrivito from 'scrivito';
import { FormGroupWidgets } from '../form-widget/form-widget-types';
import { FormGroupWidgetId, FormGroupWidgetAttributes, FormGroupWidgetColors } from './form-group-widget-definitions';

export const FormGroupWidgetClass = Scrivito.provideWidgetClass(FormGroupWidgetId, {
  attributes: {
    [FormGroupWidgetAttributes.ELEMENTS]: ['widgetlist', { only: FormGroupWidgets }],
    [FormGroupWidgetAttributes.LABEL]: 'string',
    [FormGroupWidgetAttributes.BACKGROUND_COLOR]: [
      'enum',
      {
        values: Object.values(FormGroupWidgetColors),
      },
    ],
    [FormGroupWidgetAttributes.NAME]: 'string',
  },
  onlyInside: ['FormWidget'],
  extractTextAttributes: [FormGroupWidgetAttributes.LABEL, FormGroupWidgetAttributes.ELEMENTS],
});

export type FormGroupWidget = InstanceType<typeof FormGroupWidgetClass>;
