import { ValidationInfo } from './../../utils/type.utils';
import * as Scrivito from 'scrivito';
import { RadioButtonWidgetAttributes, RadioButtonWidgetId } from './radio-button-widget-definitions';
import { Message, WidgetTitle } from '../../utils/translations';
import { checkValue } from '../../utils/scrivito/validation';

Scrivito.provideEditingConfig(RadioButtonWidgetId, {
  title: WidgetTitle.RADIO_BUTTON,
  titleForContent: (obj) => obj.get(RadioButtonWidgetAttributes.LABEL) as string,
  properties: Object.values(RadioButtonWidgetAttributes),
  attributes: {
    [RadioButtonWidgetAttributes.LABEL]: {
      title: 'Label',
    },
    [RadioButtonWidgetAttributes.VALUE]: {
      title: 'Wert',
    },
    [RadioButtonWidgetAttributes.DISABLED]: {
      title: 'Deaktiviert',
    },
  },
  initialContent: {
    [RadioButtonWidgetAttributes.LABEL]: 'Label',
  },
  validations: [
    [
      RadioButtonWidgetAttributes.VALUE,
      (value: string): ValidationInfo => checkValue(value, Message.BUTTON_VALUE_REQUIRED),
    ],
    [
      RadioButtonWidgetAttributes.LABEL,
      (value: string): ValidationInfo => checkValue(value, Message.LABEL_FIELD_MISSING),
    ],
  ],
});
