import * as Scrivito from 'scrivito';
import { FormElementBaseAttributes, FormWidgetAttributes, FormWidgetId } from './form-widget-definitions';
import { isAFormColumnWidget, isAFormControlWidget, isWizardWidget } from '../shared/form/form-controls-helper-methods';
import { FormGroupWidgetAttributes, FormGroupWidgetId } from '../form-group-widget';
import { FormColumnWidgetAttributes, FormColumnWidgetId } from '../form-column-widget';
import { FormContextData } from './FormContext';
import { WizardWidgetAttributes, WizardWidgetId } from '../wizard-widget';
import { WizardPageWidgetAttributes, WizardPageWidgetId } from '../wizard-page-widget';
import { FormInputElements } from './form-widget-types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormData = { [key: string]: { value: any; valueText: string; label: string } };

export interface TopLevelAttributes {
  formActionId: string;
  workspaceId: string;
  langcode: string;
}

/**
 * Retrieves the attributes that should be sent in the top level of the form submission object.
 */
export const getTopLevelAttributes = async (formWidget: Scrivito.Widget): Promise<TopLevelAttributes> => {
  const formAction = await Scrivito.load(() => formWidget.get(FormWidgetAttributes.FORM_ACTION) as Scrivito.Obj);
  const formActionId = formAction?.id() ?? '';
  const workspaceId = Scrivito.currentWorkspaceId() ?? '';
  const langcode = Scrivito.Obj.root()?.language() ?? 'de';

  return { formActionId, workspaceId, langcode };
};

/**
 * Retrieve the URL of the form submission endpoint from the home page settings.
 */
export const getFormSubmissionUrl = (): string => {
  return process.env.REACT_APP_FORM_SEND_URL || 'url-missing';
};

/**
 * Data that will be sent
 */
export interface EnrichedFormData {
  formActionId: string;
  formInteractiveIds: { [objId: string]: string[] };
  workspaceId: string;
  langcode: string;
  formData: FormData;
  paramsData: { [key: string]: string | string[] };
}

/**
 * Convert data values to the structure of our API.
 *
 * @param widget the form widget
 * @param formData the data to submit
 * @param formContext
 * @returns data to send to our form API
 */
export const convertToEnrichedFormData = async (
  widget: Scrivito.Widget,
  formData: FormData,
  formContext: FormContextData
): Promise<EnrichedFormData> => {
  const { formActionId, workspaceId, langcode } = await getTopLevelAttributes(widget);

  const paramsData = {};

  return {
    workspaceId,
    formActionId,
    formInteractiveIds: formContext.interactiveIds,
    langcode,
    formData,
    paramsData,
  };
};

export interface NameLabelMappings {
  [referenceName: string]: string;
}

/**
 * This function goes through every FormGroup, containing rows and columns to collect all
 *  form input widgets. Afterwards, the reference name of each widget is used as a key
 *  and the label as a value to return a mapping of reference names to labels for each widget.
 *
 *  The resulting mapping looks like this:
 *
 *  {
 *    "phone_number": "Your phone number",
 *    "tariff_selection": "Select your tariff"
 *  }
 */
export const computeNameLabelMappings = (formWidget: Scrivito.Widget): NameLabelMappings => {
  const result: { [key: string]: string } = {};
  const formGroups = (formWidget.get(FormWidgetAttributes.ELEMENTS) as Scrivito.Widget[]) ?? [];
  const formColumns: Scrivito.Widget[] = [];
  const formControls: Scrivito.Widget[] = [];
  const formWizards: Scrivito.Widget[] = [];

  // Get all form controls from the groups
  formGroups.forEach((formGroup) => {
    const controls = formGroup.get(FormGroupWidgetAttributes.ELEMENTS) as Scrivito.Widget[];
    controls.forEach((control) => {
      // Check if it is a control, or if it is a column
      if (isAFormControlWidget(control)) {
        formControls.push(control);
      } else if (isAFormColumnWidget(control)) {
        formColumns.push(control);
      } else if (isWizardWidget(control)) {
        formWizards.push(control);
      }
    });
  });

  // Get all children that are wizards
  formWizards.forEach((wizard) => {
    // Get all pages of the wizard
    const pages = wizard.get(WizardWidgetAttributes.PAGES) as Scrivito.Widget[];
    pages.forEach((page) => {
      // Get all controls from a wizard page
      const controls = page.get(WizardPageWidgetAttributes.WIDGETS) as Scrivito.Widget[];
      controls.forEach((control) => {
        // Check if it is a control, or if it is a column
        if (isAFormControlWidget(control)) {
          formControls.push(control);
        } else if (isAFormColumnWidget(control)) {
          formColumns.push(control);
        }
      });
    });
  });

  // Get all children from form columns
  formColumns.forEach((column) => {
    const controls = column.get(FormColumnWidgetAttributes.ELEMENTS) as Scrivito.Widget[];
    controls.forEach((control) => {
      // Check if it is a control, or if it is a column
      if (isAFormControlWidget(control)) {
        formControls.push(control);
      }
    });
  });

  // Map form controls to result
  formControls.forEach((control) => {
    const key = (control.get(FormElementBaseAttributes.NAME) as string) || 'reference-name-missing';
    result[key] = (control.get('label') as string) || (control.get('groupLabel') as string) || '';
  });

  return result;
};

/**
 * Get the valueText attribute used in the converted / enhanced form data.
 *
 * @param form the form element
 * @param name the name of the form field
 * @param value the value to get the valueText for
 */
export const getValueTextFor = (form: HTMLFormElement, name: string, value: unknown): string => {
  const field = getFormFieldFor(form, name, value);
  return field?.getAttribute('data-value-text') || `${value}`;
};

/**
 * Get the form field element inside the given form with the specified name.
 * Some inputs like checkboxes in a single group share the same name, so
 *  the value of the form input must be provided as well.
 *
 * @param form the form element
 * @param name the name of the form field
 * @param value the value of the form field with given name
 */
export const getFormFieldFor = (form: HTMLFormElement, name: string, value: unknown): Element | null => {
  const formFields = Array.from(form.querySelectorAll(`[name='${name}']`));

  if (!formFields?.length) {
    return null;
  }

  if (formFields.length === 1) {
    return formFields[0];
  }

  return (
    formFields.find((formField) => {
      const formFieldValue = formField.getAttribute('value') ?? '';
      return formFieldValue === value;
    }) ?? null
  );
};

/**
 * Returns enriched and transformed data for the given form field.
 * This includes attributes like the value in plain text or the label for the given field.
 *
 * @param form the form element to restrict queries to
 * @param name the name of the form control
 * @param value the value of the form control
 * @param nameLabelMappings an object with mappings of reference names and labels
 *
 * @returns enriched data for the given form control
 */
const getDataForFormControl = (
  form: HTMLFormElement,
  name: string,
  value: object | object[],
  nameLabelMappings: { [key: string]: string }
): { value: object | object[]; label: string; valueText: string | string[] } => {
  const hasMultipleValues = Array.isArray(value);

  if (!hasMultipleValues) {
    return {
      value,
      label: nameLabelMappings[name],
      valueText: getValueTextFor(form, name, value),
    };
  } else {
    // is array
    if (value.length > 0 && value[0].hasOwnProperty('name')) {
      // is upload button file list
      const fileNames = value.map((item) => item.name);
      const fileNamesStripped = fileNames.map((item) => item.substring(item.indexOf('__') + 2));

      return {
        // flatten array to file name list
        value: fileNames,
        label: nameLabelMappings[name],
        valueText: fileNamesStripped,
      };
    }
  }

  return {
    value,
    label: nameLabelMappings[name],
    valueText: value.map((v: object) => getValueTextFor(form, name, v)),
  };
};

/**
 * Convert formik values to the structure of our API.
 *
 * @param widget
 * @param formRef
 * @param formikValues the form values in formik format
 * @param formContext
 * @returns data to send to our form API
 */
export const convertFormikDataToEnrichedFormData = async (
  widget: Scrivito.Widget,
  formRef: React.RefObject<HTMLFormElement>,
  formikValues: { [key: string]: object },
  formContext: FormContextData
): Promise<EnrichedFormData> => {
  const nameLabelMappings = computeNameLabelMappings(widget);
  const { formActionId, workspaceId, langcode } = await getTopLevelAttributes(widget);

  const formElement = formRef.current;
  if (!formElement) {
    throw new Error('formElement does not exist');
  }

  const uploadIds: string[] = [];
  const inputFiles = formElement.querySelectorAll('input[type="file"]');
  inputFiles.forEach((input) => {
    uploadIds.push((input as HTMLInputElement).name);
  });
  const formData = Object.entries(formikValues).reduce((result, [referenceName, formikValue]) => {
    return {
      ...result,
      [referenceName]: getDataForFormControl(formElement, referenceName, formikValue, nameLabelMappings),
    };
  }, {});

  const paramsData = {
    uploadIds,
  };

  return {
    workspaceId,
    formActionId,
    formInteractiveIds: formContext.interactiveIds,
    langcode,
    formData,
    paramsData,
  };
};

export function compareFormValue(expected: string, value: never): boolean {
  const expectedLower = expected.toLowerCase();

  // strings
  if (expected === value) {
    return true;
  }

  // toString
  if (value && (value as object).toString() === expected) {
    return true;
  }

  // boolean
  if (!value && ['0', 'false', 'falsch'].indexOf(expectedLower) >= 0) {
    return true;
  }
  if (value === true && ['1', 'true', 'wahr'].indexOf(expectedLower) >= 0) {
    return true;
  }

  // string arrays
  if (Array.isArray(value)) {
    const valueArray = value as string[];
    if (valueArray.indexOf(expected) >= 0) {
      return true;
    }
  }

  return false;
}

/**
 * Returns a list of the form controls as scrivito widgets from a form.
 * @param form The Scrivito Widget which represents the form
 * @param excludeElements You can exclude form groups (e.g. not visible ones).
 */
export function getFieldsFromForm(form: Scrivito.Widget, excludeElements: string[] = []): Scrivito.Widget[] {
  const result: Scrivito.Widget[] = []; // The found form controls
  const stack: Scrivito.Widget[] = [form]; // A stack of widgets to process

  while (stack.length) {
    const widget = stack.shift() as Scrivito.Widget;
    const name = (widget.get(FormGroupWidgetAttributes.NAME) ?? '') as string;
    if (excludeElements.indexOf(name) >= 0) {
      continue;
    }

    switch (widget.objClass()) {
      case FormWidgetId:
        {
          const children = widget.get(FormWidgetAttributes.ELEMENTS) as Scrivito.Widget[];
          stack.push(...children);
        }
        break;

      case FormGroupWidgetId:
        {
          const children = widget.get(FormGroupWidgetAttributes.ELEMENTS) as Scrivito.Widget[];
          stack.push(...children);
        }
        break;

      case FormColumnWidgetId:
        {
          const children = widget.get(FormColumnWidgetAttributes.ELEMENTS) as Scrivito.Widget[];
          stack.push(...children);
        }
        break;

      case WizardWidgetId:
        {
          const children = widget.get(WizardWidgetAttributes.PAGES) as Scrivito.Widget[];
          stack.push(...children);
        }
        break;

      case WizardPageWidgetId:
        {
          const children = widget.get(WizardPageWidgetAttributes.WIDGETS) as Scrivito.Widget[];
          stack.push(...children);
        }
        break;

      default:
        {
          // It is not a container, so maybe it is a control.
          // In that case, add it to the result.
          if (FormInputElements.indexOf(widget.objClass()) >= 0) {
            result.push(widget);
          }
        }
        break;
    }
  }

  return result;
}
