import * as Scrivito from 'scrivito';
import { BreadcrumbComponent } from '../../components/controls/breadcrumb/breadcrumb';
import { BreadcrumbWidget, BreadcrumbWidgetClass } from './breadcrumb-widget-class';
import { BreadcrumbWidgetAttributes } from './breadcrumb-widget-definitions';
import classNames from 'classnames';
import { spacingClassName } from '../../utils/scrivito/spacing-definitions';

/**
 * Breadcrumb Widget
 */
export const BreadcrumbWidgetComponent: React.FC<{ widget: BreadcrumbWidget }> = Scrivito.connect(({ widget }) => {
  // get root obj of page
  const root = Scrivito.Obj.root();

  // get current page obj
  const currentPage = Scrivito.currentPage();

  // get widget parameters
  const maxItems = Number(widget.get(BreadcrumbWidgetAttributes.MAX_ITEMS));
  const itemsBeforeCollapse = Number(widget.get(BreadcrumbWidgetAttributes.ITEMS_BEFORE_COLLAPSE));
  const itemsAfterCollapse = Number(widget.get(BreadcrumbWidgetAttributes.ITEMS_AFTER_COLLAPSE));

  return (
    <Scrivito.WidgetTag>
      <div className={classNames(spacingClassName(widget))}>
        <BreadcrumbComponent
          maxItems={maxItems ? maxItems : undefined}
          itemsBeforeCollapse={itemsBeforeCollapse}
          itemsAfterCollapse={itemsAfterCollapse}
          root={root}
          currentPage={currentPage}
        />
      </div>
    </Scrivito.WidgetTag>
  );
});

Scrivito.provideComponent(BreadcrumbWidgetClass, BreadcrumbWidgetComponent);
