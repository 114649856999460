import * as Scrivito from 'scrivito';
import { LandingSubPageAttributes, LandingSubPageId } from './landing-sub-page-definitions';
import { BasePage } from '../base-page';
import { ImageObjectId, VideoObjectId } from '../../objects';
import { navigationClassAttributes } from '../landing-page/landing-page-navigation';
import { scheduleClassAttributes } from '../properties/schedule';

export const LandingSubPageObjectClass = Scrivito.provideObjClass(LandingSubPageId, {
  extend: BasePage,
  attributes: {
    [LandingSubPageAttributes.HERO_MEDIA]: ['reference', { only: [VideoObjectId, ImageObjectId] }],
    [LandingSubPageAttributes.HERO_MEDIA_ALT]: 'string',
    [LandingSubPageAttributes.HERO_MEDIA_TITLE]: 'string',
    ...(navigationClassAttributes as object),
    ...(scheduleClassAttributes as object),
  },
});

export type LandingSubPage = InstanceType<typeof LandingSubPageObjectClass>;
