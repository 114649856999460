import { useMemo, useRef } from 'react';
import { executeSearch, SEARCH_INCLUDES, SEARCH_MEDIA_OBJECTS, SEARCH_PAGE_OBJECTS } from './search-utils.scrivito';
import * as Scrivito from 'scrivito';
import { useMatomo } from '../../utils/matomo';

export interface SearchResult {
  items: Array<Scrivito.Obj>;
  total: number;
}

export interface SearchFunctionResult {
  pageSearch: SearchResult;
  fileSearch: SearchResult;
}

export type ResultCallback = (result: SearchFunctionResult) => void;
export type SearchFunction = (query: string, limit: number, callback: ResultCallback) => void;

export function useSearchFilters(): { urlFileFilter: string | null; urlFilter: string | null; tags: string[] } {
  const urlSearchParams = new URLSearchParams(document.location.search);
  const urlFileFilter = urlSearchParams.get('file');
  const urlFilter = urlSearchParams.get('filter');
  const tags = useMemo(() => (urlFilter ? urlFilter.split(',') : []), [urlFilter]);
  return { urlFileFilter, urlFilter, tags };
}

export function useSearchFunction(): SearchFunction {
  const matomo = useMatomo();
  const lastRequestHash = useRef('');
  const { urlFileFilter, urlFilter, tags } = useSearchFilters();

  return async (query, limit, callback) => {
    const requestHash = JSON.stringify([query, limit, urlFilter, urlFileFilter]);
    if (requestHash === lastRequestHash.current) {
      return;
    }
    lastRequestHash.current = requestHash;

    // When searching without filter, search for page objects only (media objects are separate)
    let currentSearchObjects = SEARCH_PAGE_OBJECTS;
    // Searching with file filter will only search for media objects
    if (urlFileFilter === 'true') {
      currentSearchObjects = SEARCH_MEDIA_OBJECTS;
    } else if (tags.length > 0) {
      // When searching with tag filters, include all results
      currentSearchObjects = SEARCH_INCLUDES;
    }

    // We need to blend out file searches, when the dl box is not shown
    const requestFileSearch = !urlFileFilter && !tags.length;
    const pageSearchRequest: Promise<SearchResult> = executeSearch(query, limit, currentSearchObjects, tags);
    const fileSearchRequest: Promise<SearchResult> = requestFileSearch
      ? executeSearch(query, limit, SEARCH_MEDIA_OBJECTS, tags)
      : Promise.resolve<SearchResult>({ total: 0, items: [] });
    const pageSearch = await pageSearchRequest;
    const fileSearch = await fileSearchRequest;

    const allFilters = [];
    if (urlFileFilter) {
      allFilters.push('File');
    }
    if (urlFilter) {
      allFilters.push(...urlFilter.split(','));
    }
    matomo.search(query, pageSearch.total + fileSearch.total, allFilters.join(', '));
    callback({ pageSearch, fileSearch });
  };
}
