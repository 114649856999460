import * as Scrivito from 'scrivito';
import { ColumnWidgetAttributes, ColumnWidgetId } from './column-widget-definitions';

export const ColumnWidgetClass = Scrivito.provideWidgetClass(ColumnWidgetId, {
  attributes: {
    [ColumnWidgetAttributes.ELEMENTS]: [
      'widgetlist',
      { only: ['TextWidget', 'ImageWidget', 'VideoWidget', 'TableWidget', 'IconListWidget'] },
    ],
  },
  extractTextAttributes: [ColumnWidgetAttributes.ELEMENTS],
});

export type ColumnWidget = InstanceType<typeof ColumnWidgetClass>;
