import * as Scrivito from 'scrivito';
import { IntroTextWidgetAttributes, IntroTextWidgetId } from './intro-text-widget-definitions';
import { HeadlineWidgetTag } from '../headline-widget';
import { spacingClassAttributes } from '../../utils/scrivito/spacing-definitions';

export const IntroTextWidgetClass = Scrivito.provideWidgetClass(IntroTextWidgetId, {
  attributes: {
    [IntroTextWidgetAttributes.ID]: 'string',
    [IntroTextWidgetAttributes.HEADLINE]: 'string',
    [IntroTextWidgetAttributes.TEXT]: 'html',
    [IntroTextWidgetAttributes.COMPONENT]: [
      'enum',
      {
        values: Object.values(HeadlineWidgetTag),
      },
    ],
    ...spacingClassAttributes(),
  },
});

export type IntroTextWidget = InstanceType<typeof IntroTextWidgetClass>;
