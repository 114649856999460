import React, { KeyboardEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './slider.module.scss';
import classNames from 'classnames';
import { visuallyHidden } from '@mui/utils';
import { AriaText } from '../../../utils/translations';
import { ResponsiveImageContextProvider } from '../image/responsive-image/responsive-image.context';

export interface SlideModel {
  ariaLabel: string;
  content: React.ReactNode;
}

interface SliderComponentProps {
  slides: SlideModel[];
  editorMode?: boolean;
  ariaDescription?: string;
}

let UUID = 0;

const genID = (prefix: string, suffix?: string): string => {
  const suffix2 = (suffix ?? 'empty').replace(/[^a-zA-Z0-9]/g, '_').toLowerCase();
  // increase id
  UUID++;
  return [prefix, UUID, suffix2].join('-');
};

export const SliderComponent: React.FC<SliderComponentProps> = (props) => {
  const [current, setCurrent] = useState<number>(0);
  const [touchX, setTouchX] = useState<number>(0);
  const [touchMoveX, setTouchMoveX] = useState<number>(0);
  const buttonContainerRef = useRef<HTMLDivElement | null>(null);
  const sliderRef = useRef<HTMLElement | null>(null);

  const idDescription = useMemo(() => genID('id-slider-desc', props.ariaDescription), [props.ariaDescription]);

  const onSelectSlide = useCallback(
    (selection: number) => {
      const select = Math.max(Math.min(selection, props.slides.length - 1), 0);
      setCurrent(select);

      const selectedButton = buttonContainerRef.current?.children[select] as HTMLButtonElement;
      selectedButton?.focus();
    },
    [setCurrent, buttonContainerRef, props.slides.length]
  );

  const onSelectorKeyDown: KeyboardEventHandler = (e) => {
    if (e.code === 'ArrowLeft') {
      onSelectSlide(current - 1);
    } else if (e.code === 'ArrowRight') {
      onSelectSlide(current + 1);
    }
  };

  const position = (index: number): string => {
    // First centering all slides than put them left or right,
    // depending on the current slide, with additional 10% padding to
    // prevent issues
    let position = index * -100;
    if (index < current) {
      position -= 110;
    } else if (index > current) {
      position += 110;
    }
    return `${position}%`;
  };

  useEffect(() => {
    const currentRef = sliderRef.current;
    if (!currentRef) {
      return undefined;
    }

    const onTouchStart = (ev: TouchEvent): void => {
      setTouchX(ev.touches[0].clientX);
    };

    const onTouchMove = (ev: TouchEvent): void => {
      setTouchMoveX(ev.touches[0].clientX - touchX);
    };

    const onTouchEnd = (): void => {
      if (touchMoveX > 100) {
        onSelectSlide(current - 1);
      } else if (touchMoveX < -100) {
        onSelectSlide(current + 1);
      }
      setTouchX(0);
      setTouchMoveX(0);
    };

    currentRef.addEventListener('touchstart', onTouchStart);
    currentRef.addEventListener('touchmove', onTouchMove);
    currentRef.addEventListener('touchend', onTouchEnd);

    return () => {
      currentRef.removeEventListener('touchstart', onTouchStart);
      currentRef.removeEventListener('touchmove', onTouchMove);
      currentRef.removeEventListener('touchend', onTouchEnd);
    };
  }, [current, onSelectSlide, touchX, setTouchX, touchMoveX, setTouchMoveX]);

  return (
    <ResponsiveImageContextProvider loading="eager">
      <section ref={sliderRef} className={styles.Slider}>
        <h3 id={idDescription} style={visuallyHidden}>
          {props.ariaDescription}
        </h3>
        <ul
          className={classNames(styles.SlideContainer, { [styles.EditorMode]: props.editorMode })}
          style={{ transform: `translateX(${touchMoveX}px)` }}
        >
          {props.slides.map((slide, index) => (
            <li
              key={index}
              className={classNames(styles.Slide, { [styles.Current]: index === current })}
              style={{ left: position(index) }}
              aria-label={slide.ariaLabel}
            >
              {slide.content}
            </li>
          ))}
        </ul>
        <div ref={buttonContainerRef} className={styles.SlideSelector} aria-label={AriaText.SLIDER_SELECTION_BUTTONS}>
          {props.slides.map((slide, index) => (
            <button
              key={index}
              type="button"
              className={classNames(styles.SlideSelectorButton, { [styles.Current]: index === current })}
              onClick={(): void => onSelectSlide(index)}
              onKeyDown={onSelectorKeyDown}
              aria-label={slide.ariaLabel}
            >
              <div className={styles.Line} />
            </button>
          ))}
        </div>
      </section>
    </ResponsiveImageContextProvider>
  );
};
