import * as Scrivito from 'scrivito';
import {
  TimelineEntryWidgetAttributes,
  TimelineEntryWidgetId,
  TimelineWidgetAttributes,
  TimelineWidgetId,
} from './timeline-widget-definitions';
import { HelperText, Message, WidgetTitle } from '../../utils/translations';
import { spacingAttributes, spacingContent, spacingGroup } from '../../utils/scrivito/spacing-definitions';
import { ValidationInfo } from '../../utils/type.utils';
import { checkValue } from '../../utils/scrivito/validation';
import { TimelineEntryWidgetClass } from './timeline-widget-class';
import Thumbnail from '../../assets/thumbnails/timeline.svg';

Scrivito.provideEditingConfig(TimelineWidgetId, {
  title: WidgetTitle.TIMELINE,
  thumbnail: Thumbnail,
  properties: Object.values(TimelineWidgetAttributes),
  attributes: {
    [TimelineWidgetAttributes.TITLE]: {
      title: 'Titel',
    },
    [TimelineWidgetAttributes.ELEMENT_ID]: {
      title: HelperText.ELEMENT_ID,
      description: HelperText.ELEMENT_ID_DESCRIPTION,
    },
    [TimelineWidgetAttributes.ENTRIES]: {
      title: 'Einträge',
    },
    ...spacingAttributes,
  },
  propertiesGroups: [spacingGroup],
  validations: [
    [
      TimelineEntryWidgetAttributes.TITLE,
      (value: string): ValidationInfo => checkValue(value, Message.TITLE_MISSING, 'error'),
    ],
  ],
  initialContent: {
    [TimelineWidgetAttributes.TITLE]: 'Beispiel-Titel',
    [TimelineWidgetAttributes.ENTRIES]: [new TimelineEntryWidgetClass(), new TimelineEntryWidgetClass()],
    ...spacingContent,
  },
});

Scrivito.provideEditingConfig(TimelineEntryWidgetId, {
  title: WidgetTitle.TIMELINE_ENTRY,
  properties: Object.values(TimelineEntryWidgetAttributes),
  attributes: {
    [TimelineEntryWidgetAttributes.TITLE]: {
      title: 'Titel',
    },
    [TimelineEntryWidgetAttributes.DATE]: {
      title: 'Datum',
    },
    [TimelineEntryWidgetAttributes.IMAGE]: {
      title: 'Bild',
    },
    [TimelineEntryWidgetAttributes.SHORT_TEXT]: {
      title: 'Kurztext (wird immer angezeigt)',
    },
    [TimelineEntryWidgetAttributes.TEXT]: {
      title: 'Text (wird zusätzlich im ausgeklappten Zustand angezeigt)',
    },
  },
  validations: [
    [
      TimelineEntryWidgetAttributes.TITLE,
      (value: string): ValidationInfo => checkValue(value, Message.TITLE_MISSING, 'error'),
    ],
    [
      TimelineEntryWidgetAttributes.DATE,
      (value: string): ValidationInfo => checkValue(value, Message.DATE_MISSING, 'error'),
    ],
    [
      TimelineEntryWidgetAttributes.SHORT_TEXT,
      (value: string): ValidationInfo => checkValue(value, Message.SHORT_TEXT_MISSING, 'error'),
    ],
  ],
  initialContent: {
    [TimelineEntryWidgetAttributes.TITLE]: 'Beispiel-Titel',
    [TimelineEntryWidgetAttributes.DATE]: new Date(new Date().getFullYear() - 1, 0, 1),
    [TimelineEntryWidgetAttributes.SHORT_TEXT]: '<p>Kurzer Text</p>',
    [TimelineEntryWidgetAttributes.TEXT]: '<p>Ausgeklappter Text</p>',
  },
});
