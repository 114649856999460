import * as Scrivito from 'scrivito';
import { IconObjectAttributes, IconObjectId } from './icon-object-definitions';

Scrivito.provideEditingConfig(IconObjectId, {
  title: 'Icon',
  properties: [IconObjectAttributes.TITLE, IconObjectAttributes.TAGS],
  attributes: {
    [IconObjectAttributes.TITLE]: {
      title: 'Title',
      description: 'A title used for searching icons',
    },
    [IconObjectAttributes.TAGS]: {
      title: 'Tags',
      description: 'Additional search keywords',
    },
  },
});
