import * as React from 'react';
import * as Scrivito from 'scrivito';
import styles from './image-widget.module.scss';
import {
  ImageWidgetAttributes,
  ImageWidgetId,
  ImageWidgetPosition,
  ImageWidgetBubbleColor,
} from './image-widget-definitions';

import { getAttributeOrFallback } from '../../utils/scrivito/image.utils';
import { ImageWidget, ImageWidgetClass } from './image-widget-class';
import classNames from 'classnames';
import { ImageObjectAttributes } from '../../objects';
import { spacingClassName } from '../../utils/scrivito/spacing-definitions';
import { ResponsiveImage } from '../../components/controls/image/responsive-image/responsive-image.scrivito';

export const ImageWidgetComponent: React.FC<{ widget: ImageWidget }> = ({ widget }) => {
  // Get the image-object of the widget.
  const image = getAttributeOrFallback(widget, ImageWidgetAttributes.IMAGE);

  /*
  Returns a Scrivito.ImageTag if no image has been selected for the widget yet,
  so that the editor can select an image by clicking on the widget.
  */
  if (!image) {
    return (
      <Scrivito.ImageTag
        className={spacingClassName(widget)}
        content={widget as unknown as Scrivito.Obj}
        attribute="image"
      />
    );
  }

  // Get the binary of the selected image.
  const binary = image.get('blob');
  const imgWidth = image.metadata().get('width') as number;
  const imgHeight = image.get('height') as number;

  // Get the alternative text of the selected image.
  // if an alt tag is set for the image for the current usage, use that
  // otherwise use the global alt text set in the content browser
  const altText = widget.get(ImageWidgetAttributes.ALT_TAG) || image.get(ImageObjectAttributes.ALTERNATIVE_TEXT);

  const titleText = widget.get(ImageWidgetAttributes.TITLE_TAG) || image.get(ImageObjectAttributes.TITLE);

  const hasCaption = (widget.get(ImageWidgetAttributes.CAPTION) as string) || null;
  const caption =
    hasCaption || Scrivito.isInPlaceEditingActive() ? (
      <Scrivito.ContentTag content={widget} attribute={ImageWidgetAttributes.CAPTION} />
    ) : undefined;
  const position = widget.get(ImageWidgetAttributes.POSITIONING) ?? ImageWidgetPosition.CENTER;

  const width = widget.get(ImageWidgetAttributes.WIDTH);
  const link = (widget.get(ImageWidgetAttributes.LINK) as Scrivito.Link) || null;
  const scalable = widget.get(ImageWidgetAttributes.SCALABLE) as boolean;

  const bubble = widget.get(ImageWidgetAttributes.BUBBLE) as ImageWidgetBubbleColor;
  const isPortraitMode = image.metadata().get('height') > image.metadata().get('width');

  return (
    <Scrivito.WidgetTag className={classNames({ FullWidth: position === ImageWidgetPosition.FULL })}>
      <div className={spacingClassName(widget)}>
        <div data-test-id={ImageWidgetId} className={classNames(styles.ImageWidget, styles[position])}>
          <div
            className={classNames(styles.ImageWrapper, {
              [styles.HeightLimited]: isPortraitMode,
            })}
          >
            <ResponsiveImage
              alt={altText}
              title={titleText}
              caption={caption}
              binary={binary}
              width={width}
              meta={imgWidth && imgHeight ? { width: imgWidth, height: imgHeight } : undefined}
              link={link}
              bubble={bubble}
              scalable={scalable}
            />
          </div>
        </div>
      </div>
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(ImageWidgetClass, ImageWidgetComponent);
