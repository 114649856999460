// eslint-disable-next-line max-len
import { WagonSelectionItemWidgetAttributes } from './../wagon-selection-item-widget/wagon-selection-item-widget-definitions';
import * as Scrivito from 'scrivito';
import { HelperText, Message, WidgetTitle } from '../../utils/translations';
import { ValidationInfo } from '../../utils/type.utils';
import {
  FormElementBaseAttributes,
  formElementBaseAttributesValidation,
  FormElementBaseGroupLabelAttributes,
  groupLabelFormElementBaseClassAttributesDefinition,
  groupLabelFormElementBaseClassAttributesValidation,
} from '../form-widget';
import { WagonSelectionWidgetAttributes, WagonSelectionWidgetId } from './wagon-selection-widget-definitions';
import Thumbnail from '../../assets/thumbnails/wagon-selection.svg';
import { WagonSelectionItemWidgetClass } from '../wagon-selection-item-widget';

const minRequiredItems = 2;

Scrivito.provideEditingConfig(WagonSelectionWidgetId, {
  title: WidgetTitle.WAGON_SELECTION,
  titleForContent: (obj) =>
    `${WidgetTitle.WAGON_SELECTION}: ${
      (obj.get(FormElementBaseAttributes.NAME) as string) || Message.FORM_INPUT_NAME_MISSING
    }`,
  thumbnail: Thumbnail,
  properties: [...Object.values(FormElementBaseGroupLabelAttributes), ...Object.values(WagonSelectionWidgetAttributes)],
  propertiesGroups: [{ title: 'Validation', properties: [...Object.values(FormElementBaseAttributes)] }],
  attributes: {
    ...(groupLabelFormElementBaseClassAttributesDefinition as object),

    [WagonSelectionWidgetAttributes.DISABLED]: {
      title: HelperText.DISABLE_ALL,
    },
    [WagonSelectionWidgetAttributes.ITEMS]: {
      title: HelperText.WAGONS,
    },
    [WagonSelectionWidgetAttributes.INITIAL_VALUE]: {
      title: HelperText.INPUT_DEFAULT_VALUE,
      description: HelperText.RADIO_GROUP_DEFAULT_SELECTED_RADIO_DESCRIPTION,
    },
  },
  validations: [
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(formElementBaseAttributesValidation as Array<any>),
    ...(groupLabelFormElementBaseClassAttributesValidation as Array<never>),
    [
      WagonSelectionWidgetAttributes.INITIAL_VALUE,
      (value: string, { widget }: { widget: Scrivito.Widget }): ValidationInfo => {
        const wagonSelectionItemValues = (widget.get(WagonSelectionWidgetAttributes.ITEMS) as Scrivito.Widget[]).map(
          (item) => item.get(WagonSelectionItemWidgetAttributes.VALUE)
        );
        if (!wagonSelectionItemValues.includes(value)) {
          return {
            message: Message.WAGON_SELECTION_DEFAULT_VALUE_REQUIRED,
            severity: 'error',
          };
        }
        return undefined;
      },
    ],
  ],
  initialContent: {
    [WagonSelectionWidgetAttributes.INITIAL_VALUE]: '0',
    [WagonSelectionWidgetAttributes.ITEMS]: [...Array(minRequiredItems)].map(
      (_, index) =>
        new WagonSelectionItemWidgetClass({
          [WagonSelectionItemWidgetAttributes.NAME]: `${HelperText.WAGON_GROUP_LABEL_INITIAL_VALUE} ${index + 1}`,
          [WagonSelectionItemWidgetAttributes.VALUE]: String(index),
          [WagonSelectionItemWidgetAttributes.CONTENT]: 'Beispieltext',
          [WagonSelectionItemWidgetAttributes.PRICE]: '0',
        })
    ),
  },
});
