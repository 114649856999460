import * as Scrivito from 'scrivito';
import { IntroTextWidget, IntroTextWidgetClass } from './intro-text-widget-class';
import React, { ElementType } from 'react';
import { IntroTextWidgetAttributes, IntroTextWidgetId } from './intro-text-widget-definitions';
import styles from './intro-text-widget.module.scss';
import { HeadlineWidgetVariant } from '../headline-widget';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import { spacingClassName } from '../../utils/scrivito/spacing-definitions';
import { contentTagOrHtml } from '../../utils/scrivito/widget.utils';
import { LandingPageId } from '../../pages/landing-page';
import { LandingSubPageId } from '../../pages/landing-sub-page';

export const IntroTextWidgetComponent: React.FC<{ widget: IntroTextWidget }> = ({ widget }) => {
  const page = Scrivito.currentPage();
  const landingPage = page?.objClass() === LandingPageId || page?.objClass() === LandingSubPageId;
  const id = widget.get(IntroTextWidgetAttributes.ID) || undefined;
  const component = widget.get(IntroTextWidgetAttributes.COMPONENT) ?? '';

  return (
    <Scrivito.WidgetTag>
      <div className={spacingClassName(widget)}>
        <div
          className={classNames(styles.IntroTextWidget, {
            [styles.LandingPage]: landingPage,
          })}
        >
          <Typography
            variant={HeadlineWidgetVariant.XL}
            component={component as ElementType}
            id={id}
            data-test-id={IntroTextWidgetId}
          >
            {contentTagOrHtml(widget, IntroTextWidgetAttributes.HEADLINE, 'span')}
          </Typography>
          <div className={styles.Text}>{contentTagOrHtml(widget, IntroTextWidgetAttributes.TEXT)}</div>
        </div>
      </div>
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(IntroTextWidgetClass, IntroTextWidgetComponent);
