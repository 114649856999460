import * as Scrivito from 'scrivito';
import { IntroTextWidgetAttributes, IntroTextWidgetId } from './intro-text-widget-definitions';
import { ValidationInfo } from '../../utils/type.utils';
import { checkValue, formElementIdAttributeValidation } from '../../utils/scrivito/validation';
import { HelperText, Message, WidgetTitle } from '../../utils/translations';
import { HeadlineWidgetTag } from '../headline-widget';
import { spacingAttributes, spacingContent, spacingGroup } from '../../utils/scrivito/spacing-definitions';
import Thumbnail from '../../assets/thumbnails/intro-text.svg';

Scrivito.provideEditingConfig(IntroTextWidgetId, {
  title: WidgetTitle.INTRO_TEXT,
  thumbnail: Thumbnail,
  properties: Object.values(IntroTextWidgetAttributes),
  attributes: {
    [IntroTextWidgetAttributes.HEADLINE]: {
      title: 'Überschrift',
    },
    [IntroTextWidgetAttributes.TEXT]: {
      title: 'Text',
      options: {
        toolbar: ['bold', 'italic', 'link', 'mark', 'subscript', 'superscript'],
      },
    },
    [IntroTextWidgetAttributes.COMPONENT]: {
      title: 'Hierarchie der Überschrift (HTML)',
      values: [
        { value: HeadlineWidgetTag.H1, title: '<h1>' },
        { value: HeadlineWidgetTag.H2, title: '<h2>' },
        { value: HeadlineWidgetTag.H3, title: '<h3>' },
        { value: HeadlineWidgetTag.H4, title: '<h4>' },
        { value: HeadlineWidgetTag.H5, title: '<h5>' },
        { value: HeadlineWidgetTag.H6, title: '<h6>' },
      ],
    },
    [IntroTextWidgetAttributes.ID]: {
      title: HelperText.ELEMENT_ID,
      description: HelperText.ELEMENT_ID_DESCRIPTION,
    },
    ...spacingAttributes,
  },
  propertiesGroups: [spacingGroup],
  validations: [
    [
      IntroTextWidgetAttributes.HEADLINE,
      (value: string | null): ValidationInfo => checkValue(value, Message.HEADLINE_MISSING, 'warning'),
    ],
    [
      IntroTextWidgetAttributes.TEXT,
      (value: string | null): ValidationInfo => checkValue(value, Message.TEXT_MISSING, 'warning'),
    ],
    formElementIdAttributeValidation(IntroTextWidgetAttributes.ID),
  ],
  initialContent: {
    [IntroTextWidgetAttributes.HEADLINE]: 'Überschrift',
    [IntroTextWidgetAttributes.TEXT]: '<p>Beispieltext</p>',
    [IntroTextWidgetAttributes.COMPONENT]: HeadlineWidgetTag.H2,
    ...spacingContent,
  },
});
