export const IconListItemWidgetId = 'IconListItemWidget';

export enum IconListItemWidgetAttributes {
  TEXT = 'text',
  ICON = 'icon',
}

export enum IconListItemWidgetIcons {
  LIST = 'list',
  CHECKMARK = 'checkmark',
  CROSS = 'cross',
  STAR = 'star',
}
