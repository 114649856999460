export const HeadlineWidgetId = 'HeadlineWidget';

export enum HeadlineWidgetAttributes {
  ID = 'idHeadline',
  TEXT = 'text',
  COMPONENT = 'component',
  VARIANT = 'variant',
}

export enum HeadlineWidgetTag {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
}

export enum HeadlineWidgetVariant {
  XS = 'h6',
  S = 'h5',
  M = 'h4',
  L = 'h3',
  XL = 'h2',
  XXL = 'h1',
}
