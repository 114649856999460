import { HelperText, Message, WidgetTitle } from './../../utils/translations';
import * as Scrivito from 'scrivito';
import {
  FormElementBaseAttributes,
  formElementBaseAttributesDefinition,
  formElementBaseAttributesValidation,
} from '../form-widget/form-widget-definitions';
import { DateTimeWidgetAttributes, DateTimeWidgetId, DateTimeWidgetTypes } from './date-time-widget-definitions';
import Thumbnail from '../../assets/thumbnails/datetime.svg';
import { ValidationInfo } from '../../utils/type.utils';
import { checkDate, checkValue, checkYear } from '../../utils/scrivito/validation';
import dayjs from 'dayjs';

Scrivito.provideEditingConfig(DateTimeWidgetId, {
  title: WidgetTitle.DATE_TIME,
  titleForContent: (obj) =>
    `${WidgetTitle.DATE_TIME}: ${
      (obj.get(FormElementBaseAttributes.NAME) as string) || Message.FORM_INPUT_NAME_MISSING
    }`,
  thumbnail: Thumbnail,
  properties: (obj) => [
    DateTimeWidgetAttributes.LABEL,
    DateTimeWidgetAttributes.NOW,
    [DateTimeWidgetAttributes.VALUE, { enabled: obj.get(DateTimeWidgetAttributes.NOW) === false }],
    DateTimeWidgetAttributes.TYPE,
    DateTimeWidgetAttributes.HELPER_TEXT,
    [
      DateTimeWidgetAttributes.MIN_DATE_NOW,
      { enabled: obj.get(DateTimeWidgetAttributes.TYPE) === DateTimeWidgetTypes.DATE },
    ],
    [
      DateTimeWidgetAttributes.MIN_DATE,
      {
        enabled:
          obj.get(DateTimeWidgetAttributes.MIN_DATE_NOW) === false &&
          obj.get(DateTimeWidgetAttributes.TYPE) === DateTimeWidgetTypes.DATE,
      },
    ],
    [
      DateTimeWidgetAttributes.MAX_DATE,
      { enabled: obj.get(DateTimeWidgetAttributes.TYPE) === DateTimeWidgetTypes.DATE },
    ],
    DateTimeWidgetAttributes.DISABLED,
    DateTimeWidgetAttributes.READ_ONLY,
  ],
  propertiesGroups: [
    {
      title: 'Validation',
      properties: [...Object.values(FormElementBaseAttributes)],
    },
  ],

  attributes: {
    [DateTimeWidgetAttributes.LABEL]: {
      title: 'Label',
    },
    [DateTimeWidgetAttributes.VALUE]: {
      title: 'Initialer Wert',
      description: HelperText.DATE_INITIAL_VALUE,
    },
    [DateTimeWidgetAttributes.NOW]: {
      title: 'Aktuelles Datum/Uhrzeit anzeigen',
      description: HelperText.DATE_NOW,
    },
    [DateTimeWidgetAttributes.MIN_DATE]: {
      title: 'Minimal mögliches Jahr (jjjj)',
    },
    [DateTimeWidgetAttributes.MIN_DATE_NOW]: {
      title: 'Minimal mögliches Datum ist die aktuelle Zeit',
    },
    [DateTimeWidgetAttributes.MAX_DATE]: {
      title: 'Maximal mögliches Jahr (jjjj)',
    },
    [DateTimeWidgetAttributes.TYPE]: {
      title: 'Eingabetyp',
      values: [
        { value: DateTimeWidgetTypes.DATE, title: 'Datum' },
        { value: DateTimeWidgetTypes.TIME, title: 'Uhrzeit' },
      ],
    },
    [DateTimeWidgetAttributes.HELPER_TEXT]: {
      title: 'Hilfstext',
    },
    ...(formElementBaseAttributesDefinition as object),
  },
  validations: [
    [DateTimeWidgetAttributes.VALUE, (value: string): ValidationInfo => checkDate(value, Message.VALID_DATE)],
    [DateTimeWidgetAttributes.LABEL, (value: string): ValidationInfo => checkValue(value, Message.LABEL_FIELD_MISSING)],
    [DateTimeWidgetAttributes.TYPE, (value: string): ValidationInfo => checkValue(value, Message.CHOOSE_OPTION)],
    [DateTimeWidgetAttributes.MIN_DATE, (value: string): ValidationInfo => checkYear(value, Message.VALID_YEAR)],
    [DateTimeWidgetAttributes.MAX_DATE, (value: string): ValidationInfo => checkYear(value, Message.VALID_YEAR)],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(formElementBaseAttributesValidation as Array<any>),
  ],
  initialContent: {
    [DateTimeWidgetAttributes.LABEL]: 'Label',
    [DateTimeWidgetAttributes.NOW]: true,
    [DateTimeWidgetAttributes.TYPE]: DateTimeWidgetTypes.DATE,
    [DateTimeWidgetAttributes.MIN_DATE_NOW]: false,
    [DateTimeWidgetAttributes.MAX_DATE]: dayjs().add(10, 'years').format('YYYY'),
    [DateTimeWidgetAttributes.MIN_DATE]: dayjs().subtract(90, 'years').format('YYYY'),
  },
});
