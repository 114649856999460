import * as Scrivito from 'scrivito';
import { ModalAttributes } from '../../../pages/home-page/home-page-modal';
import { stripHtml } from '../../../utils/string';

export type PageBannerText = [boolean, string, string];

/**
 * This hook returns the important values for showing the banner or banner related components.
 * The return value is an array consisting of following values
 * 1. A boolean, if the banner should be shown
 * 2. The headline text as string
 * 3. The main text as string
 */
export const usePageBannerInfo = (): PageBannerText => {
  const root = Scrivito.Obj.root();
  if (!root) {
    return [false, '', ''];
  }

  const now = new Date();
  const type = root?.get(ModalAttributes.TYPE) as string;
  const headline = root?.get(ModalAttributes.HEADLINE) as string;
  const showPageBannerFrom = root?.get(ModalAttributes.VISIBLE_FROM) as Date | undefined;
  const showPageBannerUntil = root?.get(ModalAttributes.VISIBLE_UNTIL) as Date | undefined;

  const showPageBanner =
    !(showPageBannerFrom && showPageBannerFrom > now) && // Don't show too early
    !(showPageBannerUntil && showPageBannerUntil < now) && // Don't show too late
    (showPageBannerFrom || showPageBannerUntil); // Don't show, when no time is set

  if (showPageBanner && type === 'warning' && headline) {
    const textHtml = (root?.get(ModalAttributes.TEXT) ?? '') as string;
    const text = stripHtml(textHtml);

    return [true, headline, text];
  }

  return [false, '', ''];
};
