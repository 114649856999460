// Add spacing to a component
// *-class.ts: include `...spacingClassAttributes(),` to the attributes
// *-editing-config.ts:
//   - add `...spacingAttributes,` to the attributes
//   - add `propertiesGroups: [spacingGroup],` after the properties
//   - add `...spacingContent` to the initial Content
// *-widget.tsx: add `className={spacingClassName(widget)}` to the widget tag

export enum SpacingWidgetAttributes {
  SPACING = 'spacing',
}

export enum SpacingSize {
  LARGE = 'large',
  NORMAL = 'normal',
  SMALL = 'small',
}

export const spacingContent = {
  [SpacingWidgetAttributes.SPACING]: SpacingSize.NORMAL,
};

export const spacingAttributes = {
  [SpacingWidgetAttributes.SPACING]: {
    title: 'Abstand zum nächsten Widget',
    values: [
      { value: SpacingSize.LARGE, title: 'Groß' },
      { value: SpacingSize.NORMAL, title: 'Normal' },
      { value: SpacingSize.SMALL, title: 'Klein' },
    ],
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const spacingClassAttributes = (): any => {
  return {
    [SpacingWidgetAttributes.SPACING]: [
      'enum',
      {
        values: Object.values(SpacingSize),
      },
    ],
  };
};

export const spacingGroup = {
  title: 'Abstand',
  key: 'widget-spacing',
  properties: [SpacingWidgetAttributes.SPACING],
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const spacingClassName = (widget: any): string => {
  const size = widget.get(SpacingWidgetAttributes.SPACING) as SpacingSize;
  if (size === SpacingSize.LARGE) {
    return 'SpacingLarge';
  }
  if (size === SpacingSize.SMALL) {
    return 'SpacingSmall';
  }
  return 'Spacing';
};
