import * as Scrivito from 'scrivito';
import { TableRowWidgetId } from '../table-row-widget/table-row-widget-definitions';
import { TableCellWidgetAttributes, TableCellWidgetId } from './table-cell-widget-definitions';

export const TableCellWidgetClass = Scrivito.provideWidgetClass(TableCellWidgetId, {
  attributes: {
    [TableCellWidgetAttributes.CONTENT]: 'html',
    [TableCellWidgetAttributes.WIDTH]: 'string',
  },
  onlyInside: [TableRowWidgetId],
});

export type TableCellWidget = InstanceType<typeof TableCellWidgetClass>;
