import * as Scrivito from 'scrivito';
import { Message } from '../../../utils/translations';
import { FormElementBaseAttributes } from '../../form-widget';
import { FormColumnWidgetAttributes } from '../../form-column-widget';
import { WizardWidgetId } from '../../wizard-widget';

export interface FormControlProps {
  name: string;
  required: boolean;
}

// gets placeholder for widget from scrivito widget attributes
export const placeholderForWidget = (widget: Scrivito.Widget): string | undefined =>
  (widget.get('placeholder') as string) ?? undefined;

// gets helper text for widget from scrivito widget attributes
export const helperTextForWidget = (widget: Scrivito.Widget): React.ReactElement => (
  <Scrivito.ContentTag content={widget} attribute="helperText" />
);

// gets invalid message for widget from scrivito widget attributes
export const invalidMessageForWidget = (widget: Scrivito.Widget): string =>
  (widget.get(FormElementBaseAttributes.CUSTOM_INVALID_MESSAGE) as string) || Message.INVALID_MESSAGE;

export const invalidEmailMessageForWidget = (widget: Scrivito.Widget): string =>
  (widget.get(FormElementBaseAttributes.CUSTOM_INVALID_MESSAGE) as string) || Message.INVALID_MESSAGE_EMAIL;

// gets optional attribute for widget from scrivito widget attributes
export const isFormControlRequired = (widget: Scrivito.Widget): boolean =>
  !(widget.get(FormElementBaseAttributes.OPTIONAL) as boolean) ?? true;

// gets reference name for widget from scrivito widget attributes
export const referenceNameForWidget = (widget: Scrivito.Widget): string =>
  (widget.get(FormElementBaseAttributes.NAME) as string) ?? '';

// inserts name and required attribute in props
export const injectFormControlProps = (widget: Scrivito.Widget): FormControlProps => {
  const name = referenceNameForWidget(widget);
  const required = isFormControlRequired(widget);

  return { name, required };
};

// checks if a widget is a form widget
export const isAFormControlWidget = (widgetToCheck: Scrivito.Widget): boolean => {
  const referenceName = widgetToCheck.get(FormElementBaseAttributes.NAME) as string;
  return !!referenceName?.length;
};

// checks if a widget is a form widget
export const isAFormColumnWidget = (widgetToCheck: Scrivito.Widget): boolean => {
  const children = widgetToCheck.get(FormColumnWidgetAttributes.ELEMENTS) as Scrivito.Widget[];
  return !!children;
};

export const isWizardWidget = (widgetToCheck: Scrivito.Widget): boolean => {
  return widgetToCheck.objClass() === WizardWidgetId;
};

// inserts data-value-text for props
export const injectValueText = (widget: Scrivito.Widget): { 'data-value-text': string } => {
  const valueText = (widget.get('label') as string) ?? '';

  return {
    'data-value-text': valueText,
  };
};

/**
 * @description Checks if a field-widget has a regular-expression.
 * If so, the regular-expression will be created and returned.
 * If some error occurs, e.g. the regular expression is not valid, an error-message will be logged.
 * This should not happen because the regular-expression will be validated before, when it has been
 * entered into the fields properties.
 *
 * @param widget
 */
export const regExpForWidget = (widget: Scrivito.Widget): RegExp | undefined => {
  const regExpString = widget.get('regexp') as string;
  if (regExpString) {
    try {
      const split = regExpString.split('/');
      const flags = split.pop();
      split.shift();
      const pattern = split.join('/');
      return new RegExp(pattern, flags);
    } catch (err) {
      console.error(err);
    }
  }
};
