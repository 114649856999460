import React, { useState } from 'react';
import styles from './landing-navigation.module.scss';
import classNames from 'classnames';
import * as Scrivito from 'scrivito';
import { NavigationAttributes } from '../../../../pages/landing-page/landing-page-navigation';
import { LandingNavigationHeader } from './header/landing-navigation-header';
import { LandingNavigationDrawer } from './drawer/landing-navigation-drawer';

interface LandingNavigationComponentProps {
  small?: boolean;
}

const LandingNavigationComponent: React.FC<LandingNavigationComponentProps> = (props) => {
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState<boolean>(false);

  const root = Scrivito.Obj.root();
  const navElements = (root?.get(NavigationAttributes.NAVIGATION) as Scrivito.Link[]) ?? [];

  return (
    <div id="skip-link-nav" tabIndex={-1} className={classNames(styles.Navigation, { [styles.Small]: props.small })}>
      {/* Mega menu navigation area */}
      <LandingNavigationHeader small={!!props.small} onBurgerButton={(): void => setMobileDrawerOpen(true)} />

      <LandingNavigationDrawer
        navigation={navElements}
        open={mobileDrawerOpen}
        onClose={(): void => setMobileDrawerOpen(false)}
      />
    </div>
  );
};

export const LandingNavigation = Scrivito.connect(LandingNavigationComponent);
