import { useField, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Scrivito from 'scrivito';
import { TextInputComponent } from '../../components/controls/text-input/text-input';
import { getFormikErrorMessage } from '../../utils/formik/get-error';
import { FormElementBaseAttributes } from '../form-widget';
import { useFormContext } from '../form-widget/FormContext';
import { TextInputWidget, TextInputWidgetClass } from './text-input-widget-class';
import { TextInputWidgetAttributes, TextInputWidgetTypes } from './text-input-widget-definitions';

export const TextInputWidgetComponent: React.FC<{ widget: TextInputWidget }> = ({ widget }) => {
  const [value, setValue] = useState<string>(widget.get(TextInputWidgetAttributes.VALUE) ?? '');

  const initValue = widget.get(TextInputWidgetAttributes.VALUE);
  const label = widget.get(TextInputWidgetAttributes.LABEL) ?? '';
  const placeholder = widget.get(TextInputWidgetAttributes.PLACEHOLDER) ?? '';
  const type = widget.get(TextInputWidgetAttributes.TYPE) ?? TextInputWidgetTypes.TEXT;
  const optional = !!widget.get(FormElementBaseAttributes.OPTIONAL) as boolean;
  const helperText = widget.get(TextInputWidgetAttributes.HELPER_TEXT) ?? '';
  const disabled = widget.get(TextInputWidgetAttributes.DISABLED);
  const readOnly = widget.get(TextInputWidgetAttributes.READ_ONLY);
  const multiLine = widget.get(TextInputWidgetAttributes.MULTILINE);

  const name = widget.get(FormElementBaseAttributes.NAME);

  const formContext = useFormContext();
  const { handleBlur } = useFormikContext();
  const [field, meta] = useField(name);

  useEffect(() => {
    if (initValue) {
      setValue(initValue);
    }
  }, [initValue]);

  const { t } = useTranslation();

  if (formContext.isHiddenElement(name)) {
    return null;
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    formContext.setInputValue(name, event.target.value);

    setValue?.(event.target.value);
    field.onChange(event);
  };

  return (
    <Scrivito.WidgetTag hidden={!Scrivito.isInPlaceEditingActive() && type === TextInputWidgetTypes.HIDDEN}>
      <TextInputComponent
        name={name}
        type={type}
        label={type === TextInputWidgetTypes.HIDDEN ? t('component.textInput.hiddenInputLabel') : label}
        value={value}
        error={meta.touched && !!meta.error}
        errorMessage={getFormikErrorMessage(meta.error)}
        optional={optional}
        helperText={helperText}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={type === TextInputWidgetTypes.HIDDEN ? true : readOnly}
        multiline={multiLine}
        onChange={onChange}
        onBlur={handleBlur}
        inEditMode={Scrivito.isInPlaceEditingActive()}
      />
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(TextInputWidgetClass, TextInputWidgetComponent);
