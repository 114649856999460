import { useLanguageVersions } from '../../../utils/scrivito/language-versions';
import { getLinkUrl } from '../../../utils/scrivito';

export function useMetaDataLanguages(): React.ReactNode[] {
  const languageVersions = useLanguageVersions();
  const result: React.ReactNode[] = [];

  for (const version of languageVersions) {
    const linkUrl = getLinkUrl(version.link);

    if (linkUrl) {
      result.push(<link rel="alternate" key={version.short} href={linkUrl} hrefLang={version.short} />);

      if (version.short === 'de') {
        result.push(<link rel="alternate" key={version.short} href={linkUrl} hrefLang="x-default" />);
      }
    }
  }

  return result;
}
