import * as Scrivito from 'scrivito';
import { HelperText, Message } from '../../utils/translations';
import { ValidationInfo } from '../../utils/type.utils';
import { ClosingLineWidgetClass } from '../../widgets/closing-line-widget/closing-line-widget-class';

export const FooterWidgetId = 'FooterWidget';

export enum FooterWidgetAttributes {
  FOOTER_NAVIGATION = 'footerNavigation',
  SOCIAL_MEDIA = 'socialMedia',
  APPOINTMENT = 'appointment',
  CLOSING_LINE = 'closingLine',
}

export const footerWidgetClassAttributes = {
  [FooterWidgetAttributes.FOOTER_NAVIGATION]: ['widgetlist', { only: 'LinkListWidget' }],
  [FooterWidgetAttributes.SOCIAL_MEDIA]: ['widgetlist', { only: 'SocialMediaWidget' }],
  [FooterWidgetAttributes.APPOINTMENT]: ['widgetlist', { only: 'AppointmentWidget' }],
  [FooterWidgetAttributes.CLOSING_LINE]: ['widgetlist', { only: 'ClosingLineWidget' }],
};

export const footerWidgetAttributesDefinition = {
  [FooterWidgetAttributes.FOOTER_NAVIGATION]: {
    title: HelperText.FOOTER_NAVIGATION,
  },
  [FooterWidgetAttributes.SOCIAL_MEDIA]: {
    title: HelperText.SOCIAL_MEDIA,
  },
  [FooterWidgetAttributes.CLOSING_LINE]: {
    title: HelperText.CLOSING_LINE,
  },
  [FooterWidgetAttributes.APPOINTMENT]: {
    title: HelperText.APPOINTMENT,
  },
};

const widgetListValidationFn = (list: Array<Scrivito.Widget>): ValidationInfo => {
  if (!list || list.length <= 0) {
    return {
      message: Message.FOOTER_WIDGETLIST_MIN_ONE_ITEM,
      severity: 'error',
    };
  } else if (list.length !== 1) {
    return {
      message: Message.FOOTER_WIDGETLIST_ONLY_ONE_ITEM,
      severity: 'error',
    };
  }
  return undefined;
};

export const footerWidgetValidationsDefinition = [[FooterWidgetAttributes.CLOSING_LINE, widgetListValidationFn]];

export const footerWidgetInitialContent = {
  [FooterWidgetAttributes.CLOSING_LINE]: [new ClosingLineWidgetClass()],
};
