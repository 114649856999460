import React, { ReactNode } from 'react';
import styles from './search-result.module.scss';
import classNames from 'classnames';
import Highlighter from 'react-highlight-words';
import { ReactComponent as IconStar } from '../../../assets/icons/icon-star.svg';
import * as Scrivito from 'scrivito';
import { renderFileIcon } from '../../../utils/icons.utils';
import { ReactComponent as IconDownload } from '../../../assets/icons/icon-download.svg';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

/**
 * Properties for the search result component
 */
export interface SearchResultProps {
  headline: string;
  text: string;
  link: Scrivito.Obj;
  linkText: string;
  keywords: string[];
  highlight: boolean;
  isMediaFile?: boolean;
  fileClick?: (event: React.MouseEvent) => void;
}

/**
 * Creates a search result component
 */
export const SearchResultComponent: React.FC<SearchResultProps> = (props) => {
  const { t } = useTranslation();

  const renderHeadline = (): ReactNode => {
    if (props.isMediaFile) {
      return (
        <Button className={styles.DownloadItem} onClick={props.fileClick}>
          <span className={styles.FileIcon}>{renderFileIcon(props.headline)}</span>
          <Highlighter
            highlightClassName={styles.HighlightedHeadline}
            searchWords={props.keywords}
            autoEscape={true}
            textToHighlight={props.headline}
          />
          <span className={styles.DownloadIcon}>
            <IconDownload />
          </span>
        </Button>
      );
    }

    // Return other headline
    return (
      <Scrivito.LinkTag to={props.link}>
        <h2>
          <Highlighter
            highlightClassName={styles.HighlightedHeadline}
            searchWords={props.keywords}
            autoEscape={true}
            textToHighlight={props.headline}
          />
        </h2>
      </Scrivito.LinkTag>
    );
  };

  return (
    <div className={classNames(styles.SearchResult, { [styles.Highlight]: props.highlight })}>
      <div className={styles.HeadlineWrapper}>{renderHeadline()}</div>
      {props.highlight && <IconStar />}
      <p>
        <Highlighter
          highlightClassName={styles.HighlightedText}
          searchWords={props.keywords}
          autoEscape={true}
          textToHighlight={props.text}
        />
      </p>
      {!props.isMediaFile && <Scrivito.LinkTag to={props.link}>{t(props.linkText)}</Scrivito.LinkTag>}
    </div>
  );
};
