import * as Scrivito from 'scrivito';
import { spacingClassAttributes } from '../../utils/scrivito/spacing-definitions';
import { HeadlineWidgetTag } from '../headline-widget';
import { InfoTeaserWidgetId } from '../info-teaser-widget';
import { InfoTeaserGroupWidgetAttributes, InfoTeaserGroupWidgetId } from './info-teaser-group-widget-definitions';

export const InfoTeaserGroupWidgetClass = Scrivito.provideWidgetClass(InfoTeaserGroupWidgetId, {
  attributes: {
    [InfoTeaserGroupWidgetAttributes.HEADLINE_COMPONENT]: [
      'enum',
      {
        values: Object.values(HeadlineWidgetTag),
      },
    ],
    [InfoTeaserGroupWidgetAttributes.ITEMS]: ['widgetlist', { only: InfoTeaserWidgetId }],
    ...spacingClassAttributes(),
  },
});

export type InfoTeaserGroupWidget = InstanceType<typeof InfoTeaserGroupWidgetClass>;
