import * as Scrivito from 'scrivito';
import { ReactComponent as RheinbahnLogoImageOnly } from '../../../../../assets/img/rheinbahn-logo-image-only.svg';
import { ReactComponent as RheinbahnLogoTextOnly } from '../../../../../assets/img/rheinbahn-logo-text-only.svg';
import { ReactComponent as IconDownload } from '../../../../../assets/icons/icon-download-2.svg';
import { ReactComponent as IconBurger } from '../../../../../assets/icons/icon-burger.svg';
import { NavigationAttributes } from '../../../../../pages/landing-page/landing-page-navigation';
import classNames from 'classnames';
import styles from './landing-navigation-header.module.scss';
import { LandingNavigationHeaderLink } from './landing-navigation-header-link';
import { useTranslation } from 'react-i18next';
import { LandingPageAttributes } from '../../../../../pages/landing-page';
import { BasePageAttributes } from '../../../../../pages/base-page';

interface NavigationHeaderProps {
  small: boolean;
  onBurgerButton: () => void;
}

export const LandingNavigationHeader: React.FC<NavigationHeaderProps> = Scrivito.connect(
  ({ small, onBurgerButton }) => {
    const root = Scrivito.Obj.root();
    const title = <Scrivito.ContentTag tag="span" content={root} attribute={BasePageAttributes.TITLE} />;
    const homeLink = new Scrivito.Link({ url: '/' });
    const navElements = (root?.get(NavigationAttributes.NAVIGATION) as Scrivito.Link[]) ?? [];
    const downloadText = root?.get(LandingPageAttributes.DOWNLOAD_BUTTON_TEXT) as string;
    const downloadLink = root?.get(LandingPageAttributes.DOWNLOAD_BUTTON_LINK) as Scrivito.Link;
    const { t } = useTranslation();

    return (
      <div className={classNames(styles.NavigationHeader, { [styles.Small]: small })}>
        <div className={styles.LogoArea}>
          <Scrivito.LinkTag to={homeLink} aria-label={t('component.navigation.logoTitle')}>
            <div className={styles.LogoContainer}>
              <RheinbahnLogoImageOnly />
              <RheinbahnLogoTextOnly className={styles.Text} />
            </div>
          </Scrivito.LinkTag>
        </div>

        <div className={styles.TitleArea}>
          <Scrivito.LinkTag to={root}>{title}</Scrivito.LinkTag>
        </div>

        <nav className={styles.LinksArea}>
          {navElements.map((element, index) => (
            <LandingNavigationHeaderLink key={`nav-link-${index}`} element={element} />
          ))}
        </nav>

        <div className={classNames(styles.ButtonArea, { [styles.Small]: small })}>
          {downloadText && downloadLink && (
            <Scrivito.LinkTag to={downloadLink}>
              <IconDownload />
              <span>{downloadText}</span>
            </Scrivito.LinkTag>
          )}
        </div>

        <div className={styles.IconsArea}>
          <button
            className={classNames(styles.White, styles.MobileOnly)}
            onClick={onBurgerButton}
            aria-label={t('component.navigation.menuButtonTitle')}
          >
            <IconBurger />
          </button>
        </div>
      </div>
    );
  }
);
