export enum BasePageAttributes {
  TITLE = 'title',
  TAGS = 'tags',
  BODY = 'body',
  CANONICAL_URL = 'canonicalUrl',
  NO_INDEX = 'noIndex',
  META_TITLE = 'metaTitle',
  META_DESCRIPTION = 'metaDescription',
  META_TAGS = 'metaTags',
  SOCIAL_MEDIA_TITLE = 'socialMediaTitle',
  SOCIAL_MEDIA_DESCRIPTION = 'socialMediaDescription',
  SOCIAL_MEDIA_IMAGE = 'socialMediaImage',
  SEARCH_HIGHLIGHT = 'searchHighlight',
  FEATURE_SNIPPETS_RAW = 'featuresSnippetsRaw',
  APP_BANNER_ICON = 'appBannerIcon',
  APP_BANNER_TITLE = 'appBannerTitle',
  APP_BANNER_TEXT = 'appBannerText',
  APP_BANNER_URL_GOOGLE = 'appBannerUrlGoogle',
  APP_BANNER_URL_APPLE = 'appBannerAppleUrl',
}
