import { FormColumnWidgetId } from './../form-column-widget/form-column-widget-definitions';
import * as Scrivito from 'scrivito';
import { FormGroupWidgetId } from '../form-group-widget/form-group-widget-definitions';
import { getFormElementBaseClassAttributes } from '../form-widget';
import { DateTimeWidgetAttributes, DateTimeWidgetId, DateTimeWidgetTypes } from './date-time-widget-definitions';
import { WizardPageWidgetId } from '../wizard-page-widget';

export const DateTimeWidgetClass = Scrivito.provideWidgetClass(DateTimeWidgetId, {
  attributes: {
    [DateTimeWidgetAttributes.LABEL]: 'string',
    [DateTimeWidgetAttributes.VALUE]: 'string',
    [DateTimeWidgetAttributes.NOW]: 'boolean',
    [DateTimeWidgetAttributes.TYPE]: ['enum', { values: Object.values(DateTimeWidgetTypes) }],
    [DateTimeWidgetAttributes.MIN_DATE]: 'string',
    [DateTimeWidgetAttributes.MIN_DATE_NOW]: 'boolean',
    [DateTimeWidgetAttributes.MAX_DATE]: 'string',
    [DateTimeWidgetAttributes.HELPER_TEXT]: 'string',
    [DateTimeWidgetAttributes.DISABLED]: 'boolean',
    [DateTimeWidgetAttributes.READ_ONLY]: 'boolean',
    ...getFormElementBaseClassAttributes(),
  },
  onlyInside: [FormGroupWidgetId, FormColumnWidgetId, WizardPageWidgetId],
});

export type DateTimeWidget = InstanceType<typeof DateTimeWidgetClass>;
