import * as Scrivito from 'scrivito';
import { FormColumnWidgetId } from '../form-column-widget/form-column-widget-definitions';
import { FormGroupWidgetId } from '../form-group-widget';
import { getFormElementBaseClassAttributes, groupLabelFormElementBaseClassAttributes } from '../form-widget';

import { WagonSelectionItemWidgetId } from '../wagon-selection-item-widget/wagon-selection-item-widget-definitions';
import { WagonSelectionWidgetAttributes, WagonSelectionWidgetId } from './wagon-selection-widget-definitions';
import { WizardPageWidgetId } from '../wizard-page-widget';

export const WagonSelectionWidgetClass = Scrivito.provideWidgetClass(WagonSelectionWidgetId, {
  attributes: {
    ...(groupLabelFormElementBaseClassAttributes as object),
    [WagonSelectionWidgetAttributes.DISABLED]: 'boolean',
    [WagonSelectionWidgetAttributes.ITEMS]: ['widgetlist', { only: [WagonSelectionItemWidgetId] }],
    [WagonSelectionWidgetAttributes.INITIAL_VALUE]: 'string',
    ...getFormElementBaseClassAttributes(),
  },
  onlyInside: [FormGroupWidgetId, FormColumnWidgetId, WizardPageWidgetId],
});

export type WagonSelectionWidget = InstanceType<typeof WagonSelectionWidgetClass>;
