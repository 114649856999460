import React from 'react';
import styles from './video-container.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { VideoWidgetBubbleColor } from '../../../widgets/video-widget/video-widget-definitions';
import { isInternalScrivitoSubUrl } from '../../../utils/general.utils';

interface VideoContainerProps {
  acceptedCookies: boolean;
  url: string;
  caption?: React.ReactNode;
  position?: 'center' | 'left' | 'right';
  orientation?: 'landscape' | 'portrait';
  bubble?: VideoWidgetBubbleColor;
}

export const VideoContainer: React.FC<VideoContainerProps> = (props) => {
  // TODO: Cookie consent tool integration
  const { t } = useTranslation();
  let content: React.ReactNode | null = null;

  if (props.url) {
    const isYoutube = props.url.startsWith('https://www.youtube.com/');
    const isYoutubeShare = props.url.startsWith('https://youtu.be/');
    const isVimeo = props.url.startsWith('https://vimeo.com/');
    const isScrivito = isInternalScrivitoSubUrl(props.url);

    if (isYoutube) {
      // e.g. https://www.youtube.com/watch?v=4m9vDtV_AxE
      const url = new URL(props.url);
      const id = url.searchParams.get('v');

      content = (
        <iframe
          src={`https://www.youtube.com/embed/${id}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      );
    } else if (isYoutubeShare) {
      const url = new URL(props.url);
      const id = url.pathname.substring(1);

      content = (
        <iframe
          src={`https://www.youtube.com/embed/${id}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      );
    } else if (isVimeo) {
      const pattern = /^https:\/\/vimeo\.com\/(\d+)/;
      const match = pattern.exec(props.url);
      if (match) {
        const id = match[1];
        const url = `https://player.vimeo.com/video/${id}?color=e10025`;
        content = <iframe src={url} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen />;
      }
    }

    if (isScrivito) {
      content = (
        <video controls>
          <source src={props.url} />
        </video>
      );
    }
  }

  if (!content) {
    return <h3>{t('component.videoContainer.missingVideo')}</h3>;
  }

  const bubble = props.bubble ?? VideoWidgetBubbleColor.NO_COLOR;
  const bubbleClass = bubble !== VideoWidgetBubbleColor.NO_COLOR ? styles[bubble] : undefined;

  return (
    <div
      className={classNames(styles.VideoContainer, {
        [styles.Left]: props.position === 'left',
        [styles.Right]: props.position === 'right',
        [styles.Portrait]: props.orientation === 'portrait',
      })}
    >
      <figure
        className={classNames(styles.VideoBox, bubbleClass, {
          [styles.Bubble]: bubble !== VideoWidgetBubbleColor.NO_COLOR,
        })}
      >
        {content}
        <figcaption>{props.caption}</figcaption>
      </figure>
    </div>
  );
};
