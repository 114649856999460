import * as Scrivito from 'scrivito';
import {
  IconListItemWidgetAttributes,
  IconListItemWidgetIcons,
  IconListItemWidgetId,
} from './icon-list-item-widget-definitions';
import { HelperText, WidgetTitle } from '../../utils/translations';
import { makeTextPreview } from '../../utils/string';

Scrivito.provideEditingConfig(IconListItemWidgetId, {
  title: WidgetTitle.ICON_LIST_ITEM,
  properties: Object.values(IconListItemWidgetAttributes),
  attributes: {
    [IconListItemWidgetAttributes.TEXT]: {
      title: HelperText.TEXT,
      options: {
        toolbar: ['bold', 'italic', 'link', 'subscript', 'superscript'],
      },
    },
    [IconListItemWidgetAttributes.ICON]: {
      title: HelperText.ICON,
      values: [
        { value: IconListItemWidgetIcons.LIST, title: 'Wie Liste' },
        { value: IconListItemWidgetIcons.CHECKMARK, title: 'Checkmark' },
        { value: IconListItemWidgetIcons.CROSS, title: 'X' },
        { value: IconListItemWidgetIcons.STAR, title: 'Sternchen' },
      ],
    },
  },
  validations: [],
  initialContent: {
    [IconListItemWidgetAttributes.TEXT]: HelperText.TEXT,
    [IconListItemWidgetAttributes.ICON]: IconListItemWidgetIcons.LIST,
  },
  titleForContent: (widget) => {
    const text = widget.get(IconListItemWidgetAttributes.TEXT) as string;
    return makeTextPreview(text, WidgetTitle.ICON_LIST_ITEM, true);
  },
});
