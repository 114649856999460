import * as Scrivito from 'scrivito';
import { BasePage, BasePageAttributes } from '../base-page';
import { HomePageId } from './home-page-definitions';
import { ContentPageId } from '../content-page';
import { modalClassAttributes } from './home-page-modal';
import { error404ClassAttributes } from './home-page-404';
import { navigationClassAttributes } from './home-page-navigation';
import { HeroAttributes, heroClassAttributes } from '../properties/hero';
import { tooltipClassAttributes } from './home-page-tooltips';
import { searchClassAttributes } from './home-page-search';
import { footerWidgetClassAttributes } from './home-page-footer';
import { formsClassAttributes } from './home-page-forms';
import { OverviewPageId } from '../overview-page';
import { RootPageAttributes } from '../base-page/root-page-definitions';

export const HomePageObjectClass = Scrivito.provideObjClass(HomePageId, {
  extend: BasePage,
  onlyAsRoot: true,
  onlyChildren: [ContentPageId, OverviewPageId],
  attributes: {
    [RootPageAttributes.PATH]: 'string',
    [RootPageAttributes.BASE_SITE_ID]: 'string',
    ...(heroClassAttributes as object),
    ...(navigationClassAttributes as object),
    ...(modalClassAttributes as object),
    ...(error404ClassAttributes as object),
    ...(tooltipClassAttributes as object),
    ...(footerWidgetClassAttributes as object),
    ...(searchClassAttributes as object),
    ...(formsClassAttributes as object),
  },
  extractTextAttributes: [HeroAttributes.TITLE, HeroAttributes.SUBTITLE, BasePageAttributes.BODY],
});

export type HomePage = InstanceType<typeof HomePageObjectClass>;
