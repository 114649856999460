import * as Scrivito from 'scrivito';
import { CircleTeaserGroupWidgetId } from '../circle-teaser-group-widget/circle-teaser-group-widget-definitions';
import { CircleTeaserWidgetAttributes, CircleTeaserWidgetId } from './circle-teaser-widget-definitions';

export const CircleTeaserWidgetClass = Scrivito.provideWidgetClass(CircleTeaserWidgetId, {
  attributes: {
    [CircleTeaserWidgetAttributes.LINK]: 'link',
    [CircleTeaserWidgetAttributes.IMAGE]: ['reference', { only: 'Image' }],
    [CircleTeaserWidgetAttributes.HOVER_TEXT]: 'string',
  },
  onlyInside: [CircleTeaserGroupWidgetId],
});

export type CircleTeaserWidget = InstanceType<typeof CircleTeaserWidgetClass>;
