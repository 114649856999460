import { Button, Menu, MenuItem } from '@mui/material';
import classNames from 'classnames';
import * as React from 'react';
import * as Scrivito from 'scrivito';
import { ReactComponent as IconChevron } from '../../../assets/icons/chevron.svg';
import { BasePageAttributes } from '../../../pages/base-page';
import { HelperText } from '../../../utils/translations';
import style from './link-dropdown.module.scss';

/**
 * Properties for a link dropdown menu
 */
export interface LinkDropdownComponentProps {
  items: Array<Scrivito.Obj>;
  currentPage?: Scrivito.Obj;
  label?: string;
}

/**
 * Renders a link dropdown component
 */
export const LinkDropdownComponent: React.FC<LinkDropdownComponentProps> = ({ items, label, currentPage }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (item?: Scrivito.Obj): void => {
    setAnchorEl(null);
    if (item) {
      Scrivito.navigateTo(item);
    }
  };
  return (
    <div className={style.LinkDropdownContainer}>
      <Button
        className={classNames(style.LinkDropdown, {
          [style.IsOpen]: open,
        })}
        id="link-dropdown-host-btn"
        data-testid="link-dropdown-host"
        aria-controls={open ? 'link-dropdown-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <span>{label || HelperText.LINK_DROPDOWN_SELECT_SUBJECT}</span>
        <div className={style.Icon}>
          <IconChevron />
        </div>
      </Button>
      <Menu
        id="link-dropdown-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={(): void => handleClose()}
        className={style.LinkDropdownMenu}
        data-testid="link-dropdown-menu"
        MenuListProps={{
          'aria-labelledby': 'link-dropdown-host-btn',
        }}
      >
        {items?.length > 0 &&
          items.map((item: Scrivito.Obj, index: number) => {
            return (
              <MenuItem
                key={index}
                onClick={(): void => handleClose(item)}
                className={classNames(style.LinkDropdownMenuItem, {
                  [style.Selected]: item.id() === currentPage?.id(),
                })}
              >
                <Scrivito.LinkTag to={item}>{item.get(BasePageAttributes.TITLE) as string}</Scrivito.LinkTag>
              </MenuItem>
            );
          })}
      </Menu>
    </div>
  );
};
