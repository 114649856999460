import { useFormikContext } from 'formik';
import { ChangeEventHandler } from 'react';
import * as Scrivito from 'scrivito';
import { ResponsiveImage } from '../../components/controls/image/responsive-image/responsive-image.scrivito';
import { WagonSelectionItemComponent } from '../../components/controls/wagon-selection-item/wagon-selection-item';
import { WagonSelectionComponent } from '../../components/controls/wagon-selection/wagon-selection';
import { FormElementBaseAttributes, FormElementBaseGroupLabelAttributes } from '../form-widget';
import { referenceNameForWidget } from '../shared/form/form-controls-helper-methods';
import { WagonSelectionItemWidget } from '../wagon-selection-item-widget';
// eslint-disable-next-line max-len
import { WagonSelectionItemWidgetAttributes } from '../wagon-selection-item-widget/wagon-selection-item-widget-definitions';
import { WagonSelectionWidget, WagonSelectionWidgetClass } from './wagon-selection-widget-class';
import { WagonSelectionWidgetAttributes } from './wagon-selection-widget-definitions';
import { useFormContext } from '../form-widget/FormContext';

export const WagonSelectionWidgetComponent: React.FC<{ widget: WagonSelectionWidget }> = ({ widget }) => {
  const items = widget.get(WagonSelectionWidgetAttributes.ITEMS) as Array<WagonSelectionItemWidget>;
  const allDisabled = widget.get(WagonSelectionWidgetAttributes.DISABLED);
  const initialValue = widget.get(WagonSelectionWidgetAttributes.INITIAL_VALUE);
  const formName = widget.get(FormElementBaseAttributes.NAME);
  const labelVisible = widget.get(FormElementBaseGroupLabelAttributes.LABEL_GROUP_VISIBLE);

  const { setFieldValue } = useFormikContext();
  const formContext = useFormContext();
  const referenceName = referenceNameForWidget(widget);

  if (formContext.isHiddenElement(referenceName)) {
    return null;
  }

  const onChange = (event: ChangeEventHandler<HTMLInputElement>, newValue: string | null): void => {
    setFieldValue(referenceName, newValue);
  };

  return (
    <Scrivito.WidgetTag>
      <WagonSelectionComponent
        initialValue={initialValue}
        name={formName}
        onChange={onChange}
        id={widget.id()}
        labelVisible={labelVisible}
        labelText={widget.get(FormElementBaseGroupLabelAttributes.LABEL_GROUP)}
        label={
          labelVisible && (
            <Scrivito.ContentTag content={widget} attribute={FormElementBaseGroupLabelAttributes.LABEL_GROUP} />
          )
        }
      >
        {items.map((wagon: WagonSelectionItemWidget, index) => {
          const labelText = wagon.get(WagonSelectionItemWidgetAttributes.NAME) as string;
          const imagesList = wagon.get(WagonSelectionItemWidgetAttributes.IMAGES);
          const images =
            imagesList?.map((image, index) => {
              const binary = image.get('blob') as Scrivito.Binary;
              const altText = image.get('alternativeText') as string;
              const titleText = image.get('title') as string;
              return <ResponsiveImage alt={altText} title={titleText} binary={binary} key={index} />;
            }) || [];

          return (
            <WagonSelectionItemComponent
              key={index}
              value={wagon.get(WagonSelectionItemWidgetAttributes.VALUE) as string}
              year={wagon.get(WagonSelectionItemWidgetAttributes.YEAR) as number}
              label={<Scrivito.ContentTag content={wagon} attribute={WagonSelectionItemWidgetAttributes.NAME} />}
              labelText={labelText}
              price={wagon.get(WagonSelectionItemWidgetAttributes.PRICE)}
              capacity={wagon.get(WagonSelectionItemWidgetAttributes.CAPACITY) as number}
              duration={wagon.get(WagonSelectionItemWidgetAttributes.DURATION)}
              content={<Scrivito.ContentTag content={wagon} attribute={WagonSelectionItemWidgetAttributes.CONTENT} />}
              images={images}
              disabled={allDisabled || wagon.get(WagonSelectionItemWidgetAttributes.DISABLED)}
            />
          );
        })}
      </WagonSelectionComponent>
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(WagonSelectionWidgetClass, WagonSelectionWidgetComponent);
