import { FormControl, FormHelperText } from '@mui/material';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import { FocusEventHandler, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Upload } from '../../../assets/icons/icon-upload.svg';
import { getSizeWithUnit, sanitizeFileName } from '../../../utils/general.utils';
import { ButtonComponent } from '../button/button';
import { UploadButtonFileComponent, UploadButtonFileComponentProps } from '../upload-button-file/upload-button-file';
import styles from './upload-button.module.scss';
import { ReactComponent as IconAlertCircle } from '../../../assets/icons/icon-alert-circle.svg';

export interface UploadButtonComponentProps {
  /**
   * Label of upload button component.
   */
  label: JSX.Element;
  /**
   * Whether label is visible.
   */
  labelVisible?: boolean;
  /**
   * Form id of input.
   */
  id?: string;
  /**
   * Whether multiple files are allowed to select/upload.
   */
  multiple?: boolean;
  /**
   * Restrict accepted types of input.
   */
  accept?: string;
  /**
   * The maximum size, in bytes, of the data contained in the Blob object.
   */
  maxSize?: number;
  /**
   * The maximum size, in bytes, of multiple files.
   */
  multipleFileSize?: number;
  /**
   * Whether form requires input.
   */
  required?: boolean;
  /**
   * Form name of input.
   */
  name?: string;
  /**
   * Error of input.
   */
  error?: boolean;
  /**
   * Message is displayed in case of error.
   */
  errorMessage?: string;
  /**
   * Helper text of input.
   */
  helperText?: string;
  /**
   * Change handler of input field.
   */
  onChange?: (fileMap: Map<string, UploadButtonFileComponentProps>) => void;
  /**
   * On blur handler for the input field.
   */
  onBlur?: FocusEventHandler;
  /**
   * API Key for the upload request.
   */
  apiKey: string;
}

/**
 * Static id of upload button component.
 */
let _id = 1;

/**
 * Get unique id to be used as name of input field in forms.
 * TODO check when refactoring form controls
 */
const getUniqueId = (): number => {
  _id = _id + 1;
  return _id;
};

export const UploadButtonComponent: React.FC<UploadButtonComponentProps> = ({
  id,
  label,
  labelVisible,
  multiple,
  error,
  errorMessage,
  helperText,
  required,
  accept,
  maxSize,
  multipleFileSize,
  name,
  onChange,
  onBlur,
  apiKey,
}) => {
  const { t } = useTranslation();

  labelVisible = labelVisible === undefined ? true : labelVisible;

  // Unique id of one upload button component.
  const idValue = id || getUniqueId();

  // selected files
  const [fileMap, setFileMap] = useState<Map<string, UploadButtonFileComponentProps>>(new Map());

  // sum of selected files
  let currentFileSizeSum = 0;

  const [multipleFileSizeExceededError, setMultipleFileSizeExceededError] = useState<boolean>(false);

  /**
   * On change handler of input.
   */
  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const fileList = event.target.files;

    if (fileList) {
      // hide error Message
      setMultipleFileSizeExceededError(false);

      const fileListAsArray = Array.from<File>(fileList);

      if (multiple && multipleFileSize) {
        // check file size sum
        const newFileSizeSum = fileListAsArray.reduce((sum, file) => sum + file.size, 0);

        if (currentFileSizeSum + newFileSizeSum > multipleFileSize) {
          // show error message
          setMultipleFileSizeExceededError(true);
          // do not add over-sized files to map
          return;
        }
      }

      // add new files to map
      for (const file of fileListAsArray) {
        addFileToMap(file);
      }

      onChange && onChange(fileMap);
    }
  };

  /**
   * Add file to file map and handle file size error.
   * @param file File added by user.
   */
  const addFileToMap = (file: File): void => {
    // Compare filename + modified date to add file to upload list.
    // Always used as key of fileMap
    const uniqueFileName = getUniqueFileName(file);

    // if only one file is allowed, and not already uploaded
    // then clear before adding new one.
    if (!multiple && !fileMap.has(uniqueFileName)) {
      fileMap.clear();
      currentFileSizeSum = 0;
    }

    // generate unique name prefixed with hash to distinguish same file name of all uploads
    const hashedName = generateFileUploadName(file, nanoid());

    let fileTooBig = false;
    if (maxSize) {
      fileTooBig = isFileTooBig(file.size);
    }

    if (!fileMap.has(uniqueFileName)) {
      fileMap.set(uniqueFileName, {
        file,
        hashedName,
        status: fileTooBig ? 'error' : 'pending',
        errorMessage: fileTooBig ? fileSizeExceededErrorMessage(file, maxSize) : undefined,
      });

      // upload only if file is not too big
      if (!fileTooBig) {
        handleFileUpload(file, hashedName, uniqueFileName).then(() => {
          if (process.env.NODE_ENV === 'development') {
            console.debug(`uploading request for ${hashedName} finished`);
          }
        });
      }
    }
  };

  /**
   * Handle File upload an update file status
   * @param file File to be uploaded
   * @param hashedName file name to be saved on server
   * @param uniqueFileName key to access file in fileMap
   * @returns Promise of Response when file is uploaded
   */
  const handleFileUpload = async (file: File, hashedName: string, uniqueFileName: string): Promise<Response | null> => {
    const p = uploadFile(file, hashedName);
    // Execute if the upload throws an error or the status code is not 200
    const onError = (): void => {
      // update size of all uploaded files
      currentFileSizeSum -= file.size;

      // could be already removed by user
      const f = fileMap.get(uniqueFileName);
      if (f) {
        // update status
        f.status = 'error';
        f.errorMessage = t('component.uploadButton.errorFileUpload');
        setFileMap(new Map(fileMap));
        onChange && onChange(fileMap);
      }
      console.error(`error while uploading ${hashedName}:`, error);
    };

    p.then((response) => {
      if (response?.status === 200) {
        // could be already removed by user
        const f = fileMap.get(uniqueFileName);
        if (f) {
          // update status
          f.status = 'success';
          setFileMap(new Map(fileMap));
          onChange && onChange(fileMap);
        }
      } else {
        onError();
      }
    }).catch(() => onError());
    return p;
  };

  /**
   * Remove file from file map.
   * @param fileProps
   */
  const onRemoveFile = (fileProps: UploadButtonFileComponentProps): void => {
    const deleted = fileMap.delete(getUniqueFileName(fileProps.file));

    if (deleted) {
      // update size of all uploaded files
      currentFileSizeSum -= fileProps.file.size;

      setFileMap(new Map(fileMap));
      onChange && onChange(fileMap);
    }
  };

  /**
   * Uploads a file to the s3 bucket
   * @param file The file to upload
   * @param uploadName The file name to upload
   */
  const uploadFile = async (file: File, uploadName: string): Promise<Response | null> => {
    const formSubmissionURL = process.env.REACT_APP_FORM_UPLOAD_URL || 'url-missing';

    return fetch(formSubmissionURL + uploadName, {
      method: 'PUT',
      redirect: 'error',
      headers: {
        'X-Api-Key': apiKey,
      },
      body: await toBase64(file),
    });
  };

  /**
   * Converts the given file to a base64 string
   * @param file The file to convert
   */
  const toBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (): void => resolve(reader.result);
      reader.onerror = (error): void => reject(error);
    });
  };

  /**
   *
   * Compares file size in bytes to max file size in kilobytes if maxSize is specified.
   *
   * @param fileSize file size in kilobytes
   * @returns
   */
  const isFileTooBig = (fileSize: number): boolean => {
    if (maxSize) {
      return fileSize > maxSize;
    }
    return false;
  };

  /**
   * get unique file name.
   * @param file
   * @returns
   */
  const getUniqueFileName = (file: File): string => {
    const sanitizedFileName = sanitizeFileName(file.name);

    return `${sanitizedFileName}_${file.lastModified}`;
  };

  /**
   * Generate filename with hash.
   * @param file
   * @param hash
   * @returns
   */
  const generateFileUploadName = (file: File, hash: string): string => {
    return `${hash}__${sanitizeFileName(file.name)}`;
  };

  /**
   * Get error message for when max file size is exceeded.
   * @param file
   * @param maxSizeValue
   * @returns
   */
  const fileSizeExceededErrorMessage = (file: File, maxSizeValue = maxSize): string | undefined => {
    if (maxSizeValue) {
      return `${t('component.uploadButton.errorFileTooBig')} ${getSizeWithUnit(file.size)}. ${t(
        'component.uploadButton.errorFileTooBigChangeFile'
      )} ${getSizeWithUnit(maxSizeValue)}`;
    }
  };

  const inputRef = useRef<HTMLInputElement | null>(null);

  const input = (
    <input
      data-testid="input"
      accept={accept}
      id={`upload-file-${idValue}`}
      name={name}
      multiple={multiple}
      type="file"
      onBlur={onBlur}
      onChange={onChangeFile}
      hidden={true}
      ref={inputRef}
    />
  );

  return (
    <div className={styles.UploadButton}>
      <label htmlFor={`upload-file-${idValue}`} className={styles.UploadButtonContainer}>
        <div className={styles.LabelContainer}>
          {labelVisible && <span className={styles.Label}>{label}</span>}
          {!required && <span className={styles.OptionalLabel}>{t('component.forms.optional')}</span>}
        </div>
        <div className={styles.UploadFileWrapper}>
          <FormControl>
            <ButtonComponent
              href={null}
              color={'primary'}
              icon={<Upload />}
              small={false}
              content={
                multiple && fileMap.size > 0 ? (
                  <>
                    <span>{t('component.uploadButton.labelAdd')}</span>
                    {input}
                  </>
                ) : (
                  <>
                    <span>{t('component.uploadButton.labelDefault')}</span>
                    {input}
                  </>
                )
              }
              component="button"
              type="button"
              onClick={(): void => inputRef.current?.click()}
            />
          </FormControl>

          <div className={styles.FileListWrapper}>
            {fileMap.size > 0 && (
              <div className={styles.FileList}>
                {Array.from(fileMap.values()).map((fileProps: UploadButtonFileComponentProps, index: number) => (
                  <UploadButtonFileComponent
                    key={index}
                    hashedName={fileProps.hashedName}
                    file={fileProps.file}
                    status={fileProps.status}
                    errorMessage={fileProps.errorMessage}
                    onRemove={onRemoveFile}
                  />
                ))}
              </div>
            )}
            {helperText && !error && <FormHelperText className={styles.HelperText}>{helperText}</FormHelperText>}
            {multipleFileSizeExceededError && (
              <FormHelperText className={classNames(styles.ErrorText, styles.ErrorExceededMultipleFileSize)}>
                {t('component.uploadButton.errorMultipleFilesTooBig')}{' '}
                {multipleFileSize && getSizeWithUnit(multipleFileSize)}.
              </FormHelperText>
            )}
            {errorMessage && error && (
              <div className={styles.ErrorMessageWrapper}>
                <IconAlertCircle aria-hidden="true" />
                <FormHelperText className={styles.ErrorText}>{errorMessage}</FormHelperText>
              </div>
            )}
          </div>
        </div>
      </label>
    </div>
  );
};
