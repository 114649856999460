import styles from './footer.module.scss';

/**
 * Properties for the footer component
 */
export interface FooterComponentProps {
  id?: string;
  children?: React.ReactNode;
}

/**
 * Creates a headline component
 */
export const FooterComponent: React.FC<FooterComponentProps> = ({ id, children }) => {
  return (
    <footer id={id} className={styles.Footer}>
      <div className={styles.Content}>{children}</div>
    </footer>
  );
};
