import * as Scrivito from 'scrivito';
import React from 'react';

/**
 * Returns the text of a Scrivito Widget
 * @param widget The widget to get the text from
 */
export const getText = (widget: Scrivito.Widget): string => {
  return Object.entries(widget.attributeDefinitions())
    .flatMap(([name, [type]]) => {
      switch (type) {
        case 'widgetlist':
          return (widget.get(name) as Scrivito.Widget[]).map(getText);
        case 'string':
          return widget.get(name);
        case 'html':
          // Put your favorite HTML/regex disclaimer here
          return (widget.get(name) as string).replace(/<.*?>/gm, '');
        default:
          return '';
      }
    })
    .filter((s) => !!s)
    .join(' ');
};

/**
 * Renders a content tag or the html element whether the user is in editing mode or not.
 * It removes the "isPasted" id from the html in preview/published content.
 * @param widget The widget to get the html from
 * @param attribute The attribute that references to the html field
 * @param tag Optional tag for the content tag
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const contentTagOrHtml = (widget: Scrivito.Widget, attribute: string, tag?: string): any => {
  // TODO this can't be used like that because it destroys the internal scrivito links
  // const html = widget.get(attribute) as string;
  // return Scrivito.isInPlaceEditingActive() ? (
  //   <Scrivito.ContentTag tag={tag} content={widget} attribute={attribute} />
  // ) : (
  //   parse(removeIds(html))
  // );
  return <Scrivito.ContentTag tag={tag} content={widget} attribute={attribute} />;
};
