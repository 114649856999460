export const SocialMediaWidgetId = 'SocialMediaWidget';

export enum SocialMediaWidgetAttributes {
  TYPE = 'type',
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  TWITTER = 'twitter',
  YOUTUBE = 'youtube',
  LINKEDIN = 'linkedin',
  BLOG = 'blog',
}

// Icons must be copied from the Icons enum (icons.utils.tsx),
// since typescript does not allow using computed string values in enums.
// See https://github.com/microsoft/TypeScript/issues/40793
export enum SocialMediaWidgetType {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  TWITTER = 'twitter',
  YOUTUBE = 'youtube',
  LINKEDIN = 'linkedin',
  BLOG = 'blog',
}
