import classNames from 'classnames';
import * as Scrivito from 'scrivito';
import { ResponsiveImage } from '../../components/controls/image/responsive-image/responsive-image.scrivito';
import { InfoTeaserComponent } from '../../components/controls/info-teaser/info-teaser';
import { hasManualHyphens, removeHtmlTagsExceptBreaks } from '../../utils/general.utils';
import { spacingClassName } from '../../utils/scrivito/spacing-definitions';
import { HeadlineWidgetTag } from '../headline-widget';
import { InfoTeaserWidget, InfoTeaserWidgetAttributes } from '../info-teaser-widget';
import { InfoTeaserGroupWidget, InfoTeaserGroupWidgetClass } from './info-teaser-group-widget-class';
import { InfoTeaserGroupWidgetAttributes } from './info-teaser-group-widget-definitions';
import styles from './info-teaser-group-widget.module.scss';

export const InfoTeaserGroupWidgetComponent: React.FC<{ widget: InfoTeaserGroupWidget }> = ({ widget }) => {
  const items = widget.get(InfoTeaserGroupWidgetAttributes.ITEMS) as Array<InfoTeaserWidget>;
  const headlineComponent: HeadlineWidgetTag = widget.get(
    InfoTeaserGroupWidgetAttributes.HEADLINE_COMPONENT
  ) as HeadlineWidgetTag;

  return (
    <Scrivito.WidgetTag>
      <div className={classNames(spacingClassName(widget), styles.InfoTeaserGroupWidget)}>
        {items.map((item: InfoTeaserWidget, index) => {
          const headline = item.get(InfoTeaserWidgetAttributes.HEADLINE);
          const text = item.get(InfoTeaserWidgetAttributes.TEXT);
          const media = item.get(InfoTeaserWidgetAttributes.MEDIA);
          const icon = item.get(InfoTeaserWidgetAttributes.ICON);
          // always defined due to widget validation
          const link =
            item.get(InfoTeaserWidgetAttributes.LINK) ||
            new Scrivito.Link({
              target: '#',
              url: '#',
              title: '',
              obj: null,
            });

          // remove html tags from `html` headline and text
          const headlineSanitized = removeHtmlTagsExceptBreaks(headline);
          const textSanitized = removeHtmlTagsExceptBreaks(text);

          return (
            <InfoTeaserComponent
              link={link}
              icon={icon}
              headlineComponent={headlineComponent}
              headline={
                headline && (
                  <span
                    className={classNames({ ManualHyphens: hasManualHyphens(headlineSanitized) })}
                    dangerouslySetInnerHTML={{ __html: headlineSanitized }}
                  />
                )
              }
              text={
                text && (
                  <span
                    className={classNames({ ManualHyphens: hasManualHyphens(textSanitized) })}
                    dangerouslySetInnerHTML={{ __html: textSanitized }}
                  />
                )
              }
              linkText={link.title() ? <span>{link.title()}</span> : undefined}
              media={
                media && (
                  <ResponsiveImage
                    alt={media?.get('alternativeText') as string}
                    title={media?.get('title') as string}
                    binary={media?.get('blob') as Scrivito.Binary}
                  />
                )
              }
              key={index}
            />
          );
        })}
      </div>
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(InfoTeaserGroupWidgetClass, InfoTeaserGroupWidgetComponent);
