import ToggleButtonGroup from '@mui/material/ToggleButtonGroup/ToggleButtonGroup';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import React from 'react';
import * as Scrivito from 'scrivito';
import {
  ToggleButtonComponent,
  ToggleButtonComponentProps,
} from '../../components/controls/toggle-button/toggle-button';
import { FormElementBaseGroupLabelAttributes } from '../form-widget';
import { referenceNameForWidget } from '../shared/form/form-controls-helper-methods';
import { ToggleButtonWidgetAttributes } from '../toggle-button-widget';
import { ToggleButtonGroupWidget, ToggleButtonGroupWidgetClass } from './toggle-button-group-widget-class';
import { ToggleButtonGroupWidgetAttributes } from './toggle-button-group-widget-definitions';
import styles from './toggle-button-group-widget.module.scss';
import { useFormContext } from '../form-widget/FormContext';

export const ToggleButtonGroupWidgetComponent: React.FC<{ widget: ToggleButtonGroupWidget }> = ({ widget }) => {
  const disabled = widget.get(ToggleButtonGroupWidgetAttributes.DISABLED);
  const defaultValue = widget.get(ToggleButtonGroupWidgetAttributes.VALUE);
  const labelVisible = widget.get(FormElementBaseGroupLabelAttributes.LABEL_GROUP_VISIBLE);

  const formContext = useFormContext();
  const { setFieldValue } = useFormikContext();
  const referenceName = referenceNameForWidget(widget);

  const items = (widget.get(ToggleButtonGroupWidgetAttributes.ITEMS) as Array<Scrivito.Widget>) ?? [];

  // group value
  const [value, setValue] = React.useState<string | null>(defaultValue || null);

  if (formContext.isHiddenElement(referenceName)) {
    return null;
  }

  // group value handler
  const onChange = (event: React.MouseEvent<HTMLElement>, newValue: string | null): void => {
    // do not allow deselection
    if (newValue !== null) {
      setValue(newValue);
      setFieldValue(referenceName, newValue);
    }
  };

  return (
    <Scrivito.WidgetTag>
      <ToggleButtonGroup
        id={referenceName}
        value={value}
        exclusive
        disabled={disabled}
        aria-label={!labelVisible && widget.get(FormElementBaseGroupLabelAttributes.LABEL_GROUP)}
        aria-labelledby={labelVisible && widget.id()}
        onChange={onChange}
        className={classNames(styles.ToggleButtonGroupWidget, { [styles.HasLabel]: labelVisible })}
      >
        {labelVisible && (
          <Scrivito.ContentTag
            id={widget.id()}
            style={{ gridColumn: `1 / ${items.length + 1}` }}
            className={styles.ToggleButtonGroupWidgetLabel}
            content={widget}
            attribute={FormElementBaseGroupLabelAttributes.LABEL_GROUP}
          />
        )}
        {items.map((item, index) => {
          const props: ToggleButtonComponentProps = {
            name: referenceName,
            label: item.get(ToggleButtonWidgetAttributes.LABEL) as string,
            value: item.get(ToggleButtonWidgetAttributes.VALUE) as string,
            disabled: item.get(ToggleButtonWidgetAttributes.DISABLED) as boolean,
            'aria-labelledby': widget.id(),
          };
          return <ToggleButtonComponent key={index} {...props} />;
        })}
      </ToggleButtonGroup>
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(ToggleButtonGroupWidgetClass, ToggleButtonGroupWidgetComponent);
