import classNames from 'classnames';
import * as Scrivito from 'scrivito';
import { CircleTeaserWidget, CircleTeaserWidgetAttributes } from '../circle-teaser-widget';
import { CircleTeaserGroupWidget, CircleTeaserGroupWidgetClass } from './circle-teaser-group-widget-class';
import { CircleTeaserGroupWidgetAttributes } from './circle-teaser-group-widget-definitions';
import styles from './circle-teaser-group-widget.module.scss';
import { spacingClassName } from '../../utils/scrivito/spacing-definitions';
import { ImageWidgetAttributes } from '../image-widget';
import { extractResponsiveData, getAttributeOrFallback } from '../../utils/scrivito/image.utils';
import { ImageObjectAttributes } from '../../objects';

export const CircleTeaserGroupWidgetComponent: React.FC<{ widget: CircleTeaserGroupWidget }> = ({ widget }) => {
  const teasers = widget.get(CircleTeaserGroupWidgetAttributes.CIRCLE_TEASER) || [];

  return (
    <Scrivito.WidgetTag>
      <div
        className={classNames(
          styles.CircleTeaserGroup,
          styles[`circle-teaser-count-${teasers.length}`],
          spacingClassName(widget)
        )}
      >
        {teasers.map((teaser: CircleTeaserWidget, index: number) => {
          const to = teaser.get(CircleTeaserWidgetAttributes.LINK) as Scrivito.Link;
          const image = getAttributeOrFallback(teaser, ImageWidgetAttributes.IMAGE);
          const imageMeta = image?.metadata();

          const altText = image?.get(ImageObjectAttributes.ALTERNATIVE_TEXT);
          const titleText = image?.get(ImageObjectAttributes.TITLE);
          let smallestImage = undefined;
          let sourceSet = undefined;
          const responsiveData = image ? extractResponsiveData(image) : undefined;
          if (responsiveData) {
            smallestImage = responsiveData.src;
            sourceSet = responsiveData.srcSet;
          }
          return (
            <Scrivito.LinkTag
              draggable="false"
              key={index}
              to={Scrivito.isInPlaceEditingActive() ? null : to}
              className={styles.CircleTeaser}
            >
              <figure className={styles.CircleTeaserFigure}>
                <figcaption className={styles.CircleTeaserHeadline}>
                  {teaser.get(CircleTeaserWidgetAttributes.LINK)?.title() || ''}
                </figcaption>

                <div className={styles.CircleTeaserImageWrapper}>
                  <div className={styles.ImageContainer}>
                    <Scrivito.ImageTag
                      draggable="false"
                      content={teaser as unknown as Scrivito.Obj}
                      attribute={CircleTeaserWidgetAttributes.IMAGE}
                      loading="lazy"
                      className={styles.CircleTeaserImage}
                      width={imageMeta?.get('width')}
                      height={imageMeta?.get('height')}
                      alt={altText}
                      title={titleText}
                      src={smallestImage}
                      srcSet={sourceSet}
                      sizes={'100vw'}
                    />
                    <div className={styles.CircleTeaserHoverOverlay} />
                    <div className={styles.CircleTeaserHoverText}>
                      <Scrivito.ContentTag
                        content={teaser}
                        attribute={CircleTeaserWidgetAttributes.HOVER_TEXT}
                        className={styles.HoverTag}
                      />
                    </div>
                  </div>
                </div>
              </figure>
            </Scrivito.LinkTag>
          );
        })}
      </div>
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(CircleTeaserGroupWidgetClass, CircleTeaserGroupWidgetComponent);
