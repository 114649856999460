import * as Scrivito from 'scrivito';
import { TableCellWidgetId } from '../table-cell-widget';
import { TableWidgetId } from '../table-widget/table-widget-definitions';
import { TableRowWidgetAttributes, TableRowWidgetId } from './table-row-widget-definitions';

export const TableRowWidgetClass = Scrivito.provideWidgetClass(TableRowWidgetId, {
  attributes: {
    [TableRowWidgetAttributes.CELLS]: ['widgetlist', { only: TableCellWidgetId }],
  },
  onlyInside: [TableWidgetId],
});

export type TableRowWidget = InstanceType<typeof TableRowWidgetClass>;
