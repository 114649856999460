import styles from './link-list.module.scss';

export interface FooterLinkListComponentProps {
  title: string;
  items: Array<React.ReactNode>;
}

/**
 * Creates a footer link list
 */
export const LinkListComponent: React.FC<FooterLinkListComponentProps> = ({ title, items }) => {
  return (
    <div className={styles.LinkList}>
      <span className={styles.Title}>{title}</span>
      <ul aria-label={title} className={styles.List}>
        {items.map((link, index) => (
          <li key={index} className={styles.ListItem}>
            {link}
          </li>
        ))}
      </ul>
    </div>
  );
};
