import * as Scrivito from 'scrivito';
import { SimpleChoice } from '../../utils/objects/enums.utils';
import { DownloadObjectAttributes, DownloadObjectId } from './download-object-definitions';

export const DownloadObjectClass = Scrivito.provideObjClass(DownloadObjectId, {
  attributes: {
    [DownloadObjectAttributes.TITLE]: 'string',
    [DownloadObjectAttributes.TAGS]: 'stringlist',
    [DownloadObjectAttributes.BLOB]: 'binary',
    [DownloadObjectAttributes.VISIBLE_IN_SEARCH]: [
      'enum',
      {
        values: Object.values(SimpleChoice),
      },
    ],
  },
  extractTextAttributes: [`${DownloadObjectAttributes.BLOB}:text`, DownloadObjectAttributes.TITLE],
});

export type DownloadObject = InstanceType<typeof DownloadObjectClass>;
