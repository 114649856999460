import * as Scrivito from 'scrivito';
import { KpiTileWidgetAttributes, KpiTileWidgetColors, KpiTileWidgetId } from './kpi-tile-widget-definitions';
import { KpiTileGroupWidgetId } from '../kpi-tile-group-widget/kpi-tile-group-widget-definitions';

export enum KpiTileWidgetType {
  VIDEO = 'video',
  IMAGE = 'image',
}

export const KpiTileWidgetClass = Scrivito.provideWidgetClass(KpiTileWidgetId, {
  attributes: {
    [KpiTileWidgetAttributes.TYPE]: ['enum', { values: [KpiTileWidgetType.IMAGE, KpiTileWidgetType.VIDEO] }],
    [KpiTileWidgetAttributes.BACKGROUND_COLOR]: ['enum', { values: Object.values(KpiTileWidgetColors) }],
    [KpiTileWidgetAttributes.IMAGE]: ['reference', { only: 'Image' }],
    [KpiTileWidgetAttributes.VIDEO]: ['reference', { only: 'Video' }],
    [KpiTileWidgetAttributes.COUNTER_START]: 'float',
    [KpiTileWidgetAttributes.COUNTER_END]: 'float',
    [KpiTileWidgetAttributes.TEXT]: 'string',
    [KpiTileWidgetAttributes.ASSET_ALT]: 'string',
  },
  onlyInside: [KpiTileGroupWidgetId],
  extractTextAttributes: [KpiTileWidgetAttributes.TEXT],
});

export type KpiTileWidget = InstanceType<typeof KpiTileWidgetClass>;
