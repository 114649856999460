import * as Scrivito from 'scrivito';
import React from 'react';
import { NavigationFlyoutSection } from './navigation-flyout-section';
import styles from './navigation-flyout-column.module.scss';

interface NavigationFlyoutColumnProps {
  navChildren: Scrivito.Widget[];
  close: () => void;
}

export const NavigationFlyoutColumn: React.FC<NavigationFlyoutColumnProps> = Scrivito.connect(
  ({ navChildren, close }) => {
    if (!navChildren.length) {
      return null;
    }

    return (
      <div className={styles.NavigationFlyoutColumn}>
        {navChildren.map((subNav, index) => (
          <NavigationFlyoutSection key={`sub-nav-section-${index}`} nav={subNav} close={close} />
        ))}
      </div>
    );
  }
);
