export const CheckboxWidgetId = 'CheckboxWidget';

export enum CheckboxWidgetAttributes {
  LABEL = 'label',
  CHECKED = 'checked',
  DISABLED = 'disabled',
  READONLY = 'readonly',
  REQUIRED = 'required',
  VALUE = 'value',
}
