import { checkValue, idAndNameRegex } from '../../utils/scrivito/validation';
import { HelperText, Message } from '../../utils/translations';
import { ValidationInfo } from '../../utils/type.utils';

export const FormWidgetId = 'FormWidget';

export enum FormWidgetAttributes {
  ELEMENTS = 'elements',
  SUBMIT_BUTTON_TEXT = 'submitButtonText',
  SUBMIT_BUTTON_ENABLED = 'submitButtonEnabled',
  FORM_ACTION = 'formAction',
  SUCCESS_PAGE = 'successPage',
  ERROR_MESSAGE = 'errorMessage',
  SPECIAL_SUBMISSION_ERRORS = 'specialSubmissionErrors',
  URL_PARAMETERS_TO_SEND = 'urlParametersToSend',
  INTERACTIVE_LOGIC = 'interactiveLogic',
  HIDDEN_FORM_DATA = 'hiddenFormData',
  ELEMENT_ID = 'elementId',
  SUBMISSION_ERROR = 'submissionError',
}

export enum FormElementBaseAttributes {
  NAME = 'name',
  OPTIONAL = 'optional',
  CUSTOM_INVALID_MESSAGE = 'invalidMessage',
}

export const formElementBaseClassAttributes = {
  [FormElementBaseAttributes.NAME]: 'string',
  [FormElementBaseAttributes.OPTIONAL]: 'boolean',
  [FormElementBaseAttributes.CUSTOM_INVALID_MESSAGE]: 'string',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFormElementBaseClassAttributes = (): any => {
  return formElementBaseClassAttributes;
};

export const formElementBaseAttributesDefinition = {
  [FormElementBaseAttributes.NAME]: {
    title: 'Name',
  },
  [FormElementBaseAttributes.OPTIONAL]: {
    title: 'Optional',
  },
  [FormElementBaseAttributes.CUSTOM_INVALID_MESSAGE]: {
    title: 'Benutzerdefinierte Fehlermeldung',
    description: HelperText.CUSTOM_INVALID_MESSAGE,
  },
};

export const formElementBaseAttributesValidation = [
  [
    FormElementBaseAttributes.NAME,
    (value: string | null): ValidationInfo => {
      const missing = checkValue(value, Message.NAME_MISSING);
      if (missing) {
        return missing;
      } else if (value && !idAndNameRegex.test(value)) {
        return {
          severity: 'error',
          message: Message.INVALID_INPUT,
        };
      }
      return undefined;
    },
  ],
];

export enum FormElementBaseGroupLabelAttributes {
  LABEL_GROUP = 'groupLabel',
  LABEL_GROUP_VISIBLE = 'groupLabelVisible',
}

export const groupLabelFormElementBaseClassAttributes = {
  [FormElementBaseGroupLabelAttributes.LABEL_GROUP]: 'string',
  [FormElementBaseGroupLabelAttributes.LABEL_GROUP_VISIBLE]: 'boolean',
};

export const groupLabelFormElementBaseClassAttributesDefinition = {
  [FormElementBaseGroupLabelAttributes.LABEL_GROUP]: {
    title: HelperText.LABEL,
    description: HelperText.LABEL_GROUP_DESCRIPTION,
  },
  [FormElementBaseGroupLabelAttributes.LABEL_GROUP_VISIBLE]: {
    title: HelperText.LABEL_VISIBLE,
  },
};

export const groupLabelFormElementBaseClassAttributesValidation = [
  [
    FormElementBaseGroupLabelAttributes.LABEL_GROUP,
    (value: string | null): ValidationInfo => checkValue(value, Message.LABEL_FIELD_MISSING),
  ],
];
