export const MAX_SUGGESTIONS = 7;

export enum EfaStopType {
  Poi = 'poi',
  Stop = 'stop',
  Street = 'street',
  Address = 'address',
  Unknown = 'unknown',
}

export interface EfaStopSuggestion {
  id: string;
  name: string;
  type: EfaStopType;
  coordinates?: GeolocationCoordinates;
}

export interface EfaTripRequest {
  from: EfaStopSuggestion | string;
  to: EfaStopSuggestion | string;
  date?: string;
  time?: string;
  departure: 'dep' | 'arr';
  accessible?: boolean;
  language: string;
}

export interface EfaDepartureRequest {
  from: EfaStopSuggestion | string;
  date?: string;
  time?: string;
  language: string;
}

/**
 * Checks if the given value matches any stops. The interface for the auto-suggest/
 * stop finder is provided by Mentz. The same API is used by app.rheinbahn.de.
 * The function converts the results in an array of EfaStopSuggestion. If there are
 * no results or an error occurs, an empty array will be returned.
 * @param value The value given to the interface
 */
export const getSuggestions = (value: string): Promise<EfaStopSuggestion[]> => {
  return fetch(`${process.env.REACT_APP_EFA_STOPFINDER}&name_sf=${value}`)
    .then((response) => response.json())
    .then((data) => {
      const stops = [];
      if (data.locations) {
        for (const [index, stop] of data.locations.entries()) {
          stops.push({ id: stop.id, name: stop.name, type: stop.type });
          if (index === MAX_SUGGESTIONS - 1) {
            break;
          }
        }
      }
      return stops;
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
};

/**
 * Returns the stop that is the closest to the given coordinates or null if none was found.
 * @param coordinates The coordinates of the user
 */
export const getNearestStop = (coordinates: GeolocationCoordinates): Promise<EfaStopSuggestion | null> => {
  let url = process.env.REACT_APP_EFA_STOPFINDER_LOCATION || '';
  url += `&name_sf=coord:${coordinates.longitude}:${coordinates.latitude}:WGS84[dd.ddddd]`;

  return fetch(url)
    .then((response) => response.json())
    .then((data) => {
      if (data.locations.length > 0) {
        const stop = data.locations[0];
        return { id: stop.id, name: stop.name, type: stop.type };
      }
      return null;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};

/**
 * Builds a trip request from the given parameters and opens a window/tab with the created url.
 * @param request The request options
 */
export const searchTripAndRedirect = (request: EfaTripRequest): void => {
  let baseUrl = process.env.REACT_APP_EFA_TRIP_REQUEST;

  // Basic parameters
  const origin = typeof request.from === 'string' ? request.from : request.from.name;
  const originId = typeof request.from === 'string' ? '' : request.from.id;
  const destination = typeof request.to === 'string' ? request.to : request.to.name;
  const destinationId = typeof request.to === 'string' ? '' : request.to.id;

  let params =
    `itdDateDayMonthYear=${request.date}` +
    `&itdTime=${request.time}` +
    `&itdTripDateTimeDepArr=${request.departure}` +
    `&origin=${originId}` +
    `&name_origin=${origin}` +
    `&destination=${destinationId}` +
    `&name_destination=${destination}`;

  if (request.accessible) {
    params +=
      // eslint-disable-next-line spellcheck/spell-checker
      '&imparedOptionsActive=1' +
      '&noElevators=on' +
      '&noEscalators=on' +
      '&noSolidStairs=on' +
      '&wheelchair=on' +
      '&lowfloorVehicles=on' +
      '&bikeTakeAlong=on';
  }
  // Add encoded parameter to base url and add the language property (decoded)
  baseUrl += encodeURIComponent(params) + `&lng=${request.language}`;

  // Open url in new tab
  window.open(baseUrl);
};

/**
 * Builds a departure request from the given parameters and opens a window/tab with the created url.
 * @param request The request options
 */
export const searchDepartureAndRedirect = (request: EfaDepartureRequest): void => {
  let baseUrl = process.env.REACT_APP_EFA_DEPATURE_REQUEST;
  const origin = typeof request.from === 'string' ? request.from : request.from.name;
  const originId = typeof request.from === 'string' ? '' : request.from.id;

  // Basic parameters
  const params =
    `&origin=${originId}` +
    `&itdDateDayMonthYear=${request.date}` +
    `&itdTime=${request.time}` +
    `&name_origin=${origin}`;

  // Add encoded parameter to base url and add the language property (decoded)
  baseUrl += encodeURIComponent(params) + `&lng=${request.language}`;

  // Open url in new tab
  window.open(baseUrl);
};
