import * as Scrivito from 'scrivito';
import { CheckboxWidget, CheckboxWidgetClass } from './checkbox-widget-class';
import React from 'react';
import { CheckboxWidgetAttributes, CheckboxWidgetId } from './checkbox-widget-definitions';
import { CheckboxComponent } from '../../components/controls/checkbox/checkbox';
import { stripHtml } from '../../utils/string';

export const CheckboxWidgetComponent: React.FC<{ widget: CheckboxWidget }> = ({ widget }) => {
  const checked = widget.get(CheckboxWidgetAttributes.CHECKED) as boolean;
  const disabled = widget.get(CheckboxWidgetAttributes.DISABLED) as boolean;
  const required = widget.get(CheckboxWidgetAttributes.REQUIRED) as boolean;
  const labelText = stripHtml(widget.get(CheckboxWidgetAttributes.LABEL) as string);
  const label = <Scrivito.ContentTag content={widget} attribute={CheckboxWidgetAttributes.LABEL} />;
  const id = widget.id();

  return (
    <Scrivito.WidgetTag>
      <CheckboxComponent
        id={`${CheckboxWidgetId}-${id}`}
        label={label}
        labelText={labelText}
        defaultChecked={checked}
        disabled={disabled}
        required={required}
      />
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(CheckboxWidgetClass, CheckboxWidgetComponent);
