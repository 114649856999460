import { HeadlineWidgetId, HeadlineWidgetVariant } from '../../../widgets/headline-widget';
import { ElementType, ReactElement } from 'react';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import style from './headline.module.scss';

/**
 * Properties for the headline component
 */
export interface HeadlineProps {
  variant: HeadlineWidgetVariant;
  component: ElementType;
  id: string;
  content: ReactElement;
}

/**
 * Creates a headline component
 */
export const HeadlineComponent: React.FC<HeadlineProps> = ({ variant, component, id, content }) => {
  return (
    <Typography
      className={classNames(style.HeadlineWidget)}
      variant={variant as HeadlineWidgetVariant}
      component={component as ElementType}
      id={id}
      data-test-id={HeadlineWidgetId}
    >
      {content}
    </Typography>
  );
};
