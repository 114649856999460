import React from 'react';
import * as Scrivito from 'scrivito';
import { ReactComponent as IconClose } from '../../../../../assets/icons/icon-close.svg';
import styles from './landing-navigation-drawer-header.module.scss';

interface NavigationDrawerHeaderProps {
  title?: string;
  onButtonClose: () => void;
}

export const LandingNavigationDrawerHeader: React.FC<NavigationDrawerHeaderProps> = Scrivito.connect((props) => {
  return (
    <div className={styles.NavigationDrawerHeader}>
      <div className={styles.IconsArea}>
        <button onClick={props.onButtonClose}>
          <IconClose />
        </button>
      </div>
    </div>
  );
});
