import * as Scrivito from 'scrivito';
import { BigTeaserWidgetAttributes, BigTeaserWidgetId, ButtonPositions } from './big-teaser-widget-definitions';
import { spacingClassAttributes } from '../../utils/scrivito/spacing-definitions';

export const BigTeaserWidgetClass = Scrivito.provideWidgetClass(BigTeaserWidgetId, {
  attributes: {
    [BigTeaserWidgetAttributes.IMAGE]: ['reference', { only: 'Image' }],
    [BigTeaserWidgetAttributes.IMAGE_ALT]: 'string',
    [BigTeaserWidgetAttributes.IMAGE_TITLE]: 'string',
    [BigTeaserWidgetAttributes.LINK]: 'link',
    [BigTeaserWidgetAttributes.HOVER_TEXT]: 'string',
    [BigTeaserWidgetAttributes.BUTTON_POSITION]: [
      'enum',
      {
        values: Object.values(ButtonPositions),
      },
    ],
    [BigTeaserWidgetAttributes.ID]: 'string',
    ...spacingClassAttributes(),
  },
});

export type BigTeaserWidget = InstanceType<typeof BigTeaserWidgetClass>;
