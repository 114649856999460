import * as Scrivito from 'scrivito';
import React from 'react';
import styles from './navigation-drawer-page.module.scss';
import { List, ListItem, ListItemButton } from '@mui/material';
import { NavigationEntryAttrs } from '../../../../../pages/home-page/home-page-navigation';
import { isLinkCurrentPage, useHierarchy } from '../../../../../utils/scrivito';
import classNames from 'classnames';

interface NavigationDrawerPageProps {
  widgets: Scrivito.Widget[];
  overview?: Scrivito.Widget;
  sub?: boolean;
  subSub?: boolean;
  disabled?: boolean;
  onClick?: (widget: Scrivito.Widget) => void;
  afterNav?: () => void;
}

export const NavigationDrawerPage: React.FC<NavigationDrawerPageProps> = Scrivito.connect((props) => {
  const currentPage = Scrivito.currentPage();
  const overviewLink = props.overview?.get(NavigationEntryAttrs.LINK) as Scrivito.Link;
  if (!props.widgets.length) {
    return null;
  }

  const navigateTo = (link: Scrivito.Link): void => {
    Scrivito.navigateTo(link);
    props.afterNav?.();
  };

  return (
    <div
      className={classNames(styles.NavigationDrawerPage, {
        [styles.Sub]: props.sub ?? props.subSub,
        [styles.SubSub]: props.subSub,
      })}
    >
      <List>
        {overviewLink && (
          <ListItem
            onClick={(): void => navigateTo(overviewLink)}
            tabIndex={props.disabled ? -1 : 0}
            className={classNames({
              [styles.Current]: isLinkCurrentPage(currentPage, overviewLink),
            })}
          >
            <ListItemButton>Übersicht</ListItemButton>
          </ListItem>
        )}

        {props.widgets.map((widget) => (
          <NavListItem
            key={widget.id()}
            widget={widget}
            onClick={(): void => props.onClick?.(widget)}
            navigateTo={navigateTo}
            disabled={!!props.disabled}
          />
        ))}
      </List>
    </div>
  );
});

const NavListItem: React.FC<{
  widget: Scrivito.Widget;
  onClick: () => void;
  disabled: boolean;
  navigateTo: (link: Scrivito.Link) => void;
}> = Scrivito.connect(({ widget, onClick, disabled, navigateTo }) => {
  const hierarchy = useHierarchy();

  const label = widget.get(NavigationEntryAttrs.LABEL) as string;
  const link = widget.get(NavigationEntryAttrs.LINK) as Scrivito.Link;
  const linkTitle = link?.title() ?? label;
  const children = widget.get(NavigationEntryAttrs.CHILDREN) as Scrivito.Widget[];
  const hasChildren = !!children?.length;
  const current = hierarchy.contains(widget);

  // If it has children, the link will be rendered as overview in the next layer.
  // The children of layer 1 will be ignored, because those are "children1", "children2",
  // "children3", and not "children".
  if (link && !hasChildren) {
    return (
      <ListItem key={linkTitle}>
        <ListItemButton
          className={classNames({ [styles.Current]: current })}
          onClick={(): void => navigateTo(link)}
          tabIndex={disabled ? -1 : 0}
        >
          <span>{linkTitle}</span>
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <ListItem key={linkTitle}>
      <ListItemButton
        className={classNames({
          [styles.HasChildren]: hasChildren,
          [styles.Current]: current,
        })}
        onClick={onClick}
        tabIndex={disabled ? -1 : 0}
      >
        <span>{linkTitle}</span>
      </ListItemButton>
    </ListItem>
  );
});
