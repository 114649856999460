export const IconListWidgetId = 'IconListWidget';

export enum IconListWidgetAttributes {
  HEADLINE = 'headline',
  HEADLINE_HIERARCHY = 'headlineHierarchy',
  ITEMS = 'items',
  ICON = 'icon',
  ID = 'idIconList',
}

export enum IconListWidgetHeadlineHierarchy {
  HEADLINE1 = 'headline1',
  HEADLINE2 = 'headline2',
  HEADLINE3 = 'headline3',
  HEADLINE4 = 'headline4',
  HEADLINE5 = 'headline5',
  HEADLINE6 = 'headline6',
}

export enum IconListWidgetIcons {
  CHECKMARK = 'checkmark',
  CROSS = 'cross',
  STAR = 'star',
}
