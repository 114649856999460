import { TypographyVariant } from '@mui/material';
import React from 'react';
import * as Scrivito from 'scrivito';
import { HeroAttributes, HeroColor } from '../../../pages/properties/hero';
import { HeadlineWidgetTag } from '../../../widgets/headline-widget';
import { BreadcrumbComponent } from '../../controls/breadcrumb/breadcrumb';
import { ResponsiveImage } from '../../controls/image/responsive-image/responsive-image.scrivito';
import { HeroComponent } from './hero';
import styles from './hero.module.scss';

export interface HeroProps {
  /**
   * Root Obj of page.
   */
  root: Scrivito.Obj | null;
  /**
   * Current Obj of page.
   */
  currentPage: Scrivito.Obj;
}

export const Hero: React.FC<HeroProps> = Scrivito.connect(({ currentPage, root }) => {
  const active = currentPage.get(HeroAttributes.ACTIVE) as boolean;
  const title = currentPage.get(HeroAttributes.TITLE) as string;
  const titleElement = currentPage.get(HeroAttributes.TITLE_ELEMENT) as HeadlineWidgetTag;
  const subtitle = currentPage.get(HeroAttributes.SUBTITLE) as string;
  const link = currentPage.get(HeroAttributes.LINK) as Scrivito.Link;
  const linkText = link?.title() ?? 'Button';
  const color = currentPage.get(HeroAttributes.COLOR) as HeroColor;
  const image = currentPage.get(HeroAttributes.IMAGE) as Scrivito.Obj;
  const hideImageOnMobile = currentPage.get(HeroAttributes.IMAGE_HIDE_MOBILE) as boolean;
  const showJourneyPlanner = currentPage.get(HeroAttributes.JOURNEY_PLANNER) as boolean;
  const smallFont = currentPage.get(HeroAttributes.SMALL_FONT) as boolean;
  let img = null;
  if (image) {
    // Get the binary of the selected image.
    const binary = image.get('blob') as Scrivito.Binary;
    const imgHeight = image.metadata().get('height') as number;
    const imgWidth = image.metadata().get('width') as number;
    const altText = image.get('alternativeText') as string;
    const titleText = image.get('title') as string;
    img = (
      <ResponsiveImage
        alt={altText}
        title={titleText}
        binary={binary}
        meta={imgWidth && imgHeight ? { width: imgWidth, height: imgHeight } : undefined}
      />
    );
  }

  const breadcrumb = (
    <div className="breadcrumb">
      <BreadcrumbComponent root={root} currentPage={currentPage} />
    </div>
  );

  if (!active) {
    return <section className={styles.NoHero}>{breadcrumb}</section>;
  }

  return (
    <HeroComponent
      title={title}
      titleElement={titleElement as TypographyVariant}
      subtitle={subtitle}
      linkText={linkText}
      link={link}
      color={color}
      image={img}
      breadcrumb={breadcrumb}
      imageHideMobile={hideImageOnMobile}
      showJourneyPlanner={showJourneyPlanner}
      showJourneyText={!!(title || subtitle) && showJourneyPlanner}
      smallFont={smallFont}
    />
  );
});
