import { ButtonComponent } from '../../controls/button/button';
import * as Scrivito from 'scrivito';
import styles from './appointment.module.scss';

export interface AppointmentComponentProps {
  title: string;
  link: Scrivito.Link | null;
}

/**
 * Creates a appointment component (used in footer).
 */
export const AppointmentComponent: React.FC<AppointmentComponentProps> = ({ title, link }) => {
  return (
    <div className={styles.Appointment}>
      <span className={styles.Title}>{title}</span>
      <ButtonComponent href={link} color={'info'} small={false} content={<span>{link?.title()}</span>} />
    </div>
  );
};
