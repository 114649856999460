import { HelperText, Message, WidgetTitle } from './../../utils/translations';
import * as Scrivito from 'scrivito';
import {
  FormElementBaseAttributes,
  formElementBaseAttributesDefinition,
  formElementBaseAttributesValidation,
} from '../form-widget/form-widget-definitions';
import { TextInputWidgetAttributes, TextInputWidgetId, TextInputWidgetTypes } from './text-input-widget-definitions';
import Thumbnail from '../../assets/thumbnails/input.svg';
import { ValidationInfo } from '../../utils/type.utils';
import { checkValue } from '../../utils/scrivito/validation';

Scrivito.provideEditingConfig(TextInputWidgetId, {
  title: WidgetTitle.TEXT_INPUT,
  titleForContent: (obj) =>
    `${WidgetTitle.TEXT_INPUT}: ${
      (obj.get(FormElementBaseAttributes.NAME) as string) || Message.FORM_INPUT_NAME_MISSING
    }`,
  thumbnail: Thumbnail,
  properties: Object.values(TextInputWidgetAttributes).filter((item) => item !== TextInputWidgetAttributes.REGEXP),
  propertiesGroups: [
    {
      title: 'Validation',
      properties: [...Object.values(FormElementBaseAttributes), TextInputWidgetAttributes.REGEXP],
    },
  ],

  attributes: {
    [TextInputWidgetAttributes.LABEL]: {
      title: 'Label',
    },
    [TextInputWidgetAttributes.PLACEHOLDER]: {
      title: 'Platzhalter',
    },
    [TextInputWidgetAttributes.VALUE]: {
      title: 'Initialer Wert',
    },
    [TextInputWidgetAttributes.TYPE]: {
      title: 'Eingabetyp',
      values: [
        { value: TextInputWidgetTypes.TEXT, title: 'Text' },
        { value: TextInputWidgetTypes.PASSWORD, title: 'Passwort' },
        { value: TextInputWidgetTypes.NUMBER, title: 'Nummer' },
        { value: TextInputWidgetTypes.EMAIL, title: 'E-Mail' },
        { value: TextInputWidgetTypes.TEL, title: 'Telefon' },
        { value: TextInputWidgetTypes.HIDDEN, title: 'hidden' },
      ],
    },
    [TextInputWidgetAttributes.HELPER_TEXT]: {
      title: 'Hilfstext',
    },
    [TextInputWidgetAttributes.MULTILINE]: {
      title: 'Mehrzeilig',
    },
    [TextInputWidgetAttributes.REGEXP]: {
      title: 'Regulärer Ausdruck',
      description: HelperText.REGEXP_DESCRIPTION,
    },
    ...(formElementBaseAttributesDefinition as object),
  },
  validations: [
    [
      TextInputWidgetAttributes.LABEL,
      (value: string): ValidationInfo => checkValue(value, Message.LABEL_FIELD_MISSING),
    ],
    [TextInputWidgetAttributes.TYPE, (value: string): ValidationInfo => checkValue(value, Message.CHOOSE_OPTION)],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(formElementBaseAttributesValidation as Array<any>),
  ],
  initialContent: {
    [TextInputWidgetAttributes.LABEL]: 'Label',
    [TextInputWidgetAttributes.TYPE]: TextInputWidgetTypes.TEXT,
  },
});
