export const KpiTileWidgetId = 'KpiTileWidget';

export enum KpiTileWidgetAttributes {
  TYPE = 'type',
  TEXT = 'text',
  COUNTER_START = 'counterStart',
  COUNTER_END = 'counterEnd',
  BACKGROUND_COLOR = 'backgroundColor',
  IMAGE = 'image',
  VIDEO = 'video',
  ASSET_ALT = 'assetAltText',
}

export enum KpiTileWidgetColors {
  DARK_GREEN = 'darkGreen',
  DARK_RED = 'darkRed',
  VIOLET = 'violet',
  RED = 'red',
  GRAY = 'gray',
}
