import React, { useEffect, useRef, useState } from 'react';
import styles from './wizard.module.scss';
import { WizardHeader } from './wizard-header';
import { WizardHeaderSmall } from './wizard-header-small';
import { WizardFooter } from './wizard-footer';
import { useViewportWidth } from '../../../utils/hooks.utils';
import { breakpoints } from '../../../utils/scrivito/breakpoints';

export interface WizardPageProps {
  id: string;
  title: string;
  nextButtonText: string;
  content: React.ReactNode;
}

export interface WizardProps {
  grayBackground: boolean;
  pages: WizardPageProps[];
  onButtonPrev?: (index: number) => Promise<boolean>;
  onButtonNext?: (index: number) => Promise<boolean>;
}

// This object exists for the case, that no page is added.
// It is an editor fault and exists mostly just to catch error cases for tests.
const fallbackPage: WizardPageProps = {
  id: '',
  title: '',
  nextButtonText: '',
  content: null,
};

export const Wizard: React.FC<WizardProps> = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const viewPort = useViewportWidth() ?? 0;
  const currentPage = props.pages[currentIndex] ?? fallbackPage;
  const refWizard = useRef<HTMLDivElement | null>(null);
  const refCurrentPage = useRef<HTMLDivElement | null>(null);

  const onButtonPrev = async (): Promise<void> => {
    if (!props.onButtonPrev || (await props.onButtonPrev(currentIndex))) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const onButtonNext = async (): Promise<void> => {
    if (!props.onButtonNext || (await props.onButtonNext(currentIndex))) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  useEffect(() => {
    const page = refCurrentPage.current;
    if (page) {
      const element = page.querySelector('a, button, input, textarea, select') as HTMLElement;
      element?.focus();
    }
  }, [currentIndex]);

  return (
    <div className={styles.Wizard} ref={refWizard}>
      {viewPort >= breakpoints.DESKTOP_XS && (
        <WizardHeader
          grayBackground={props.grayBackground}
          currentIndex={currentIndex}
          pages={props.pages.map((page) => ({ id: page.id, title: page.title }))}
          onSetIndex={(index): void => setCurrentIndex(index)}
        />
      )}
      {viewPort < breakpoints.DESKTOP_XS && (
        <WizardHeaderSmall title={currentPage.title} currentIndex={currentIndex} pageCount={props.pages.length} />
      )}

      {props.pages.map((page, index) => (
        <div
          key={page.id}
          ref={index === currentIndex ? refCurrentPage : undefined}
          style={{ display: index === currentIndex ? 'inherit' : 'none' }}
        >
          {page.content}
        </div>
      ))}

      <WizardFooter
        currentIndex={currentIndex}
        pageCount={props.pages.length}
        nextButtonText={currentPage.nextButtonText}
        onButtonPrev={onButtonPrev}
        onButtonNext={onButtonNext}
      />
    </div>
  );
};
