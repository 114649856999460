import { ButtonWidgetIcons } from '../../../widgets/button-widget';

export const PressReleaseButtonWidgetId = 'PressReleaseButtonWidget';

export enum PressReleaseButtonWidgetAttributes {
  LINK = 'link',
  ICON = 'icon',
}

export const PressReleaseButtonIcons = ButtonWidgetIcons;
