import * as Scrivito from 'scrivito';
import { ImageWidgetAttributes } from '../../widgets/image-widget';
import { breakpoints } from './breakpoints';
import { convertSourceSet } from '../../components';

/**
 * Returns the given attribute of the Widget or Obj if not in Scrivito Content, e.g. in tests or in Storybook.
 * Used for Images and Icons that require a fallback.
 *
 * @param content The Widget or Obj
 * @param attributeName The attribute to return the fallback for
 */
export const getAttributeOrFallback = (
  content: Scrivito.Widget | Scrivito.Obj,
  attributeName: ImageWidgetAttributes
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  return content.get(attributeName);
};

/**
 * Takes a Scrivito image to extract the blob data and calculates the optimized sizes for responsive images.
 * Returns the src and srcSet that should be provided to the Scrivito.ImageTag
 * @param content The scrivito image
 */
export const extractResponsiveData = (content: Scrivito.Widget): { src?: string; srcSet: string } | undefined => {
  const sources: Array<{ url: string; width: number }> = [];
  const binary = content.get('blob') as Scrivito.Binary;
  if (binary) {
    // Convert the binary data to a sources array
    Object.values(breakpoints).forEach((breakpoint) => {
      sources.push({ url: binary.optimizeFor({ width: breakpoint }).url(), width: breakpoint });
    });
    // Creates the src image and the string for srcSet
    return convertSourceSet(sources);
  }
  return undefined;
};
