import styles from './wagon-selection-item.module.scss';
import { ReactComponent as IconClock } from '../../../assets/icons/icon-clock.svg';
import { ReactComponent as IconWomanMan } from '../../../assets/icons/icon-woman-man.svg';
import { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

export interface WagonSelectionItemComponentProps<T = string> {
  formName?: string;
  value: T;
  selectedValue?: T;
  label: JSX.Element;
  labelText: string;
  price?: string;
  capacity?: number;
  duration?: string;
  year?: number;
  content?: JSX.Element;
  images?: Array<JSX.Element>;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  maxLengthPrice?: number;
  maxLengthCapacity?: number;
}

export const WagonSelectionItemComponent: React.FC<WagonSelectionItemComponentProps> = ({
  value,
  formName,
  label,
  labelText,
  price,
  capacity,
  duration,
  year,
  content,
  images,
  disabled,
  selectedValue,
  onChange,
  maxLengthPrice,
  maxLengthCapacity,
}) => {
  const { t } = useTranslation();

  const manufactureYear = t('component.wagonSelectionItem.manufactureYear');
  const people = t('component.wagonSelectionItem.people');
  const hours = t('component.wagonSelectionItem.hours');
  const formProps = { 'data-value-text': labelText };

  const onInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const element = e.target as HTMLElement;
    if ('scrollIntoView' in element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    onChange?.(e);
  };

  return (
    <label className={styles.WagonSelectionItem}>
      <input
        type={'radio'}
        value={value}
        name={formName}
        className={styles.Input}
        disabled={disabled}
        id={`${formName}-input-${value}`}
        checked={selectedValue ? value === selectedValue : undefined}
        onChange={onInputChange}
        {...formProps}
      />
      <div className={styles.GridContainer}>
        <div className={styles.Radio}>
          <div className={styles.RadioIndicator} />
        </div>
        <div className={styles.Container}>
          <span className={styles.Label}>{label}</span>

          <div className={styles.AdditionalInformation}>
            {year && (
              <span
                className={styles.Year}
                title={`${manufactureYear} ${year}`}
                aria-label={`${manufactureYear} ${year}`}
              >
                {year}
              </span>
            )}

            {capacity && (
              <div
                className={styles.CapacityContainer}
                style={{ width: maxLengthCapacity && maxLengthCapacity + 'ch' }}
                title={`${capacity} ${people}`}
                aria-label={`${capacity} ${people}`}
              >
                <IconWomanMan aria-hidden="true" />
                <div aria-hidden="true">
                  <span>{capacity}</span>
                  <span className={styles.UnitLong}> {people}</span>
                </div>
              </div>
            )}

            {duration && (
              <div
                className={styles.DurationContainer}
                title={`${duration} ${hours}`}
                aria-label={`${duration} ${hours}`}
              >
                <IconClock aria-hidden="true" />
                <div aria-hidden="true">
                  <span>{duration}</span>
                  <span className={styles.UnitLong}> {hours}</span>
                  <span className={styles.UnitShort}> {t('component.wagonSelectionItem.hoursShort')}</span>
                </div>
              </div>
            )}
            <div
              className={classNames(styles.PriceContainer, styles.PriceContainerInfo)}
              style={{ width: maxLengthPrice && maxLengthPrice + 'ch' }}
            >
              <span>{t('component.wagonSelectionItem.from')} </span>
              <span className={styles.Price}>{price} €</span>
            </div>
          </div>
          <div className={styles.ContentContainer}>{content}</div>
          <div className={styles.PriceContainer}>
            <span>{t('component.wagonSelectionItem.from')} </span>
            <span className={styles.Price}>{price} €</span>
          </div>
        </div>
        <div className={styles.ImagesContainer}>{images}</div>
      </div>
    </label>
  );
};
