import React from 'react';
import { buttonColors, ButtonWidgetKinds } from '../../../widgets/button-widget';
import { ButtonComponent, Color } from '../button/button';
import * as Scrivito from 'scrivito';
import { ButtonBaseProps, CircularProgress } from '@mui/material';
import classNames from 'classnames';
import styles from './loading-button.module.scss';
import { ReactComponent as IconTick } from '../../../assets/icons/icon-success.svg';

interface LoadingButtonProps extends Omit<ButtonBaseProps, 'content'> {
  content: React.ReactElement | string;
  done: boolean;
  loading: boolean;
  href?: Scrivito.Link;
  color?: Color;
  small?: boolean;
  buttonClassName?: string;
}

export const LoadingButton: React.FC<LoadingButtonProps> = (props) => {
  let content = props.content;
  if (props.loading) {
    content = (
      <>
        {content}
        <CircularProgress className={styles.LoadingIcon} style={{ width: '1.75rem', height: '1.75rem' }} />
      </>
    );
  } else if (props.done) {
    content = (
      <>
        {content}
        <IconTick className={styles.DoneIcon} />
      </>
    );
  }

  return (
    <ButtonComponent
      href={props.href ?? null}
      color={props.color ?? buttonColors[ButtonWidgetKinds.CTA]}
      small={props.small ?? false}
      content={content}
      disabled={props.loading || props.disabled}
      className={classNames(props.buttonClassName, styles.LoadingButton, {
        [styles.Done]: props.done,
        [styles.Loading]: props.loading,
      })}
      onClick={props.onClick}
      type={props.type}
    />
  );
};
