import * as Scrivito from 'scrivito';
import { ColorBoxWidget, ColorBoxWidgetClass } from './color-box-widget-class';
import { ColorBoxWidgetAttributes } from './color-box-widget-definitions';
import { spacingClassName } from '../../utils/scrivito/spacing-definitions';
import classNames from 'classnames';
import styles from './color-box-widget.module.scss';

export const ColorBoxWidgetComponent: React.FC<{ widget: ColorBoxWidget }> = ({ widget }) => {
  const color = widget.get(ColorBoxWidgetAttributes.COLOR) as string;
  const elementId = (widget.get(ColorBoxWidgetAttributes.ELEMENT_ID) as string) || undefined;

  return (
    <Scrivito.WidgetTag id={elementId} className="FullWidth">
      <div className={classNames(styles.ColorBoxWidget, color, spacingClassName(widget))}>
        <Scrivito.ContentTag content={widget} attribute={ColorBoxWidgetAttributes.ELEMENTS} />
      </div>
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(ColorBoxWidgetClass, ColorBoxWidgetComponent);
