import * as Scrivito from 'scrivito';
import { BasePage, BasePageAttributes } from '../base-page';
import { ContentPageId } from './content-page-definitions';
import { heroClassAttributes } from '../properties/hero';
import { scheduleClassAttributes } from '../properties/schedule';

export const ContentPageObjectClass = Scrivito.provideObjClass(ContentPageId, {
  extend: BasePage,
  attributes: {
    ...(heroClassAttributes as object),
    ...(scheduleClassAttributes as object),
  },
  extractTextAttributes: [BasePageAttributes.BODY],
});

export type ContentPage = InstanceType<typeof ContentPageObjectClass>;
