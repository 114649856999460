import * as Scrivito from 'scrivito';
import { BasePage, BasePageAttributes } from '../base-page';
import { heroClassAttributes } from '../properties/hero';
import { FaqPageAttributes, FaqPageId } from './faq-page-definitions';
import { HeadlineWidgetTag } from '../../widgets/headline-widget';
import { scheduleClassAttributes } from '../properties/schedule';

export const FaqPageObjectClass = Scrivito.provideObjClass(FaqPageId, {
  extend: BasePage,
  attributes: {
    [FaqPageAttributes.HEADLINE]: 'string',
    [FaqPageAttributes.HEADLINE_ELEMENT]: ['enum', { values: [HeadlineWidgetTag.H1, HeadlineWidgetTag.H2] }],
    [FaqPageAttributes.SUBJECT_PARENT_PAGE]: 'reference',
    ...(heroClassAttributes as object),
    ...(scheduleClassAttributes as object),
  },
  extractTextAttributes: [FaqPageAttributes.HEADLINE, BasePageAttributes.BODY],
});

export type FaqPage = InstanceType<typeof FaqPageObjectClass>;
