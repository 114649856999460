import * as Scrivito from 'scrivito';
import { ButtonWidget, ButtonWidgetClass } from './button-widget-class';
import React from 'react';
import {
  ButtonWidgetAttributes,
  ButtonWidgetIcons,
  ButtonWidgetKinds,
  ButtonWidgetSizes,
} from './button-widget-definitions';
import { Icon, renderIcon } from '../../utils/icons.utils';
import { ButtonComponent, Color } from '../../components/controls/button/button';
import { spacingClassName } from '../../utils/scrivito/spacing-definitions';

// Map user selection to MUI colors
export const buttonColors: { [kind: string]: Color } = {
  [ButtonWidgetKinds.PRIMARY]: 'primary',
  [ButtonWidgetKinds.SECONDARY]: 'secondary',
  [ButtonWidgetKinds.CTA]: 'info',
};

export const ButtonWidgetComponent: React.FC<{ widget: ButtonWidget }> = ({ widget }) => {
  const linkObj = widget.get(ButtonWidgetAttributes.LINK) as Scrivito.Link;
  const linkTitle = linkObj?.title();
  const kind = widget.get(ButtonWidgetAttributes.KIND) ?? ButtonWidgetKinds.PRIMARY;
  const color = buttonColors[kind];
  const buttonIcon = widget.get(ButtonWidgetAttributes.ICON);
  const icon = buttonIcon === ButtonWidgetIcons.NONE ? undefined : renderIcon(buttonIcon as Icon);
  const isSmall = widget.get(ButtonWidgetAttributes.SIZE) === ButtonWidgetSizes.SMALL;
  const content = <>{linkTitle ?? 'Button'}</>;

  return (
    <Scrivito.WidgetTag>
      <div className={spacingClassName(widget)}>
        <ButtonComponent href={linkObj} color={color} small={isSmall} content={content} icon={icon} />
      </div>
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(ButtonWidgetClass, ButtonWidgetComponent);
