import * as Scrivito from 'scrivito';
import { HeadlineWidgetAttributes, HeadlineWidgetTag, HeadlineWidgetVariant } from './headline-widget-definitions';
import { HeadlineWidget, HeadlineWidgetClass } from './headline-widget-class';
import { ElementType } from 'react';
import { HeadlineComponent } from '../../components/controls/headline/headline';

export const HeadlineWidgetComponent: React.FC<{ widget: HeadlineWidget }> = ({ widget }) => {
  const component = (widget.get(HeadlineWidgetAttributes.COMPONENT) ?? HeadlineWidgetTag.H2) as ElementType;
  const variant = (widget.get(HeadlineWidgetAttributes.VARIANT) ?? HeadlineWidgetVariant.M) as HeadlineWidgetVariant;
  const id = widget.get(HeadlineWidgetAttributes.ID);
  const content = <Scrivito.ContentTag content={widget} attribute={HeadlineWidgetAttributes.TEXT} />;

  return (
    <Scrivito.WidgetTag>
      <HeadlineComponent component={component} variant={variant} id={id} content={content} />
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(HeadlineWidgetClass, HeadlineWidgetComponent);
