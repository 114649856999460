import Breadcrumbs, { BreadcrumbsProps } from '@mui/material/Breadcrumbs';
import React, { useMemo } from 'react';
import * as Scrivito from 'scrivito';
import styles from './breadcrumb.module.scss';
import { ReactComponent as RheinbahnLogo } from '../../../assets/img/rheinbahn-logo-small.svg';
import classNames from 'classnames';
import { useHierarchy } from '../../../utils/scrivito';
import { NavigationEntryAttrs } from '../../../pages/home-page/home-page-navigation';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

export type LinkType = Scrivito.Obj | Scrivito.Link | null;

/**
 * Breadcrumb Logo Component
 */
export const BreadCrumbLogoComponent: React.FC<{
  t: TFunction;
  /**
   * Root Obj of page.
   */
  root?: LinkType;
}> = ({ root, t }) => {
  const label = t('component.breadcrumb.start') as string;
  return (
    <Scrivito.LinkTag to={root} className={styles.BreadCrumbLogoComponent} title={label} aria-label={label}>
      <RheinbahnLogo />
    </Scrivito.LinkTag>
  );
};

/**
 * Breadcrumb Separator Component
 */
export const BreadcrumbSeparatorComponent: React.FC = () => (
  <span className={styles.BreadcrumbSeparatorComponent}>-</span>
);

export interface BreadcrumbProps extends BreadcrumbsProps {
  /**
   * Root Obj of page.
   */
  root?: LinkType;

  /**
   * Obj of current page.
   */
  currentPage?: LinkType;
}

interface AncestorEntry {
  title: string;
  link: LinkType;
}

function objFromLinkType(link: LinkType): Scrivito.Obj | null {
  if (!link) {
    return null;
  }

  let obj: Scrivito.Obj = link as unknown as Scrivito.Obj;
  if ('obj' in link) {
    obj = (link as unknown as Scrivito.Link).obj() as Scrivito.Obj;
  }

  return obj;
}

/**
 * Breadcrumb Component
 */
export const BreadcrumbComponent: React.FC<BreadcrumbProps> = Scrivito.connect((props) => {
  const hierarchy = useHierarchy();
  const scrivitoAncestors: Array<Scrivito.Obj | null> = (Scrivito.currentPage()?.ancestors() ?? []).splice(1);
  const { t } = useTranslation();

  const ancestors: AncestorEntry[] = useMemo(() => {
    /*
      First attempt to fetch the hierarchy from the useHierarchy, which uses the navigation.
      When it isn't working, use the Scrivito page hierarchy.
     */
    const result = hierarchy.widgets.map((n) => {
      const label = n.get(NavigationEntryAttrs.LABEL) as string;
      const link = n.get(NavigationEntryAttrs.LINK) as Scrivito.Link | null;
      const linkTitle = link?.title() as string | undefined;
      const title = linkTitle || label || '';

      return { title, link };
    });

    if (result.length > 0) {
      return result;
    }

    return scrivitoAncestors
      .filter((n) => n)
      .map((n) => n as Scrivito.Obj)
      .map((link) => {
        const title: string = (link.get('title') as string) ?? '';
        return { title, link };
      });
  }, [hierarchy.widgets, scrivitoAncestors]);

  const rootEqualsCurrent =
    props.currentPage &&
    props.root instanceof Scrivito.Obj &&
    (props.currentPage as Scrivito.Obj).id() === props.root?.id();

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      className={styles.Breadcrumb}
      maxItems={props?.maxItems}
      separator=""
      itemsBeforeCollapse={props?.itemsBeforeCollapse}
      itemsAfterCollapse={props?.itemsAfterCollapse}
    >
      {!rootEqualsCurrent && <BreadCrumbLogoComponent t={t} root={props?.root} />}
      {ancestors.map((ancestor, index) => {
        const obj = objFromLinkType(ancestor.link);
        if (obj && obj.id() === Scrivito.currentPage()?.id()) {
          return null;
        }

        return <BreadcrumbSimpleComponent key={index} link={ancestor.link} label={ancestor.title} />;
      })}
      {/* show current page only if not root page (logo) */}
      {!rootEqualsCurrent ? <BreadcrumbSimpleComponent link={props.currentPage} /> : null}
      {props?.children}
    </Breadcrumbs>
  );
});

/**
 * Breadcrumb Simple Component
 * Displays anchor element (link) as breadcrumb item.
 */
export const BreadcrumbSimpleComponent: React.FC<{
  link?: LinkType;
  label?: string;
}> = ({ link, label }) => {
  let title = label;
  let id = null;

  if (link instanceof Scrivito.Obj) {
    title = link.get('title') as string;
    id = link.id();
  }

  const currentPage = Scrivito.currentPage();

  return (
    <div
      className={classNames(styles.BreadCrumbSimpleLink, {
        [styles.active]: currentPage?.id() === id,
      })}
    >
      <BreadcrumbSeparatorComponent />
      <Scrivito.LinkTag to={link}>{title}</Scrivito.LinkTag>
    </div>
  );
};
