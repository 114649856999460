import { HelperText, WidgetTitle } from '../../utils/translations';
import * as Scrivito from 'scrivito';
import { KpiTileWidgetAttributes, KpiTileWidgetColors, KpiTileWidgetId } from './kpi-tile-widget-definitions';
import { titleForContentOfHtml } from '../../utils/scrivito/title-for-content-html';
import { KpiTileWidgetType } from './kpi-tile-widget-class';
import { VideoWidgetAttributes } from '../video-widget';

Scrivito.provideEditingConfig(KpiTileWidgetId, {
  title: WidgetTitle.KPI_TILE,
  titleForContent: (obj) => {
    switch (obj.get(KpiTileWidgetAttributes.TYPE)) {
      case KpiTileWidgetType.VIDEO:
        return `[${HelperText.KPI_TILE_TYPE_VIDEO}] ${(obj.get(KpiTileWidgetAttributes.VIDEO) as Scrivito.Obj).get(
          VideoWidgetAttributes.TITLE
        )}`;
      case KpiTileWidgetType.IMAGE:
      default:
        if (obj.get(KpiTileWidgetAttributes.IMAGE)) {
          return `[${HelperText.KPI_TILE_TYPE_IMAGE}] ${titleForContentOfHtml(obj, KpiTileWidgetAttributes.TEXT)}`;
        } else {
          const backgroundColor = obj.get(KpiTileWidgetAttributes.BACKGROUND_COLOR) as string;
          if (backgroundColor) {
            return `[${backgroundColor.charAt(0).toUpperCase()}${backgroundColor.substring(1)}] ${titleForContentOfHtml(
              obj,
              KpiTileWidgetAttributes.TEXT
            )}`;
          }
        }
        return '';
    }
  },
  // display properties depending on type
  properties: (obj) => {
    switch (obj.get(KpiTileWidgetAttributes.TYPE)) {
      case KpiTileWidgetType.VIDEO:
        return [KpiTileWidgetAttributes.TYPE, KpiTileWidgetAttributes.VIDEO, KpiTileWidgetAttributes.ASSET_ALT];
      case KpiTileWidgetType.IMAGE:
      default:
        return [
          KpiTileWidgetAttributes.TYPE,
          KpiTileWidgetAttributes.TEXT,
          KpiTileWidgetAttributes.COUNTER_START,
          KpiTileWidgetAttributes.COUNTER_END,
          KpiTileWidgetAttributes.BACKGROUND_COLOR,
          KpiTileWidgetAttributes.IMAGE,
          KpiTileWidgetAttributes.ASSET_ALT,
        ];
    }
  },
  attributes: {
    [KpiTileWidgetAttributes.TYPE]: {
      title: HelperText.KPI_TILE_TYPE,
      values: [
        { value: KpiTileWidgetType.IMAGE, title: HelperText.KPI_TILE_TYPE_IMAGE },
        { value: KpiTileWidgetType.VIDEO, title: HelperText.KPI_TILE_TYPE_VIDEO },
      ],
    },
    [KpiTileWidgetAttributes.TEXT]: {
      title: HelperText.KPI_TILE_TEXT,
    },
    [KpiTileWidgetAttributes.COUNTER_START]: {
      title: HelperText.KPI_TILE_COUNTER_START,
    },
    [KpiTileWidgetAttributes.COUNTER_END]: {
      title: HelperText.KPI_TILE_COUNTER_END,
    },
    [KpiTileWidgetAttributes.BACKGROUND_COLOR]: {
      title: HelperText.BACKGROUND_COLOR,
      values: [
        { value: KpiTileWidgetColors.DARK_GREEN, title: 'dunkelgrün' },
        { value: KpiTileWidgetColors.DARK_RED, title: 'dunkelrot' },
        { value: KpiTileWidgetColors.VIOLET, title: 'lila' },
        { value: KpiTileWidgetColors.RED, title: 'rot' },
        { value: KpiTileWidgetColors.GRAY, title: 'grau' },
      ],
    },
    [KpiTileWidgetAttributes.IMAGE]: {
      title: HelperText.KPI_TILE_IMAGE,
    },
    [KpiTileWidgetAttributes.ASSET_ALT]: {
      title: HelperText.ASSET_ALT,
    },
    [KpiTileWidgetAttributes.VIDEO]: {
      title: HelperText.KPI_TILE_VIDEO,
    },
  },
  validations: [],
  initialContent: {
    [KpiTileWidgetAttributes.TYPE]: KpiTileWidgetType.IMAGE,
    [KpiTileWidgetAttributes.BACKGROUND_COLOR]: KpiTileWidgetColors.DARK_GREEN,
  },
});
