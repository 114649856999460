import * as Scrivito from 'scrivito';
import { VideoReelSliderWidgetAttributes, VideoReelSliderWidgetId } from './video-reel-slider-widget-definitions';
import { spacingAttributes, spacingContent, spacingGroup } from '../../utils/scrivito/spacing-definitions';
import { HelperText, Message, WidgetTitle } from '../../utils/translations';
import { ValidationInfo } from '../../utils/type.utils';
import Thumbnail from '../../assets/thumbnails/reels_video_slider.svg';

Scrivito.provideEditingConfig(VideoReelSliderWidgetId, {
  title: WidgetTitle.VIDEO_REEL_WIDGET,
  thumbnail: Thumbnail,
  properties: Object.values(VideoReelSliderWidgetAttributes),
  propertiesGroups: [spacingGroup],
  attributes: {
    [VideoReelSliderWidgetAttributes.TITLE]: {
      title: 'Titel',
    },
    [VideoReelSliderWidgetAttributes.ELEMENT_ID]: {
      title: HelperText.ELEMENT_ID,
      description: HelperText.ELEMENT_ID_DESCRIPTION,
    },
    [VideoReelSliderWidgetAttributes.TEXT]: {
      title: 'Text',
      options: {
        toolbar: ['link', 'bold', 'superscript', 'subscript'],
      },
    },
    [VideoReelSliderWidgetAttributes.VIDEOS]: {
      title: 'Videos',
    },
    ...spacingAttributes,
  },
  validations: [
    [
      VideoReelSliderWidgetAttributes.VIDEOS,
      (value: Scrivito.Obj[]): ValidationInfo => {
        if (value.length < 2) {
          return {
            severity: 'error',
            message: Message.VIDEO_REEL_MIN_COUNT,
          };
        }
        return undefined;
      },
    ],
  ],
  initialContent: {
    ...spacingContent,
  },
});
