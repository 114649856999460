import React from 'react';
import * as Scrivito from 'scrivito';
import { formatDate } from '../../../utils/general.utils';
import styles from './press-release-preview.module.scss';

/**
 * Properties for the search result component
 */
export interface PressReleasePreviewProps {
  headline: string;
  subHeadline: string;
  link: Scrivito.Obj;
  date: Date;
}

/**
 * Creates a press release preview used in overview pages
 */
export const PressReleasePreview: React.FC<PressReleasePreviewProps> = (props) => {
  return (
    <li className={styles.PressReleasePreview}>
      <Scrivito.LinkTag to={props.link}>
        <time>{formatDate(props.date)}</time>
        <div className={styles.PressReleaseHeadlines}>
          <span className={styles.PressReleaseSubHeadline}>{props.subHeadline}</span>
          <span className={styles.PressReleaseHeadline}>{props.headline}</span>
        </div>
      </Scrivito.LinkTag>
    </li>
  );
};
