import * as Scrivito from 'scrivito';
import { DownloadObjectId, IconObjectId, ImageObjectId, VideoObjectId } from '../objects';

/**
 * Configure how the content browser handles file uploads.
 */
export const configureObjClassForContentType = (): void => {
  Scrivito.configureObjClassForContentType({
    'image/*': ImageObjectId,
    'image/svg+xml': IconObjectId,
    'video/*': VideoObjectId,
    '*/*': DownloadObjectId,
  });
};
