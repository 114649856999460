import * as Scrivito from 'scrivito';
import {
  IconListWidgetAttributes,
  IconListWidgetIcons,
  IconListWidgetId,
  IconListWidgetHeadlineHierarchy,
} from './icon-list-widget-definitions';
import { HelperText, WidgetTitle } from '../../utils/translations';
import { spacingAttributes, spacingContent, spacingGroup } from '../../utils/scrivito/spacing-definitions';
import Thumbnail from '../../assets/thumbnails/featured-list.svg';

Scrivito.provideEditingConfig(IconListWidgetId, {
  title: WidgetTitle.ICON_LIST,
  thumbnail: Thumbnail,
  properties: Object.values(IconListWidgetAttributes),
  attributes: {
    [IconListWidgetAttributes.HEADLINE]: {
      title: HelperText.HEADLINE,
    },
    [IconListWidgetAttributes.HEADLINE_HIERARCHY]: {
      title: HelperText.HEADLINE_HIERARCHY,
      values: [
        { value: IconListWidgetHeadlineHierarchy.HEADLINE1, title: '<h1>' },
        { value: IconListWidgetHeadlineHierarchy.HEADLINE2, title: '<h2>' },
        { value: IconListWidgetHeadlineHierarchy.HEADLINE3, title: '<h3>' },
        { value: IconListWidgetHeadlineHierarchy.HEADLINE4, title: '<h4>' },
        { value: IconListWidgetHeadlineHierarchy.HEADLINE5, title: '<h5>' },
        { value: IconListWidgetHeadlineHierarchy.HEADLINE6, title: '<h6>' },
      ],
    },
    [IconListWidgetAttributes.ITEMS]: {
      title: HelperText.LIST,
    },
    [IconListWidgetAttributes.ICON]: {
      title: HelperText.ICON,
      values: [
        { value: IconListWidgetIcons.CHECKMARK, title: 'Checkmark' },
        { value: IconListWidgetIcons.CROSS, title: 'X' },
        { value: IconListWidgetIcons.STAR, title: 'Sternchen' },
      ],
    },
    [IconListWidgetAttributes.ID]: {
      title: HelperText.ELEMENT_ID,
      description: HelperText.ELEMENT_ID_DESCRIPTION,
    },
    ...spacingAttributes,
  },
  propertiesGroups: [spacingGroup],
  validations: [],
  initialContent: {
    [IconListWidgetAttributes.HEADLINE_HIERARCHY]: IconListWidgetHeadlineHierarchy.HEADLINE3,
    [IconListWidgetAttributes.ICON]: IconListWidgetIcons.CHECKMARK,
    ...spacingContent,
  },
});
