export const HighlightTeaserWidgetId = 'HighlightTeaserWidget';

export enum HighlightTeaserWidgetAttributes {
  LINK = 'link',
  SUBJECT = 'subject',
  TEXT = 'text',
  IMAGE = 'image',
  IMAGE_ALT = 'imageAltText',
  IMAGE_TITLE = 'imageTitle',
  INNER_SHADOW = 'innerShadow',
}
