import * as Scrivito from 'scrivito';
import {
  TimelineEntryWidget,
  TimelineEntryWidgetClass,
  TimelineWidget,
  TimelineWidgetClass,
} from './timeline-widget-class';
import { TimelineComponent } from '../../components/building-blocks/timeline/timeline';
import { TimelineEntryWidgetAttributes, TimelineWidgetAttributes } from './timeline-widget-definitions';
import { spacingClassName } from '../../utils/scrivito/spacing-definitions';

import { TimelineEntry } from '../../components/building-blocks/timeline/timeline-entry';
import { ResponsiveImage } from '../../components/controls/image/responsive-image/responsive-image.scrivito';
import { ImageObjectAttributes } from '../../objects';
import { stripHtml } from '../../utils/string';

export const TimelineWidgetComponent: React.FC<{ widget: TimelineWidget }> = ({ widget }) => {
  const title = <Scrivito.ContentTag tag="h2" content={widget} attribute={TimelineWidgetAttributes.TITLE} />;
  const elementId = (widget.get(TimelineWidgetAttributes.ELEMENT_ID) as string) || undefined;
  const content = <Scrivito.ContentTag content={widget} attribute={TimelineWidgetAttributes.ENTRIES} />;

  return (
    <Scrivito.WidgetTag>
      <div id={elementId} className={spacingClassName(widget)}>
        <TimelineComponent title={title} content={content} />
      </div>
    </Scrivito.WidgetTag>
  );
};

export const TimelineEntryWidgetComponent: React.FC<{ widget: TimelineEntryWidget }> = ({ widget }) => {
  const title = <Scrivito.ContentTag tag="h3" content={widget} attribute={TimelineEntryWidgetAttributes.TITLE} />;
  const date = widget.get(TimelineEntryWidgetAttributes.DATE) as Date;
  const shortText = (
    <Scrivito.ContentTag tag="p" content={widget} attribute={TimelineEntryWidgetAttributes.SHORT_TEXT} />
  );
  const text = <Scrivito.ContentTag tag="p" content={widget} attribute={TimelineEntryWidgetAttributes.TEXT} />;
  const textHtml = widget.get(TimelineEntryWidgetAttributes.TEXT) as string;
  const textFilled = stripHtml(textHtml).length > 0;

  const image = widget.get(TimelineEntryWidgetAttributes.IMAGE);
  const imageBlob = image?.get('blob') as Scrivito.Binary;
  let imageNode: React.ReactNode = (
    <Scrivito.ImageTag content={widget} attribute={TimelineEntryWidgetAttributes.IMAGE} />
  );
  if (image && imageBlob) {
    const alt = image.get(ImageObjectAttributes.ALTERNATIVE_TEXT) as string;
    const title = image.get(ImageObjectAttributes.TITLE) as string;
    imageNode = <ResponsiveImage alt={alt} binary={imageBlob} title={title} />;
  }

  return (
    <Scrivito.WidgetTag>
      <TimelineEntry
        title={title}
        date={date}
        image={imageNode}
        shortText={shortText}
        text={text}
        hasLongText={textFilled}
      />
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(TimelineWidgetClass, TimelineWidgetComponent);
Scrivito.provideComponent(TimelineEntryWidgetClass, TimelineEntryWidgetComponent);
