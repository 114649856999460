import * as Scrivito from 'scrivito';
import {
  SocialMediaWidgetAttributes,
  SocialMediaWidgetId,
  SocialMediaWidgetType,
} from './social-media-widget-definitions';
import { createExternalLink } from '../../utils/scrivito';
import { ValidationInfo } from '../../utils/type.utils';
import { Message, WidgetTitle } from '../../utils/translations';
import { spacingAttributes, spacingContent, spacingGroup } from '../../utils/scrivito/spacing-definitions';
import Thumbnail from '../../assets/thumbnails/social-media.svg';

Scrivito.provideEditingConfig(SocialMediaWidgetId, {
  title: WidgetTitle.SOCIAL_MEDIA,
  thumbnail: Thumbnail,
  properties: Object.values(SocialMediaWidgetAttributes),
  attributes: {
    [SocialMediaWidgetAttributes.TYPE]: {
      title: 'Anzeigen',
      values: [
        { value: SocialMediaWidgetType.FACEBOOK, title: 'Facebook' },
        { value: SocialMediaWidgetType.INSTAGRAM, title: 'Instagram' },
        { value: SocialMediaWidgetType.TWITTER, title: 'Twitter' },
        { value: SocialMediaWidgetType.YOUTUBE, title: 'YouTube' },
        { value: SocialMediaWidgetType.LINKEDIN, title: 'LinkedIn' },
        { value: SocialMediaWidgetType.BLOG, title: 'Blog' },
      ],
    },
    [SocialMediaWidgetAttributes.FACEBOOK]: {
      title: 'Facebook-URL',
    },
    [SocialMediaWidgetAttributes.INSTAGRAM]: {
      title: 'Instagram-URL',
    },
    [SocialMediaWidgetAttributes.TWITTER]: {
      title: 'Twitter-URL',
    },
    [SocialMediaWidgetAttributes.YOUTUBE]: {
      title: 'YouTube-URL',
    },
    [SocialMediaWidgetAttributes.LINKEDIN]: {
      title: 'LinkedIn-URL',
    },
    [SocialMediaWidgetAttributes.BLOG]: {
      title: 'Blog-URL',
    },
    ...spacingAttributes,
  },
  propertiesGroups: [spacingGroup],
  validations: [
    [
      SocialMediaWidgetAttributes.TYPE,
      (value: string[] | null): ValidationInfo => {
        if (value?.length === 0) {
          return {
            message: Message.SOCIAL_MEDIA_LINK_MISSING,
            severity: 'error',
          };
        }
        return undefined;
      },
    ],
  ],
  initialContent: {
    [SocialMediaWidgetAttributes.TYPE]: Object.values(SocialMediaWidgetType),
    [SocialMediaWidgetAttributes.FACEBOOK]: createExternalLink('http://de-de.facebook.com/Rheinbahn', 'Facebook'),
    [SocialMediaWidgetAttributes.INSTAGRAM]: createExternalLink('https://instagram.com/rheinbahn/', 'Instagram'),
    [SocialMediaWidgetAttributes.TWITTER]: createExternalLink('https://twitter.com/DieRheinbahn', 'Twitter'),
    [SocialMediaWidgetAttributes.YOUTUBE]: createExternalLink('https://www.youtube.com/user/DieRheinbahn', 'YouTube'),
    [SocialMediaWidgetAttributes.LINKEDIN]: createExternalLink(
      'https://de.linkedin.com/company/rheinbahn-ag',
      'LinkedIn'
    ),
    [SocialMediaWidgetAttributes.BLOG]: createExternalLink('https://blog.rheinbahn.de/', 'Blog'),
    ...spacingContent,
  },
});
