import React, { FocusEventHandler, ReactNode, useEffect, useMemo, useState } from 'react';
import { Autocomplete, CircularProgress } from '@mui/material';
import styles from './autosuggest.module.scss';
import Highlighter from 'react-highlight-words';
import { TextInputComponent } from '../../controls/text-input/text-input';
import throttle from 'lodash.throttle';
import { renderEfaIcon } from '../../../utils/icons.utils';
import { ReactComponent as IconLocation } from '../../../assets/icons/icon-location.svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { EfaStopSuggestion, getNearestStop, getSuggestions } from '../api/efa-api.utils';
import { useViewportHeight } from '../../../utils/hooks.utils';

interface AutoSuggestComponentProps {
  id?: string;
  label: string;
  locationFinder?: boolean;
  value?: EfaStopSuggestion;
  className?: string;
  onFocus?: FocusEventHandler;
  onChange?: (value: EfaStopSuggestion | string) => void;
  onError?: (error: string) => void;
  /**
   * Scrolls the input field into the view when necessary to avoid opening the flyout above
   */
  scrollIntoView?: boolean;
}

export const AutoSuggestComponent: React.FC<AutoSuggestComponentProps> = ({ ...props }) => {
  const [value, setValue] = useState<string | EfaStopSuggestion>(props.value || '');
  const [inputValue, setInputValue] = useState(props.value?.name || '');
  const [options, setOptions] = useState<Array<string | EfaStopSuggestion>>([]);
  const [enableLocationButton, setEnableLocationButton] = useState(true);
  const viewportHeight = useViewportHeight();
  const { t } = useTranslation();

  // Get suggestions from the input value
  const fetch = useMemo(
    () =>
      throttle((request) => {
        return getSuggestions(request?.input);
      }, 1000),
    []
  );

  // Update value if changed from the higher order component (switch from/to)
  useEffect(() => {
    if (props.value) {
      setValue(props.value);
      setInputValue(props.value.name);
    } else {
      setValue('');
      setInputValue('');
    }
  }, [props.value]);

  const updateValue = (newInputValue: string): void => {
    // Look for suggestions if the input value has at least 3 characters
    if (newInputValue.length < 3) {
      // Clear options only when it is not already empty
      if (options.length !== 0) {
        setOptions([]);
      }
      return undefined;
    }

    fetch({ input: newInputValue })?.then((results) => {
      let newOptions: Array<string | EfaStopSuggestion> = [];
      if (results) {
        newOptions = [...newOptions, ...results];
      }
      setOptions(newOptions);
    });
  };

  // Get the geolocation from the user if available
  const onLocationCLick = (): void => {
    const options = {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: 0,
    };

    const success = async (position: GeolocationPosition): Promise<void> => {
      const coordinates = position.coords;

      // Get the nearest stop from the current position
      const stop = await getNearestStop(coordinates);
      if (stop) {
        setValue(stop);
        setInputValue(stop.name);
        props.onChange && props.onChange(stop);
      } else {
        props.onError && props.onError(t('journeyPlanner.stopError'));
      }
      setEnableLocationButton(true);
    };

    const error = (): void => {
      setEnableLocationButton(true);
      if (props.onError) {
        props.onError(t('journeyPlanner.locationError'));
      }
    };

    navigator.geolocation.getCurrentPosition(success, error, options);
    setEnableLocationButton(false);
  };

  return (
    <div className={classNames(styles.AutoSuggest, props.className)} data-test-id="EfaAutoSuggest">
      <Autocomplete
        id={props.id}
        data-testid={'autocomplete'}
        freeSolo
        ListboxProps={{
          className: styles.AutoSuggestDropdown,
        }}
        value={value}
        filterOptions={(x): Array<string | EfaStopSuggestion> => x}
        getOptionLabel={(option: EfaStopSuggestion | string): string =>
          typeof option === 'string' ? option : option.name
        }
        componentsProps={{
          popper: {
            modifiers: [
              {
                name: 'flip',
                enabled: !props.scrollIntoView,
              },
            ],
          },
        }}
        disableClearable
        selectOnFocus
        disablePortal
        noOptionsText={t('search.noAutoCompleteSuggestions')}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        onChange={(event, newValue): void => {
          props.onChange && props.onChange(newValue);
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue): void => {
          // Set value only if it is not the same as the current value
          if (newInputValue !== inputValue) {
            props.onChange && props.onChange(newInputValue);
            setInputValue(newInputValue);
            updateValue(newInputValue);
          }
        }}
        onFocus={props.onFocus}
        renderOption={(props, option: EfaStopSuggestion | string): ReactNode => (
          <li
            {...props}
            key={typeof option === 'string' ? option : option.id}
            title={typeof option === 'string' ? option : option.name}
          >
            {typeof option !== 'string' && renderEfaIcon(option.type)}
            <Highlighter
              highlightClassName={styles.HighlightKeyword}
              searchWords={[inputValue, typeof value === 'string' ? value : value.name]}
              autoEscape={true}
              textToHighlight={typeof option === 'string' ? option : option.name}
            />
          </li>
        )}
        renderInput={(params): JSX.Element => (
          <TextInputComponent
            {...params}
            label={props.label}
            modifierClass={'TextInputComponentAutosuggest'}
            data-testid="input"
            hideOptionalLabel={true}
            onFocus={(e: React.FocusEvent): void => {
              // Scrolls into view when the input's position from the top is half of the viewport height
              // Makes sure that the flyout is opened below and not above (otherwise it will be cut off by the header)
              if (props.scrollIntoView) {
                const element = e.target as HTMLElement;
                const elementPositionTop = element.getBoundingClientRect().top;
                const totalHeight = (viewportHeight || 0) * 0.5;
                if ('scrollIntoView' in element && elementPositionTop > totalHeight) {
                  element.scrollIntoView({ behavior: 'smooth' });
                }
              }
            }}
          />
        )}
      />
      {props.locationFinder && (
        <button
          data-testid="locationFinder"
          className={styles.LocationButton}
          type="button"
          onClick={onLocationCLick}
          disabled={!enableLocationButton}
          title={t('journeyPlanner.locationTitle')}
        >
          {enableLocationButton ? <IconLocation /> : <CircularProgress size={'1.5rem'} />}
        </button>
      )}
    </div>
  );
};
