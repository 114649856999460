import * as Scrivito from 'scrivito';
import { FormSpecialSubmissionErrorWidgetId } from './form-special-submission-error-widget-id';
import { FormSpecialSubmissionErrorWidgetAttributes } from './form-special-submission-error-widget-definitions';
import { FormWidgetId } from '../form-widget/form-widget-definitions';

export const FormRowWidget = Scrivito.provideWidgetClass(FormSpecialSubmissionErrorWidgetId, {
  attributes: {
    [FormSpecialSubmissionErrorWidgetAttributes.TRIGGERING_ERROR_MESSAGE]: 'string',
    [FormSpecialSubmissionErrorWidgetAttributes.RESULTING_SPECIAL_ERROR]: 'string',
  },
  onlyInside: [FormWidgetId],
});
