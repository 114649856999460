import { HelperText, Message, WidgetTitle } from '../../utils/translations';
import * as Scrivito from 'scrivito';
import { ValidationInfo } from '../../utils/type.utils';
import { TextTeaserWidgetClass } from '../text-teaser-widget';
import { TextTeaserGroupWidgetAttributes, TextTeaserGroupWidgetId } from './text-teaser-group-widget-definitions';
import { spacingAttributes, spacingContent, spacingGroup } from '../../utils/scrivito/spacing-definitions';
import Thumbnail from '../../assets/thumbnails/text-teaser.svg';

const minRequiredTeaser = 1;
const maxAllowedTeaser = 6;

Scrivito.provideEditingConfig(TextTeaserGroupWidgetId, {
  title: WidgetTitle.TEXT_TEASER_GROUP,
  thumbnail: Thumbnail,
  properties: Object.values(TextTeaserGroupWidgetAttributes),
  attributes: {
    [TextTeaserGroupWidgetAttributes.TEXT_TEASER]: {
      title: HelperText.TEXT_TEASER_GROUP_TEASER,
    },
    ...spacingAttributes,
  },
  propertiesGroups: [spacingGroup],
  validations: [
    [
      TextTeaserGroupWidgetAttributes.TEXT_TEASER,
      (widgets: Array<Scrivito.Widget>): ValidationInfo => {
        if (widgets && widgets.length < minRequiredTeaser) {
          return {
            message: Message.TEXT_TEASER_GROUP_MIN_ITEMS,
            severity: 'error',
          };
        }
        if (widgets && widgets.length > maxAllowedTeaser) {
          return {
            message: Message.TEXT_TEASER_GROUP_MAX_ITEMS,
            severity: 'error',
          };
        }

        return undefined;
      },
    ],
  ],
  initialContent: {
    [TextTeaserGroupWidgetAttributes.TEXT_TEASER]: Array.from(
      { length: minRequiredTeaser },
      () => new TextTeaserWidgetClass()
    ),
    ...spacingContent,
  },
});
