import classNames from 'classnames';
import * as Scrivito from 'scrivito';
import { getAttributeOrFallback } from '../../utils/scrivito/image.utils';
import { ImageWidgetAttributes } from '../image-widget';

import styles from './kpi-tile-group-widget.module.scss';
import { spacingClassName } from '../../utils/scrivito/spacing-definitions';

import { ResponsiveImage } from '../../components/controls/image/responsive-image/responsive-image.scrivito';
import React from 'react';
import { KpiTileWidget, KpiTileWidgetAttributes, KpiTileWidgetColors } from '../kpi-tile-widget';
import { KpiTileComponent } from '../../components/controls/kpi-tile/kpi-tile';
import { KpiTileGroupWidget, KpiTileGroupWidgetClass } from './kpi-tile-group-widget-class';
import { KpiTileGroupWidgetAttributes } from './kpi-tile-group-widget-definitions';
import { isInternalScrivitoSubUrl } from '../../utils/general.utils';

export const KpiTileGroupWidgetComponent: React.FC<{ widget: KpiTileGroupWidget }> = ({ widget }) => {
  const teasers = widget.get(KpiTileGroupWidgetAttributes.KPI_TILE) || [];
  const id = widget.get(KpiTileGroupWidgetAttributes.ID) || undefined;

  return (
    <Scrivito.WidgetTag className={'FullWidth'}>
      <div id={id} className={classNames(styles.KpiTileGroup, spacingClassName(widget))}>
        <div
          className={classNames(styles.KpiTileGroupGrid, {
            [styles['teaser-count-even']]: teasers.length % 2 === 0,
            [styles['teaser-count-tree']]: teasers.length === 3,
            [styles['teaser-count-two']]: teasers.length === 2,
          })}
        >
          {teasers.map((teaser: KpiTileWidget, index: number) => {
            const text = teaser.get(KpiTileWidgetAttributes.TEXT);
            const counterStart = teaser.get(KpiTileWidgetAttributes.COUNTER_START) || undefined;
            const counterEnd = teaser.get(KpiTileWidgetAttributes.COUNTER_END) || undefined;
            const backgroundColor =
              teaser.get(KpiTileWidgetAttributes.BACKGROUND_COLOR) || KpiTileWidgetColors.DARK_GREEN;
            const image = getAttributeOrFallback(teaser, ImageWidgetAttributes.IMAGE);
            const video = teaser.get(KpiTileWidgetAttributes.VIDEO);

            let asset = null;
            if (image) {
              // Get the binary of the selected image.
              const binary = image.get('blob') as Scrivito.Binary;
              const imgHeight = image.metadata().get('height') as number;
              const imgWidth = image.metadata().get('width') as number;
              const altText = teaser.get(KpiTileWidgetAttributes.ASSET_ALT) || (image.get('alternativeText') as string);
              const titleText = image.get('title') as string;
              asset = (
                <ResponsiveImage
                  alt={altText}
                  title={titleText}
                  binary={binary}
                  meta={imgWidth && imgHeight ? { width: imgWidth, height: imgHeight } : undefined}
                />
              );
            } else if (video) {
              const videoUrl = video?.contentUrl() || '';
              const altText = teaser.get(KpiTileWidgetAttributes.ASSET_ALT);

              // check for thumbnail that is resolved before actual image url
              if (isInternalScrivitoSubUrl(videoUrl)) {
                asset = (
                  <video controls={false} muted playsInline={true}>
                    <source src={videoUrl} aria-label={altText} />
                  </video>
                );
              }
            }

            return (
              <KpiTileComponent
                key={index}
                text={text}
                counterStart={counterStart}
                counterEnd={counterEnd}
                asset={asset}
                backgroundColor={backgroundColor}
              />
            );
          })}
        </div>
      </div>
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(KpiTileGroupWidgetClass, KpiTileGroupWidgetComponent);
