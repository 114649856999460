import * as Scrivito from 'scrivito';
import { DownloadWidgetAttributes, DownloadWidgetId } from './download-widget-definitions';
import { HeadlineWidgetTag } from '../headline-widget';
import { ValidationInfo } from '../../utils/type.utils';
import { HelperText, Message, WidgetTitle } from '../../utils/translations';
import { checkValue, formElementIdAttributeValidation } from '../../utils/scrivito/validation';
import { spacingAttributes, spacingContent, spacingGroup } from '../../utils/scrivito/spacing-definitions';
import Thumbnail from '../../assets/thumbnails/download-box.svg';

Scrivito.provideEditingConfig(DownloadWidgetId, {
  title: WidgetTitle.DOWNLOAD_BOX,
  thumbnail: Thumbnail,
  properties: Object.values(DownloadWidgetAttributes),
  attributes: {
    [DownloadWidgetAttributes.HEADLINE]: {
      title: 'Überschrift',
    },
    [DownloadWidgetAttributes.HEADLINE_COMPONENT]: {
      title: 'Hierarchie der Überschrift (HTML)',
      values: [
        { value: HeadlineWidgetTag.H1, title: '<h1>' },
        { value: HeadlineWidgetTag.H2, title: '<h2>' },
        { value: HeadlineWidgetTag.H3, title: '<h3>' },
        { value: HeadlineWidgetTag.H4, title: '<h4>' },
        { value: HeadlineWidgetTag.H5, title: '<h5>' },
        { value: HeadlineWidgetTag.H6, title: '<h6>' },
      ],
    },
    [DownloadWidgetAttributes.FILES]: {
      title: 'Dateien',
    },
    [DownloadWidgetAttributes.DOWNLOAD_BUTTON_TEXT]: {
      title: 'Text für den Download-Button',
    },
    [DownloadWidgetAttributes.DOWNLOAD_ZIP_NAME]: {
      title: 'Name für die ZIP-Datei',
    },
    [DownloadWidgetAttributes.ID]: {
      title: HelperText.ELEMENT_ID,
      description: HelperText.ELEMENT_ID_DESCRIPTION,
    },
    ...spacingAttributes,
  },
  propertiesGroups: [spacingGroup],
  validations: [
    [
      DownloadWidgetAttributes.FILES,
      (files: Array<Scrivito.Widget>): ValidationInfo => {
        if (files?.length < 1) {
          return {
            message: Message.DOWNLOAD_ITEM_MIN,
            severity: 'error',
          };
        }
        return undefined;
      },
    ],
    [
      DownloadWidgetAttributes.DOWNLOAD_BUTTON_TEXT,
      (value: string): ValidationInfo => checkValue(value, Message.BUTTON_NAME_MISSING),
    ],
    [DownloadWidgetAttributes.HEADLINE, (value: string): ValidationInfo => checkValue(value, Message.HEADLINE_MISSING)],
    formElementIdAttributeValidation(DownloadWidgetAttributes.ID),
  ],
  initialContent: {
    [DownloadWidgetAttributes.HEADLINE]: 'Nützliche Informationen zum Herunterladen',
    [DownloadWidgetAttributes.HEADLINE_COMPONENT]: HeadlineWidgetTag.H3,
    [DownloadWidgetAttributes.DOWNLOAD_BUTTON_TEXT]: 'Herunterladen',
    [DownloadWidgetAttributes.DOWNLOAD_ZIP_NAME]: 'rheinbahn.zip',
    ...spacingContent,
  },
});
