import * as Scrivito from 'scrivito';
import { HighlightTeaserWidget, HighlightTeaserWidgetClass } from './highlight-teaser-widget-class';

/**
 * Placeholder widget, get rendered by HighlightTeaserGroupWidget
 */
export const HighlightTeaserWidgetComponent: React.FC<{ widget: HighlightTeaserWidget }> = () => {
  return <Scrivito.WidgetTag />;
};

Scrivito.provideComponent(HighlightTeaserWidgetClass, HighlightTeaserWidgetComponent);
