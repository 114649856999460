import { SimpleChoice } from './../../utils/objects/enums.utils';
import * as Scrivito from 'scrivito';
import { ImageObjectAttributes, ImageObjectId } from './image-object-definitions';

export const ImageObjectClass = Scrivito.provideObjClass(ImageObjectId, {
  attributes: {
    [ImageObjectAttributes.BLOB]: 'binary',
    [ImageObjectAttributes.TAGS]: 'stringlist',
    [ImageObjectAttributes.ALTERNATIVE_TEXT]: 'string',
    [ImageObjectAttributes.TITLE]: 'string',
    [ImageObjectAttributes.VISIBLE_IN_SEARCH]: [
      'enum',
      {
        values: Object.values(SimpleChoice),
      },
    ],
  },
  extractTextAttributes: [ImageObjectAttributes.ALTERNATIVE_TEXT, ImageObjectAttributes.TITLE],
});

export type ImageObject = InstanceType<typeof ImageObjectClass>;
