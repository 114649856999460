import * as Scrivito from 'scrivito';
import { AccordionElementWidgetId } from '../accordion-element-widget/accordion-element-widget-definitions';
import { AccordionWidgetAttributes, AccordionWidgetId } from './accordion-widget-definitions';
import { spacingClassAttributes } from '../../utils/scrivito/spacing-definitions';

export const AccordionWidgetClass = Scrivito.provideWidgetClass(AccordionWidgetId, {
  attributes: {
    [AccordionWidgetAttributes.ELEMENTS]: ['widgetlist', { only: AccordionElementWidgetId }],
    ...spacingClassAttributes(),
  },
  extractTextAttributes: [AccordionWidgetAttributes.ELEMENTS],
});

export type AccordionWidget = InstanceType<typeof AccordionWidgetClass>;
