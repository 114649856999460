import * as Scrivito from 'scrivito';
import { TextTeaserWidget, TextTeaserWidgetClass } from './text-teaser-widget-class';

/**
 * Placeholder widget, get rendered by TextTeaserGroupWidget
 */
export const TextTeaserWidgetComponent: React.FC<{ widget: TextTeaserWidget }> = () => {
  return <Scrivito.WidgetTag />;
};

Scrivito.provideComponent(TextTeaserWidgetClass, TextTeaserWidgetComponent);
