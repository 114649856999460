import {
  FormElementBaseAttributes,
  formElementBaseAttributesDefinition,
  formElementBaseAttributesValidation,
  FormElementBaseGroupLabelAttributes,
  groupLabelFormElementBaseClassAttributesDefinition,
  groupLabelFormElementBaseClassAttributesValidation,
} from './../form-widget/form-widget-definitions';
import { HelperText, Message, WidgetTitle } from './../../utils/translations';
import { RadioButtonWidgetAttributes } from './../radio-button-widget/radio-button-widget-definitions';
import * as Scrivito from 'scrivito';
import { RadioButtonWidgetClass } from '../radio-button-widget';
import { RadioGroupWidgetAttributes, RadioGroupWidgetId } from './radio-group-widget-definitions';
import { ValidationInfo } from '../../utils/type.utils';
import {
  ColumnCountAttributes,
  columnCountAttributes,
  columnCountInitialContent,
} from '../../utils/scrivito/column-count-definitions';
import Thumbnail from '../../assets/thumbnails/radio-group.svg';

const minRequiredItems = 2;

Scrivito.provideEditingConfig(RadioGroupWidgetId, {
  title: WidgetTitle.RADIO_GROUP,
  titleForContent: (obj) =>
    `${WidgetTitle.RADIO_GROUP}: ${
      (obj.get(FormElementBaseAttributes.NAME) as string) || Message.FORM_INPUT_NAME_MISSING
    }`,
  thumbnail: Thumbnail,
  properties: [
    ...Object.values(FormElementBaseGroupLabelAttributes),
    ...Object.values(RadioGroupWidgetAttributes),
    ColumnCountAttributes.COLUMN_COUNT,
  ],
  propertiesGroups: [{ title: 'Validation', properties: [FormElementBaseAttributes.NAME] }],
  attributes: {
    ...(groupLabelFormElementBaseClassAttributesDefinition as object),
    [RadioButtonWidgetAttributes.LABEL]: {
      title: HelperText.LABEL,
      description: HelperText.OPTIONAL_LABEL,
    },
    [RadioGroupWidgetAttributes.RADIOS]: {
      title: HelperText.RADIO_GROUP_ITEMS,
      description: HelperText.RADIO_GROUP_ITEMS_DESCRIPTION,
    },
    [RadioGroupWidgetAttributes.DEFAULT_SELECTED_RADIO]: {
      title: HelperText.INPUT_DEFAULT_VALUE,
      description: HelperText.RADIO_GROUP_DEFAULT_SELECTED_RADIO_DESCRIPTION,
    },
    ...columnCountAttributes,
    ...(formElementBaseAttributesDefinition as object),
  },
  initialContent: {
    [RadioGroupWidgetAttributes.RADIOS]: [...Array(minRequiredItems)].map(
      (_, index) =>
        new RadioButtonWidgetClass({
          [RadioButtonWidgetAttributes.LABEL]: `${HelperText.RADIO_GROUP_LABEL_INITIAL_VALUE} ${index + 1}`,
          [RadioButtonWidgetAttributes.VALUE]: String(index),
        })
    ),
    ...columnCountInitialContent,
  },
  validations: [
    [
      RadioGroupWidgetAttributes.RADIOS,
      (radios: Array<Scrivito.Widget>): ValidationInfo => {
        if (radios?.length < minRequiredItems) {
          return {
            message: Message.RADIO_GROUP_MIN_ITEM_COUNT_REQUIRED,
            severity: 'error',
          };
        }
        return undefined;
      },
    ],
    [
      RadioGroupWidgetAttributes.DEFAULT_SELECTED_RADIO,
      (value: string, { widget }: { widget: Scrivito.Widget }): ValidationInfo => {
        const radioButtonValues = (widget.get(RadioGroupWidgetAttributes.RADIOS) as Scrivito.Widget[]).map((item) =>
          item.get(RadioButtonWidgetAttributes.VALUE)
        );
        if (!radioButtonValues.includes(value)) {
          return {
            message: Message.RADIO_GROUP_DEFAULT_VALUE_REQUIRED,
            severity: 'error',
          };
        }
        return undefined;
      },
    ],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(formElementBaseAttributesValidation as Array<any>),
    ...groupLabelFormElementBaseClassAttributesValidation,
  ],
});
