import * as Scrivito from 'scrivito';
import { AccordionWidgetAttributes } from './accordion-widget-definitions';
import { AccordionWidget, AccordionWidgetClass } from './accordion-widget-class';
import { AccordionComponent } from '../../components/controls/accordion/accordion';
import { AccordionElementWidgetAttributes } from '../accordion-element-widget/accordion-element-widget-definitions';
import { spacingClassName } from '../../utils/scrivito/spacing-definitions';
import { contentTagOrHtml } from '../../utils/scrivito/widget.utils';

export const AccordionWidgetComponent: React.FC<{ widget: AccordionWidget }> = ({ widget }) => {
  const elements: Scrivito.Widget[] = widget.get(AccordionWidgetAttributes.ELEMENTS);

  return (
    <Scrivito.WidgetTag>
      <div className={spacingClassName(widget)}>
        {elements?.length > 0 &&
          elements.map((element: Scrivito.Widget, index: number) => {
            const headlineText = element.get(AccordionElementWidgetAttributes.HEADLINE) as string;
            const headline = contentTagOrHtml(element, AccordionElementWidgetAttributes.HEADLINE, 'span');
            const text = contentTagOrHtml(element, AccordionElementWidgetAttributes.TEXT, 'span');
            const id = (element.get(AccordionElementWidgetAttributes.ID) as string) || undefined;
            return (
              <AccordionComponent
                id={id}
                key={index}
                index={index}
                summary={headline}
                summaryText={headlineText}
                details={text}
              />
            );
          })}
      </div>
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(AccordionWidgetClass, AccordionWidgetComponent);
