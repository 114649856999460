import { useEffect, useState } from 'react';
import { debounce } from '@mui/material';

/**
 * A hook that will listen to the window resize event and report the current with of
 * the viewport.
 */
export const useViewportWidth = (): number | undefined => {
  const [viewportWidth, setViewportWidth] = useState<number | undefined>();
  useEffect(() => {
    const resizeEvent = (): void => {
      setViewportWidth(window.innerWidth ?? 0);
    };
    resizeEvent();
    const debounceOnResize = debounce(resizeEvent);

    window.addEventListener('resize', debounceOnResize);

    return () => {
      window.removeEventListener('resize', debounceOnResize);
    };
  }, []);

  return viewportWidth;
};

/**
 * A hook that will listen to the window resize event and report the current height of
 * the viewport.
 */
export const useViewportHeight = (): number | undefined => {
  const [viewportHeight, setViewportHeight] = useState<number | undefined>();
  useEffect(() => {
    const resizeEvent = (): void => {
      setViewportHeight(window.innerHeight ?? 0);
    };
    resizeEvent();
    const debounceOnResize = debounce(resizeEvent);

    window.addEventListener('resize', debounceOnResize);

    return () => {
      window.removeEventListener('resize', debounceOnResize);
    };
  }, []);

  return viewportHeight;
};
