import * as Scrivito from 'scrivito';
import React from 'react';
import style from './search-body.module.scss';
import { BasePageAttributes } from '../../../pages/base-page';
import { getSynonymListForQuery, renderDownloadBox, renderSingleSearchResult } from '../search-utils.scrivito';
import { ButtonComponent } from '../../controls/button/button';
import { ReactComponent as Network } from '../../../assets/img/rheinbahn-network-cut.svg';
import { ReactComponent as Rocket } from '../../../assets/img/rheinbahn-rocket.svg';
import { useTranslation } from 'react-i18next';
import { SearchResult } from '../search-function';

export interface SearchBodyProps {
  result: SearchResult;
  fileResult: SearchResult;
  query: string;
  loadMoreButtonClickHandler: () => void;
}

const SearchBodyComponent: React.FC<SearchBodyProps> = ({
  result,
  fileResult,
  query,
  loadMoreButtonClickHandler,
}): JSX.Element => {
  const { t } = useTranslation();

  // sort array to get highlights at top
  const orderedResult = result.items.sort((a, b) => {
    return a.get(BasePageAttributes.SEARCH_HIGHLIGHT) && !b.get(BasePageAttributes.SEARCH_HIGHLIGHT) ? -1 : 1;
  });

  const currentSynonymList = getSynonymListForQuery(query);
  const searchTerms = currentSynonymList ?? query.split(/\s+/);

  const urlSearchParams = new URLSearchParams(document.location.search);
  const urlFilter = urlSearchParams.get('filter');
  const urlFileFilter = urlSearchParams.get('file');

  const resultList = orderedResult.map((page, index) => {
    return renderSingleSearchResult(page, searchTerms, index);
  });

  const downloadBoxPosition = Math.min(4, resultList.length);
  const renderBox = !urlFilter?.length && urlFileFilter !== 'true';
  const totalResults = renderBox ? result.total + fileResult.total : result.total;
  return (
    <div className={style.SearchBody}>
      {query !== '' && (
        <h2 id="SearchResultsHeadline" className={style.SearchResultsHeadline}>
          <span>{`${totalResults} ${totalResults === 1 ? t('search.searchResult') : t('search.searchResults')}:`}</span>
          <span className={style.Highlight}>{query}</span>
        </h2>
      )}
      {totalResults > 0 && (
        <div className={style.ResultList}>
          {resultList.slice(0, downloadBoxPosition)}
          {renderBox && renderDownloadBox(query, fileResult)}
          {resultList.slice(downloadBoxPosition)}
        </div>
      )}
      {result.total > result.items.length && (
        <div className={style.LoadMore}>
          <ButtonComponent
            onClick={loadMoreButtonClickHandler}
            href={null}
            color={'primary'}
            content={<span>{t('search.loadMore')}</span>}
            small={false}
          />
        </div>
      )}
      {(result.total === 0 || query === '') && (
        <div className={style.Illustrations}>
          <Rocket className={style.Rocket} />
          <Network className={style.Network} />
        </div>
      )}
    </div>
  );
};

export const SearchBody = Scrivito.connect(SearchBodyComponent);
