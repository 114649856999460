import * as Scrivito from 'scrivito';
import {
  ButtonWidgetAttributes,
  ButtonWidgetIcons,
  ButtonWidgetId,
  ButtonWidgetKinds,
  ButtonWidgetSizes,
} from './button-widget-definitions';
import { spacingAttributes, spacingContent, spacingGroup } from '../../utils/scrivito/spacing-definitions';
import { WidgetTitle } from '../../utils/translations';
import ButtonThumbnail from '../../assets/thumbnails/button.svg';

Scrivito.provideEditingConfig(ButtonWidgetId, {
  title: WidgetTitle.BUTTON,
  thumbnail: ButtonThumbnail,
  properties: [
    ButtonWidgetAttributes.LINK,
    ButtonWidgetAttributes.KIND,
    ButtonWidgetAttributes.SIZE,
    ButtonWidgetAttributes.ICON,
  ],
  propertiesGroups: [spacingGroup],
  attributes: {
    [ButtonWidgetAttributes.LINK]: {
      title: 'Link',
    },
    [ButtonWidgetAttributes.KIND]: {
      title: 'Art',
      values: [
        { value: ButtonWidgetKinds.PRIMARY, title: 'Primary' },
        { value: ButtonWidgetKinds.SECONDARY, title: 'Secondary' },
        { value: ButtonWidgetKinds.CTA, title: 'Call to Action' },
      ],
    },
    [ButtonWidgetAttributes.SIZE]: {
      title: 'Größe',
      values: [
        { value: ButtonWidgetSizes.LARGE, title: 'Groß' },
        { value: ButtonWidgetSizes.SMALL, title: 'Klein' },
      ],
    },
    [ButtonWidgetAttributes.ICON]: {
      title: 'Icon',
      values: [
        { value: ButtonWidgetIcons.NONE, title: 'ohne' },
        { value: ButtonWidgetIcons.FILE, title: 'Datei generisch' },
        { value: ButtonWidgetIcons.FILE_PDF, title: 'PDF' },
        { value: ButtonWidgetIcons.FILE_PPT, title: 'PPT' },
        { value: ButtonWidgetIcons.FILE_ARCHIVE, title: 'Archiv generisch' },
        { value: ButtonWidgetIcons.FILE_ZIP, title: 'ZIP' },
        { value: ButtonWidgetIcons.FILE_VIDEO, title: 'Video' },
        { value: ButtonWidgetIcons.FILE_IMAGE, title: 'Bild' },
        { value: ButtonWidgetIcons.SHOPPING_CART, title: 'Einkaufswagen' },
        { value: ButtonWidgetIcons.TICKET, title: 'Ticket' },
        { value: ButtonWidgetIcons.INFO, title: 'Info' },
        { value: ButtonWidgetIcons.MAP, title: 'Landkarte' },
        { value: ButtonWidgetIcons.TIP, title: 'Tipp' },
      ],
    },
    ...spacingAttributes,
  },
  validations: [],
  initialContent: {
    [ButtonWidgetAttributes.LINK]: new Scrivito.Link({
      url: '#',
      title: 'Button Text',
    }),
    [ButtonWidgetAttributes.KIND]: ButtonWidgetKinds.PRIMARY,
    [ButtonWidgetAttributes.SIZE]: ButtonWidgetSizes.LARGE,
    [ButtonWidgetAttributes.ICON]: ButtonWidgetIcons.NONE,
    ...spacingContent,
  },
  titleForContent: (obj) => {
    const link = obj.get(ButtonWidgetAttributes.LINK) as Scrivito.Link | null;
    return link?.title() ?? 'Button';
  },
});
