import { FormGroupWidgetClass } from './../form-group-widget/form-group-widget-class';
import * as Scrivito from 'scrivito';
import { FormWidgetAttributes, FormWidgetId } from './form-widget-definitions';
import { spacingAttributes, spacingContent, spacingGroup } from '../../utils/scrivito/spacing-definitions';
import { ValidationInfo } from '../../utils/type.utils';
import { checkValue, formElementIdAttributeValidation } from '../../utils/scrivito/validation';
import { HelperText, Message, WidgetTitle } from '../../utils/translations';
import Thumbnail from '../../assets/thumbnails/form.svg';

Scrivito.provideEditingConfig(FormWidgetId, {
  title: WidgetTitle.FORM,
  thumbnail: Thumbnail,
  propertiesGroups: [
    {
      title: 'Formular-Actions',
      properties: [
        FormWidgetAttributes.FORM_ACTION,
        FormWidgetAttributes.SUCCESS_PAGE,
        FormWidgetAttributes.ELEMENT_ID,
        FormWidgetAttributes.ERROR_MESSAGE,
        FormWidgetAttributes.SUBMIT_BUTTON_ENABLED,
        FormWidgetAttributes.SUBMIT_BUTTON_TEXT,
        FormWidgetAttributes.SUBMISSION_ERROR,
        FormWidgetAttributes.SPECIAL_SUBMISSION_ERRORS,
      ],
    },
    {
      title: 'Interaktionen',
      properties: [FormWidgetAttributes.INTERACTIVE_LOGIC],
    },
    {
      title: 'Formular-Bausteine',
      properties: [FormWidgetAttributes.ELEMENTS],
    },
    spacingGroup,
  ],
  attributes: {
    [FormWidgetAttributes.SUBMIT_BUTTON_TEXT]: { title: 'Text für Submit-Button' },
    [FormWidgetAttributes.SUBMIT_BUTTON_ENABLED]: { title: 'Submit-Button anzeigen' },
    [FormWidgetAttributes.FORM_ACTION]: {
      title: HelperText.FORM_ACTION_TITLE,
      description: HelperText.FORM_ACTION,
    },
    [FormWidgetAttributes.SUCCESS_PAGE]: {
      title: HelperText.SUCCESS_PAGE_TITLE,
      description: HelperText.SUCCESS_PAGE,
    },
    [FormWidgetAttributes.ERROR_MESSAGE]: {
      title: HelperText.ERROR_MESSAGE_TITLE,
      description: HelperText.ERROR_MESSAGE,
    },
    [FormWidgetAttributes.ELEMENT_ID]: {
      title: HelperText.ELEMENT_ID,
      description: HelperText.ELEMENT_ID_DESCRIPTION,
    },
    [FormWidgetAttributes.INTERACTIVE_LOGIC]: {
      title: 'Interaktive Logik',
    },
    [FormWidgetAttributes.ELEMENT_ID]: {
      title: HelperText.ELEMENT_ID,
      description: HelperText.ELEMENT_ID_DESCRIPTION,
    },
    ...spacingAttributes,
  },
  validations: [
    [
      FormWidgetAttributes.SUBMIT_BUTTON_TEXT,
      (value: string | null): ValidationInfo => checkValue(value, Message.SUBMIT_BUTTON_TEXT_MISSING),
    ],
    formElementIdAttributeValidation(FormWidgetAttributes.ELEMENT_ID),
  ],
  initialContent: {
    [FormWidgetAttributes.ELEMENTS]: [
      new FormGroupWidgetClass({
        label: 'Überschrift',
      }),
    ],
    [FormWidgetAttributes.SUBMIT_BUTTON_TEXT]: 'Absenden',
    [FormWidgetAttributes.SUBMIT_BUTTON_ENABLED]: true,
    [FormWidgetAttributes.ERROR_MESSAGE]: 'Es ist ein Fehler aufgetreten.',
    ...spacingContent,
  },
});
