import * as Scrivito from 'scrivito';
import { columnCountClassAttributes } from '../../utils/scrivito/column-count-definitions';
import { FormGroupWidgetId } from '../form-group-widget/form-group-widget-definitions';
import {
  getFormElementBaseClassAttributes,
  groupLabelFormElementBaseClassAttributes,
} from '../form-widget/form-widget-definitions';
import { RadioButtonWidgetId } from '../radio-button-widget';
import { FormColumnWidgetId } from './../form-column-widget/form-column-widget-definitions';
import { RadioGroupWidgetAttributes, RadioGroupWidgetId } from './radio-group-widget-definitions';
import { WizardPageWidgetId } from '../wizard-page-widget';

export const RadioGroupWidgetClass = Scrivito.provideWidgetClass(RadioGroupWidgetId, {
  attributes: {
    ...(groupLabelFormElementBaseClassAttributes as object),
    [RadioGroupWidgetAttributes.RADIOS]: ['widgetlist', { only: RadioButtonWidgetId }],
    [RadioGroupWidgetAttributes.DEFAULT_SELECTED_RADIO]: 'string',
    ...(columnCountClassAttributes as object),
    ...getFormElementBaseClassAttributes(),
  },
  onlyInside: [FormGroupWidgetId, FormColumnWidgetId, WizardPageWidgetId],
});

export type RadioGroupWidget = InstanceType<typeof RadioGroupWidgetClass>;
