import * as Scrivito from 'scrivito';
import { ButtonGroupWidgetAttributes, ButtonGroupWidgetId } from './button-group-widget-definitions';
import { spacingClassAttributes } from '../../utils/scrivito/spacing-definitions';
import { ButtonWidgetId } from '../button-widget';

export const ButtonGroupWidgetClass = Scrivito.provideWidgetClass(ButtonGroupWidgetId, {
  attributes: {
    [ButtonGroupWidgetAttributes.BUTTONS]: ['widgetlist', { only: [ButtonWidgetId] }],
    ...spacingClassAttributes(),
  },
});

export type ButtonGroupWidget = InstanceType<typeof ButtonGroupWidgetClass>;
