import * as Scrivito from 'scrivito';
import { IconListWidget, IconListWidgetClass } from './icon-list-widget-class';
import { IconListWidgetAttributes, IconListWidgetHeadlineHierarchy } from './icon-list-widget-definitions';
import { IconListContext, IconListContextData } from './icon-list-widget.context';
import { IconListItemWidgetIcons } from '../icon-list-item-widget/icon-list-item-widget-definitions';
import styles from './icon-list-widget.module.scss';
import { spacingClassName } from '../../utils/scrivito/spacing-definitions';
import classNames from 'classnames';

function headlineToTag(headline: IconListWidgetHeadlineHierarchy): string {
  const tags: { [key: string]: string } = {
    [IconListWidgetHeadlineHierarchy.HEADLINE1]: 'h1',
    [IconListWidgetHeadlineHierarchy.HEADLINE2]: 'h2',
    [IconListWidgetHeadlineHierarchy.HEADLINE3]: 'h3',
    [IconListWidgetHeadlineHierarchy.HEADLINE4]: 'h4',
    [IconListWidgetHeadlineHierarchy.HEADLINE5]: 'h5',
    [IconListWidgetHeadlineHierarchy.HEADLINE6]: 'h6',
  };

  return tags[headline] || 'h3';
}

export const IconListWidgetComponent: React.FC<{ widget: IconListWidget }> = ({ widget }) => {
  const hasHeadline = !!widget.get(IconListWidgetAttributes.HEADLINE);
  const headlineStyle = widget.get(IconListWidgetAttributes.HEADLINE_HIERARCHY) as IconListWidgetHeadlineHierarchy;
  const elementID = widget.get(IconListWidgetAttributes.ID) as string;
  const listIcon = widget.get(IconListWidgetAttributes.ICON) as string;

  const context: IconListContextData = {
    getIconType(icon: string): string {
      if (icon === IconListItemWidgetIcons.LIST) {
        return listIcon;
      }
      return icon;
    },
  };

  return (
    <Scrivito.WidgetTag>
      <div id={elementID} className={classNames(styles.IconList, spacingClassName(widget))}>
        {(Scrivito.isInPlaceEditingActive() || hasHeadline) && (
          <Scrivito.ContentTag
            tag={headlineToTag(headlineStyle)}
            content={widget}
            attribute={IconListWidgetAttributes.HEADLINE}
          />
        )}
        <IconListContext.Provider value={context}>
          <Scrivito.ContentTag content={widget} attribute={IconListWidgetAttributes.ITEMS} />
        </IconListContext.Provider>
      </div>
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(IconListWidgetClass, IconListWidgetComponent);
