import * as Scrivito from 'scrivito';
import { WizardPageWidget, WizardPageWidgetClass } from './wizard-page-widget-class';
import { WizardPageWidgetAttributes } from './wizard-page-widget-definitions';
import styles from './wizard-page-widget.module.scss';

export const WizardPageWidgetComponent: React.FC<{ widget: WizardPageWidget }> = ({ widget }) => {
  if (!Scrivito.isInPlaceEditingActive()) {
    // Will be rendered in wizard widget
    return null;
  }

  return (
    <Scrivito.WidgetTag>
      <Scrivito.ContentTag
        className={styles.WizardPageTitle}
        content={widget}
        attribute={WizardPageWidgetAttributes.TITLE}
      />
      <Scrivito.ContentTag content={widget} attribute={WizardPageWidgetAttributes.WIDGETS} />
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(WizardPageWidgetClass, WizardPageWidgetComponent);
