import * as Scrivito from 'scrivito';
import {
  HeadlineWidgetAttributes,
  HeadlineWidgetId,
  HeadlineWidgetTag,
  HeadlineWidgetVariant,
} from './headline-widget-definitions';
import { ValidationInfo } from '../../utils/type.utils';
import { HelperText, Message, WidgetTitle } from '../../utils/translations';
import { checkValue, formElementIdAttributeValidation } from '../../utils/scrivito/validation';
import Thumbnail from '../../assets/thumbnails/headline.svg';

Scrivito.provideEditingConfig(HeadlineWidgetId, {
  title: WidgetTitle.HEADLINE,
  thumbnail: Thumbnail,
  properties: [
    HeadlineWidgetAttributes.TEXT,
    HeadlineWidgetAttributes.VARIANT,
    HeadlineWidgetAttributes.COMPONENT,
    HeadlineWidgetAttributes.ID,
  ],
  attributes: {
    [HeadlineWidgetAttributes.ID]: {
      title: HelperText.ELEMENT_ID,
      description: HelperText.ELEMENT_ID_DESCRIPTION,
    },
    [HeadlineWidgetAttributes.TEXT]: {
      title: HelperText.HEADLINE_TEXT,
    },
    [HeadlineWidgetAttributes.VARIANT]: {
      title: HelperText.HEADLINE_SIZE,
      values: [
        { value: HeadlineWidgetVariant.XXL, title: 'Überschrift 1' },
        { value: HeadlineWidgetVariant.XL, title: 'Überschrift 2' },
        { value: HeadlineWidgetVariant.L, title: 'Überschrift 3' },
        { value: HeadlineWidgetVariant.M, title: 'Überschrift 4' },
        { value: HeadlineWidgetVariant.S, title: 'Überschrift 5' },
        { value: HeadlineWidgetVariant.XS, title: 'Überschrift 6' },
      ],
    },
    [HeadlineWidgetAttributes.COMPONENT]: {
      title: HelperText.HEADLINE_HIERARCHY,
      values: [
        { value: HeadlineWidgetTag.H1, title: '<h1>' },
        { value: HeadlineWidgetTag.H2, title: '<h2>' },
        { value: HeadlineWidgetTag.H3, title: '<h3>' },
        { value: HeadlineWidgetTag.H4, title: '<h4>' },
        { value: HeadlineWidgetTag.H5, title: '<h5>' },
        { value: HeadlineWidgetTag.H6, title: '<h6>' },
      ],
    },
  },
  validations: [
    [
      HeadlineWidgetAttributes.TEXT,
      (value: string | null): ValidationInfo => checkValue(value, Message.TEXT_INPUT_MISSING),
    ],
    formElementIdAttributeValidation(HeadlineWidgetAttributes.ID),
  ],
  initialContent: {
    [HeadlineWidgetAttributes.VARIANT]: HeadlineWidgetVariant.XL,
    [HeadlineWidgetAttributes.COMPONENT]: HeadlineWidgetTag.H2,
    [HeadlineWidgetAttributes.TEXT]: 'Überschrift',
  },
});
