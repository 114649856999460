import * as Scrivito from 'scrivito';
import { spacingAttributes, spacingContent, spacingGroup } from '../../utils/scrivito/spacing-definitions';
import { HelperText, Message, WidgetTitle } from '../../utils/translations';
import { ValidationInfo } from '../../utils/type.utils';
import { HeadlineWidgetTag } from '../headline-widget';
import { InfoTeaserWidgetClass } from '../info-teaser-widget';
import { InfoTeaserGroupWidgetAttributes, InfoTeaserGroupWidgetId } from './info-teaser-group-widget-definitions';
import Thumbnail from '../../assets/thumbnails/teaser-info.svg';

Scrivito.provideEditingConfig(InfoTeaserGroupWidgetId, {
  title: WidgetTitle.INFO_TEASER_GROUP,
  thumbnail: Thumbnail,
  properties: Object.values(InfoTeaserGroupWidgetAttributes),
  propertiesGroups: [spacingGroup],
  attributes: {
    [InfoTeaserGroupWidgetAttributes.ITEMS]: {
      title: HelperText.ITEMS,
    },
    [InfoTeaserGroupWidgetAttributes.HEADLINE_COMPONENT]: {
      title: HelperText.HEADLINE_HIERARCHY,
      values: [
        { value: HeadlineWidgetTag.H1, title: '<h1>' },
        { value: HeadlineWidgetTag.H2, title: '<h2>' },
        { value: HeadlineWidgetTag.H3, title: '<h3>' },
        { value: HeadlineWidgetTag.H4, title: '<h4>' },
        { value: HeadlineWidgetTag.H5, title: '<h5>' },
        { value: HeadlineWidgetTag.H6, title: '<h6>' },
      ],
    },
    ...spacingAttributes,
  },
  validations: [
    [
      InfoTeaserGroupWidgetAttributes.ITEMS,
      (items: Array<Scrivito.Widget>): ValidationInfo => {
        if (items?.length <= 0) {
          return {
            message: Message.ADD_AT_LEAST_ONE_ITEM,
            severity: 'error',
          };
        }

        return undefined;
      },
    ],
  ],
  initialContent: {
    [InfoTeaserGroupWidgetAttributes.ITEMS]: [new InfoTeaserWidgetClass()],
    [InfoTeaserGroupWidgetAttributes.HEADLINE_COMPONENT]: HeadlineWidgetTag.H3,
    ...spacingContent,
  },
});
