import * as Scrivito from 'scrivito';
import { ImageWidgetAttributes } from '../image-widget';
import { CountdownWidget, CountdownWidgetClass } from './countdown-widget-class';
import { CountdownWidgetAttributes } from './countdown-widget-definitions';
import { spacingClassName } from '../../utils/scrivito/spacing-definitions';
import { getAttributeOrFallback } from '../../utils/scrivito/image.utils';
import { CountdownComponent } from '../../components/building-blocks/countdown/countdown';
import { ResponsiveImage } from '../../components/controls/image/responsive-image/responsive-image.scrivito';
import { useTranslation } from 'react-i18next';

export const CountdownWidgetComponent: React.FC<{ widget: CountdownWidget }> = ({ widget }) => {
  const { t } = useTranslation();

  const buttonToggle = widget.get(CountdownWidgetAttributes.BUTTON_TOGGLE) as boolean;
  const link = widget.get(CountdownWidgetAttributes.BUTTON_LINK) as Scrivito.Link;
  const buttonText = link?.title() || t('component.countdown.buttonMore');
  const countdownTitle = widget.get(CountdownWidgetAttributes.COUNTDOWN_TITLE) as string;
  const countdownDate = widget.get(CountdownWidgetAttributes.COUNTDOWN_DATE) as Date;
  const countdownUnit = widget.get(CountdownWidgetAttributes.COUNTDOWN_UNIT) as string;
  const countdownTitleFinished = widget.get(CountdownWidgetAttributes.COUNTDOWN_TITLE_FINISHED) as string;
  const id = widget.get(CountdownWidgetAttributes.ID) as string;

  const image = getAttributeOrFallback(widget, ImageWidgetAttributes.IMAGE);
  const binary = image?.get('blob') as Scrivito.Binary;
  let imageNode: React.ReactNode = <Scrivito.ImageTag content={widget} attribute={ImageWidgetAttributes.IMAGE} />;
  if (image && binary) {
    const imgHeight = image.metadata().get('height') as number;
    const imgWidth = image.metadata().get('width') as number;
    const altText = widget.get(CountdownWidgetAttributes.IMAGE_ALT) || (image.get('alternativeText') as string);
    const titleText = widget.get(CountdownWidgetAttributes.IMAGE_TITLE) || (image.get('title') as string);
    imageNode = (
      <ResponsiveImage
        alt={altText}
        binary={binary}
        title={titleText}
        meta={imgWidth && imgHeight ? { width: imgWidth, height: imgHeight } : undefined}
      />
    );
  }

  return (
    <Scrivito.WidgetTag>
      <CountdownComponent
        className={spacingClassName(widget)}
        countdownDate={countdownDate}
        countdownTitle={countdownTitle}
        countdownTitleFinished={countdownTitleFinished}
        countdownUnit={countdownUnit}
        buttonText={buttonText}
        link={link}
        buttonToggle={buttonToggle}
        image={imageNode}
        id={id}
      />
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(CountdownWidgetClass, CountdownWidgetComponent);
