import { Modal } from '@mui/material';
import React, { createContext, useContext, useEffect, useState } from 'react';
import styles from './responsive-image.module.scss';
import classNames from 'classnames';

interface ImageScalableContextData {
  setScaledImage: (image: React.ReactNode) => void;
}

const ImageScalableContext = createContext<ImageScalableContextData>({
  setScaledImage: () => null,
});

export function useImageScalableContext(): ImageScalableContextData {
  return useContext(ImageScalableContext);
}

export const ImageScalableProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [divWrapper, setDivWrapper] = useState<HTMLDivElement | null>(null);
  const [scaledImage, setScaledImage] = useState<React.ReactNode>(null);
  const [aspectRatioQuotient, setAspectRatioQuotient] = useState<number>(1);

  useEffect(() => {
    const image = divWrapper?.getElementsByTagName('img')?.item(0) as HTMLImageElement;
    const handler = (): void => {
      const ratioImage = (image?.width || 1) / (image?.height || 1);
      const ratioWindow = window.innerWidth / window.innerHeight;
      setAspectRatioQuotient(ratioImage / ratioWindow);
    };

    image?.addEventListener('load', handler);
    window.addEventListener('resize', handler);

    return () => {
      image?.removeEventListener('load', handler);
      window.removeEventListener('resize', handler);
    };
  }, [divWrapper]);

  return (
    <ImageScalableContext.Provider value={{ setScaledImage }}>
      {children}
      <Modal open={!!scaledImage} onClose={(): void => setScaledImage(false)}>
        <div
          ref={setDivWrapper}
          onClick={(): void => setScaledImage(null)}
          className={classNames(styles.ScaledImageView, {
            [styles.ImageHorizontalAnchor]: aspectRatioQuotient >= 1,
            [styles.ImageVerticalAnchor]: aspectRatioQuotient < 1,
          })}
        >
          {scaledImage}
        </div>
      </Modal>
    </ImageScalableContext.Provider>
  );
};
