import { HelperText, WidgetTitle } from './../../utils/translations';
import * as Scrivito from 'scrivito';
import { TableRowWidgetAttributes, TableRowWidgetId } from './table-row-widget-definitions';

Scrivito.provideEditingConfig(TableRowWidgetId, {
  title: WidgetTitle.TABLE_ROW,
  properties: Object.values(TableRowWidgetAttributes),
  attributes: {
    [TableRowWidgetAttributes.CELLS]: {
      title: HelperText.TABLE_ROWS,
    },
  },
});
