import * as Scrivito from 'scrivito';
import {
  ButtonWidgetAttributes,
  ButtonWidgetIcons,
  ButtonWidgetId,
  ButtonWidgetKinds,
  ButtonWidgetSizes,
} from './button-widget-definitions';
import { spacingClassAttributes } from '../../utils/scrivito/spacing-definitions';

export const ButtonWidgetClass = Scrivito.provideWidgetClass(ButtonWidgetId, {
  attributes: {
    [ButtonWidgetAttributes.LINK]: 'link',
    [ButtonWidgetAttributes.KIND]: [
      'enum',
      {
        values: Object.values(ButtonWidgetKinds),
      },
    ],
    [ButtonWidgetAttributes.SIZE]: [
      'enum',
      {
        values: Object.values(ButtonWidgetSizes),
      },
    ],
    [ButtonWidgetAttributes.ICON]: [
      'enum',
      {
        values: Object.values(ButtonWidgetIcons),
      },
    ],
    ...spacingClassAttributes(),
  },
});

export type ButtonWidget = InstanceType<typeof ButtonWidgetClass>;
