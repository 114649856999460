import styles from './timeline.module.scss';

interface TimelineComponentProps {
  title: React.ReactNode;
  content: React.ReactNode;
}

export const TimelineComponent: React.FC<TimelineComponentProps> = ({ title, content }) => {
  return (
    <div className={styles.Timeline}>
      {title}

      <div className={styles.Entries}>{content}</div>
    </div>
  );
};
