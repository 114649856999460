import * as Scrivito from 'scrivito';
import { CircleTeaserWidget, CircleTeaserWidgetClass } from './circle-teaser-widget-class';

/**
 * Placeholder widget, get rendered by CircleTeaserGroupWidget
 */
export const CircleTeaserWidgetComponent: React.FC<{ widget: CircleTeaserWidget }> = () => {
  return <Scrivito.WidgetTag />;
};

Scrivito.provideComponent(CircleTeaserWidgetClass, CircleTeaserWidgetComponent);
