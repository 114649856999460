import * as Scrivito from 'scrivito';
import { ValidationInfo } from '../../utils/type.utils';
import { AccordionElementWidgetAttributes, AccordionElementWidgetId } from './accordion-element-widget-definitions';
import { checkValue } from '../../utils/scrivito/validation';
import { HelperText, Message, WidgetTitle } from '../../utils/translations';

Scrivito.provideEditingConfig(AccordionElementWidgetId, {
  title: WidgetTitle.ACCORDION_ELEMENT,
  titleForContent: (obj) => obj.get(AccordionElementWidgetAttributes.HEADLINE) as string,
  attributes: {
    [AccordionElementWidgetAttributes.HEADLINE]: {
      title: 'Überschrift',
    },
    [AccordionElementWidgetAttributes.TEXT]: {
      title: 'Text',
      options: {
        toolbar: [
          'bold',
          'italic',
          'link',
          'bulletList',
          'orderedList',
          'subscript',
          'superscript',
          'header3',
          'header4',
          'header5',
          'header6',
          'mark',
        ],
      },
    },
    [AccordionElementWidgetAttributes.ID]: {
      title: HelperText.ELEMENT_ID,
      description: HelperText.ELEMENT_ID_DESCRIPTION,
    },
  },
  properties: Object.values(AccordionElementWidgetAttributes),
  initialContent: {
    [AccordionElementWidgetAttributes.HEADLINE]: 'Überschrift für Akkordeon-Element',
    [AccordionElementWidgetAttributes.TEXT]: '<p>Platzhalter-Text für Akkordeon-Element</p>',
  },
  validations: [
    [
      AccordionElementWidgetAttributes.HEADLINE,
      (value: string | null): ValidationInfo => checkValue(value, Message.HEADLINE_MISSING),
    ],
    [
      AccordionElementWidgetAttributes.TEXT,
      (value: string | null): ValidationInfo => checkValue(value, Message.TEXT_MISSING),
    ],
  ],
});
