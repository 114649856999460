import * as Scrivito from 'scrivito';
import { WizardPageWidgetAttributes, WizardPageWidgetId } from './wizard-page-widget-definitions';
import { WizardWidgetId } from '../wizard-widget/wizard-widget-definitions';
import { FormInputElements } from '../form-widget/form-widget-types';
import { FormColumnWidgetId } from '../form-column-widget/form-column-widget-definitions';
import { TextWidgetId } from '../text-widget';

const allowedWizardPageChildren = [...FormInputElements, FormColumnWidgetId, TextWidgetId];

export const WizardPageWidgetClass = Scrivito.provideWidgetClass(WizardPageWidgetId, {
  onlyInside: WizardWidgetId,
  attributes: {
    [WizardPageWidgetAttributes.TITLE]: 'string',
    [WizardPageWidgetAttributes.WIDGETS]: ['widgetlist', { only: allowedWizardPageChildren }],
    [WizardPageWidgetAttributes.NEXT_BUTTON_TEXT]: 'string',
  },
});

export type WizardPageWidget = InstanceType<typeof WizardPageWidgetClass>;
