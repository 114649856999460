import { HelperText, WidgetTitle } from './../../utils/translations';
import { ValidationInfo } from './../../utils/type.utils';
import * as Scrivito from 'scrivito';
import { HighlightTeaserWidgetAttributes, HighlightTeaserWidgetId } from './highlight-teaser-widget-definitions';
import { Message } from '../../utils/translations';
import { titleForContentOfHtml } from '../../utils/scrivito/title-for-content-html';

Scrivito.provideEditingConfig(HighlightTeaserWidgetId, {
  title: WidgetTitle.HIGHLIGHT_TEASER,
  titleForContent: (obj) => titleForContentOfHtml(obj, HighlightTeaserWidgetAttributes.TEXT),
  properties: Object.values(HighlightTeaserWidgetAttributes),
  attributes: {
    [HighlightTeaserWidgetAttributes.LINK]: {
      title: HelperText.HIGHLIGHT_TEASER_LINK,
    },
    [HighlightTeaserWidgetAttributes.SUBJECT]: {
      title: HelperText.HIGHLIGHT_TEASER_SUBJECT,
    },
    [HighlightTeaserWidgetAttributes.TEXT]: {
      title: HelperText.HIGHLIGHT_TEASER_TEXT,
    },
    [HighlightTeaserWidgetAttributes.IMAGE]: {
      title: HelperText.HIGHLIGHT_TEASER_IMAGE,
    },
    [HighlightTeaserWidgetAttributes.IMAGE_ALT]: {
      title: HelperText.IMAGE_ALT,
    },
    [HighlightTeaserWidgetAttributes.IMAGE_TITLE]: {
      title: HelperText.IMAGE_TITLE,
    },
    [HighlightTeaserWidgetAttributes.INNER_SHADOW]: {
      title: HelperText.HIGHLIGHT_TEASER_INNER_SHADOW,
    },
  },
  validations: [
    [
      HighlightTeaserWidgetAttributes.LINK,
      (link: Scrivito.Obj): ValidationInfo => {
        if (!link) {
          return {
            message: Message.TEXT_TEASER_LINK_REQUIRED,
            severity: 'error',
          };
        }
        return undefined;
      },
    ],
    [
      HighlightTeaserWidgetAttributes.IMAGE,
      (image: Scrivito.Obj): ValidationInfo => {
        if (!image) {
          return {
            message: Message.TEXT_TEASER_IMAGE_REQUIRED,
            severity: 'error',
          };
        }
        return undefined;
      },
    ],
    [
      HighlightTeaserWidgetAttributes.SUBJECT,
      (subject: Scrivito.Obj): ValidationInfo => {
        if (!subject) {
          return {
            message: Message.TEXT_TEASER_SUBJECT_REQUIRED,
            severity: 'error',
          };
        }
        return undefined;
      },
    ],
  ],
  initialContent: {
    [HighlightTeaserWidgetAttributes.LINK]: new Scrivito.Link({
      url: '#',
      title: 'Einfach. Klicken.',
    }),
  },
});
