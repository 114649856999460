import * as Scrivito from 'scrivito';

import { spacingClassAttributes } from '../../utils/scrivito/spacing-definitions';
import { KpiTileGroupWidgetAttributes, KpiTileGroupWidgetId } from './kpi-tile-group-widget-definitions';
import { KpiTileWidgetId } from '../kpi-tile-widget/kpi-tile-widget-definitions';

export const KpiTileGroupWidgetClass = Scrivito.provideWidgetClass(KpiTileGroupWidgetId, {
  attributes: {
    [KpiTileGroupWidgetAttributes.ID]: 'string',
    [KpiTileGroupWidgetAttributes.KPI_TILE]: ['widgetlist', { only: KpiTileWidgetId }],
    ...spacingClassAttributes(),
  },
});

export type KpiTileGroupWidget = InstanceType<typeof KpiTileGroupWidgetClass>;
