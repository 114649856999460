export enum Message {
  TEXT_INPUT_MISSING = 'Bitte füllen Sie das Textfeld aus',
  ANCHOR_MISSING = 'Bitte geben Sie einen Sprunglink an',
  HEADLINE_MISSING = 'Bitte geben Sie eine Überschrift an',
  TEXT_MISSING = 'Bitte geben Sie einen Text an',
  VALUE_PRICE_MISSING = 'Bitte geben Sie einen Preis an.',
  VALUE_NAME_MISSING = 'Bitte geben Sie einen Namen an.',
  NAME_MISSING = 'Bitte geben Sie einen Namen an',
  INVALID_INPUT = 'Der eingegebene Wert ist ungültig.',
  INVALID_MESSAGE = 'Bitte geben Sie einen gültigen Wert ein.',
  INVALID_MESSAGE_MISSING = 'Bitte geben Sie einen Text an, der im Fehlerfall angezeigt wird',
  FORM_ACTION_MISSING = 'Bitte füllen Sie das Aktions-Feld aus',
  SUBMIT_BUTTON_TEXT_MISSING = 'Bitte geben Sie einen Text für den Absenden-Button ein',
  ERROR_TEXT_MISSING = 'Bitte geben Sie einen Text für den Fehlerfall ein',
  SUCCESS_PAGE_MISSING = 'Bitte geben Sie eine Seite an, auf die nach Absenden des Formulars weitergeleitet wird',
  LABEL_FIELD_MISSING = 'Bitte füllen Sie das Label-Feld aus',
  VALUE_FIELD_MISSING = 'Bitte füllen Sie das Value-Feld aus',
  LINK_MISSING = 'Bitte füllen Sie das Link-Feld aus',
  LINK_TITLE_MISSING = 'Bitte füllen Sie das Titel Feld des Links aus.',
  EMAIL_TEMPLATE_NAME_MISSING = 'Der Name der E-Mail-Vorlage ist erforderlich (Standard: FormEmail).',
  EMAIL_SUBJECT_MISSING = 'Der E-Mail-Betreff ist erforderlich.',
  FROM_ADDRESS_ACCOUNT_ERROR = 'Die Domain muss nicht angegeben werden (nur der Teil der E-Mail-Adresse vor der Domain).',
  EMAIL_ADDRESS_INVALID = 'Eine oder mehrere E-Mail-Adressen in dieser Liste sind ungültig.',
  EMAIL_TARGET_MISSING = 'Wenn keine Ziel-E-Mail-Adresse angegeben wird, muss der Versand über die interaktive Logik erfolgen.',
  PAGE_TITEL_MISSING = 'Bitte geben Sie einen Seitentitel an',
  SOURCE_URI_MISSING = 'Bitte geben Sie eine Quell-URI an',
  TARGET_URI_MISSING = 'Bitte geben Sie eine Ziel-URI an',
  TARGET_FORM_GROUP_MISSING = 'Bitte wählen Sie eine Target Form Group aus',
  ALT_TEXT_MISSING = 'Bild hat keinen alternativen Text',
  SOCIAL_MEDIA_LINK_MISSING = 'Es muss mindestens ein Social-Media-Link ausgewählt werden',
  RADIO_GROUP_MIN_ITEM_COUNT_REQUIRED = 'Die Radio Gruppe muss mindestens zwei Auswahloptionen besitzen.',
  RADIO_GROUP_DEFAULT_VALUE_REQUIRED = 'Die Radio Gruppe muss einen Default-Wert besitzen.',
  WAGON_SELECTION_DEFAULT_VALUE_REQUIRED = 'Die Partybahn-Auswahl muss einen Default-Wert besitzen.',
  TOGGLE_GROUP_DEFAULT_VALUE_REQUIRED = 'Die Umschaltbutton-Gruppe muss einen Default-Wert besitzen.',
  TOGGLE_BUTTON_GROUP_MIN_ITEM_COUNT_REQUIRED = 'Die Gruppe muss mindestens zwei Auswahloptionen besitzen.',
  BUTTON_VALUE_REQUIRED = 'Bitte geben Sie einen Wert ein.',
  CHOOSE_OPTION = 'Bitte wählen Sie eine Option aus.',
  VALID_DATE = 'Bitte geben Sie ein gültiges Datum ein.',
  VALID_YEAR = 'Bitte geben Sie ein gültiges Jahr ein.',
  LIST_HAS_DUPLICATES = 'Es gibt Duplikate in den Labeln der Elemente.',
  PLEASE_CHOOSE = 'Bitte wählen ...',
  TEXT_TEASER_LINK_REQUIRED = 'Bitte einen Link auswählen.',
  TEXT_TEASER_IMAGE_REQUIRED = 'Bitte eine Bild auswählen.',
  TEXT_TEASER_SUBJECT_REQUIRED = 'Bitte eine Überschrift angeben.',
  HIGHLIGHT_TEASER_GROUP_MIN_ITEMS = 'Bitte mindestens zwei Teaser angeben.',
  KPI_TILE_GROUP_MIN_ITEMS = 'Bitte mindestens zwei Teaser angeben.',
  TEXT_TEASER_GROUP_MIN_ITEMS = 'Bitte mindestens einen Teaser angeben.',
  TEXT_TEASER_GROUP_MAX_ITEMS = 'Maximal 6 Teaser erlaubt.',
  URL_MISSING = 'Bitte geben Sie eine URL an',
  VIDEO_URL_MISSING = 'Bitte geben Sie eine URL an oder wählen Sie eine Video-Datei aus.',
  URL_INVALID = 'Die URL ist ungültig',
  URL_AND_VIDEO = 'Bitte geben Sie entweder eine URL an oder wählen ein Video aus.',
  COLUMN_LENGTH = 'Es müssen zwei Elemente angegeben werden.',
  CIRCLE_TEASER_LINK_REQUIRED = 'Bitte einen Link auswählen.',
  CIRCLE_TEASER_IMAGE_REQUIRED = 'Bitte eine Bild auswählen.',
  CIRCLE_TEASER_SUBJECT_REQUIRED = 'Bitte eine Überschrift angeben.',
  CIRCLE_TEASER_GROUP_MIN_ITEMS = 'Bitte mindestens zwei Teaser angeben.',
  CIRCLE_TEASER_GROUP_MAX_ITEMS = 'Maximal 4 Teaser erlaubt.',
  DOWNLOAD_ITEM_MIN = 'Bitte wählen Sie mindestens eine Datei aus',
  BUTTON_NAME_MISSING = 'Bitte geben Sie einen Button-Text an',
  FOOTER_WIDGETLIST_ONLY_ONE_ITEM = 'Nur ein Eintrag zulässig.',
  FOOTER_WIDGETLIST_MIN_ONE_ITEM = 'Mindestens ein Eintrag ist erforderlich.',
  PERMALINK_MISSING = 'Das Feld "Permalink" ist ebenfalls erforderlich.',
  ADD_AT_LEAST_ONE_ITEM = 'Bitte mindestens ein Eintrag hinzufügen.',
  ID_REQUIRED = 'Eine eindeutige ID ist erforderlich',
  SLIDER_SLIDES_DESCRIPTION = 'Minimal 3, maximal 7 Slides möglich',
  SLIDER_TOO_SMALL = 'Bitte fügen Sie mindestens 3 Slides hinzu',
  SLIDER_TOO_LARGE = 'Bitte reduzieren Sie die Größe auf maximal 7 Slides',
  SWITCH_PAGE_REQUIRED = 'Bitte geben Sie eine Seite an, auf die standardmäßig weitergeleitet wird.',
  HERO_IMAGE_REQUIRED = 'Bitte wählen Sie ein Bild aus.',
  COUNTDOWN_IMAGE_REQUIRED = 'Bitte wählen Sie ein Bild für das Countdown-Widget aus.',
  NO_VALID_REGEXP = 'Bitte geben Sie einen validen regulären Ausdruck ein.',
  CONFIRMATION_EMAIL_ADDRESS_REF_ERROR = 'Der Name des Referenz-Texteingabefeldes ist für eine Bestätigungsmail erforderlich.',
  CONFIRMATION_EMAIL_TEMPLATE_REQUIRED = 'Das Template für die Bestätigungsmail ist erforderlich (Standard: FormConfirmationEmail).',
  NO_MESSAGE_SPECIFIED = '<Keine Fehlermeldung spezifiziert.>',
  WHEN = 'wenn',
  NO_ITEM_WITH_VALUE = 'Der vorselektierte Wert ist in den Optionen nicht vorhanden.',
  REQUIRED_CHECKBOXES = 'Bitte wählen Sie die erforderlichen Checkboxen aus.',
  WIZARD_TITLE_MISSING = 'Bitte geben Sie einen Titel ein.',
  INVALID_MESSAGE_EMAIL = 'Bitte geben Sie eine gültige Email-Adresse an.',
  VALUE_PRICE_ERROR = 'Bitte geben Sie einen gültigen Preis ein.',
  VALUE_DURATION_ERROR = 'Bitte geben Sie eine gültige Dauer an.',
  FORM_INPUT_NAME_MISSING = 'NAME FEHLT',
  TITLE_MISSING = 'Bitte geben Sie einen Titel an',
  DATE_MISSING = 'Bitte geben Sie ein Datum an',
  DATE_NOT_WITHIN_969_DAYS = 'Bitte geben Sie ein Datum ein, das maxmimal 969 Tage in der Zukunft liegt.',
  DATE_IN_PAST = 'Bitte geben Sie ein Datum ein, das nicht in der Vergangenheit liegt.',
  SHORT_TEXT_MISSING = 'Bitte geben Sie ein Kurztext an',
  VIDEO_REEL_MIN_COUNT = 'Bitte wählen Sie mindestens zwei Videos aus',
  COLOR_MISSING = 'Bitte geben Sie eine Farbe an',
  BUBBLE_MISSING = 'Bitte geben Sie einen Sprechblasentyp an.',
  APP_ICON_MISSING = 'Bitte geben Sie ein Icon an',
  APP_TITLE_MISSING = 'Bitte geben Sie einen Titel an',
  APP_TEXT_MISSING = 'Bitte geben Sie einen Text an',
  APP_URL_MISSING = 'Bitte geben Sie eine Link zum Store an',
}

export enum HelperText {
  ERROR_MESSAGE_TITLE = 'Fehlertext',
  ERROR_MESSAGE = 'Geben Sie einen Text ein, der angezeigt wird, wenn ein Fehler auftritt.',
  FORM_TITLE = 'Titel, der im Content Browser angezeigt wird. Wird kein Titel angegeben, wird dieser generiert.',
  FORM_ACTION_TITLE = 'Aktion',
  FORM_ACTION = 'Die Aktion, die ausgeführt wird, wenn das Formular abgeschickt wird',
  FORM_ACTION_TYPE = 'Abhängig von der Auswahl, wird das Formular anders verarbeitet.',
  SUCCESS_PAGE_TITLE = 'Seite für erfolgreiche Übermittlung',
  BUBBLE_TITLE = 'Sprechblase auswählen',
  BUBBLE_DESCRIPTION = 'Erscheint unter dem Bild-Widget und kann in verschiedenen Farben angelegt werden.',
  SUCCESS_PAGE = 'Der Benutzer wird nach dem erfolgreichen Absenden auf diese Seite weitergeleitet.',
  SAVE_FORM_SUBMISSION_TO_DATABASE = 'Wenn diese Option aktiviert ist, werden die übermittelten Formulardaten in der Datenbank gespeichert; dies ist für einige Formularaktionstypen erforderlich. Die in der Datenbank gespeicherten Daten werden nach 90 Tagen automatisch gelöscht.',
  TARGET_EMAIL_ADDRESSES = 'Die Formulareingaben werden zur weiteren Bearbeitung an die angegebene(n) E-Mail-Adresse(n) gesendet.',
  EMAIL_TEMPLATE_NAME = 'Die E-Mail-Vorlage, die verwendet werden soll.',
  EMAIL_SUBJECT = 'Der Text, der in der Betreffzeile der E-Mail angezeigt werden soll. Werte aus Formulareingaben können in handlebars-Notation angegeben werden (z. B. {customer_email_address}).',
  EXTRA_EMAIL_TEXT = 'Dieser Text wird in den zusätzlichen Textbereich einer E-Mail-Vorlage eingefügt (sofern die Vorlage dies unterstützt).',
  EMAIL_LINK_TARGET = 'Dieser Link wird in einigen E-Mail-Vorlagen verwendet',
  FROM_ADDRESS_NAME = 'Der Name des sendenden E-Mail-Kontos, der den Empfängern angezeigt wird (standardmäßig "Rheinbahn – Kundenservice").',
  FROM_ADDRESS_ACCOUNT = 'Der erste Teil der E-Mail-Adresse (ohne Domain), die für den Versand von E-Mails verwendet wird (Standard ist "kundenbetreuung" z. B. "kundenbetreuung@rheinbahn.de").',
  RELATIVE_PATH = 'URI muss relativ angegeben werden',
  ABSOLUTE_OR_RELATIVE_PATH = 'URI kann absolut oder relativ angegeben werden.',
  PERMALINK_SOURCE_URI = 'Im Feld "Permalink" kann die Quell-URI angegeben werden.',
  IMAGE_POSITIONING = 'Hinweis: Die Positionierung greift nicht bei kleinen Bildschirmen und bei Bildern im Widget »Spalten«',
  IMAGE_ALT = 'Text, der angezeigt wird, wenn das Bild nicht geladen oder wahrgenommen werden kann',
  ASSET_ALT = 'Text, der angezeigt wird, wenn das Bild/Video nicht geladen oder wahrgenommen werden kann',
  IMAGE_ALT_FALLBACK = 'Text, der angezeigt wird, wenn das Bild nicht geladen oder wahrgenommen werden kann. Wird kein Text angegeben, werden die Informationen aus dem Bildobjekt übernommen, sofern vorhanden.',
  IMAGE_TITLE = 'Information, die bei Hover angezeigt wird',
  IMAGE_TITLE_FALLBACK = 'Information, die bei Hover angezeigt wird. Wird kein Text angegeben, werden die Informationen aus dem Bildobjekt übernommen, sofern vorhanden.',
  BREADCRUMB_MAX_ITEMS = 'Maximale Anzahl an Elementen',
  BREADCRUMB_MAX_ITEMS_DESCRIPTION = 'Spezifiziert die maximal sichtbare Anzahl an Elementen der Breadcrumb Navigation.',
  BREADCRUMB_ITEMS_BEFORE_COLLAPSE = 'Elemente vor Verkürzung',
  BREADCRUMB_ITEMS_BEFORE_COLLAPSE_DESCRIPTION = 'Spezifiziert die Anzahl der sichtbaren Elemente vor der Verkürzung, wenn die maximale Anzahl an Elementen überschritten wird.',
  BREADCRUMB_ITEMS_AFTER_COLLAPSE = 'Elemente nach Verkürzung',
  BREADCRUMB_ITEMS_AFTER_COLLAPSE_DESCRIPTION = 'Spezifiziert die Anzahl der sichtbaren Elemente nach der Verkürzung, wenn die maximale Anzahl an Elementen überschritten wird.',
  RADIO_GROUP_LABEL = 'Label',
  RADIO_GROUP_FORM_NAME = 'Form Name',
  RADIO_GROUP_ITEMS = 'Auswahloptionen',
  RADIO_GROUP_ITEMS_DESCRIPTION = 'Auswahloptionen der Radio Gruppe.',
  INPUT_DEFAULT_VALUE = 'Vorselektierte Auswahloption',
  RADIO_GROUP_DEFAULT_SELECTED_RADIO_DESCRIPTION = 'Wert der vorselektierten Auswahloption der Gruppe.',
  RADIO_GROUP_LABEL_INITIAL_VALUE = 'Option',
  TOGGLE_BUTTON_LABEL = 'Label',
  TOGGLE_BUTTON_VALUE = 'Wert',
  TOGGLE_BUTTON_DISABLED = 'Deaktiviert',
  TOGGLE_BUTTON_LABEL_INITIAL_VALUE = 'Option',
  TOGGLE_BUTTON_GROUP_LABEL = 'Accessibility Label',
  TOGGLE_BUTTON_GROUP_DEFAULT_VALUE = 'Vorausgewählter Wert',
  TOGGLE_BUTTON_GROUP_DISABLED = 'Gruppe deaktiviert',
  TEXT_TEASER_GROUP_TEASER = 'Teaser',
  TEXT_TEASER_LINK = 'Teaser-Link',
  TEXT_TEASER_IMAGE = 'Teaser-Bild',
  TEXT_TEASER_TEXT = 'Teaser-Text',
  TEXT_TEASER_HOVER_TEXT = 'Hover-Text (optional)',
  TEASER_HOVER_TEXT_DEFAULT = 'Einfach. Klicken.',
  LINK_IN_EDIT_MODE = 'Hinweis: Im Edit-Modus ist der Link auf dem Teaser deaktiviert, um In-Place-Editing des Hover-Texts zu ermöglichen.',
  CIRCLE_TEASER_GROUP_TEASER = 'Teaser',
  CIRCLE_TEASER_LINK = 'Teaser-Link',
  CIRCLE_TEASER_IMAGE = 'Teaser-Bild',
  CIRCLE_TEASER_TEXT = 'Überschrift',
  CIRCLE_TEASER_HOVER_TEXT = 'Hover-Text (optional)',
  TABLE_EDITOR_TITLE = 'Tabelle',
  TABLE_EDITOR_ALIGN_COLUMN_CENTER = 'Spaltenausrichtung zentriert',
  TABLE_EDITOR_ALIGN_COLUMN_LEFT = 'Spaltenausrichtung links',
  TABLE_EDITOR_ALIGN_COLUMN_RIGHT = 'Spaltenausrichtung rechts',
  TABLE_EDITOR_DELETE_ROW = 'Zeile löschen',
  TABLE_EDITOR_DELETE_COLUMN = 'Spalte löschen',
  TABLE_EDITOR_ADD_COLUMN = 'Spalte hinzufügen',
  TABLE_EDITOR_ADD_ROW = 'Zeile hinzufügen',
  TABLE_EDITOR_AREA_TITLE = 'Tabellenstruktur und -werte',
  TABLE_EDITOR_AREA_DESCRIPTION = 'Zeilen und Spalten können hinzugefügt oder gelöscht werden. Zudem kann der Zelleninhalt geändert werden.',
  TABLE_ROWS = 'Zeilen',
  TABLE_CELL_CONTENT = 'Inhalt',
  TABLE_HEADER_TITLE = '',
  TABLE_ROWS_TITLE = 'Zeilen/Inhalt',
  VIDEO_URL_DESCRIPTION = 'Sie können eine Youtube- oder Vimeo-URL nutzen',
  TABLE_SECTION_WIDTH = 'Breite',
  TABLE_FULL_WIDTH = 'Volle Breite',
  TABLE_FULL_WIDTH_DESCRIPTION = 'Die Tabelle erstreckt sich über die volle Seitenbreite.',
  TABLE_SHOW_HEADER = 'Kopfzeile ein/ausblenden',
  TABLE_MANUAL_HYPHEN = 'Automatische Silbentrennung deaktivieren',
  META_DESCRIPTION = 'Wird verwendet, um in den Suchmaschinen einen kurzen Beschreibungstext anzuzeigen',
  SOCIAL_MEDIA = 'Social Media',
  SOCIAL_MEDIA_TITLE = 'Titel für Facebook/Twitter',
  SOCIAL_MEDIA_DESCRIPTION = 'Teaser-Text für Facebook/Twitter',
  SOCIAL_MEDIA_PREVIEW = 'Vorschaubild für Facebook/Twitter',
  ERROR_PAGE_WIDGETS = 'Widgets, die auf der 404-Seite angezeigt werden',
  LABEL = 'Label',
  LABEL_VISIBLE = 'Label sichtbar',
  LINK = 'Link',
  LINKS = 'Links',
  FOOTER_NAVIGATION = 'Footer-Navigation',
  APPOINTMENT = 'Call-to-Action',
  APPOINTMENT_DEFAULT_LABEL = 'Einfach. Fragen.',
  APPOINTMENT_DEFAULT_LINK_TITLE = 'Termin buchen',
  INFO_LINE = 'Infozeile',
  ACTIVE = 'Aktivieren',
  TITLE = 'Titel',
  CLOSING_LINE = 'Abschluss-Zeile',
  CLOSING_LINE_LIST_NORMAL = ' Linkkgruppe 1 (grau)',
  CLOSING_LINE_LIST_NORMAL_DESCRIPTION = 'Links in der Liste werden schwarz dargestellt und sind links positioniert',
  CLOSING_LINE_LIST_IMPORTANT = ' Linkkgruppe 2 (rot)',
  CLOSING_LINE_LIST_IMPORTANT_DESCRIPTION = 'Links in der Liste werden rot dargestellt und sind rechts positioniert.',
  FORM_ID = 'Form Name',
  FORM_ID_DESCRIPTION = 'Intern verwendeter Name zur indentifikation des Feldes innerhalb der Form',
  UPLOAD_BUTTON_MULTI_FILE = 'Mehrere Dateien',
  UPLOAD_BUTTON_MULTI_FILE_DESCRIPTION = 'Es können gleichzeitig mehrere Dateien ausgewählt werden.',
  UPLOAD_BUTTON_ACCEPT = 'Akzeptierte Dateiformate',
  UPLOAD_BUTTON_ACCEPT_DESCRIPTION = 'Reduziert die Dateiformate auf die ausgewählten Werte. Bei keiner Auswahl sind alle Dateiformate erlaubt.',
  TABLE_EDITOR_SHOW_HEADER = 'Kopfzeile anzeigen',
  TABLE_EDITOR_HIDE_HEADER = 'Kopfzeile verbergen',
  TABLE_EDITOR_COLUMN_WIDTH = 'Spaltenbreite definieren',
  SELECT_PLACEHOLDER = 'Bitte wählen ...',
  LINK_DROPDOWN_SELECT_SUBJECT = 'Thema wählen',
  HEADLINE = 'Überschrift',
  FAQ_PAGE_SUBJECTS_PARENT_PAGE = 'Übergeordnete Seite der FAQ-Themen',
  FAQ_PAGE_SUBJECTS_PARENT_PAGE_DESCRIPTION = 'Themenwechsel-Liste neben der Überschrift wird automatisch mit den Unterseiten der ausgwählten Seite befüllt. Wird die Seite nicht angegeben, ist der Themenwechsler nicht zu sehen.',
  ITEMS = 'Einträge',
  LINK_TARGET = 'Ziellink',
  IMAGE = 'Bild',
  TEXT = 'Text',
  INFO_TEASER_LINK_TEXT = 'Hervorgehobener Text (wie Link)',
  HEADLINE_HIERARCHY = 'Hierarchie der Überschrift (HTML)',
  HEADLINE_SIZE = 'Größe der Überschrift',
  HEADLINE_TEXT = 'Text der Überschrift',
  ELEMENT_ID = 'Sprungmarken-ID',
  ELEMENT_ID_DESCRIPTION = 'Vergeben Sie eine eindeutige ID, die als Sprungmarke dienen soll. Sprungmarken können als Linkziel verwendet werden. Beispiel: "https://www.rheinbahn.de/page#id" oder innerhalb der selben Seite auch "#MeineSprungmarke".',
  FORM_ELEMENTS = 'Formular-Elemente',
  BACKGROUND_COLOR = 'Hintergrundfarbe',
  COLUMN_COUNT = 'Spaltenanzahl',
  CHECKBOX_ITEMS = 'Checkboxen',
  CHECKBOX_GROUP_LABEL_INITIAL_VALUE = 'Option',
  IMAGE_WIDTH = 'Bildbreite (in px)',
  IMAGE_WIDTH_DESCRIPTION = 'Durch Setzen der Bildbreite auf einen Wert (in px), wird das Bild auf diese Breite festgesetzt. Ohne Eingabe spannt sich das Bild auf die volle Breite auf.',
  IMAGE_SIZE = 'Bildgröße (automatisch)',
  FORM_NAME = 'Form Name',
  DISABLE_ALL = 'Alle deaktivieren',
  WAGONS = 'Waggons',
  WAGON_GROUP_LABEL_INITIAL_VALUE = 'Bahn',
  NAME = 'Name',
  FORM_VALUE = 'Form Wert',
  YEAR = 'Jahr',
  CAPACITY = 'Anzahl der Personen',
  PRICE = 'Preis (in Euro)',
  DURATION = 'Dauer (in Stunden)',
  CONTENT = 'Inhalt',
  IMAGES = 'Bilder',
  DISABLED = 'Deaktiviert',
  SEARCH_HIGHLIGHT_DESCRIPTION = 'Wenn diese Seite in den Suchergebnissen auftaucht, wird sie unter den ersten Suchergebnissen angezeigt.',
  HERO_SUB_TITLE_DESCRIPTION = 'Der Text bricht automatisch um. Da dies automatisch nicht immer korrekt ist, kann man dies manuell steuern, indem in der HTML-Ansicht an den erforderlichen Stellen `&shy;` oder `<wbr>` im Text eingetragen wird. `&shy;` erzwingt, wenn nötig, einen Textumbruch mit Trennzeichen nur noch an den ausgewiesenen Stellen. `<wbr>` erzwingt, wenn nötig, einen Textumbruch ohne Trennzeichen. Unabhänig davon wird mithilfe von `<br>` ein Zeilenumbruch immer erzwungen und mit `&nbsp;` ein nicht umbrechendes Leerzeichen eingefügt, alternativ kann man mit `&#8209;` einen nicht umbrechenden Bindestrich einfügen.',
  SUB_TITLE = 'Untertitel',
  HEROE_HIDE_IMAGE_ON_MOBILE = 'Bild mobil ausblenden',
  HERO_BUBBLE_COLOR = 'Farbe der Sprechblase',
  HERO_SHOW_JOURNEY_PLANNER = 'Schnellauskunft anzeigen',
  HERO_SMALL_FONT = 'Kleine Schrift',
  HERO_SMALL_FONT_DESCRIPTION = 'Wählen Sie diese Option, wenn viel Text angegeben wird. Bei kleineren Bildschirmen (bis 1339px) wird die Schriftgröße verringert.',
  GREY = 'Grau',
  PURPLE = 'Violett',
  BLUE = 'Blau',
  RED = 'Rot',
  IMAGE_VISIBLE_IN_SEARCH = 'Lege fest, ob das Bild über die Suche auf der Webseite auffindbar ist.',
  FILE_VISIBLE_IN_SEARCH = 'Lege fest, ob die Datei über die Suche auf der Webseite auffindbar ist.',
  VIDEO_VISIBLE_IN_SEARCH = 'Lege fest, ob das Video über die Suche auf der Webseite auffindbar ist.',
  CUSTOM_INVALID_MESSAGE = 'Individuelle Fehlermeldung speziell für dieses Formular-Element.',
  REGEXP_DESCRIPTION = 'Der eingegebene Wert muss diesem regulären Ausdruck entsprechen. Bitte lesen Sie die Anweisungen zum Erstellen eines regulären Ausdrucks unter: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp. Beispiel: /^hello$/gmi',
  FORM_CONFIRMATION_EMAIL_ADDRESS_REF = 'Geben Sie den Namen des Texteingabefeldes ein, das die E-Mail-Adresse des Absenders enthält. Wirkt sich nur aus, wenn "Bestätigungsmail senden" aktiviert ist.',
  FORM_CONFIRMATION_EMAIL_TEMPLATE = 'Die Vorlage für die Bestätigungsmail, die verwendet werden soll. Wirkt sich nur aus, wenn "Bestätigungsmail senden" aktiviert ist.',
  FORM_CONFIRMATION_EMAIL_CUSTOMER = 'Wenn diese Option aktiviert ist, werden die übermittelten Formulardaten an den Kunden gesendet.',
  OPTIONAL_LABEL = 'Das Label wird nur angezeigt, wenn der Inhalt befüllt wurde.',
  LABEL_GROUP_DESCRIPTION = 'Auch wenn das Label nicht angezeigt wird, wird es für Screenreader benötigt.',
  TRIGGERING_ERROR_MESSAGE_DESCRIPTION = 'Der Special Error wird gezeigt, wenn diese Fehlermeldung vom Backend zurückkommt.',
  RESULTING_SPECIAL_ERROR_DESCRIPTION = 'Die Standard-Fehlermeldung wird mit diesem Special Error ersetzt, wenn die oben spezifizierte Fehlermeldung vom Backend zurückkommt.',
  INTERACTIVE_EMAIL_WIDGET_TITLE = 'Form Interaktion E-Mail',
  INTERACTIVE_EMAIL_CONTROL_NAME_TITLE = 'Formularfeld-Name',
  INTERACTIVE_EMAIL_CONTROL_VALUE_TITLE = 'Formularfeld-Wert',
  INTERACTIVE_EMAIL_EMAIL_TITLE = 'Empfänger E-Mail-Adresse',
  INTERACTIVE_EMAIL_WIDGET_TITLE_TEMPLATE = 'Wenn das Feld [$controlName] den Wert [$controlValue] hat, dann wird das Formular an $email gesendet',
  INTERACTIVE_LOGIC_WIDGET_TITLE = 'Form Interaktion Logik',
  INTERACTIVE_LOGIC_HIDDEN = 'versteckt',
  INTERACTIVE_LOGIC_SHOWN = 'sichtbar',
  INTERACTIVE_LOGIC_FORM_GROUP_TITLE = 'Ziel-Formular-Element',
  INTERACTIVE_LOGIC_FORM_GROUP_DESCRIPTION = 'Übergeben Sie den Referenznamen des Formularelements, auf das sich die Logik bezieht.',
  INTERACTIVE_LOGIC_CONTROL_NAME_TITLE = 'Formularfeld-Name',
  INTERACTIVE_LOGIC_CONTROL_NAME_DESCRIPTION = 'Dieses Formularsteuerelement löst diese Aktion aus.',
  INTERACTIVE_LOGIC_CONTROL_VALUE_TITLE = 'Formularfeld-Wert',
  INTERACTIVE_LOGIC_CONTROL_VALUE_DESCRIPTION = 'Wenn dieser Wert mit dem Wert im angegebenen Formularsteuerelement übereinstimmt, wird die Aktion ausgelöst.',
  INTERACTIVE_LOGIC_HIDE_TITLE = 'Formulargruppe bei Übereinstimmung ausblenden',
  INTERACTIVE_LOGIC_HIDE_DESCRIPTION = 'Wenn die Aktion ausgelöst wird, kann die Formulargruppe entweder ein- oder ausgeblendet werden.',
  INTERACTIVE_LOGIC_WIDGET_TITLE_TEMPLATE = 'Wenn das Feld [$controlName] den Wert [$controlValue] hat, dann soll der Abschnitt [$formGroup] $visibility sein',
  SCHEDULING_TITLE = 'Zeitbasierter Inhalt',
  SCHEDULING_VISIBLE_FROM = 'Anzeigen ab',
  SCHEDULING_VISIBLE_UNTIL = 'Anzeigen bis einschließlich',
  SCHEDULING_CHILDREN = 'Inhalt',
  SCHEDULING_EDITOR_VISIBLE = 'Hinweis: Der Inhalt ist momentan nicht sichtbar.',
  SCHEDULING_EDITOR_FROM = 'Der Abschnitt ist sichtbar ab dem $date.',
  SCHEDULING_EDITOR_UNTIL = 'Der Abschnitt ist sichtbar bis einschließlich zum $date.',
  DATE_INITIAL_VALUE = 'Bitte geben Sie die initialen Werte wie folgt an; Datum: "jjjj-mm-tt" (z. B. 2022-12-31); Uhrzeit: "HH:mm" (z. B. 20:00).',
  DATE_NOW = 'Wenn das aktuelle Datum bzw. die aktuelle Uhrzeit angezeigt werden soll, wird der initiale Wert überschrieben.',
  WIZARD_LABEL_INITIAL_VALUE = 'Seite',
  TOOLTIP_NAME = 'Das Schlüsselwort, das mit [ID] im Text-Widget referenziert werden kann, um den Tooltip anzuzeigen.',
  TOOLTIP_TEXT = 'Der Text, der in dem Tooltip angezeigt wird.',
  MISSING_VIDEO = 'Kein Video angegeben',
  ICON = 'Symbol',
  LIST = 'Liste',
  HIGHLIGHT_TEASER_LINK = 'Teaser-Link',
  HIGHLIGHT_TEASER_IMAGE = 'Teaser-Bild',
  HIGHLIGHT_TEASER_TEXT = 'Überschrift',
  HIGHLIGHT_TEASER_SUBJECT = 'Kategorie',
  HIGHLIGHT_TEASER_INNER_SHADOW = 'Inneren Schatten anzeigen',
  KPI_TILE_TEXT = 'Text',
  KPI_TILE_IMAGE = 'Hintergrundbild',
  KPI_TILE_VIDEO = 'Video',
  KPI_TILE_COUNTER_START = 'Zähler Startwert',
  KPI_TILE_COUNTER_END = 'Zähler Endwert',
  KPI_TILE_TYPE = 'Variante',
  KPI_TILE_TYPE_IMAGE = 'Bild',
  KPI_TILE_TYPE_VIDEO = 'Video',
  COUNTDOWN_DATE = 'Datum für Countdown',
  COUNTDOWN_UNIT = 'Zeiteinheit für Countdown-Tage ',
  COUNTDOWN_UNIT_DESCRIPTION = 'Je nach Satzbau des vorherigen Satzes kann man "Tage" bzw. "Tagen" auswählen. Wenn der Countdown "1" anzeigt, steht automatisch immer "Tag" an dieser Stelle.',
  COUNTDOWN_TITLE = 'Text für Countdown',
  COUNTDOWN_TITLE_FINISHED = 'Text für Countdown, wenn das Ziel-Datum erreicht ist',
  COUNTDOWN_BUTTON_TOGGLE = 'Button anzeigen',
  COUNTDOWN_BUTTON_LINK = 'Link und Button-Text',
  COUNTDOWN_BUTTON_LINK_DESCRIPTION = 'Der Link gibt an, auf welche Seite man durch einen Klick kommt. Der Link-Text (zweites Feld) gibt an, welcher Text auf dem Button steht, sofern er denn sichtbar ist.',
  COUNTDOWN_IMAGE = 'Bild',
  COUNTDOWN_IMAGE_ALT = 'Alternativer Bild-Text',
  COUNTDOWN_IMAGE_TITLE = 'Bild-Titel',

  ROOT_PAGE_PATH = 'Der Pfad, der verwendet wird, um die Unterseite anzuzeigen, z. B. "/landing-page".',
  ROOT_PAGE_BASE_SITE_ID = 'Die ID der Seite, auf die sich der Pfad bezieht. Dies kann entweder "de" für rheinbahn.de oder "en" für rheinbahn.com sein.',
}

export enum AriaText {
  SLIDER_SELECTION_BUTTONS = 'Schaltflächen zum Wechsel der Slides darüber',
}

export enum WidgetTitle {
  ACCORDION = 'Akkordeon',
  ACCORDION_ELEMENT = 'Akkordeon-Element',
  APPOINTMENT = 'Termin',
  BIG_TEASER = 'Kampagnen-Teaser',
  BREADCRUMB = 'Breadcrumb',
  BUTTON = 'Button',
  BUTTON_GROUP = 'Button-Gruppe',
  CHECKBOX = 'Checkbox-Element',
  CHECKBOX_GROUP = 'Checkbox',
  CIRCLE_TEASER = 'Circle-Teaser-Element',
  CIRCLE_TEASER_GROUP = 'Circle-Teaser',
  CLOSING_LINE = 'Abschluss-Zeile',
  COLUMN = 'Spalte',
  COUNTDOWN = 'Countdown',
  DATE_TIME = 'Datum-/Zeiteingabe',
  DOWNLOAD_BOX = 'Download-Box',
  DROPDOWN = 'Dropdown',
  DROPDOWN_ITEM = 'Dropdown-Element',
  FORM = 'Formular',
  FORM_COLUMN = 'Formular-Spalten',
  FORM_EXAMPLE = 'Beispielformular',
  FORM_GROUP = 'Formular-Abschnitt',
  FORM_INTERACTIVE_EMAIL = 'Formular-Interaktion E-Mail',
  FORM_INTERACTIVE_LOGIC = 'Formular-Interaktion Logik',
  FORM_SPECIAL_SUBMISSION_ERROR = 'Besonderer Übermittlungsfehler',
  HEADLINE = 'Überschrift',
  HIGHLIGHT_TEASER = 'Highlight-Teaser-Element',
  HIGHLIGHT_TEASER_GROUP = 'Highlight-Teaser',
  ICON_LIST = ' Icon-Liste',
  ICON_LIST_ITEM = 'Icon-Listenelement',
  IFRAME = 'iFrame',
  IMAGE = 'Bild',
  INFO_TEASER = 'Info-Teaser-Element',
  INFO_TEASER_GROUP = 'Info-Teaser',
  INTRO_TEXT = 'Intro-Text',
  LANDING_PAGE_HERO = 'Landing-Page Hero',
  LINK_LIST = 'Link-Liste',
  RADIO_BUTTON = 'Radio-Button-Element',
  RADIO_GROUP = 'Radio-Button',
  SLIDE = 'Slide',
  SLIDER = 'Slideshow',
  SOCIAL_MEDIA = 'Social Media Icons',
  TABLE = 'Tabelle',
  TABLE_CELL = 'Tabellenzelle',
  TABLE_ROW = 'Tabellenzeile',
  TEXT = 'Text',
  TEXT_INPUT = 'Texteingabe',
  TEXT_TEASER = 'Text-Teaser-Element',
  TEXT_TEASER_GROUP = 'Text-Teaser',
  TIMELINE = 'Timeline',
  TIMELINE_ENTRY = 'Timeline-Element',
  TOGGLE_BUTTON = 'Toggle-Button-Element',
  TOGGLE_BUTTON_GROUP = 'Toggle-Button',
  UPLOAD_BUTTON = 'Upload-Button',
  VIDEO = 'Video',
  VIDEO_REEL_WIDGET = 'Video-Reels',
  WAGON_SELECTION = 'Partybahn-Auswahl',
  WAGON_SELECTION_ITEM = 'Partybahn-Element',
  WIZARD = 'Wizard',
  WIZARD_PAGE = 'Wizard-Seite',
  KPI_TILE = 'KPI-Box-Element',
  KPI_TILE_GROUP = 'KPI-Box',
  COLOR_BOX_WIDGET = 'Abschnitt mit Hintergrund',
}

export enum PageTitle {
  PRESS_RELEASE = 'Pressemitteilung',
  OVERVIEW = 'Übersichtsseite',
}
