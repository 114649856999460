import { Typography } from '@mui/material';
import * as Scrivito from 'scrivito';
import { Icon, renderIcon } from '../../../utils/icons.utils';
import { ButtonWidgetIcons } from '../../../widgets/button-widget';
import styles from './info-teaser.module.scss';

export interface InfoTeaserComponentProps {
  link: Scrivito.Obj | Scrivito.Link;
  headline?: React.ReactNode;
  headlineComponent?: React.ElementType;
  text?: React.ReactNode;
  linkText?: React.ReactNode;
  media?: React.ReactNode;
  icon?: React.ReactNode;
}

export const InfoTeaserComponent: React.FC<InfoTeaserComponentProps> = Scrivito.connect(
  ({ link, headline, headlineComponent, text, linkText, media, icon }) => {
    const iconElement = icon === ButtonWidgetIcons.NONE ? undefined : renderIcon(icon as Icon);

    return (
      <Scrivito.LinkTag
        to={Scrivito.isInPlaceEditingActive() ? null : link}
        className={styles.InfoTeaser}
        draggable="false"
      >
        {media && <div className={styles.Image}>{media}</div>}

        {headline && (
          <div className={styles.Headline}>
            {icon && <div className={styles.Icon}>{iconElement}</div>}
            <Typography variant={'inherit'} component={headlineComponent || 'h3'}>
              {headline}
            </Typography>
          </div>
        )}
        {text && <div className={styles.Text}>{text}</div>}
        {linkText && <div className={styles.LinkText}>{linkText}</div>}
      </Scrivito.LinkTag>
    );
  }
);
