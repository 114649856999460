import FormControlLabel from '@mui/material/FormControlLabel';
import Radio, { RadioProps } from '@mui/material/Radio';
import classNames from 'classnames';
import styles from './radio-button.module.scss';
import * as React from 'react';

export interface RadioButtonProps extends RadioProps {
  /**
   * Label of radio input.
   */
  label: string;
}

const RadioButtonIcon: React.FC<{ checked: boolean }> = ({ checked }) => {
  return (
    <div
      className={classNames(styles.RadioButtonIcon, {
        [styles.checked]: checked,
      })}
    >
      <div className={styles.inner}>
        <div className={styles.dot} />
      </div>
    </div>
  );
};

export const RadioButtonComponent: React.FC<RadioButtonProps> = ({
  label,
  value,
  name,
  disabled,
  checked,
  inputProps,
}) => {
  const icon = <RadioButtonIcon checked={false} />;
  const checkedIcon = <RadioButtonIcon checked={true} />;
  const customInputProps = { 'data-value-text': label, ...inputProps } as React.InputHTMLAttributes<HTMLInputElement>;
  const radio = <Radio icon={icon} checkedIcon={checkedIcon} disabled={disabled} inputProps={customInputProps} />;

  return (
    <FormControlLabel
      className={classNames(styles.RadioButton, { [styles.Disabled]: disabled })}
      name={name}
      control={radio}
      label={label}
      value={value}
      disabled={disabled}
      checked={checked}
    />
  );
};
