import React, { ReactElement } from 'react';
import { Tab, Tabs, TabsProps } from '@mui/material';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import styles from './tabs.module.scss';

interface TabsComponentProps extends TabsProps {
  tabs: Array<{
    label: string;
    content: ReactElement;
  }>;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel = (props: TabPanelProps): ReactJSXElement => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <>{children}</>}
    </div>
  );
};

const a11yProps = (index: number): { id: string; 'aria-controls': string } => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
};

/**
 * Renders tabs with tab panels
 * @param props The tab component props
 */
export const TabsComponent: React.FC<TabsComponentProps> = ({ ...props }) => {
  const { tabs, ...other } = props;
  return (
    <div className={styles.TabsComponent}>
      <Tabs {...other}>
        {tabs.map((tab, index) => {
          return <Tab key={index} label={tab.label} {...a11yProps(index)} tabIndex={0} />;
        })}
      </Tabs>
      {tabs.map((tab, index) => {
        return (
          <TabPanel key={index} value={other.value} index={index}>
            {tab.content}
          </TabPanel>
        );
      })}
    </div>
  );
};
