import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import styles from './journey-planner.module.scss';
import { useViewportWidth } from '../../../utils/hooks.utils';
import { breakpoints } from '../../../utils/scrivito/breakpoints';
import { TripPlanner } from './trip-planner';
import { DepartureFinder } from './departure-finder';
import { EfaStopSuggestion, searchDepartureAndRedirect, searchTripAndRedirect } from '../api/efa-api.utils';
import dayjs from 'dayjs';
import { TabsComponent } from '../../controls/tabs/tabs';
import { CollapsibleTabsComponent } from '../../controls/collapsible-tabs/collapsible-tabs';
import { useTranslation } from 'react-i18next';
import { TextInputComponent } from '../../controls/text-input/text-input';
import { Collapse } from '@mui/material';
import { DATE_FORMAT_INT, TIME_FORMAT } from '../../../utils/general.utils';

export interface JourneyPlannerProps {
  onDropdownOpen?: (value: boolean) => void;
}

export const JourneyPlanner: React.FC<JourneyPlannerProps> = ({ onDropdownOpen }) => {
  const HERO_ID = 'heroSpeechBubble';
  const INITIAL_FULL_VISIBLE_SIZE = breakpoints.DESKTOP_L;
  const { i18n, t } = useTranslation();
  // ID of the tab (desktop)/accordion (mobile) that is currently opened
  const [openId, setOpenId] = useState<number>(0);
  // Items in the dropdown to select date/time
  const dropdownItems = useMemo(
    () => [
      { value: 'now', label: t('journeyPlanner.startNow') },
      { value: 'dep', label: t('journeyPlanner.departureFrom') },
      { value: 'arr', label: t('journeyPlanner.arrivalUntil') },
    ],
    [t]
  );
  const [from, setFrom] = useState<string | EfaStopSuggestion>('');
  const [to, setTo] = useState<string | EfaStopSuggestion>('');
  const now = dayjs();
  const [date, setDate] = useState(now.format(DATE_FORMAT_INT));
  const [time, setTime] = useState(now.format(TIME_FORMAT));
  const [accessible, setAccessible] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(dropdownItems[0]);
  const [showDateTime, setShowDateTime] = useState(false);
  const viewPortWidth = useViewportWidth() ?? 0;
  const [showOptions, setShowOptions] = useState(false);
  const [showTabs, setShowTabs] = useState(viewPortWidth > breakpoints.DESKTOP_XS);

  // Change visibility of tabs or collapsible tabs (accordions) depending on screen size
  useEffect(() => {
    const isVisible = viewPortWidth >= INITIAL_FULL_VISIBLE_SIZE;
    setShowOptions(isVisible);
    setShowTabs(viewPortWidth > breakpoints.DESKTOP_XS);
  }, [viewPortWidth, INITIAL_FULL_VISIBLE_SIZE]);

  // Show date time depending on dropdown selection
  useEffect(() => {
    setShowDateTime(dropdownValue.value !== dropdownItems[0].value);
  }, [dropdownValue, dropdownItems]);

  // Fire event when show options changed
  useEffect(() => {
    onDropdownOpen && onDropdownOpen(showOptions);
  }, [showOptions, onDropdownOpen]);

  // Handles the speech bubble outside click event
  useEffect(() => {
    /**
     * Handles the outside click of the speech bubble when screen size is less than
     * INITIAL_FULL_VISIBLE_SIZE and nothing has been changed by the user.
     * @param event The click event
     */
    const onOutsideClick = (event: MouseEvent): void => {
      let element: HTMLElement | null = event.target as HTMLElement;
      const isOpenDropdown = document.querySelector('.MuiModal-root') !== null;
      while (element && element.tagName !== 'BODY' && element.id !== HERO_ID && !isOpenDropdown) {
        element = element.parentElement;
      }
      const fromEmpty = typeof from === 'string' ? from.length === 0 : from?.name.length === 0;
      const toEmpty = typeof to === 'string' ? to.length === 0 : to?.name.length === 0;
      // Only validate dates if dropdown value is not 'now'
      const dateChanged = dropdownItems[0].value !== dropdownValue.value;
      const hasNotChanged = dateChanged
        ? time.length === 0 && date.length === 0 && fromEmpty && toEmpty
        : fromEmpty && toEmpty;
      const hide = element?.id !== HERO_ID && !isOpenDropdown && hasNotChanged;
      // Hide options
      setShowOptions(!hide);
    };

    // Listen to outside click
    if (showOptions && viewPortWidth < INITIAL_FULL_VISIBLE_SIZE) {
      window.addEventListener('click', onOutsideClick);
    } else {
      window.removeEventListener('click', onOutsideClick);
    }

    return () => {
      window.removeEventListener('click', onOutsideClick);
    };
  }, [from, to, time, date, dropdownItems, dropdownValue, showOptions, viewPortWidth, INITIAL_FULL_VISIBLE_SIZE]);

  /**
   * Formats the date and time to the specific format that is required in the api
   */
  const formatDateTime = (): { time: string; date: string } => {
    const efaDateFormat = 'DDMMYYYY';
    const efaTimeFormat = 'HHmm';
    const isNow = dropdownValue.value === 'now';
    const isLocalDate = date.indexOf('-') === -1; // is format yyyy-mm-dd or dd.mm.yyyy?
    // get current date or input date depending on the given input format
    const currentDate = isNow ? dayjs() : isLocalDate ? dayjs(date, efaDateFormat) : dayjs(date);
    const currentTime = isNow ? dayjs() : dayjs(time, TIME_FORMAT); // get current date or input date
    return { date: currentDate.format(efaDateFormat), time: currentTime.format(efaTimeFormat) };
  };

  /**
   * Handles the submit event for the trip request
   * @param event The form or mouse event (mouse event for the detail search link)
   */
  const onTripSubmit = (event: FormEvent | React.MouseEvent): void => {
    event.preventDefault();
    const { time, date } = formatDateTime();
    searchTripAndRedirect({
      from,
      to,
      departure: dropdownValue.value === 'arr' ? 'arr' : 'dep',
      time,
      date,
      accessible,
      language: i18n.language,
    });
  };

  /**
   * Handles the submit event for the departure request
   * @param event The form event
   */
  const onDepartureSubmit = (event: FormEvent): void => {
    event.preventDefault();
    const { time, date } = formatDateTime();
    searchDepartureAndRedirect({
      from,
      time,
      date,
      language: i18n.language,
    });
  };

  // Options to set date and time
  const options = (
    <Collapse in={showDateTime}>
      <div className={styles.DateTimePickers}>
        <TextInputComponent
          type={'date'}
          value={date}
          label={t('journeyPlanner.date')}
          hideOptionalLabel={true}
          onClick={(): void => {
            // workaround to focus input field on safari if value is empty
            if (!date) {
              setDate(dayjs().format(DATE_FORMAT_INT));
            }
          }}
          onChange={(event): void => setDate(typeof event === 'string' ? event : event.target.value)}
        />
        <TextInputComponent
          type={'time'}
          value={time}
          label={t('journeyPlanner.time')}
          onClick={(): void => {
            // workaround to focus input field on safari if value is empty
            if (!time) {
              setTime(dayjs().format(TIME_FORMAT));
            }
          }}
          hideOptionalLabel={true}
          onChange={(event): void => setTime(typeof event === 'string' ? event : event.target.value)}
        />
      </div>
    </Collapse>
  );

  // Tab labels and contents that is rendered either in a tab component or a collapsible tab component
  const labelAndContent = [
    {
      label: t('journeyPlanner.planTrip'),
      content: (
        <TripPlanner
          dropdownValue={dropdownValue}
          dropdownItems={dropdownItems}
          setAccessible={setAccessible}
          setShowOptions={setShowOptions}
          showOptions={showOptions}
          setFrom={setFrom}
          from={from}
          setTo={setTo}
          to={to}
          onSubmit={onTripSubmit}
          options={options}
          setDropdownValue={setDropdownValue}
        />
      ),
    },
    {
      label: t('journeyPlanner.findDeparture'),
      content: (
        <DepartureFinder
          dropdownItems={[dropdownItems[0], dropdownItems[1]]}
          dropdownValue={dropdownValue}
          setFrom={setFrom}
          from={from}
          onSubmit={onDepartureSubmit}
          options={options}
          setDropdownValue={setDropdownValue}
        />
      ),
    },
  ];

  // Handles the tab change depending on mobile or desktop (collapsible tabs or tabs)
  const handleTabChange = (newValue: number): void => {
    setOpenId(newValue);
    if (dropdownValue.value === dropdownItems[2].value) {
      setDropdownValue(dropdownItems[1]);
    }
  };

  return (
    <div className={styles.JourneyPlanner}>
      {showTabs && (
        <TabsComponent
          value={openId}
          onChange={(_, newValue): void => handleTabChange(newValue)}
          aria-label={t('journeyPlanner.ariaTabs')}
          tabs={labelAndContent}
        />
      )}
      {!showTabs && (
        <CollapsibleTabsComponent
          value={openId}
          tabs={labelAndContent}
          onChange={(newValue): void => handleTabChange(newValue)}
        />
      )}
    </div>
  );
};
