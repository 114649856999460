export enum FormsAttributes {
  API_KEY = 'apiKey',
}

export const formsAttributes = {
  [FormsAttributes.API_KEY]: {
    title: 'API-Key',
    description: 'API-Key für den Form-Upload',
  },
};

export const formsClassAttributes = {
  [FormsAttributes.API_KEY]: 'string',
};
