import { ImageObjectId } from '../../objects';
import * as Scrivito from 'scrivito';

export enum ModalAttributes {
  TYPE = 'modalType',
  TITLE = 'modalTitle',
  HEADLINE = 'modalHeadline',
  TEXT = 'modalText',
  IMAGE = 'modalImage',
  LINK = 'modalLink',
  VISIBLE_FROM = 'modalVisibleFrom',
  VISIBLE_UNTIL = 'modalVisibleUntil',
  HIDE_INITIALLY = 'hideInitially',
}

export const modalAttributes = {
  [ModalAttributes.VISIBLE_FROM]: {
    title: 'Anzeigen von',
  },
  [ModalAttributes.VISIBLE_UNTIL]: {
    title: 'Anzeigen bis',
  },
  [ModalAttributes.TITLE]: {
    title: 'Titel',
  },
  [ModalAttributes.HEADLINE]: {
    title: 'Überschrift',
  },
  [ModalAttributes.TEXT]: {
    title: 'Text',
  },
  [ModalAttributes.IMAGE]: {
    title: 'Bild',
  },
  [ModalAttributes.LINK]: {
    title: 'Link',
  },
  [ModalAttributes.TYPE]: {
    title: 'Typ',
    values: [
      { value: 'info', title: 'Information' },
      { value: 'warning', title: 'Warnung' },
    ],
  },
  [ModalAttributes.HIDE_INITIALLY]: {
    title: 'Pop-up nur bei Klick auf den Banner anzeigen lassen (nur bei Warnungen möglich)',
  },
};

export const modalClassAttributes = {
  [ModalAttributes.TITLE]: 'string',
  [ModalAttributes.HEADLINE]: 'string',
  [ModalAttributes.IMAGE]: ['reference', { only: ImageObjectId }],
  [ModalAttributes.LINK]: 'link',
  [ModalAttributes.TEXT]: 'html',
  [ModalAttributes.TYPE]: ['enum', { values: ['warning', 'info'] }],
  [ModalAttributes.VISIBLE_FROM]: 'date',
  [ModalAttributes.VISIBLE_UNTIL]: 'date',
  [ModalAttributes.HIDE_INITIALLY]: 'boolean',
};

export const modalInitialContent = {
  [ModalAttributes.LINK]: new Scrivito.Link({
    url: '#',
    title: 'Button Text',
  }),
  [ModalAttributes.TYPE]: 'info',
  [ModalAttributes.VISIBLE_FROM]: new Date(),
  [ModalAttributes.HIDE_INITIALLY]: false,
};
