import * as Scrivito from 'scrivito';
import { spacingClassAttributes } from '../../utils/scrivito/spacing-definitions';
import { TableCellWidgetId } from '../table-cell-widget';
import { TableRowWidgetId } from '../table-row-widget';
import { TableWidgetAttributes, TableWidgetId } from './table-widget-definitions';

export const TableWidgetClass = Scrivito.provideWidgetClass(TableWidgetId, {
  attributes: {
    ...spacingClassAttributes(),
    [TableWidgetAttributes.COLUMN_ALIGNMENTS]: 'stringlist',
    [TableWidgetAttributes.HEADER_CELLS]: ['widgetlist', { only: TableCellWidgetId }],
    [TableWidgetAttributes.ROWS]: ['widgetlist', { only: TableRowWidgetId }],
    [TableWidgetAttributes.FULL_WIDTH]: 'boolean',
    [TableWidgetAttributes.SHOW_HEADER]: 'boolean',
    [TableWidgetAttributes.MANUAL_HYPHEN]: 'boolean',
  },
});

export type TableWidget = InstanceType<typeof TableWidgetClass>;
