import * as Scrivito from 'scrivito';
import { VideoReelSliderWidgetAttributes, VideoReelSliderWidgetId } from './video-reel-slider-widget-definitions';
import { spacingClassAttributes } from '../../utils/scrivito/spacing-definitions';
import { VideoObjectId } from '../../objects';

export const VideoReelSliderWidgetClass = Scrivito.provideWidgetClass(VideoReelSliderWidgetId, {
  attributes: {
    [VideoReelSliderWidgetAttributes.TITLE]: 'string',
    [VideoReelSliderWidgetAttributes.ELEMENT_ID]: 'string',
    [VideoReelSliderWidgetAttributes.TEXT]: 'html',
    [VideoReelSliderWidgetAttributes.VIDEOS]: ['referencelist', { only: [VideoObjectId] }],
    ...spacingClassAttributes(),
  },
});

export type VideoReelSliderWidget = InstanceType<typeof VideoReelSliderWidgetClass>;
