import * as Scrivito from 'scrivito';
import { TextWidgetAttributes, TextWidgetId } from './text-widget-definitions';
import { spacingAttributes, spacingContent, spacingGroup } from '../../utils/scrivito/spacing-definitions';
import { HelperText, WidgetTitle } from '../../utils/translations';
import { formElementIdAttributeValidation } from '../../utils/scrivito/validation';
import Thumbnail from '../../assets/thumbnails/text.svg';

Scrivito.provideEditingConfig(TextWidgetId, {
  title: WidgetTitle.TEXT,
  thumbnail: Thumbnail,
  properties: Object.values(TextWidgetAttributes),
  attributes: {
    [TextWidgetAttributes.TEXT]: {
      title: 'Text',
      options: {
        toolbar: [
          'bold',
          'italic',
          'link',
          'bulletList',
          'orderedList',
          'subscript',
          'superscript',
          'header1',
          'header2',
          'header3',
          'header4',
          'header5',
          'header6',
          'mark',
        ],
      },
    },
    [TextWidgetAttributes.ID]: {
      title: HelperText.ELEMENT_ID,
      description: HelperText.ELEMENT_ID_DESCRIPTION,
    },
    [TextWidgetAttributes.GRAY]: {
      title: 'Grauer Hintergrund',
    },
    ...spacingAttributes,
  },
  propertiesGroups: [spacingGroup],
  validations: [formElementIdAttributeValidation(TextWidgetAttributes.ID)],
  initialContent: {
    [TextWidgetAttributes.TEXT]: '<p>Beispieltext</p>',
    ...spacingContent,
  },
});
