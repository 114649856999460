import * as Scrivito from 'scrivito';
import { FormColumnWidgetAttributes, FormColumnWidgetId } from './form-column-widget-definitions';
import { ValidationInfo } from '../../utils/type.utils';
import { Message, WidgetTitle } from '../../utils/translations';
import Thumbnail from '../../assets/thumbnails/form-columns.svg';

Scrivito.provideEditingConfig(FormColumnWidgetId, {
  title: WidgetTitle.FORM_COLUMN,
  thumbnail: Thumbnail,
  properties: [FormColumnWidgetAttributes.ELEMENTS],
  attributes: {},
  validations: [
    [
      FormColumnWidgetAttributes.ELEMENTS,
      (widgets: Array<Scrivito.Widget>): ValidationInfo => {
        if (widgets.length !== 2) {
          return {
            message: Message.COLUMN_LENGTH,
            severity: 'error',
          };
        }
        return undefined;
      },
    ],
  ],
});
