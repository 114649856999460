export const LandingPageId = 'LandingPage';

export enum LandingPageAttributes {
  DOWNLOAD_BUTTON_TEXT = 'downloadButtonText',
  DOWNLOAD_BUTTON_TEXT_SHORT = 'downloadButtonTextShort',
  DOWNLOAD_BUTTON_LINK = 'downloadButtonLink',
  HERO_MEDIA = 'heroMedia',
  HERO_MEDIA_ALT = 'heroMediaAlt',
  HERO_MEDIA_TITLE = 'heroMediaTitle',
}
