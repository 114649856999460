import { FormColumnWidgetId } from './../form-column-widget/form-column-widget-definitions';
import * as Scrivito from 'scrivito';
import { FormGroupWidgetId } from '../form-group-widget/form-group-widget-definitions';
import { getFormElementBaseClassAttributes, groupLabelFormElementBaseClassAttributes } from '../form-widget';
import { ToggleButtonWidgetId } from '../toggle-button-widget';
import { ToggleButtonGroupWidgetAttributes, ToggleButtonGroupWidgetId } from './toggle-button-group-widget-definitions';
import { WizardPageWidgetId } from '../wizard-page-widget';

export const ToggleButtonGroupWidgetClass = Scrivito.provideWidgetClass(ToggleButtonGroupWidgetId, {
  attributes: {
    ...(groupLabelFormElementBaseClassAttributes as object),
    [ToggleButtonGroupWidgetAttributes.VALUE]: 'string',
    [ToggleButtonGroupWidgetAttributes.DISABLED]: 'boolean',
    [ToggleButtonGroupWidgetAttributes.ITEMS]: ['widgetlist', { only: ToggleButtonWidgetId }],
    ...getFormElementBaseClassAttributes(),
  },
  onlyInside: [FormGroupWidgetId, FormColumnWidgetId, WizardPageWidgetId],
});

export type ToggleButtonGroupWidget = InstanceType<typeof ToggleButtonGroupWidgetClass>;
