export const VideoWidgetId = 'VideoWidget';

export enum VideoWidgetAttributes {
  VIDEO = 'video',
  URL = 'url',
  CAPTION = 'caption',
  POSITION = 'position',
  ORIENTATION = 'orientation',
  COOKIE_CONSENT = 'cookieConsent',
  TITLE = 'title',
  BUBBLE = 'bubble',
}

export enum VideoWidgetPosition {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum VideoWidgetOrientation {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait',
}

export enum VideoWidgetBubbleColor {
  NO_COLOR = 'noColor',
  LIGHT_BLUE = 'lightBlue',
  DARK_GREEN = 'darkGreen',
  VIOLET = 'violet',
  PINK = 'pink',
}
