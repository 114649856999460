import * as Scrivito from 'scrivito';
import { checkValue } from '../../utils/scrivito/validation';
import { ValidationInfo } from '../../utils/type.utils';
import { HelperText, Message, WidgetTitle } from './../../utils/translations';
import { DropdownItemWidget } from './../dropdown-item-widget/dropdown-item-widget-class';
import { DropdownItemWidgetAttributes } from './../dropdown-item-widget/dropdown-item-widget-definitions';
import {
  FormElementBaseAttributes,
  formElementBaseAttributesDefinition,
  formElementBaseAttributesValidation,
} from './../form-widget/form-widget-definitions';
import { DropdownWidgetAttributes, DropdownWidgetId } from './dropdown-widget-definitions';
import Thumbnail from '../../assets/thumbnails/dropdown.svg';

Scrivito.provideEditingConfig(DropdownWidgetId, {
  title: WidgetTitle.DROPDOWN,
  titleForContent: (obj) =>
    `${WidgetTitle.DROPDOWN}: ${
      (obj.get(FormElementBaseAttributes.NAME) as string) || Message.FORM_INPUT_NAME_MISSING
    }`,
  thumbnail: Thumbnail,
  properties: Object.values(DropdownWidgetAttributes),
  propertiesGroups: [
    {
      title: 'Validation',
      properties: [...Object.values(FormElementBaseAttributes)],
    },
  ],

  attributes: {
    [DropdownWidgetAttributes.ITEMS]: {
      title: 'Items',
    },
    [DropdownWidgetAttributes.DISABLED]: {
      title: 'Dropdown deaktivieren',
    },
    [DropdownWidgetAttributes.PLACEHOLDER]: {
      title: 'Platzhalter',
    },
    [DropdownWidgetAttributes.DEFAULT_VALUE]: {
      title: HelperText.INPUT_DEFAULT_VALUE,
    },
    ...(formElementBaseAttributesDefinition as object),
  },
  initialContent: {
    [DropdownWidgetAttributes.PLACEHOLDER]: Message.PLEASE_CHOOSE,
    [FormElementBaseAttributes.OPTIONAL]: false,
    [DropdownWidgetAttributes.ITEMS]: [
      new DropdownItemWidget({ label: 'Platzhalter-Label 1', value: 'value1' }),
      new DropdownItemWidget({ label: 'Platzhalter-Label 2', value: 'value2' }),
    ],
  },

  validations: [
    [
      DropdownItemWidgetAttributes.LABEL,
      (value: string | null): ValidationInfo => checkValue(value, Message.LABEL_FIELD_MISSING),
    ],
    [
      DropdownWidgetAttributes.ITEMS,
      (widgets: Array<Scrivito.Widget>): ValidationInfo => {
        const labelArray: string[] = [];
        widgets?.map((widget) => {
          labelArray.push(widget.get(DropdownItemWidgetAttributes.LABEL) as string);
        });
        const hasDuplicate = labelArray.some((val, i) => labelArray.indexOf(val) !== i);

        if (hasDuplicate) {
          return {
            message: Message.LIST_HAS_DUPLICATES,
            severity: 'error',
          };
        }
        return undefined;
      },
    ],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(formElementBaseAttributesValidation as Array<any>),
  ],
});
