import React from 'react';
import * as Scrivito from 'scrivito';
import styles from './landing-navigation-drawer.module.scss';
import { Drawer, List, ListItem, ListItemButton } from '@mui/material';
import { LandingNavigationDrawerHeader } from './landing-navigation-drawer-header';
import { ReactComponent as IconDownload } from '../../../../../assets/icons/icon-download-2.svg';
import { LandingPageAttributes } from '../../../../../pages/landing-page';
import { linkStripHash } from '../../../../../utils/scrivito';

export const LandingNavigationDrawer: React.FC<{
  navigation: Scrivito.Link[];
  open: boolean;
  onClose: () => void;
}> = Scrivito.connect(({ navigation, open, onClose }) => {
  const root = Scrivito.Obj.root();
  const currentPage = Scrivito.currentPage();
  const downloadText = root?.get(LandingPageAttributes.DOWNLOAD_BUTTON_TEXT_SHORT) as string;
  const downloadLink = root?.get(LandingPageAttributes.DOWNLOAD_BUTTON_LINK) as Scrivito.Link;
  const navigateTo = (link: Scrivito.Link): void => {
    const strippedLink = linkStripHash(currentPage, link);
    Scrivito.navigateTo(strippedLink);
    onClose();
  };

  return (
    <Drawer anchor="left" open={open} onClose={onClose} className={styles.NavigationDrawer}>
      <LandingNavigationDrawerHeader title={''} onButtonClose={onClose} />

      <div className={styles.NavigationDrawerContent}>
        <List>
          {navigation.map((link) => {
            const linkTitle = link?.title();

            return (
              <ListItem key={linkTitle}>
                <ListItemButton onClick={(): void => navigateTo(link)}>
                  <span>{linkTitle}</span>
                </ListItemButton>
              </ListItem>
            );
          })}

          {downloadText && downloadLink && (
            <ListItem className={styles.DownloadButtonItem}>
              <Scrivito.LinkTag className={styles.DownloadButton} to={downloadLink}>
                <IconDownload />
                <span>{downloadText}</span>
              </Scrivito.LinkTag>
            </ListItem>
          )}
        </List>
      </div>
    </Drawer>
  );
});
