import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import style from './accordion.module.scss';
import { ReactComponent as IconChevron } from '../../../assets/icons/chevron.svg';
import { ReactElement, useEffect, useState } from 'react';
import { browserHistory } from '../../../config/history';
import { useMatomo } from '../../../utils/matomo';

/**
 * Properties for the accordion component
 */
export interface AccordionProps {
  id?: string;
  index: number;
  summary: ReactElement;
  summaryText: string;
  details: ReactElement;
}

const isCurrentHash = (id: string | undefined): boolean => window.location.hash === '#' + id;

/**
 * Renders an accordion component
 */
export const AccordionComponent: React.FC<AccordionProps> = ({ id, summary, summaryText, details, index }) => {
  const matomo = useMatomo();
  const [open, setOpen] = useState(isCurrentHash(id));

  useEffect(() => {
    return browserHistory.listen(() => {
      if (isCurrentHash(id)) {
        setOpen(true);
      }
    });
  }, [id]);

  function onChange(_: unknown, value: boolean): void {
    matomo.accordion(summaryText, value ? 'show' : 'hide');
    setOpen(value);
  }

  return (
    <Accordion
      id={id}
      className={style.AccordionWidget}
      data-test-id={`AccordionElement${index}`}
      expanded={open}
      onChange={onChange}
      disableGutters
      square
    >
      <div className={style.AccordionSummaryWrapper}>
        <AccordionSummary
          className={style.Summary}
          expandIcon={<IconChevron />}
          id={`panel${index}-header`}
          aria-controls={`panel${index}-content`}
        >
          <Typography className={style.Headline}>{summary}</Typography>
        </AccordionSummary>
      </div>
      <AccordionDetails className={style.Details}>
        <Typography className={style.Text}>{details}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};
