import { useEffect, useRef } from 'react';

interface MatomoFunctions {
  virtualPageView(title: string, url: string, lang: string, section: string[]): void;

  search(keyword: string, count: number, filter: string): void;

  accordion(title: string, action: 'show' | 'hide'): void;
}

interface MatomoInstance {
  push(o: object): void;
}

export interface WindowWithMatomo extends Window {
  // Should contain the matomo instance,
  // is undefined, when it is not loaded yet and
  // is null, when the dummy should be used instead
  _mtm: MatomoInstance | undefined | null;
}

const matomoDummy: MatomoInstance = {
  push(o: object) {
    if (process.env.NODE_ENV === 'development') {
      console.info('Data pushed to matomo:', o);
    }
  },
};

export function useMatomo(): MatomoFunctions {
  const queue = useRef<object[]>([]);
  function pushQueue(o: object): void {
    const windowMatomo = (window as unknown as WindowWithMatomo)._mtm;
    if (windowMatomo === undefined) {
      queue.current.push(o);
      return;
    }

    const matomo = windowMatomo ?? matomoDummy;
    matomo.push(o);
    execQueue(matomo);
  }

  function execQueue(matomo: MatomoInstance): void {
    while (queue.current.length) {
      const item = queue.current.shift();
      if (item) {
        matomo.push(item);
      }
    }
  }

  useEffect(() => {
    const windowMatomo = (window as unknown as WindowWithMatomo)._mtm;

    if (windowMatomo === undefined) {
      // Matomo is not loaded yet
      return;
    }

    const matomo = windowMatomo ?? matomoDummy;
    execQueue(matomo);
  }, [queue, queue.current.length]);

  return {
    virtualPageView(title: string, url: string, lang: string, section: string[]): void {
      pushQueue({
        event: 'virtualPageview',
        page_title: title,
        page_url: url,
        page_language: lang,
        page_type: 'page',
        section,
      });
    },
    search(keyword: string, count: number, filter: string): void {
      pushQueue({
        event: 'sitesearch',
        searchKeyword: keyword,
        searchCount: count,
        searchFilter: filter,
      });
    },
    accordion(title: string, action: 'show' | 'hide'): void {
      pushQueue({
        event: 'showAccordion',
        accordionAction: action,
        accordionTitle: title,
      });
    },
  };
}
