import React from 'react';
import * as Scrivito from 'scrivito';
import { ReactComponent as IconArrowRight } from '../../../assets/icons/icon-arrow-right.svg';
import styles from './page-banner.module.scss';
import classNames from 'classnames';
import { usePageBannerInfo } from './page-banner.helper';

interface PageBannerProps {
  hidden: boolean;
  onClick: () => void;
  forceShow?: boolean;
}

export const PageBanner: React.FC<PageBannerProps> = Scrivito.connect((props) => {
  const [visible, headline, text] = usePageBannerInfo();

  if (!visible && !props.forceShow) {
    return null;
  }

  const showingHeadline = !headline && props.forceShow ? 'HEADLINE' : headline;
  const showingText = !text && props.forceShow ? 'TEXT' : text;

  const onClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    props.onClick();
  };

  return (
    <button
      id="skip-link-banner"
      className={classNames(styles.PageBanner, {
        [styles.Hidden]: props.hidden,
      })}
      onClick={onClick}
    >
      <strong>{showingHeadline}</strong>
      <IconArrowRight />
      <span>{showingText}</span>
    </button>
  );
});

export const PageBannerSpacer: React.FC = Scrivito.connect(() => {
  const [visible] = usePageBannerInfo();
  return visible ? <div className={styles.PageBannerSpacer} /> : null;
});
