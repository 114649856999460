import * as Scrivito from 'scrivito';
import { SliderWidget, SliderWidgetClass } from './slider-widget-class';
import { SliderWidgetAttributes, SlideWidgetAttribute } from './slider-widget-definitions';
import { spacingClassName } from '../../utils/scrivito/spacing-definitions';
import { SlideModel, SliderComponent } from '../../components/controls/slider/slider';

export const SliderWidgetComponent: React.FC<{ widget: SliderWidget }> = ({ widget }) => {
  const editorMode = Scrivito.isInPlaceEditingActive();
  const slides = widget.get(SliderWidgetAttributes.SLIDES) as Scrivito.Widget[];
  const ariaDescription = widget.get(SliderWidgetAttributes.ARIA_DESCRIPTION);

  const slideModels: SlideModel[] = slides.map((widget) => ({
    ariaLabel: widget.get(SlideWidgetAttribute.ARIA_LABEL) as string,
    content: <Scrivito.ContentTag key={widget.id()} content={widget} attribute={SlideWidgetAttribute.WIDGETS} />,
  }));

  return (
    <Scrivito.WidgetTag>
      <div className={spacingClassName(widget)}>
        <SliderComponent slides={slideModels} editorMode={editorMode} ariaDescription={ariaDescription} />
      </div>
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(SliderWidgetClass, SliderWidgetComponent);
