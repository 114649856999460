import * as Scrivito from 'scrivito';
import { ColumnWidgetAttributes, ColumnWidgetId } from './column-widget-definitions';
import { ValidationInfo } from '../../utils/type.utils';
import { Message, WidgetTitle } from '../../utils/translations';
import Thumbnail from '../../assets/thumbnails/layout-columns.svg';

Scrivito.provideEditingConfig(ColumnWidgetId, {
  title: WidgetTitle.COLUMN,
  thumbnail: Thumbnail,
  properties: [ColumnWidgetAttributes.ELEMENTS],
  attributes: {},
  validations: [
    [
      ColumnWidgetAttributes.ELEMENTS,
      (widgets: Array<Scrivito.Widget>): ValidationInfo => {
        if (widgets.length !== 2) {
          return {
            message: Message.COLUMN_LENGTH,
            severity: 'error',
          };
        }
        return undefined;
      },
    ],
  ],
});
