import classNames from 'classnames';
import { FieldArray, useField, useFormikContext } from 'formik';
import * as Scrivito from 'scrivito';
import { CheckboxComponent } from '../../components/controls/checkbox/checkbox';
import { getColumnCountValue } from '../../utils/scrivito/column-count-definitions';
import { CheckboxWidget, CheckboxWidgetAttributes } from '../checkbox-widget';
import { FormElementBaseAttributes, FormElementBaseGroupLabelAttributes } from '../form-widget/form-widget-definitions';
import { invalidMessageForWidget, referenceNameForWidget } from '../shared/form/form-controls-helper-methods';
import { CheckboxGroupWidget, CheckboxGroupWidgetClass } from './checkbox-group-widget-class';
import { CheckboxGroupWidgetAttributes } from './checkbox-group-widget-definitions';
import styles from './checkbox-group-widget.module.scss';
import { stripHtml } from '../../utils/string';
import { useFormContext } from '../form-widget/FormContext';

export const CheckboxGroupWidgetComponent: React.FC<{ widget: CheckboxGroupWidget }> = ({ widget }) => {
  const referenceName = referenceNameForWidget(widget);
  const [field, meta] = useField(referenceName);

  const { handleBlur, setFieldValue } = useFormikContext();
  const formContext = useFormContext();

  const groupLabelVisible = widget.get(FormElementBaseGroupLabelAttributes.LABEL_GROUP_VISIBLE);
  const checkboxItems = widget.get(CheckboxGroupWidgetAttributes.ITEMS);
  const name = widget.get(FormElementBaseAttributes.NAME);

  if (formContext.isHiddenElement(name)) {
    return null;
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>, value: string, checked: boolean): void => {
    if (checked) {
      setFieldValue(field.name, [...field.value, value], true);
    } else {
      setFieldValue(
        field.name,
        [...field.value].filter((item) => item !== value),
        true
      );
    }
  };

  const isInvalid = meta.touched && !!meta.error;

  const hasRequiredCheckbox = checkboxItems.some((checkbox: CheckboxWidget) =>
    checkbox.get(CheckboxWidgetAttributes.REQUIRED)
  );

  return (
    <Scrivito.WidgetTag
      className={styles.CheckboxGroupWidget}
      role="role"
      aria-label={!groupLabelVisible && widget.get(FormElementBaseGroupLabelAttributes.LABEL_GROUP)}
      aria-labelledby={groupLabelVisible ? widget.id() : undefined}
    >
      {groupLabelVisible && (
        <div className={styles.GroupLabelContainer}>
          <Scrivito.ContentTag
            tag="span"
            content={widget}
            attribute={FormElementBaseGroupLabelAttributes.LABEL_GROUP}
            className={styles.CheckboxGroupLabel}
            id={widget.id()}
          />

          {!hasRequiredCheckbox && groupLabelVisible && <span className={styles.OptionalLabel}>optional</span>}
        </div>
      )}
      <div className={classNames(styles.CheckboxGroup, styles[`Column-${getColumnCountValue(widget)}`])}>
        <FieldArray name={referenceName}>
          {(): JSX.Element =>
            checkboxItems.map((checkbox: CheckboxWidget, index: number) => {
              const label = <Scrivito.ContentTag content={checkbox} attribute={CheckboxWidgetAttributes.LABEL} />;
              const labelText = stripHtml(checkbox.get(CheckboxWidgetAttributes.LABEL) as string);
              const value = (checkbox.get(CheckboxWidgetAttributes.VALUE) as string) || '';
              const checked = checkbox.get(CheckboxWidgetAttributes.CHECKED);
              const required = checkbox.get(CheckboxWidgetAttributes.REQUIRED);
              const disabled = checkbox.get(CheckboxWidgetAttributes.DISABLED);
              const readonly = checkbox.get(CheckboxWidgetAttributes.READONLY);

              return (
                <CheckboxComponent
                  key={index}
                  name={field.name}
                  label={label}
                  labelText={labelText}
                  onBlur={handleBlur}
                  onChange={(e, checked): void => {
                    onChange(e, value, checked);
                  }}
                  defaultChecked={checked}
                  disabled={disabled}
                  readOnly={readonly}
                  value={value}
                  hideOptionalLabel={(!hasRequiredCheckbox && groupLabelVisible) || required}
                  hasError={required && isInvalid}
                />
              );
            })
          }
        </FieldArray>
        {!!referenceName?.length && isInvalid && (
          <p data-testid="errorMessage" className={styles.ErrorMessage}>
            {invalidMessageForWidget(widget)}
          </p>
        )}
      </div>
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(CheckboxGroupWidgetClass, CheckboxGroupWidgetComponent);
