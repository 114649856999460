import * as Scrivito from 'scrivito';
import { HelperText } from '../translations';

/**
 * Add column count to a component/widget
 * - *-class.ts: include `...(columnCountClassAttributes as object),` to the `attributes`
 * - *-editing-config.ts:
 *    - add `ColumnCountAttributes.COLUMN_COUNT` to `properties``
 *    - add `...columnCountAttributes` to `attributes`
 *    - add `...columnCountInitialContent` to `initialContent`
 * - *-widget.tsx:
 *    - add styling by adding `styles[`Column-${getColumnCountValue(widget)}`]` to column container.
 * - *.module.scss:
 *  - use mixin from /src/assets/scss/_helper.scss to add `column-count` styling.
 **/

export enum ColumnCountAttributes {
  COLUMN_COUNT = 'columnCount',
}

export enum ColumnCountValue {
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
}

export const columnCountClassAttributes = {
  [ColumnCountAttributes.COLUMN_COUNT]: [
    'enum',
    {
      values: Object.values(ColumnCountValue),
    },
  ],
};

export const columnCountAttributes = {
  [ColumnCountAttributes.COLUMN_COUNT]: {
    title: HelperText.COLUMN_COUNT,
    values: [
      { value: ColumnCountValue.ONE, title: '1' },
      { value: ColumnCountValue.TWO, title: '2' },
      { value: ColumnCountValue.THREE, title: '3' },
      { value: ColumnCountValue.FOUR, title: '4' },
    ],
  },
};

export const columnCountInitialContent = {
  [ColumnCountAttributes.COLUMN_COUNT]: ColumnCountValue.ONE,
};

export const getColumnCountValue = (widget: Scrivito.Widget): string => {
  const value = widget.get(ColumnCountAttributes.COLUMN_COUNT) as string;
  return value || '1';
};
