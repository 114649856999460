import * as Scrivito from 'scrivito';
import React, { ReactElement } from 'react';
import styles from './highlight-teaser.module.scss';
import { ResponsiveImageContextProvider } from '../image/responsive-image/responsive-image.context';
import classNames from 'classnames';

/**
 * Properties for the highlight teaser component
 */
export interface HighlightTeaserProps {
  href: Scrivito.Link | null;
  text: string;
  subject: string;
  innerShadow: boolean;
  image: ReactElement | null;
  isLarge: boolean;
}

/**
 * Creates a highlight teaser component
 */
export const HighlightTeaserComponent: React.FC<HighlightTeaserProps> = (props) => {
  return (
    <Scrivito.LinkTag
      draggable="false"
      to={props.href}
      className={classNames(styles.HighlightTeaser, { [styles.HighlightTeaserLarge]: props.isLarge })}
    >
      <div className={styles.HighlightTeaserContent}>
        <div className={styles.HighlightTeaserSubject}>{props.subject}</div>
        <div className={styles.HighlightTeaserText}>{props.text}</div>
      </div>
      <ResponsiveImageContextProvider loading="eager">
        <div className={styles.HighlightTeaserImageWrapper}>{props.image}</div>
      </ResponsiveImageContextProvider>
      <div className={styles.HighlightTeaserHoverOverlay} aria-hidden="true" />
      {props.innerShadow && <div className={styles.HighlightTeaserBackground} aria-hidden="true" />}
    </Scrivito.LinkTag>
  );
};
