import React from 'react';
import * as Scrivito from 'scrivito';
import { List, ListItem } from '@mui/material';
import styles from './navigation-drawer-meta.module.scss';
import classNames from 'classnames';
import { useLanguageSwitcherInfo } from '../../../../../utils/scrivito/language-versions';
import { NavigationAttributes } from '../../../../../pages/home-page/home-page-navigation';

interface NavigationDrawerMetaProps {
  meta: Scrivito.Link[];
  sub: boolean;
  afterNavigation?: () => void;
}

export const NavigationDrawerMeta: React.FC<NavigationDrawerMetaProps> = Scrivito.connect((props) => {
  const languageSwitcher = useLanguageSwitcherInfo();
  const root = Scrivito.Obj.root();
  const showLanguageSwitcher = !!root?.get(NavigationAttributes.SHOW_LANGUAGE_SWITCHER);

  const navigateTo = (link: Scrivito.Obj | Scrivito.Link): void => {
    Scrivito.navigateTo(link);
    props.afterNavigation?.();
  };

  return (
    <div
      className={classNames(styles.NavigationDrawerMeta, {
        [styles.Sub]: props.sub,
      })}
    >
      <List>
        {props.meta.map((link) => {
          const label = link.title() as string;

          return (
            <ListItem button key={label} title={label} onClick={(): void => navigateTo(link)}>
              <span>{label}</span>
            </ListItem>
          );
        })}
        {showLanguageSwitcher && languageSwitcher && (
          <ListItem button title={languageSwitcher.label} onClick={(): void => navigateTo(languageSwitcher.link)}>
            <span>{languageSwitcher.label}</span>
          </ListItem>
        )}
      </List>
    </div>
  );
});
