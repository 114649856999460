import * as Scrivito from 'scrivito';
import {
  FormInteractiveEmailWidgetAttributes,
  FormInteractiveEmailWidgetId,
} from './form-interactive-email-widget-definitions';
import { HelperText, WidgetTitle } from '../../utils/translations';

Scrivito.provideEditingConfig(FormInteractiveEmailWidgetId, {
  title: WidgetTitle.FORM_INTERACTIVE_EMAIL,
  properties: Object.values(FormInteractiveEmailWidgetAttributes),
  attributes: {
    [FormInteractiveEmailWidgetAttributes.FORM_CONTROL_NAME]: {
      title: HelperText.INTERACTIVE_EMAIL_CONTROL_NAME_TITLE,
    },
    [FormInteractiveEmailWidgetAttributes.FORM_CONTROL_VALUE]: {
      title: HelperText.INTERACTIVE_EMAIL_CONTROL_VALUE_TITLE,
    },
    [FormInteractiveEmailWidgetAttributes.EMAIL]: {
      title: HelperText.INTERACTIVE_EMAIL_EMAIL_TITLE,
    },
  },
  validations: [],
  initialContent: {},
  titleForContent: (widget: Scrivito.Widget) => {
    const formControlName = (widget.get(FormInteractiveEmailWidgetAttributes.FORM_CONTROL_NAME) as string) || '';
    const formControlValue = (widget.get(FormInteractiveEmailWidgetAttributes.FORM_CONTROL_VALUE) as string) || '';
    const email = (widget.get(FormInteractiveEmailWidgetAttributes.EMAIL) as string) || '';
    const template = HelperText.INTERACTIVE_EMAIL_WIDGET_TITLE_TEMPLATE;

    return template
      .replace('$controlName', formControlName)
      .replace('$controlValue', formControlValue)
      .replace('$email', email);
  },
});
