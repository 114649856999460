import * as Scrivito from 'scrivito';
import { FormColumnWidget, FormColumnWidgetClass } from './form-column-widget-class';
import React from 'react';
import { FormColumnWidgetAttributes } from './form-column-widget-definitions';
import styles from './form-column-widget.module.scss';
import classNames from 'classnames';

export const FormColumnWidgetComponent: React.FC<{ widget: FormColumnWidget }> = ({ widget }) => {
  return (
    <Scrivito.WidgetTag>
      <Scrivito.ContentTag
        className={classNames('Column', styles.FormColumnWidget)}
        content={widget}
        attribute={FormColumnWidgetAttributes.ELEMENTS}
      />
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(FormColumnWidgetClass, FormColumnWidgetComponent);
