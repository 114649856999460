import * as Scrivito from 'scrivito';
import { SynonymObjectAttributes, SynonymObjectId } from './synonym-object-definitions';

export const SynonymObjectClass = Scrivito.provideObjClass(SynonymObjectId, {
  attributes: {
    [SynonymObjectAttributes.TITLE]: 'string',
    [SynonymObjectAttributes.SYNONYMS]: 'stringlist',
  },
});

export type SynonymObject = InstanceType<typeof SynonymObjectClass>;
