export const TableWidgetId = 'TableWidget';

export enum TableWidgetAttributes {
  COLUMN_ALIGNMENTS = 'columnAlignments',
  HEADER_CELLS = 'headerCells',
  ROWS = 'rows',
  FULL_WIDTH = 'fullWidth',
  SHOW_HEADER = 'showHeader',
  MANUAL_HYPHEN = 'manualHyphen',
}
