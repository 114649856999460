import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import React from 'react';
import * as Scrivito from 'scrivito';
import { RadioButtonComponent, RadioButtonProps } from '../../components/controls/radio-button/radio-button';
import { getColumnCountValue } from '../../utils/scrivito/column-count-definitions';
import { FormElementBaseGroupLabelAttributes } from '../form-widget';
import { RadioButtonWidget, RadioButtonWidgetAttributes } from '../radio-button-widget';
import { referenceNameForWidget } from '../shared/form/form-controls-helper-methods';
import { RadioGroupWidget, RadioGroupWidgetClass } from './radio-group-widget-class';
import { RadioGroupWidgetAttributes } from './radio-group-widget-definitions';
import styles from './radio-group-widget.module.scss';
import { useFormContext } from '../form-widget/FormContext';

// internal used uuid for radio button form name.
let radioGroupComponentUuid = 0;

export const RadioGroupWidgetComponent: React.FC<{ widget: RadioGroupWidget }> = ({ widget }) => {
  const radios = widget.get(RadioGroupWidgetAttributes.RADIOS) ?? [];

  const id = 'radio-group-' + radioGroupComponentUuid;
  // increase value
  radioGroupComponentUuid++;

  const defaultValue = widget.get(RadioGroupWidgetAttributes.DEFAULT_SELECTED_RADIO);
  const groupLabelVisible = widget.get(FormElementBaseGroupLabelAttributes.LABEL_GROUP_VISIBLE);

  const formContext = useFormContext();
  const { setFieldValue } = useFormikContext();
  const referenceName = referenceNameForWidget(widget);

  if (formContext.isHiddenElement(referenceName)) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (event: any, newSelection: string): void => {
    setFieldValue(referenceName, newSelection);
  };

  return (
    <Scrivito.WidgetTag
      role="role"
      aria-label={!groupLabelVisible && widget.get(FormElementBaseGroupLabelAttributes.LABEL_GROUP)}
      aria-labelledby={groupLabelVisible ? widget.id() : undefined}
    >
      {groupLabelVisible && (
        <Scrivito.ContentTag
          content={widget}
          attribute={FormElementBaseGroupLabelAttributes.LABEL_GROUP}
          className={styles.RadioGroupLabel}
          id={widget.id()}
        />
      )}
      <FormControl className={styles.RadioFormControl}>
        <RadioGroup
          defaultValue={defaultValue}
          id={id}
          name={referenceName}
          className={classNames(styles.RadioGroup, styles[`Column-${getColumnCountValue(widget)}`])}
          onChange={onChange}
        >
          {radios.map((radioButton: RadioButtonWidget, index: number) => {
            const props: RadioButtonProps = {
              label: radioButton.get(RadioButtonWidgetAttributes.LABEL) as string,
              value: radioButton.get(RadioButtonWidgetAttributes.VALUE) as string,
              name: referenceName,
              disabled: radioButton.get(RadioButtonWidgetAttributes.DISABLED) as boolean,
            };

            return <RadioButtonComponent key={index} {...props} />;
          })}
        </RadioGroup>
      </FormControl>
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(RadioGroupWidgetClass, RadioGroupWidgetComponent);
