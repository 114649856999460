import * as Scrivito from 'scrivito';
import { VideoWidget, VideoWidgetClass } from './video-widget-class';
import { VideoContainer } from '../../components/controls/video-container/video-container';
import { VideoWidgetAttributes, VideoWidgetBubbleColor } from './video-widget-definitions';
import { spacingClassName } from '../../utils/scrivito/spacing-definitions';

export const VideoWidgetComponent: React.FC<{ widget: VideoWidget }> = ({ widget }) => {
  const video = widget.get(VideoWidgetAttributes.VIDEO) as Scrivito.Obj;
  const url = widget.get(VideoWidgetAttributes.URL) as string;
  const caption = <Scrivito.ContentTag content={widget} attribute={VideoWidgetAttributes.CAPTION} />;
  const position = widget.get(VideoWidgetAttributes.POSITION) as 'center' | 'left' | 'right';
  const orientation = widget.get(VideoWidgetAttributes.ORIENTATION) as 'landscape' | 'portrait';
  const cookieConsent = !!widget.get(VideoWidgetAttributes.COOKIE_CONSENT);
  const bubble = widget.get(VideoWidgetAttributes.BUBBLE) as VideoWidgetBubbleColor;
  const videoUrl = video?.contentUrl() ?? url ?? '';

  return (
    <Scrivito.WidgetTag>
      <div className={spacingClassName(widget)}>
        <VideoContainer
          acceptedCookies={cookieConsent}
          url={videoUrl}
          caption={caption}
          position={position}
          orientation={orientation}
          bubble={bubble}
        />
      </div>
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(VideoWidgetClass, VideoWidgetComponent);
