import React from 'react';
import classNames from 'classnames';
import { isCurrentPage, isDonePage, progressAria } from './wizard-helpers';
import { ReactComponent as IconTick } from '../../../assets/icons/icon-tick.svg';
import styles from './wizard-header.module.scss';

export interface WizardHeaderProps {
  grayBackground: boolean;
  currentIndex: number;
  pages: { id: string; title: string }[];
  onSetIndex: (index: number) => void;
}

export const WizardHeader: React.FC<WizardHeaderProps> = (props) => {
  const progress = progressAria(props.currentIndex, props.pages.length);

  return (
    <div
      className={classNames(styles.WizardHeader, { [styles.WizardHeaderGray]: props.grayBackground })}
      role="progressbar"
      aria-valuenow={progress}
      aria-valuemin={0}
      aria-valuemax={100}
    >
      {props.pages.map((page, index) => {
        const isCurrent = isCurrentPage(index, props.currentIndex);
        const isDone = isDonePage(index, props.currentIndex);
        let onClick: (() => void) | undefined = undefined;
        if (isDone) {
          onClick = (): void => {
            props.onSetIndex(index);
          };
        }

        return (
          <button
            key={page.id}
            type="button"
            onClick={onClick}
            className={classNames(styles.ProgressStep, {
              [styles.Current]: isCurrent,
              [styles.Done]: isDone,
            })}
            disabled={!isDone}
          >
            <div className={styles.ProgressIndicator}>
              {isCurrent && (
                <div className={styles.ProgressIndicatorIcon}>
                  <div className={styles.IconCurrentStep} />
                </div>
              )}
              {!isCurrent && isDone && (
                <div className={styles.ProgressIndicatorIcon}>
                  <IconTick className={styles.IconDoneStep} />
                </div>
              )}
              {!isCurrent && !isDone && (
                <div className={styles.ProgressIndicatorIcon}>
                  <div className={styles.IconStep} />
                </div>
              )}
            </div>
            <div>
              <span className={styles.ProgressStepTitle}>{page.title}</span>
            </div>
          </button>
        );
      })}
    </div>
  );
};
