import * as Scrivito from 'scrivito';
import {
  emailTemplateNamesWithLabels,
  formActionTypesWithLabels,
  FormActionAttributes,
  FormActionId,
  confirmationEmailTemplateNamesWithLabels,
} from './form-action-definitions';
import { ValidationInfo } from '../../utils/type.utils';
import { HelperText, Message } from '../../utils/translations';
import { checkValue } from '../../utils/scrivito/validation';

Scrivito.provideEditingConfig(FormActionId, {
  title: 'Form Action',
  titleForContent: (obj) => {
    const title = (obj.get(FormActionAttributes.TITLE) as string) || null;
    if (title) {
      return title;
    }

    const formActionType = ((): string => {
      const type = (obj.get(FormActionAttributes.FORM_ACTION_TYPE) as string) ?? '<Type missing>';

      return formActionTypesWithLabels[type];
    })();

    const emailSubject = (obj.get(FormActionAttributes.EMAIL_SUBJECT) as string) ?? '<Email subject missing>';

    const firstTargetEmailAndRemainingCount = ((): string => {
      const targets = obj.get(FormActionAttributes.TARGET_EMAIL_ADDRESSES) as string[];

      if (!targets?.length) {
        return '<Target mail address(es) missing>';
      }
      if (targets.length === 1) {
        return targets[0];
      }
      return `${targets[0]} (+${targets.length - 1})`;
    })();

    return `${formActionType} | ${emailSubject} | ${firstTargetEmailAndRemainingCount}`;
  },
  hideInSelectionDialogs: true,
  attributes: {
    [FormActionAttributes.TITLE]: {
      title: 'Titel (optional)',
      description: HelperText.FORM_TITLE,
    },
    [FormActionAttributes.FORM_ACTION_TYPE]: {
      title: 'Formularaktionstyp',
      description: HelperText.FORM_ACTION_TYPE,
      values: Object.entries(formActionTypesWithLabels).map(([value, title]) => ({ value, title })),
    },
    [FormActionAttributes.SAVE_FORM_SUBMISSION_TO_DATABASE]: {
      title: 'Formulardaten in der Datenbank speichern',
      description: HelperText.SAVE_FORM_SUBMISSION_TO_DATABASE,
    },
    [FormActionAttributes.APPEND_REFERENCE_NUMBER]: {
      title: 'Referenznummer im Betreff einfügen',
    },
    [FormActionAttributes.TARGET_EMAIL_ADDRESSES]: {
      title: 'Ziel-E-Mail-Adressen',
      description: HelperText.TARGET_EMAIL_ADDRESSES,
    },
    [FormActionAttributes.EMAIL_TEMPLATE_NAME]: {
      title: 'E-Mail-Vorlage',
      description: HelperText.EMAIL_TEMPLATE_NAME,
      values: Object.entries(emailTemplateNamesWithLabels).map(([value, title]) => ({ value, title })),
    },
    [FormActionAttributes.EMAIL_SUBJECT]: {
      title: 'E-Mail-Betreff',
      description: HelperText.EMAIL_SUBJECT,
    },
    [FormActionAttributes.EXTRA_EMAIL_TEXT]: {
      title: 'Zusätzlicher E-Mail-Text (optional)',
      description: HelperText.EXTRA_EMAIL_TEXT,
    },
    [FormActionAttributes.FROM_ADDRESS_NAME]: {
      title: 'Name des Absenders (optional)',
      description: HelperText.FROM_ADDRESS_NAME,
    },
    [FormActionAttributes.FROM_ADDRESS_ACCOUNT]: {
      title: 'E-Mailadresse des Absenders (optional)',
      description: HelperText.FROM_ADDRESS_ACCOUNT,
    },
    [FormActionAttributes.CONFIRMATION_EMAIL_ADDRESS_REF]: {
      title: 'Name des Texteingabefeldes (E-Mail)',
      description: HelperText.FORM_CONFIRMATION_EMAIL_ADDRESS_REF,
    },
    [FormActionAttributes.CONFIRMATION_EMAIL_TEMPLATE_NAME]: {
      title: 'Vorlage der Bestätigungsmail',
      description: HelperText.FORM_CONFIRMATION_EMAIL_TEMPLATE,
      values: Object.entries(confirmationEmailTemplateNamesWithLabels).map(([value, title]) => ({ value, title })),
    },
    [FormActionAttributes.CONFIRMATION_EMAIL]: {
      title: 'Bestätigungsmail senden',
      description: HelperText.FORM_CONFIRMATION_EMAIL_CUSTOMER,
    },
  },
  properties: Object.values(FormActionAttributes),
  initialContent: {
    [FormActionAttributes.FORM_ACTION_TYPE]: 'SendEmail',
    [FormActionAttributes.SAVE_FORM_SUBMISSION_TO_DATABASE]: true,
    [FormActionAttributes.APPEND_REFERENCE_NUMBER]: true,
    [FormActionAttributes.EMAIL_TEMPLATE_NAME]: emailTemplateNamesWithLabels.FormEmail,
    [FormActionAttributes.CONFIRMATION_EMAIL]: true,
    [FormActionAttributes.CONFIRMATION_EMAIL_TEMPLATE_NAME]:
      confirmationEmailTemplateNamesWithLabels.FormConfirmationEmail,
  },
  validations: [
    [
      FormActionAttributes.TARGET_EMAIL_ADDRESSES,
      (targetEmailAddresses: string[]): ValidationInfo => {
        if (!targetEmailAddresses?.length) {
          return { message: Message.EMAIL_TARGET_MISSING, severity: 'warning' };
        }

        const emailRegex =
          // eslint-disable-next-line max-len
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        const containsInvalidEmail = targetEmailAddresses.reduce((previous, address) => {
          if (!address.match(emailRegex)) {
            return true;
          }
          return previous;
        }, false);

        if (containsInvalidEmail) {
          return { message: Message.EMAIL_ADDRESS_INVALID, severity: 'error' };
        }
      },
    ],
    [
      FormActionAttributes.EMAIL_TEMPLATE_NAME,
      (emailTemplateName: string): ValidationInfo => checkValue(emailTemplateName, Message.EMAIL_TEMPLATE_NAME_MISSING),
    ],
    [
      FormActionAttributes.EMAIL_SUBJECT,
      (emailSubject: string, { obj }): ValidationInfo => {
        const formActionType = obj?.get('formActionType');
        if (!emailSubject?.length && formActionType !== 'NewsletterSubscription') {
          return {
            message: Message.EMAIL_SUBJECT_MISSING,
            severity: 'error',
          };
        }
        return undefined;
      },
    ],
    [
      FormActionAttributes.FROM_ADDRESS_ACCOUNT,
      (fromAddressAccount: string): ValidationInfo => {
        if (fromAddressAccount.includes('@')) {
          return {
            message: Message.FROM_ADDRESS_ACCOUNT_ERROR,
            severity: 'error',
          };
        }
        return undefined;
      },
    ],
    [
      FormActionAttributes.CONFIRMATION_EMAIL_ADDRESS_REF,
      (confirmationEmailAddressRef: string, { obj }): ValidationInfo => {
        const sendConfirmationEmail = obj?.get(FormActionAttributes.CONFIRMATION_EMAIL);

        if (sendConfirmationEmail && !confirmationEmailAddressRef?.length) {
          return {
            message: Message.CONFIRMATION_EMAIL_ADDRESS_REF_ERROR,
            severity: 'error',
          };
        }
        return undefined;
      },
    ],
    [
      FormActionAttributes.CONFIRMATION_EMAIL_TEMPLATE_NAME,
      (confirmationEmailTemplateName: string, { obj }): ValidationInfo => {
        const sendConfirmationEmail = obj?.get(FormActionAttributes.CONFIRMATION_EMAIL);
        if (sendConfirmationEmail && !confirmationEmailTemplateName?.length) {
          return {
            message: Message.CONFIRMATION_EMAIL_TEMPLATE_REQUIRED,
            severity: 'error',
          };
        }
        return undefined;
      },
    ],
  ],
});
