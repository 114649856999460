import { useEffect, useRef, useState } from 'react';

export interface ScrollManagerData {
  reducedHeader: boolean;
  noTransitionHeader: boolean;
  fixedPositionHeader: boolean;
}

export function useScrollManager(headerHeight: number, headerRef: React.RefObject<HTMLElement>): ScrollManagerData {
  const [scrollData, setScrollData] = useState<{ last: number; now: number }>({ last: 0, now: 0 });
  const [reduced, setReduced] = useState(false);
  const [noTransition, setNoTransition] = useState(false);
  const fixedPosition = useRef(false);

  const htmlElement: HTMLElement | null = document.querySelector('html');

  useEffect(() => {
    const onScroll = (): void =>
      setScrollData({
        last: scrollData.now,
        now: window.scrollY,
      });

    const setHTMLScrollingClass = (scrollingDown: boolean): void => {
      if (htmlElement) {
        if (!scrollingDown) {
          htmlElement.classList.add('big-header');
        } else {
          htmlElement.classList.remove('big-header');
        }
      }
    };

    if (headerRef.current) {
      const scrollingDown = scrollData.now >= scrollData.last;
      const scrollingInHeaderArea = scrollData.now < headerHeight;
      setReduced(scrollingDown && !scrollingInHeaderArea);

      setHTMLScrollingClass(scrollingDown);

      // save old position value
      const oldPositionFixed = fixedPosition.current;
      if (scrollingInHeaderArea) {
        // if is still absolute, stay absolute
        if (!fixedPosition.current) {
          // do nothing
          fixedPosition.current = false;
        } else {
          // reaching top of screen, set header's position to absolute.
          if (scrollData.now <= 0) {
            fixedPosition.current = false;
          }
        }
      } else {
        // out of header height positioning is fixed
        fixedPosition.current = true;
      }

      // whether switch from absolute to fixed
      const switchFromAbsoluteToFixed = !oldPositionFixed && fixedPosition.current;
      // prevent animation when switching from absolute to fixed
      // and trigger fade out transition of header
      // -> hide header directly
      setNoTransition(scrollingDown && switchFromAbsoluteToFixed);

      // add event listener
      window.addEventListener('scroll', onScroll, { passive: true });
    }

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollData, headerHeight, htmlElement, headerRef]);

  return {
    fixedPositionHeader: fixedPosition.current,
    noTransitionHeader: noTransition,
    reducedHeader: reduced,
  };
}
