import * as Scrivito from 'scrivito';
import { HighlightTeaserWidgetAttributes, HighlightTeaserWidgetId } from './highlight-teaser-widget-definitions';
// eslint-disable-next-line max-len
import { HighlightTeaserGroupWidgetId } from '../highlight-teaser-group-widget/highlight-teaser-group-widget-definitions';

export const HighlightTeaserWidgetClass = Scrivito.provideWidgetClass(HighlightTeaserWidgetId, {
  attributes: {
    [HighlightTeaserWidgetAttributes.LINK]: 'link',
    [HighlightTeaserWidgetAttributes.IMAGE]: ['reference', { only: 'Image' }],
    [HighlightTeaserWidgetAttributes.SUBJECT]: 'string',
    [HighlightTeaserWidgetAttributes.TEXT]: 'string',
    [HighlightTeaserWidgetAttributes.IMAGE_ALT]: 'string',
    [HighlightTeaserWidgetAttributes.IMAGE_TITLE]: 'string',
    [HighlightTeaserWidgetAttributes.INNER_SHADOW]: 'boolean',
  },
  onlyInside: [HighlightTeaserGroupWidgetId],
  extractTextAttributes: [HighlightTeaserWidgetAttributes.SUBJECT, HighlightTeaserWidgetAttributes.TEXT],
});

export type HighlightTeaserWidget = InstanceType<typeof HighlightTeaserWidgetClass>;
