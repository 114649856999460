import * as Scrivito from 'scrivito';
import { CountdownUnits, CountdownWidgetAttributes, CountdownWidgetId } from './countdown-widget-definitions';
import { spacingAttributes, spacingContent, spacingGroup } from '../../utils/scrivito/spacing-definitions';
import { HelperText, Message, WidgetTitle } from '../../utils/translations';
import { checkDateWithin969Days, checkValue, formElementIdAttributeValidation } from '../../utils/scrivito/validation';
import { ValidationInfo } from '../../utils/type.utils';

Scrivito.provideEditingConfig(CountdownWidgetId, {
  title: WidgetTitle.COUNTDOWN,
  properties: [
    CountdownWidgetAttributes.COUNTDOWN_DATE,
    CountdownWidgetAttributes.COUNTDOWN_TITLE,
    CountdownWidgetAttributes.COUNTDOWN_UNIT,
    CountdownWidgetAttributes.COUNTDOWN_TITLE_FINISHED,
    CountdownWidgetAttributes.BUTTON_TOGGLE,
    CountdownWidgetAttributes.BUTTON_LINK,
    CountdownWidgetAttributes.IMAGE,
    CountdownWidgetAttributes.IMAGE_ALT,
    CountdownWidgetAttributes.IMAGE_TITLE,
    CountdownWidgetAttributes.ID,
  ],
  propertiesGroups: [spacingGroup],
  attributes: {
    [CountdownWidgetAttributes.COUNTDOWN_DATE]: { title: HelperText.COUNTDOWN_DATE },
    [CountdownWidgetAttributes.COUNTDOWN_UNIT]: {
      title: HelperText.COUNTDOWN_UNIT,
      description: HelperText.COUNTDOWN_UNIT_DESCRIPTION,
    },
    [CountdownWidgetAttributes.COUNTDOWN_TITLE]: { title: HelperText.COUNTDOWN_TITLE },
    [CountdownWidgetAttributes.COUNTDOWN_TITLE_FINISHED]: { title: HelperText.COUNTDOWN_TITLE_FINISHED },
    [CountdownWidgetAttributes.BUTTON_TOGGLE]: { title: HelperText.COUNTDOWN_BUTTON_TOGGLE },
    [CountdownWidgetAttributes.BUTTON_LINK]: {
      title: HelperText.COUNTDOWN_BUTTON_LINK,
      description: HelperText.COUNTDOWN_BUTTON_LINK_DESCRIPTION,
    },
    [CountdownWidgetAttributes.IMAGE]: { title: HelperText.COUNTDOWN_IMAGE },
    [CountdownWidgetAttributes.IMAGE_ALT]: { title: HelperText.COUNTDOWN_IMAGE_ALT },
    [CountdownWidgetAttributes.IMAGE_TITLE]: { title: HelperText.COUNTDOWN_IMAGE_TITLE },
    [CountdownWidgetAttributes.ID]: {
      title: HelperText.ELEMENT_ID,
      description: HelperText.ELEMENT_ID_DESCRIPTION,
    },
    ...spacingAttributes,
  },
  initialContent: {
    [CountdownWidgetAttributes.COUNTDOWN_TITLE]: 'Das Ereignis beginnt in:',
    [CountdownWidgetAttributes.COUNTDOWN_TITLE_FINISHED]: 'Es ist soweit.',
    [CountdownWidgetAttributes.COUNTDOWN_DATE]: new Date(new Date().setDate(new Date().getDate() + 7)),
    [CountdownWidgetAttributes.COUNTDOWN_UNIT]: CountdownUnits.TAGEN,
    ...spacingContent,
  },
  validations: [
    formElementIdAttributeValidation(CountdownWidgetAttributes.ID),
    [
      CountdownWidgetAttributes.COUNTDOWN_DATE,
      (value: string): ValidationInfo => checkDateWithin969Days(value, Message.DATE_NOT_WITHIN_969_DAYS),
    ],
    [
      CountdownWidgetAttributes.COUNTDOWN_TITLE,
      (value: string | null): ValidationInfo => checkValue(value, Message.TITLE_MISSING),
    ],
    [
      CountdownWidgetAttributes.COUNTDOWN_TITLE_FINISHED,
      (value: string | null): ValidationInfo => checkValue(value, Message.TITLE_MISSING),
    ],
    [
      CountdownWidgetAttributes.COUNTDOWN_UNIT,
      (value: string | null): ValidationInfo => checkValue(value, Message.TEXT_MISSING),
    ],
    [
      CountdownWidgetAttributes.IMAGE,
      (value: string | null): ValidationInfo => checkValue(value, Message.COUNTDOWN_IMAGE_REQUIRED),
    ],
  ],
});
