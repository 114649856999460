import { SynonymObjectId } from '../../objects';

export enum SearchAttributes {
  SEARCH_PAGE = 'searchPage',
  SYNONYMS = 'synonyms',
}

export const searchAttributes = {
  [SearchAttributes.SEARCH_PAGE]: {
    title: 'Suche',
    description: 'Link zur Seite der Suche',
  },
  [SearchAttributes.SYNONYMS]: {
    title: 'Synonym-Listen',
  },
};

export const searchClassAttributes = {
  [SearchAttributes.SYNONYMS]: ['referencelist', { only: SynonymObjectId }],
  [SearchAttributes.SEARCH_PAGE]: 'link',
};
