import * as Scrivito from 'scrivito';
import { checkValue } from '../../utils/scrivito/validation';
import { stripHtml } from '../../utils/string';
import { HelperText, Message, WidgetTitle } from '../../utils/translations';
import { ValidationInfo } from '../../utils/type.utils';
import { ButtonWidgetIcons } from '../button-widget';
import { InfoTeaserWidgetAttributes, InfoTeaserWidgetId } from './info-teaser-widget-definitions';

Scrivito.provideEditingConfig(InfoTeaserWidgetId, {
  title: WidgetTitle.INFO_TEASER,
  titleForContent: (obj) => stripHtml(obj.get(InfoTeaserWidgetAttributes.HEADLINE) as string),
  properties: Object.values(InfoTeaserWidgetAttributes),
  attributes: {
    [InfoTeaserWidgetAttributes.LINK]: {
      title: HelperText.LINK_TARGET,
    },
    [InfoTeaserWidgetAttributes.MEDIA]: {
      title: HelperText.IMAGE,
    },
    [InfoTeaserWidgetAttributes.HEADLINE]: {
      title: HelperText.HEADLINE,
      description: HelperText.HERO_SUB_TITLE_DESCRIPTION,
      options: {
        toolbar: ['bold', 'italic', 'subscript', 'superscript'],
      },
    },
    [InfoTeaserWidgetAttributes.TEXT]: {
      title: HelperText.TEXT,
      description: HelperText.HERO_SUB_TITLE_DESCRIPTION,
      options: {
        toolbar: ['bold', 'italic', 'subscript', 'superscript'],
      },
    },
    [InfoTeaserWidgetAttributes.ICON]: {
      title: 'Icon',
      values: [
        // TODO use icon set of info teaser
        { value: ButtonWidgetIcons.NONE, title: 'ohne' },
        { value: ButtonWidgetIcons.FILE, title: 'Datei generisch' },
        { value: ButtonWidgetIcons.FILE_PDF, title: 'PDF' },
        { value: ButtonWidgetIcons.FILE_PPT, title: 'PPT' },
        { value: ButtonWidgetIcons.FILE_ARCHIVE, title: 'Archiv generisch' },
        { value: ButtonWidgetIcons.FILE_ZIP, title: 'ZIP' },
        { value: ButtonWidgetIcons.FILE_VIDEO, title: 'Video' },
        { value: ButtonWidgetIcons.FILE_IMAGE, title: 'Bild' },
        { value: ButtonWidgetIcons.SHOPPING_CART, title: 'Einkaufswagen' },
        { value: ButtonWidgetIcons.TICKET, title: 'Ticket' },
        { value: ButtonWidgetIcons.INFO, title: 'Info' },
        { value: ButtonWidgetIcons.MAP, title: 'Landkarte' },
        { value: ButtonWidgetIcons.TIP, title: 'Tipp' },
      ],
    },
  },
  validations: [
    [InfoTeaserWidgetAttributes.LINK, (value: string): ValidationInfo => checkValue(value, Message.LINK_MISSING)],
  ],
  initialContent: {
    [InfoTeaserWidgetAttributes.HEADLINE]: 'Teaser',
    [InfoTeaserWidgetAttributes.TEXT]: 'Teaser Text',
    [InfoTeaserWidgetAttributes.ICON]: ButtonWidgetIcons.NONE,
  },
});
