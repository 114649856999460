import { Button } from '@mui/material';
import React from 'react';
import classNames from 'classnames';
import styles from './social-media-link.module.scss';
import { Icon, renderIcon } from '../../../utils/icons.utils';
import { CustomScrivitoLink } from '../../../utils/type.utils';
import * as Scrivito from 'scrivito';

export interface SocialMediaLinkProps {
  type: string;
  link: Scrivito.Link | null;
}

/**
 * Creates a social media link component
 */
export const SocialMediaLinkComponent: React.FC<SocialMediaLinkProps> = ({ type, link }) => {
  const icon = renderIcon(type as Icon);
  return (
    <Button
      data-test-id="SocialMediaLink"
      component={CustomScrivitoLink}
      to={link}
      title={link?.title()}
      aria-label={link?.title()}
      className={classNames(styles.SocialMediaLink, styles[type])}
    >
      {icon && <span className={styles.Icon}>{icon}</span>}
    </Button>
  );
};
