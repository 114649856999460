import * as Scrivito from 'scrivito';
import { AppointmentComponent } from '../../components/building-blocks/appointment/appointment';
import { AppointmentWidget, AppointmentWidgetClass } from './appointment-widget-class';
import { AppointmentWidgetAttributes } from './appointment-widget-definitions';

export const AppointmentWidgetComponent: React.FC<{ widget: AppointmentWidget }> = ({ widget }) => {
  return (
    <AppointmentComponent
      title={widget?.get(AppointmentWidgetAttributes.LABEL)}
      link={widget?.get(AppointmentWidgetAttributes.LINK)}
    />
  );
};

Scrivito.provideComponent(AppointmentWidgetClass, AppointmentWidgetComponent);
