export const TextInputWidgetId = 'TextInputWidget';

export enum TextInputWidgetAttributes {
  LABEL = 'label',
  PLACEHOLDER = 'placeholder',
  VALUE = 'value',
  TYPE = 'type',
  HELPER_TEXT = 'helperText',
  DISABLED = 'disabled',
  READ_ONLY = 'readOnly',
  MULTILINE = 'multiline',
  REGEXP = 'regexp',
}

export enum TextInputWidgetTypes {
  TEXT = 'text',
  PASSWORD = 'password',
  NUMBER = 'number',
  HIDDEN = 'hidden',
  EMAIL = 'email',
  TEL = 'tel',
}
