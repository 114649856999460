import * as Scrivito from 'scrivito';
import {
  ImageWidgetAttributes,
  ImageWidgetBubbleColor,
  ImageWidgetId,
  ImageWidgetPosition,
} from './image-widget-definitions';
import { spacingClassAttributes } from '../../utils/scrivito/spacing-definitions';

export const ImageWidgetClass = Scrivito.provideWidgetClass(ImageWidgetId, {
  attributes: {
    [ImageWidgetAttributes.IMAGE]: ['reference', { only: 'Image' }],
    [ImageWidgetAttributes.CAPTION]: 'html',
    [ImageWidgetAttributes.TITLE_TAG]: 'string',
    [ImageWidgetAttributes.ALT_TAG]: 'string',
    [ImageWidgetAttributes.POSITIONING]: ['enum', { values: Object.values(ImageWidgetPosition) }],
    [ImageWidgetAttributes.WIDTH]: 'integer',
    [ImageWidgetAttributes.LINK]: 'link',
    [ImageWidgetAttributes.SCALABLE]: 'boolean',
    [ImageWidgetAttributes.BUBBLE]: ['enum', { values: Object.values(ImageWidgetBubbleColor) }],
    ...spacingClassAttributes(),
  },
  extractTextAttributes: [ImageWidgetAttributes.CAPTION],
});

export type ImageWidget = InstanceType<typeof ImageWidgetClass>;
