import * as Scrivito from 'scrivito';
import { RadioGroupWidgetId } from '../radio-group-widget/radio-group-widget-definitions';
import { RadioButtonWidgetAttributes, RadioButtonWidgetId } from './radio-button-widget-definitions';

export const RadioButtonWidgetClass = Scrivito.provideWidgetClass(RadioButtonWidgetId, {
  attributes: {
    [RadioButtonWidgetAttributes.LABEL]: 'string',
    [RadioButtonWidgetAttributes.VALUE]: 'string',
    [RadioButtonWidgetAttributes.DISABLED]: 'boolean',
  },
  onlyInside: [RadioGroupWidgetId],
  extractTextAttributes: [RadioButtonWidgetAttributes.LABEL],
});

export type RadioButtonWidget = InstanceType<typeof RadioButtonWidgetClass>;
