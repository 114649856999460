import { HelperText, WidgetTitle } from './../../utils/translations';
import { ValidationInfo } from './../../utils/type.utils';
import * as Scrivito from 'scrivito';
import { TextTeaserWidgetAttributes, TextTeaserWidgetId } from './text-teaser-widget-definitions';
import { Message } from '../../utils/translations';
import { titleForContentOfHtml } from '../../utils/scrivito/title-for-content-html';

Scrivito.provideEditingConfig(TextTeaserWidgetId, {
  title: WidgetTitle.TEXT_TEASER,
  titleForContent: (obj) => titleForContentOfHtml(obj, TextTeaserWidgetAttributes.SUBJECT),
  properties: Object.values(TextTeaserWidgetAttributes),
  attributes: {
    [TextTeaserWidgetAttributes.LINK]: {
      title: HelperText.TEXT_TEASER_LINK,
    },
    [TextTeaserWidgetAttributes.SUBJECT]: {
      title: HelperText.TEXT_TEASER_TEXT,
      options: {
        toolbar: ['bold', 'italic', 'subscript', 'superscript'],
      },
    },
    [TextTeaserWidgetAttributes.IMAGE]: {
      title: HelperText.TEXT_TEASER_IMAGE,
    },
  },
  validations: [
    [
      TextTeaserWidgetAttributes.LINK,
      (link: Scrivito.Obj): ValidationInfo => {
        if (!link) {
          return {
            message: Message.TEXT_TEASER_LINK_REQUIRED,
            severity: 'error',
          };
        }
        return undefined;
      },
    ],
    [
      TextTeaserWidgetAttributes.IMAGE,
      (image: Scrivito.Obj): ValidationInfo => {
        if (!image) {
          return {
            message: Message.TEXT_TEASER_IMAGE_REQUIRED,
            severity: 'error',
          };
        }
        return undefined;
      },
    ],
    [
      TextTeaserWidgetAttributes.SUBJECT,
      (subject: Scrivito.Obj): ValidationInfo => {
        if (!subject) {
          return {
            message: Message.TEXT_TEASER_SUBJECT_REQUIRED,
            severity: 'error',
          };
        }
        return undefined;
      },
    ],
  ],
  initialContent: {
    [TextTeaserWidgetAttributes.LINK]: new Scrivito.Link({
      url: '#',
      title: 'Einfach. Klicken.',
    }),
  },
});
