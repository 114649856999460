import classNames from 'classnames';
import * as Scrivito from 'scrivito';
import { ReactComponent as Logo } from '../../../assets/img/rheinbahn-logo.svg';
import { FooterWidgetAttributes } from '../../../pages/home-page/home-page-footer';
import { getLinkTitle } from '../../../utils/scrivito';
import { AppointmentWidget, AppointmentWidgetAttributes } from '../../../widgets/appointment-widget';
import { ClosingLineWidget, ClosingLineWidgetAttributes } from '../../../widgets/closing-line-widget';
import { LinkListWidget, LinkListWidgetAttributes } from '../../../widgets/link-list-widget';
import { SocialMediaWidget, SocialMediaWidgetAttributes } from '../../../widgets/social-media-widget';
import { SocialMediaLinkComponent } from '../../controls/social-media-link/social-media-link';
import { AppointmentComponent } from '../appointment/appointment';
import { ClosingLineComponent } from './closing-line/closing-line';
import { FooterComponent } from './footer';
import styles from './footer.scrivito.module.scss';
import './index';
import { LinkListComponent } from './link-list/link-list';

/**
 * Properties for the footer component
 */
export interface FooterProps {
  // anchor id
  id?: string;
}

/**
 * Creates a headline component
 */
export const Footer: React.FC<FooterProps> = Scrivito.connect(({ id }) => {
  const root = Scrivito.Obj.root();

  const footerLinkList: Array<LinkListWidget> =
    (root?.get(FooterWidgetAttributes.FOOTER_NAVIGATION) as Array<LinkListWidget>) || [];

  const socialMediaWidgets: Array<SocialMediaWidget> =
    (root?.get(FooterWidgetAttributes.SOCIAL_MEDIA) as Array<SocialMediaWidget>) || [];

  const socialMediaWidget: SocialMediaWidget = socialMediaWidgets[0];

  let socialMedia = [];
  if (socialMediaWidget) {
    socialMedia = socialMediaWidgets[0]?.get(SocialMediaWidgetAttributes.TYPE);
  }

  const footerAppointments: Array<AppointmentWidget> =
    (root?.get(FooterWidgetAttributes.APPOINTMENT) as Array<AppointmentWidget>) || [];

  const closingLines: Array<ClosingLineWidget> =
    (root?.get(FooterWidgetAttributes.CLOSING_LINE) as Array<ClosingLineWidget>) || [];

  const closingLine = closingLines[0];
  let closingLineListNormal: Array<JSX.Element> = [];
  let closingLineListImportant: Array<JSX.Element> = [];

  if (closingLine) {
    closingLineListNormal = (closingLine.get(ClosingLineWidgetAttributes.LIST_NORMAL) || []).map((link, index) => {
      const title = getLinkTitle(link);

      return (
        <Scrivito.LinkTag to={link} key={index} title={title}>
          {title}
        </Scrivito.LinkTag>
      );
    });

    closingLineListImportant = (closingLine.get(ClosingLineWidgetAttributes.LIST_IMPORTANT) || []).map(
      (link, index) => {
        const title = getLinkTitle(link);

        return (
          <Scrivito.LinkTag to={link} key={index} title={title}>
            {title}
          </Scrivito.LinkTag>
        );
      }
    );
  }

  return (
    <FooterComponent id={id}>
      <div
        className={classNames(styles.LogoSocialMediaContainer, {
          [styles.OnlyLogo]: socialMedia?.length <= 0,
        })}
      >
        {/* logo */}
        <Logo className={styles.Logo} />
        {/* social media */}
        {socialMedia.length ? (
          <div className={styles.SocialMediaContainer}>
            {socialMedia.map((link: SocialMediaWidgetAttributes, index: number) => {
              const scrivitoLink = (socialMediaWidgets[0].get(link) as Scrivito.Link) || null;
              return <SocialMediaLinkComponent key={index} type={link} link={scrivitoLink} />;
            })}
          </div>
        ) : undefined}
      </div>
      {/* Link list section */}
      {footerLinkList?.length > 0 && (
        <div className={styles.LinkListContainer}>
          {footerLinkList.map((list: LinkListWidget, index: number) => {
            const title = list.get(LinkListWidgetAttributes.TITLE);
            const items = list.get(LinkListWidgetAttributes.LINKS).map((link, index: number) => {
              const title = getLinkTitle(link);
              return (
                <Scrivito.LinkTag to={link} key={index} title={title}>
                  {title}
                </Scrivito.LinkTag>
              );
            });
            return <LinkListComponent title={title} key={index} items={items} />;
          })}
        </div>
      )}

      {/* Appointments section */}
      {footerAppointments?.length > 0 ? (
        <AppointmentComponent
          title={footerAppointments[0]?.get(AppointmentWidgetAttributes.LABEL)}
          link={footerAppointments[0]?.get(AppointmentWidgetAttributes.LINK)}
        />
      ) : undefined}
      {/* closing line section */}
      {closingLine ? (
        <ClosingLineComponent
          license={closingLine.get(ClosingLineWidgetAttributes.INFO_LINE)}
          listNormal={closingLineListNormal}
          listImportant={closingLineListImportant}
        />
      ) : undefined}
    </FooterComponent>
  );
});
