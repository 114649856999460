import * as Scrivito from 'scrivito';
import { basePageEditingConfig } from '../base-page';
import { OverviewPageAttributes, OverviewPageId } from './overview-page-definitions';
import { heroAttributes, heroProperties } from '../properties/hero';
import { scheduleAttributes, scheduleProperties } from '../properties/schedule';
import { HelperText, Message, PageTitle } from '../../utils/translations';
import { ValidationInfo } from '../../utils/type.utils';
import { checkValue } from '../../utils/scrivito/validation';

Scrivito.provideEditingConfig(OverviewPageId, {
  ...basePageEditingConfig,
  title: PageTitle.OVERVIEW,
  propertiesGroups: [
    {
      title: 'Übersichtsseite',
      key: 'page-overview',
      properties: Object.values(OverviewPageAttributes),
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(basePageEditingConfig.propertiesGroups as Array<any>),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(heroProperties as Array<any>),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(scheduleProperties as Array<any>),
  ],
  attributes: {
    [OverviewPageAttributes.HEADLINE]: {
      title: HelperText.HEADLINE,
    },
    [OverviewPageAttributes.ELEMENTS]: {
      title: 'Übersicht für',
      values: [{ value: OverviewPageAttributes.ELEMENTS, title: PageTitle.PRESS_RELEASE }],
    },
    [OverviewPageAttributes.FILTERS]: {
      title: 'Filter',
      description: 'Tags, die zur Filterung angezeigt werden',
    },
    ...basePageEditingConfig.attributes,
    ...heroAttributes,
    ...scheduleAttributes,
  },
  hideInSelectionDialogs: false,
  initialContent: {
    [OverviewPageAttributes.ELEMENTS]: PageTitle.PRESS_RELEASE,
  },
  validations: [
    [
      OverviewPageAttributes.ELEMENTS,
      (value: string | null): ValidationInfo => checkValue(value, Message.CHOOSE_OPTION),
    ],
  ],
});
