import * as Scrivito from 'scrivito';
import { CircleTeaserWidgetId as CircleTeaserWidgetId } from '../circle-teaser-widget';
import { CircleTeaserGroupWidgetAttributes, CircleTeaserGroupWidgetId } from './circle-teaser-group-widget-definitions';
import { spacingClassAttributes } from '../../utils/scrivito/spacing-definitions';

export const CircleTeaserGroupWidgetClass = Scrivito.provideWidgetClass(CircleTeaserGroupWidgetId, {
  attributes: {
    [CircleTeaserGroupWidgetAttributes.CIRCLE_TEASER]: ['widgetlist', { only: CircleTeaserWidgetId }],
    ...spacingClassAttributes(),
  },
});

export type CircleTeaserGroupWidget = InstanceType<typeof CircleTeaserGroupWidgetClass>;
