import React from 'react';
import * as Scrivito from 'scrivito';
import { convertFormikDataToEnrichedFormData, EnrichedFormData, getFormSubmissionUrl } from './form-widget-helpers';
import { FormWidgetAttributes } from './form-widget-definitions';
import { FormContextData } from './FormContext';
import { getServerValidationLocalizedErrorMessages, ServerValidation } from './formik-helper/formik-validation';

const formSubmissionUnknownErrorMessage = 'Ein unbekannter Fehler ist aufgetreten.';
const formSubmissionNetworkErrorMessage = 'Ein Netzwerkfehler ist aufgetreten';

type FormikSubmitHandler = (values: { [key: string]: object }) => void;

/**
 * Generates a form submit handler that sends a request
 * and navigates to a success page or shows an error.
 */
export const generateOnSubmit = (
  widget: Scrivito.Widget,
  formRef: React.RefObject<HTMLFormElement>,
  setSubmissionErrorDetails: (newValue: string) => void,
  setIsShowingErrorDialog: (newValue: boolean) => void,
  visibleFormControls: Scrivito.Widget[],
  formContext: FormContextData
): FormikSubmitHandler => {
  /**
   * Performs a post request to the backend with the converted form data.
   *
   * @param dataToSubmit the converted form data
   * @returns the response of the backend
   */
  const postDataToAPIEndpoint = async (dataToSubmit: EnrichedFormData): Promise<Response | null> => {
    const formSubmissionURL = getFormSubmissionUrl();
    const body = JSON.stringify(dataToSubmit);

    return fetch(formSubmissionURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      redirect: 'error',
      body,
    });
  };

  /**
   * Redirects to the success page specified by the editor if the form submission was successful.
   */
  const onFormSubmitSuccess = async (): Promise<void> => {
    formContext.setIsDone(true);
    const submissionSuccessPage = await Scrivito.load(
      () => widget.get(FormWidgetAttributes.SUCCESS_PAGE) as Scrivito.Link
    );
    if (!submissionSuccessPage) {
      return;
    }

    Scrivito.navigateTo(submissionSuccessPage);
  };

  /**
   * Display an error message if the form submission fails.
   *
   * @param errorMessage the error message to display
   */
  const onFormSubmitError = (errorMessage?: string): void => {
    if (errorMessage) {
      setSubmissionErrorDetails(errorMessage);
    }
    setIsShowingErrorDialog(true);
  };

  /**
   * Checks the response returned from the backend.
   * If there's an error, an appropriate message will be shown.
   * If the form submission was successful, the user gets redirected to the success page.
   *
   * @param response the backend's response to the post request
   */
  const checkResponseAndProceed = async (response: Response | null): Promise<void | null> => {
    if (!response) {
      return onFormSubmitError(formSubmissionUnknownErrorMessage);
    }

    if (response.status !== 200) {
      try {
        const serverValidation = (await response.json()) as ServerValidation;
        const referenceNamesWithErrors = getServerValidationLocalizedErrorMessages(serverValidation);

        const validationFeedback = serverValidation?.validationFeedback;
        if (validationFeedback) {
          Object.keys(referenceNamesWithErrors).forEach((fieldName) => {
            const message = referenceNamesWithErrors[fieldName];
            formContext.setCustomError(fieldName, message);
          });
        } else if (serverValidation?.localizedMessage) {
          onFormSubmitError(serverValidation?.localizedMessage);
        }

        return null;
      } catch {
        return onFormSubmitError(formSubmissionUnknownErrorMessage);
      }
    }

    return onFormSubmitSuccess();
  };

  /**
   * The onSubmit handler that will be used by formik.
   *
   * @param values the form control values managed by formik
   */
  return async (values: { [key: string]: object }): Promise<void> => {
    formContext.setIsLoading(true);
    try {
      setIsShowingErrorDialog(false);
      formContext.resetAllCustomErrors();

      // Filter out not visible form controls from the values data object
      const visibleFormControlNames = visibleFormControls.map((widget) => widget.get('name'));
      values = Object.keys(values).reduce((result, key) => {
        if (visibleFormControlNames.indexOf(key) >= 0) {
          result[key] = values[key];
        }
        return result;
      }, {} as { [key: string]: object });

      const dataToSubmit = await convertFormikDataToEnrichedFormData(widget, formRef, values, formContext);

      if (Scrivito.isInPlaceEditingActive()) {
        console.log({ rawValues: values, convertedData: dataToSubmit });
      }

      const response = (await postDataToAPIEndpoint(dataToSubmit).catch((error) => console.error(error))) || null;
      await checkResponseAndProceed(response);
    } catch (e) {
      console.error(e);
      onFormSubmitError(formSubmissionNetworkErrorMessage);
    } finally {
      formContext.setIsLoading(false);
    }
  };
};
