export const ImageWidgetId = 'ImageWidget';

export enum ImageWidgetAttributes {
  IMAGE = 'image',
  CAPTION = 'caption',
  TITLE_TAG = 'titleTag',
  ALT_TAG = 'altTag',
  POSITIONING = 'positioning',
  WIDTH = 'imageWidgetWidth',
  LINK = 'link',
  SCALABLE = 'scalable',
  BUBBLE = 'bubble',
}

export enum ImageWidgetPosition {
  CENTER = 'center',
  RIGHT = 'right',
  LEFT = 'left',
  FULL = 'full',
}

export enum ImageWidgetBubbleColor {
  NO_COLOR = 'noColor',
  LIGHT_BLUE = 'lightBlue',
  DARK_GREEN = 'darkGreen',
  VIOLET = 'violet',
  PINK = 'pink',
}
