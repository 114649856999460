import { ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';
import React from 'react';
import styles from './filter-tag-group.module.scss';
import classNames from 'classnames';

export interface FilterTagGroupComponentProps extends Omit<ToggleButtonGroupProps, 'onChange'> {
  label: string;
  // only multi-selection allowed
  value?: Array<string>;
}

export const FilterTagGroupComponent: React.FC<FilterTagGroupComponentProps> = (props) => {
  // group value
  const [values, setValues] = React.useState<Array<string> | null>(props.value || []);

  // group value handler
  const handleSelection = (event: React.MouseEvent<HTMLElement>, newValues: Array<string> | null): void => {
    setValues(newValues);
  };
  // check if there are active filter inside, otherwise hide
  const elements = props.children as Array<unknown | Array<unknown>>;
  if (elements?.length > 1) {
    // first element can be false if there is no file filter
    const falseElement = elements.filter((element) => element === false).length > 0;
    // check if the second element is an array with undefined values
    if (elements[1] instanceof Array) {
      const filters = (elements[1] as Array<unknown>).filter((element) => element !== undefined);
      // return empty element when there is no actual filter tag inside
      if (filters.length === 0 && falseElement) {
        return <></>;
      }
    }
  }

  return (
    <div className={classNames(styles.FilterTagGroup, props.className)}>
      <span className="Label" id="search-filter-label">
        {props.label}
      </span>
      <ToggleButtonGroup
        aria-labelledby="search-filter-label"
        value={values}
        disabled={props.disabled}
        onChange={handleSelection}
      >
        {props.children}
      </ToggleButtonGroup>
    </div>
  );
};
