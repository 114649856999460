import {
  FormInteractiveLogicWidgetAttributes,
  FormInteractiveLogicWidgetId,
} from './form-interactive-logic-widget-definitions';
import * as Scrivito from 'scrivito';
import { HelperText, WidgetTitle } from '../../utils/translations';

Scrivito.provideEditingConfig(FormInteractiveLogicWidgetId, {
  title: WidgetTitle.FORM_INTERACTIVE_LOGIC,
  titleForContent: (widget: Scrivito.Obj | Scrivito.Widget) => {
    const targetFormGroup = (widget.get(FormInteractiveLogicWidgetAttributes.TARGET_FORM_GROUP) as string) || '';
    const formControlName = (widget.get(FormInteractiveLogicWidgetAttributes.FORM_CONTROL_NAME) as string) || '';
    const formControlValue = (widget.get(FormInteractiveLogicWidgetAttributes.FORM_CONTROL_VALUE) as string) || '';
    const hideFormGroupWhenValueMatches =
      (widget.get(FormInteractiveLogicWidgetAttributes.HIDE_FROM_GROUP_WHEN_VALUE_MATCHES) as boolean) ?? true;

    const hiddenOrShown = hideFormGroupWhenValueMatches
      ? HelperText.INTERACTIVE_LOGIC_HIDDEN
      : HelperText.INTERACTIVE_LOGIC_SHOWN;

    const template = HelperText.INTERACTIVE_LOGIC_WIDGET_TITLE_TEMPLATE;
    return template
      .replace('$controlName', formControlName)
      .replace('$controlValue', formControlValue)
      .replace('$formGroup', targetFormGroup)
      .replace('$visibility', hiddenOrShown);
  },
  attributes: {
    [FormInteractiveLogicWidgetAttributes.TARGET_FORM_GROUP]: {
      title: HelperText.INTERACTIVE_LOGIC_FORM_GROUP_TITLE,
      description: HelperText.INTERACTIVE_LOGIC_FORM_GROUP_DESCRIPTION,
    },
    [FormInteractiveLogicWidgetAttributes.FORM_CONTROL_NAME]: {
      title: HelperText.INTERACTIVE_LOGIC_CONTROL_NAME_TITLE,
      description: HelperText.INTERACTIVE_LOGIC_CONTROL_NAME_DESCRIPTION,
    },
    [FormInteractiveLogicWidgetAttributes.FORM_CONTROL_VALUE]: {
      title: HelperText.INTERACTIVE_LOGIC_CONTROL_VALUE_TITLE,
      description: HelperText.INTERACTIVE_LOGIC_CONTROL_VALUE_DESCRIPTION,
    },
    [FormInteractiveLogicWidgetAttributes.HIDE_FROM_GROUP_WHEN_VALUE_MATCHES]: {
      title: HelperText.INTERACTIVE_LOGIC_HIDE_TITLE,
      description: HelperText.INTERACTIVE_LOGIC_HIDE_DESCRIPTION,
    },
  },
  properties: Object.values(FormInteractiveLogicWidgetAttributes),
  validations: [],
});
