import classNames from 'classnames';
import styles from './closing-line.module.scss';

export interface ClosingLineComponentComponentProps {
  license: string;
  listNormal: Array<React.ReactNode>;
  listImportant: Array<React.ReactNode>;
}

/**
 * Creates a footer link list
 */
export const ClosingLineComponent: React.FC<ClosingLineComponentComponentProps> = ({
  license,
  listNormal,
  listImportant,
}) => {
  return (
    <div className={styles.ClosingLine}>
      <div className={styles.LinkListContainer}>
        <ul className={classNames(styles.LinkList, styles.Normal)}>
          {listNormal.map((link, index) => (
            <li key={index}>{link}</li>
          ))}
        </ul>

        <ul className={styles.LinkList}>
          {listImportant.map((link, index) => (
            <li key={index}>{link}</li>
          ))}
        </ul>
      </div>

      <span className={styles.License}>{license}</span>
    </div>
  );
};
