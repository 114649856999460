import * as Scrivito from 'scrivito';
import { HelperText } from '../../utils/translations';
import { BasePageAttributes, basePageEditingConfig } from '../base-page';
import { heroProperties } from '../properties/hero';
import { FaqPageAttributes, FaqPageId } from './faq-page-definitions';
import { HeadlineWidgetTag } from '../../widgets/headline-widget';
import { scheduleProperties } from '../properties/schedule';

Scrivito.provideEditingConfig(FaqPageId, {
  ...basePageEditingConfig,
  title: 'FAQ Seite',
  propertiesGroups: [
    {
      title: 'FAQ Seite',
      key: 'page-faq',
      properties: Object.values(FaqPageAttributes),
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(basePageEditingConfig.propertiesGroups as Array<any>),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(heroProperties as Array<any>),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(scheduleProperties as Array<any>),
  ],
  properties: [...(basePageEditingConfig.properties as string[])],
  attributes: {
    [FaqPageAttributes.HEADLINE]: {
      title: HelperText.HEADLINE,
    },
    [FaqPageAttributes.HEADLINE_ELEMENT]: {
      title: HelperText.HEADLINE_HIERARCHY,
      values: [
        { value: HeadlineWidgetTag.H1, title: '<h1>' },
        { value: HeadlineWidgetTag.H2, title: '<h2>' },
      ],
    },
    [FaqPageAttributes.SUBJECT_PARENT_PAGE]: {
      title: HelperText.FAQ_PAGE_SUBJECTS_PARENT_PAGE,
      description: HelperText.FAQ_PAGE_SUBJECTS_PARENT_PAGE_DESCRIPTION,
    },
  },
  initialContent: {
    [FaqPageAttributes.HEADLINE_ELEMENT]: HeadlineWidgetTag.H1,
    [BasePageAttributes.TAGS]: ['FAQ'],
  },
  hideInSelectionDialogs: false,
});
