import { CircularProgress } from '@mui/material';
import classNames from 'classnames';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconAlertCircle } from '../../../assets/icons/icon-alert-circle.svg';
import { ReactComponent as IconClose } from '../../../assets/icons/icon-close.svg';
import { ReactComponent as IconTick } from '../../../assets/icons/icon-success.svg';
import { splitFileName } from '../../../utils/general.utils';
import { renderFileIcon } from '../../../utils/icons.utils';
import styles from './upload-button-file.module.scss';

export interface UploadButtonFileComponentProps {
  /**
   * File information of input.
   */
  file: File;
  /**
   * Unique identifier to distinguish files with same name.
   */
  hashedName: string;
  /**
   * Status of file
   * success: successfully uploaded
   * pending: uploading to bucket
   * error: error while uploading / file size too big
   */
  status: 'success' | 'pending' | 'error';
  /**
   * Optional error message
   */
  errorMessage?: string;
  /**
   * On file remove handler
   */
  onRemove?: (fileProps: UploadButtonFileComponentProps) => void;
}

export const UploadButtonFileComponent: React.FC<UploadButtonFileComponentProps> = (props) => {
  const { t } = useTranslation();

  /**
   * Render icon related to file type.
   * @param type
   * @returns
   */
  const fileTypeIcon = (type: string): JSX.Element => {
    return (
      <div className={styles.FileType} aria-hidden="true">
        {renderFileIcon(type)}
      </div>
    );
  };

  /**
   * Event handler when pressing remove button.
   * Dispatch event onRemove.
   */
  const onRemoveFile: MouseEventHandler = (event): void => {
    event.preventDefault();
    event.stopPropagation();
    if (props.onRemove) {
      props.onRemove(props);
    }
  };

  const fileNameSplit = splitFileName(props.file.name);

  return (
    <div
      className={classNames(styles.UploadButtonFile, {
        [styles.Error]: props.status === 'error',
        [styles.Success]: props.status === 'success',
      })}
    >
      <div className={styles.FileContainer}>
        <div className={styles.Status}>
          {props.status === 'success' && (
            <IconTick
              title={t('component.uploadButtonFile.statusSuccess')}
              aria-label={t('component.uploadButtonFile.statusSuccess')}
            />
          )}
          {(!props.status || props.status === 'pending') && (
            <CircularProgress
              size={'1.5rem'}
              title={t('component.uploadButtonFile.statusPending')}
              aria-label={t('component.uploadButtonFile.statusPending')}
            />
          )}
          {props.status === 'error' && (
            <IconAlertCircle
              title={t('component.uploadButtonFile.errorPending')}
              aria-label={t('component.uploadButtonFile.errorPending')}
            />
          )}
        </div>
        <div className={styles.FileType}>{fileTypeIcon(props.file.type)}</div>

        <div className={styles.Name} title={props.file.name}>
          <span className={styles.FileName} tabIndex={-1}>
            {fileNameSplit.name}
          </span>
          <span className={styles.FileExtension}>.{fileNameSplit.extension}</span>
        </div>

        <div className={styles.Action}>
          <button
            data-testid={'upload-button-file-remove-btn'}
            className={styles.RemoveButton}
            onClick={onRemoveFile}
            type="button"
            title={t('component.uploadButtonFile.removeFile')}
            aria-label={t('component.uploadButtonFile.removeFile')}
          >
            <IconClose aria-hidden="true" />
          </button>
        </div>
      </div>
      {props.status === 'error' && props.errorMessage && (
        <div className={styles.ErrorContainer}>{props.errorMessage}</div>
      )}
    </div>
  );
};
