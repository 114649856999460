import * as Scrivito from 'scrivito';
import { HelperText, Message, WidgetTitle } from '../../utils/translations';
import { FormGroupWidgetAttributes, FormGroupWidgetColors, FormGroupWidgetId } from './form-group-widget-definitions';
import Thumbnail from '../../assets/thumbnails/form-group.svg';
import { ValidationInfo } from '../../utils/type.utils';
import { checkValue } from '../../utils/scrivito/validation';

Scrivito.provideEditingConfig(FormGroupWidgetId, {
  title: WidgetTitle.FORM_GROUP,
  titleForContent: (obj) =>
    `${WidgetTitle.FORM_GROUP}: ${
      (obj.get(FormGroupWidgetAttributes.NAME) as string) || Message.FORM_INPUT_NAME_MISSING
    }`,
  thumbnail: Thumbnail,
  properties: [
    FormGroupWidgetAttributes.LABEL,
    FormGroupWidgetAttributes.ELEMENTS,
    FormGroupWidgetAttributes.BACKGROUND_COLOR,
    FormGroupWidgetAttributes.NAME,
  ],
  attributes: {
    [FormGroupWidgetAttributes.BACKGROUND_COLOR]: {
      title: HelperText.BACKGROUND_COLOR,
      values: [
        { value: FormGroupWidgetColors.WHITE, title: 'Weiß' },
        { value: FormGroupWidgetColors.GREY, title: 'Grau' },
      ],
    },
    [FormGroupWidgetAttributes.ELEMENTS]: {
      title: HelperText.FORM_ELEMENTS,
    },
    [FormGroupWidgetAttributes.LABEL]: {
      title: HelperText.LABEL,
      description: HelperText.OPTIONAL_LABEL,
    },
    [FormGroupWidgetAttributes.NAME]: {
      title: HelperText.NAME,
    },
  },
  validations: [
    [FormGroupWidgetAttributes.NAME, (value: string | null): ValidationInfo => checkValue(value, Message.NAME_MISSING)],
  ],
  initialContent: {
    [FormGroupWidgetAttributes.BACKGROUND_COLOR]: FormGroupWidgetColors.WHITE,
  },
});
