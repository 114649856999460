import { HelperText, WidgetTitle } from './../../utils/translations';
import * as Scrivito from 'scrivito';
import { BreadcrumbWidgetAttributes, BreadcrumbWidgetId } from './breadcrumb-widget-definitions';
import { spacingAttributes, spacingContent, spacingGroup } from '../../utils/scrivito/spacing-definitions';

Scrivito.provideEditingConfig(BreadcrumbWidgetId, {
  title: WidgetTitle.BREADCRUMB,
  hideInSelectionDialogs: true,
  properties: [
    BreadcrumbWidgetAttributes.MAX_ITEMS,
    BreadcrumbWidgetAttributes.ITEMS_BEFORE_COLLAPSE,
    BreadcrumbWidgetAttributes.ITEMS_AFTER_COLLAPSE,
  ],
  propertiesGroups: [spacingGroup],
  attributes: {
    [BreadcrumbWidgetAttributes.MAX_ITEMS]: {
      title: HelperText.BREADCRUMB_MAX_ITEMS,
      description: HelperText.BREADCRUMB_MAX_ITEMS_DESCRIPTION,
    },
    [BreadcrumbWidgetAttributes.ITEMS_BEFORE_COLLAPSE]: {
      title: HelperText.BREADCRUMB_ITEMS_BEFORE_COLLAPSE,
      description: HelperText.BREADCRUMB_ITEMS_BEFORE_COLLAPSE_DESCRIPTION,
    },
    [BreadcrumbWidgetAttributes.ITEMS_AFTER_COLLAPSE]: {
      title: HelperText.BREADCRUMB_ITEMS_AFTER_COLLAPSE,
      description: HelperText.BREADCRUMB_ITEMS_AFTER_COLLAPSE_DESCRIPTION,
    },
    ...spacingAttributes,
  },
  initialContent: {
    ...spacingContent,
  },
});
