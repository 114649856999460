import { useMatomo } from './matomo';
import { useHierarchy } from './scrivito';
import * as Scrivito from 'scrivito';
import React, { createContext, useContext, useEffect, useRef } from 'react';
import { BasePageAttributes } from '../pages/base-page';
import { NavigationEntryAttrs } from '../pages/home-page/home-page-navigation';
import { Hierarchy } from './scrivito/scrivito-link-definitions';

interface PageTrackerContextData {
  lastPage: React.MutableRefObject<string> | null;
}

const PageTrackerContext = createContext<PageTrackerContextData>({
  lastPage: null,
});

export const PageTrackerContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const lastPage = useRef<string>('');
  return <PageTrackerContext.Provider value={{ lastPage }}>{children}</PageTrackerContext.Provider>;
};

async function getSection(hierarchy: Hierarchy, page: Scrivito.Obj): Promise<string[]> {
  let section = await Scrivito.load(() =>
    hierarchy.widgets.map((obj) => {
      const label = obj.get(NavigationEntryAttrs.LABEL) as string;
      const link = obj.get(NavigationEntryAttrs.LINK) as Scrivito.Link | null;
      const linkTitle = link?.title() as string | undefined;
      return linkTitle || label || '';
    })
  );

  if (!section.length) {
    let scrivitoAncestors = await Scrivito.load(() => (page.ancestors() ?? []) as Array<Scrivito.Obj | null>);

    scrivitoAncestors = scrivitoAncestors.splice(1);
    section = scrivitoAncestors
      .filter((n) => n)
      .map((n) => n as Scrivito.Obj)
      .map((link) => (link.get('title') as string) ?? '');
  }

  return section;
}

export function usePageTracker(): void {
  const matomo = useMatomo();
  const hierarchy = useHierarchy();
  const { lastPage } = useContext(PageTrackerContext);
  const initialLoaded = useRef(false);
  const currentPage = Scrivito.currentPage();

  useEffect(() => {
    const page = currentPage;
    // lastPage should not be null at this point.
    if (page && lastPage) {
      if (page.id() === lastPage.current) {
        return;
      }

      lastPage.current = page.id();
      if (lastPage || !initialLoaded.current) {
        initialLoaded.current = true;
        const title =
          (page.get(BasePageAttributes.META_TITLE) as string) ??
          (page.get(BasePageAttributes.TITLE) as string) ??
          'Rheinbahn';
        const url = window.location.toString();
        const lang = page.language() ?? 'de';

        getSection(hierarchy, page).then((section) => {
          matomo.virtualPageView(title, url, lang, section);
        });
      }
    }
  }, [matomo, lastPage, currentPage, hierarchy]);
}
