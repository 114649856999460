import { HelperText, Message, WidgetTitle } from '../../utils/translations';
import * as Scrivito from 'scrivito';
import { ValidationInfo } from '../../utils/type.utils';
import {
  HighlightTeaserGroupWidgetAttributes,
  HighlightTeaserGroupWidgetId,
} from './highlight-teaser-group-widget-definitions';
import { spacingAttributes, spacingContent, spacingGroup } from '../../utils/scrivito/spacing-definitions';
import Thumbnail from '../../assets/thumbnails/highlight_teaser.svg';
import { HighlightTeaserWidgetClass } from '../highlight-teaser-widget';

const minRequiredTeaser = 2;
const maxAllowedTeaser = 6;

Scrivito.provideEditingConfig(HighlightTeaserGroupWidgetId, {
  title: WidgetTitle.HIGHLIGHT_TEASER_GROUP,
  thumbnail: Thumbnail,
  properties: Object.values(HighlightTeaserGroupWidgetAttributes),
  attributes: {
    [HighlightTeaserGroupWidgetAttributes.ELEMENT_ID]: {
      title: HelperText.ELEMENT_ID,
      description: HelperText.ELEMENT_ID_DESCRIPTION,
    },
    [HighlightTeaserGroupWidgetAttributes.HIGHLIGHT_TEASER]: {
      title: HelperText.TEXT_TEASER_GROUP_TEASER,
    },
    ...spacingAttributes,
  },
  propertiesGroups: [spacingGroup],
  validations: [
    [
      HighlightTeaserGroupWidgetAttributes.HIGHLIGHT_TEASER,
      (widgets: Array<Scrivito.Widget>): ValidationInfo => {
        if (widgets && widgets.length < minRequiredTeaser) {
          return {
            message: Message.HIGHLIGHT_TEASER_GROUP_MIN_ITEMS,
            severity: 'error',
          };
        }
        if (widgets && widgets.length > maxAllowedTeaser) {
          return {
            message: Message.TEXT_TEASER_GROUP_MAX_ITEMS,
            severity: 'error',
          };
        }

        return undefined;
      },
    ],
  ],
  initialContent: {
    [HighlightTeaserGroupWidgetAttributes.HIGHLIGHT_TEASER]: Array.from(
      { length: minRequiredTeaser },
      () => new HighlightTeaserWidgetClass()
    ),
    ...spacingContent,
  },
});
