import * as Scrivito from 'scrivito';
import {
  TableCellAlignment,
  TableCellComponentProps,
  TableComponent,
  TableComponentProps,
  TableRowComponentProps,
} from '../../components/controls/table/table';
import { spacingClassName } from '../../utils/scrivito/spacing-definitions';
import { TableCellWidgetAttributes } from '../table-cell-widget';
import { TableRowWidgetAttributes } from '../table-row-widget';
import { TableWidget, TableWidgetClass } from './table-widget-class';
import { TableWidgetAttributes } from './table-widget-definitions';

export const TableWidgetComponent: React.FC<{ widget: TableWidget }> = ({ widget }) => {
  // Whether table should be use full page width
  const fullWidth = widget.get(TableWidgetAttributes.FULL_WIDTH) || false;

  // Column Alignments
  const columnAlignments = (widget.get(TableWidgetAttributes.COLUMN_ALIGNMENTS) as Array<TableCellAlignment>) ?? [];

  // Headers
  const headerCells = (widget.get(TableWidgetAttributes.HEADER_CELLS) as Scrivito.Widget[]) ?? [];
  const headers: Array<TableCellComponentProps> = headerCells.map((headerCell, index) => {
    const key = `header${index}`;
    const content = <Scrivito.ContentTag content={headerCell} attribute={TableCellWidgetAttributes.CONTENT} />;

    return {
      key,
      content,
      align: columnAlignments[index] || 'left',
      variant: 'head',
    };
  });

  // Get the width percentages
  let widthPercentages: number[] | undefined = undefined;
  const widthFactorStrings = headerCells.map((headerCell) => headerCell.get(TableCellWidgetAttributes.WIDTH) as string);
  if (widthFactorStrings.filter((f) => !f).length === 0) {
    const widthFactors: number[] = widthFactorStrings.map((width) => +width || 1);
    const widthFactorSum = widthFactors.reduce((a, b) => a + b, 0) || 1;

    widthPercentages = widthFactors.map((factor) => (factor / widthFactorSum) * 100.0);
  }

  // Whether header row is visible
  const showHeader = widget.get(TableWidgetAttributes.SHOW_HEADER);

  const manualHyphens = !!widget.get(TableWidgetAttributes.MANUAL_HYPHEN);

  // Rows
  const rowsCells = (widget.get(TableWidgetAttributes.ROWS) as Scrivito.Widget[]) ?? [];

  const rows: Array<TableRowComponentProps> = rowsCells.map((row, rowIndex) => {
    const id = `row${rowIndex}`;

    const cells = ((): Array<TableCellComponentProps> => {
      const cellWidgets = (row.get(TableRowWidgetAttributes.CELLS) as Scrivito.Widget[]) ?? [];

      return cellWidgets.map((cell, index) => {
        const key = `row${rowIndex}-cell${index}`;
        const content = <Scrivito.ContentTag content={cell} attribute={TableCellWidgetAttributes.CONTENT} />;
        return {
          key,
          content,
          align: columnAlignments[index] || 'left',
          variant: 'body',
          widthFactor: 1,
        };
      });
    })();

    return {
      id,
      cells,
    };
  });

  const tableProps: TableComponentProps = { headers, rows, fullWidth, showHeader, manualHyphens, widthPercentages };

  return (
    <Scrivito.WidgetTag>
      <div className={spacingClassName(widget)}>
        <TableComponent {...tableProps} />
      </div>
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(TableWidgetClass, TableWidgetComponent);
