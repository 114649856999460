import classNames from 'classnames';
import * as Scrivito from 'scrivito';
import { HelperText } from '../../utils/translations';
import { ImageWidgetAttributes } from '../image-widget';
import { BigTeaserWidget, BigTeaserWidgetClass } from './big-teaser-widget-class';
import { BigTeaserWidgetAttributes, ButtonPositions } from './big-teaser-widget-definitions';
import style from './big-teaser-widget.module.scss';
import { spacingClassName } from '../../utils/scrivito/spacing-definitions';
import { extractResponsiveData, getAttributeOrFallback } from '../../utils/scrivito/image.utils';

export const BigTeaserWidgetComponent: React.FC<{ widget: BigTeaserWidget }> = ({ widget }) => {
  const image = getAttributeOrFallback(widget, ImageWidgetAttributes.IMAGE);
  const link = widget.get(BigTeaserWidgetAttributes.LINK) as Scrivito.Link;
  const buttonText = link?.title() || 'Mehr lesen';
  const buttonPosition = widget.get(BigTeaserWidgetAttributes.BUTTON_POSITION) ?? ButtonPositions.RIGHT;
  const id = widget.get(BigTeaserWidgetAttributes.ID) as string;

  let altText = '';
  let titleText = '';
  let smallestImage = undefined;
  let sourceSet = undefined;
  if (image) {
    altText = widget.get(BigTeaserWidgetAttributes.IMAGE_ALT) ?? image.get(ImageWidgetAttributes.ALT_TAG);
    titleText = widget.get(BigTeaserWidgetAttributes.IMAGE_TITLE) ?? image.get(ImageWidgetAttributes.TITLE_TAG);
    const responsiveData = extractResponsiveData(image);
    if (responsiveData) {
      smallestImage = responsiveData.src;
      sourceSet = responsiveData.srcSet;
    }
  }

  return (
    <Scrivito.LinkTag
      draggable="false"
      id={id}
      to={Scrivito.isInPlaceEditingActive() ? null : link}
      title={link?.title() ?? undefined}
      className={classNames(style.BigTeaser, spacingClassName(widget))}
      data-test-id="BigTeaserWidgetId"
    >
      <div className={style.ImageWrapper}>
        <Scrivito.ImageTag
          content={widget}
          className={style.Image}
          loading="lazy"
          title={titleText}
          alt={altText}
          attribute={BigTeaserWidgetAttributes.IMAGE}
          src={smallestImage}
          srcSet={sourceSet}
          sizes={'100vw'}
        />
        <div className={style.Overlay} />
        <div className={style.OverlayText}>
          <Scrivito.ContentTag
            content={widget}
            attribute={BigTeaserWidgetAttributes.HOVER_TEXT}
            className={style.HoverTag}
          />
        </div>
      </div>
      {buttonPosition !== ButtonPositions.NONE && (
        <div
          className={classNames(style.Button, {
            [style.ButtonLeft]: buttonPosition === ButtonPositions.LEFT,
            [style.ButtonRight]: buttonPosition === ButtonPositions.RIGHT,
          })}
        >
          {buttonText}
        </div>
      )}
      {Scrivito.isInPlaceEditingActive() && (
        <div className={style.BigTeaserNotice}>{`[${HelperText.LINK_IN_EDIT_MODE}]`}</div>
      )}
    </Scrivito.LinkTag>
  );
};

Scrivito.provideComponent(BigTeaserWidgetClass, BigTeaserWidgetComponent);
