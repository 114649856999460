import { SimpleChoice } from './../../utils/objects/enums.utils';
import * as Scrivito from 'scrivito';
import { HelperText } from '../../utils/translations';
import { VideoObjectAttributes, VideoObjectId } from './video-object-definitions';

Scrivito.provideEditingConfig(VideoObjectId, {
  title: 'Video',
  properties: [VideoObjectAttributes.TITLE, VideoObjectAttributes.VISIBLE_IN_SEARCH, VideoObjectAttributes.TAGS],
  attributes: {
    [VideoObjectAttributes.TITLE]: {
      title: 'Title',
      description: 'A descriptive title for the video',
    },
    [VideoObjectAttributes.TAGS]: {
      title: 'Tags',
      description: 'Additional search keywords',
    },
    [VideoObjectAttributes.VISIBLE_IN_SEARCH]: {
      title: 'Bild über die Suche auffindbar',
      description: HelperText.VIDEO_VISIBLE_IN_SEARCH,
    },
  },
  initialContent: { [VideoObjectAttributes.VISIBLE_IN_SEARCH]: SimpleChoice.NO },
});
