import { createContext, useContext } from 'react';

interface ResponsiveImageContextData {
  loading: 'lazy' | 'eager';
  forceScalable: boolean;
}

const ResponsiveImageContext = createContext<ResponsiveImageContextData>({
  loading: 'lazy',
  forceScalable: false,
});

/**
 * With the ResponsiveImageContextProvider, you can specify the behavior of the images inside.
 * With the property 'loading' you can disable the lazy loading behavior when needed.
 * (e.g. when it causes bugs in specific components).
 * @param props
 * @constructor
 */
export const ResponsiveImageContextProvider: React.FC<{
  children: React.ReactNode;
  loading?: 'lazy' | 'eager';
  forceScalable?: boolean;
}> = (props) => {
  return (
    <ResponsiveImageContext.Provider
      value={{
        loading: props.loading ?? 'lazy',
        forceScalable: props.forceScalable ?? false,
      }}
    >
      {props.children}
    </ResponsiveImageContext.Provider>
  );
};

export const useResponsiveImageContext = (): ResponsiveImageContextData => useContext(ResponsiveImageContext);
