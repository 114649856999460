import { FormGroupWidgetColors } from './form-group-widget-definitions';
import { createContext, useContext } from 'react';

export interface FormGroupContextData {
  backgroundColor: string;
}

const FormGroupContext = createContext<FormGroupContextData>({
  backgroundColor: FormGroupWidgetColors.WHITE,
});

export const FormGroupContextProvider = FormGroupContext.Provider;

export function useFormGroupContext(): FormGroupContextData {
  return useContext(FormGroupContext);
}
