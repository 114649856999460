import * as Scrivito from 'scrivito';
import { basePageEditingConfig } from '../base-page';
import { heroAttributes, heroProperties } from '../properties/hero';
import { error404Attributes, Error404Attributes, error404InitialContent } from './home-page-404';
import { HomePageId } from './home-page-definitions';
import {
  FooterWidgetAttributes,
  footerWidgetAttributesDefinition,
  footerWidgetInitialContent,
  footerWidgetValidationsDefinition,
} from './home-page-footer';
import { tooltipAttributes, TooltipAttributes } from './home-page-tooltips';
import { modalAttributes, ModalAttributes, modalInitialContent } from './home-page-modal';
import { NavigationAttributes, navigationAttributes, navigationInitialContent } from './home-page-navigation';
import { searchAttributes, SearchAttributes } from './home-page-search';
import { formsAttributes, FormsAttributes } from './home-page-forms';
import { RootPageAttributes, rootPageInitialContent } from '../base-page/root-page-definitions';
import { HelperText } from '../../utils/translations';

Scrivito.provideEditingConfig(HomePageId, {
  ...basePageEditingConfig,
  title: 'Startseite',
  hideInSelectionDialogs: false,
  properties: [
    RootPageAttributes.PATH,
    RootPageAttributes.BASE_SITE_ID,
    ...(basePageEditingConfig.properties as string[]),
  ],
  propertiesGroups: [
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(basePageEditingConfig.propertiesGroups as Array<any>),
    {
      title: 'Navigation',
      key: 'page-navigation',
      properties: Object.values(NavigationAttributes),
    },
    {
      title: 'Sonderinformation',
      key: 'page-overlay',
      properties: Object.values(ModalAttributes),
    },
    {
      title: 'Fehlerseite',
      key: 'page-errors',
      properties: Object.values(Error404Attributes),
    },
    {
      title: 'Tooltips',
      key: 'key-tooltip',
      properties: Object.values(TooltipAttributes),
    },
    {
      title: 'Suche',
      key: 'search',
      properties: Object.values(SearchAttributes),
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(heroProperties as Array<any>),
    {
      title: 'Footer',
      key: 'page-footer',
      properties: Object.values(FooterWidgetAttributes),
    },
    {
      title: 'Formulare',
      key: 'page-forms',
      properties: Object.values(FormsAttributes),
    },
  ],
  attributes: {
    [RootPageAttributes.PATH]: {
      title: 'Pfad',
      description: HelperText.ROOT_PAGE_PATH,
    },
    [RootPageAttributes.BASE_SITE_ID]: {
      title: 'SiteId',
      description: HelperText.ROOT_PAGE_BASE_SITE_ID,
    },
    ...basePageEditingConfig.attributes,
    ...heroAttributes,
    ...navigationAttributes,
    ...modalAttributes,
    ...error404Attributes,
    ...tooltipAttributes,
    ...footerWidgetAttributesDefinition,
    ...searchAttributes,
    ...formsAttributes,
  },
  validations: [
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(basePageEditingConfig.validations as Array<any>),
    ...footerWidgetValidationsDefinition,
  ],
  initialContent: {
    ...rootPageInitialContent,
    ...basePageEditingConfig.initialContent,
    ...modalInitialContent,
    ...error404InitialContent,
    ...footerWidgetInitialContent,
    ...navigationInitialContent,
  },
});
