import * as Scrivito from 'scrivito';
import { FormGroupWidgetId } from '../form-group-widget/form-group-widget-definitions';
import { getFormElementBaseClassAttributes } from '../form-widget';
import { FormColumnWidgetId } from './../form-column-widget/form-column-widget-definitions';
import { UploadButtonWidgetAttributes, UploadButtonWidgetId } from './upload-button-widget-definitions';
import { WizardPageWidgetId } from '../wizard-page-widget/wizard-page-widget-definitions';

export const uploadButtonWidgetAcceptedFileTypesDefault = ['.pdf', '.jpg', '.jpeg', '.png'];

export const UploadButtonWidgetClass = Scrivito.provideWidgetClass(UploadButtonWidgetId, {
  attributes: {
    [UploadButtonWidgetAttributes.LABEL_GROUP]: 'string',
    [UploadButtonWidgetAttributes.MULTIPLE]: 'boolean',
    [UploadButtonWidgetAttributes.ACCEPT]: [
      'multienum',
      {
        values: uploadButtonWidgetAcceptedFileTypesDefault,
      },
    ],
    [UploadButtonWidgetAttributes.HELPER_TEXT]: 'string',
    ...getFormElementBaseClassAttributes(),
  },
  onlyInside: [FormGroupWidgetId, FormColumnWidgetId, WizardPageWidgetId],
});

export type UploadButtonWidget = InstanceType<typeof UploadButtonWidgetClass>;
