import * as Scrivito from 'scrivito';
import { validateLinkListRequiredTitle } from '../../utils/scrivito/validation';
import { HelperText, Message, WidgetTitle } from '../../utils/translations';
import { ValidationInfo } from '../../utils/type.utils';
import { LinkListWidgetAttributes, LinkListWidgetId } from './link-list-widget-definitions';

Scrivito.provideEditingConfig(LinkListWidgetId, {
  title: WidgetTitle.LINK_LIST,
  titleForContent: (widget) => widget.get(LinkListWidgetAttributes.TITLE) as string,
  properties: Object.values(LinkListWidgetAttributes),
  attributes: {
    [LinkListWidgetAttributes.TITLE]: {
      title: HelperText.TITLE,
    },
    [LinkListWidgetAttributes.LINKS]: {
      title: HelperText.LINKS,
    },
  },
  validations: [
    [
      LinkListWidgetAttributes.TITLE,
      (value: string): ValidationInfo => {
        if (!value) {
          return {
            message: Message.TEXT_MISSING,
            severity: 'error',
          };
        }
        return undefined;
      },
    ],
    [LinkListWidgetAttributes.LINKS, validateLinkListRequiredTitle],
  ],
  initialContent: {},
});
