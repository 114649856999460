import { createContext, useContext } from 'react';

export interface IconListContextData {
  getIconType(icon: string): string;
}

export const IconListContext = createContext<IconListContextData>({
  getIconType: () => '',
});

export function useIconListContext(): IconListContextData {
  return useContext(IconListContext);
}
