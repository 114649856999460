import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';
import classNames from 'classnames';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconTick } from '../../../assets/icons/icon-tick.svg';
import styles from './checkbox.module.scss';

export interface CheckboxComponentProps extends CheckboxProps {
  label: ReactElement;
  labelText: string;
  hideOptionalLabel?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  hasError?: boolean;
}

const CheckboxIcon: React.FC<{ checked: boolean }> = ({ checked }) => {
  return (
    <div
      className={classNames(styles.CheckboxIcon, {
        [styles.Checked]: checked,
      })}
    >
      <div className={styles.BackBox} />
      <IconTick className={styles.Tick} />
    </div>
  );
};

export const CheckboxComponent: React.FC<CheckboxComponentProps> = ({
  label,
  hideOptionalLabel,
  labelText,
  disabled,
  readOnly,
  hasError,
  ...props
}) => {
  const [checked, setChecked] = useState(props.defaultChecked);
  const { t } = useTranslation();

  // Update default state when changed by the editor
  useEffect(() => {
    setChecked(props.defaultChecked);
  }, [props.defaultChecked]);

  const inputProps = { 'data-value-text': labelText } as React.InputHTMLAttributes<HTMLInputElement>;

  const checkbox = (
    <Checkbox
      {...props}
      defaultChecked={undefined}
      onChange={(e): void => {
        const toggleCheck = !checked;
        props.onChange && props.onChange(e, toggleCheck);
        setChecked(toggleCheck);
      }}
      icon={<CheckboxIcon checked={false} />}
      checkedIcon={<CheckboxIcon checked={true} />}
      data-test-id={'checkbox'}
      inputProps={{ ...inputProps, ...props.inputProps }}
      disabled={disabled || readOnly}
      readOnly={readOnly}
    />
  );
  return (
    <FormControlLabel
      className={classNames(styles.CheckboxWidget, {
        [styles.Checked]: checked,
        [styles.Disabled]: disabled,
        [styles.ReadOnly]: readOnly,
        [styles.Error]: hasError,
      })}
      control={checkbox}
      label={
        <>
          {label}
          {!props.required && !hideOptionalLabel && (
            <span className={styles.OptionalLabel}>{t('component.forms.optional')}</span>
          )}
        </>
      }
    />
  );
};
