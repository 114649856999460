import * as Scrivito from 'scrivito';
import { HelperText, Message, WidgetTitle } from '../../utils/translations';
import {
  FormElementBaseAttributes,
  formElementBaseAttributesDefinition,
  formElementBaseAttributesValidation,
} from '../form-widget';
import { uploadButtonWidgetAcceptedFileTypesDefault } from './upload-button-widget-class';
import { UploadButtonWidgetAttributes, UploadButtonWidgetId } from './upload-button-widget-definitions';
import { ValidationInfo } from '../../utils/type.utils';
import { checkValue } from '../../utils/scrivito/validation';
import Thumbnail from '../../assets/thumbnails/upload-button.svg';

Scrivito.provideEditingConfig(UploadButtonWidgetId, {
  title: WidgetTitle.UPLOAD_BUTTON,
  titleForContent: (obj) =>
    `${WidgetTitle.UPLOAD_BUTTON}: ${
      (obj.get(FormElementBaseAttributes.NAME) as string) || Message.FORM_INPUT_NAME_MISSING
    }`,
  thumbnail: Thumbnail,
  properties: [...Object.values(UploadButtonWidgetAttributes)],
  propertiesGroups: [{ title: 'Validation', properties: [...Object.values(FormElementBaseAttributes)] }],

  attributes: {
    [UploadButtonWidgetAttributes.LABEL_GROUP]: {
      title: HelperText.LABEL,
    },
    [UploadButtonWidgetAttributes.MULTIPLE]: {
      title: HelperText.UPLOAD_BUTTON_MULTI_FILE,
      description: HelperText.UPLOAD_BUTTON_MULTI_FILE_DESCRIPTION,
    },
    [UploadButtonWidgetAttributes.ACCEPT]: {
      title: HelperText.UPLOAD_BUTTON_ACCEPT,
      description: HelperText.UPLOAD_BUTTON_ACCEPT_DESCRIPTION,
    },
    [UploadButtonWidgetAttributes.HELPER_TEXT]: {
      title: 'Hilfstext',
    },
    ...(formElementBaseAttributesDefinition as object),
  },
  validations: [
    ...(formElementBaseAttributesValidation as Array<never>),
    [
      UploadButtonWidgetAttributes.LABEL_GROUP,
      (value: string): ValidationInfo => checkValue(value, Message.LABEL_FIELD_MISSING),
    ],
  ],
  initialContent: {
    [UploadButtonWidgetAttributes.LABEL_GROUP]: 'Upload Datei',
    [UploadButtonWidgetAttributes.ACCEPT]: uploadButtonWidgetAcceptedFileTypesDefault,
  },
});
