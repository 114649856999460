import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WagonSelectionItemComponentProps } from '../wagon-selection-item/wagon-selection-item';
import styles from './wagon-selection.module.scss';

export interface WagonSelectionComponentProps {
  children?: Array<React.ReactElement<WagonSelectionItemComponentProps>>;
  initialValue?: string;
  onChange?: (event: ChangeEventHandler<HTMLInputElement>, newValue: string | null) => void;
  name?: string;
  label: React.ReactElement;
  labelText: string;
  labelVisible?: boolean;
  id: string;
}

// fallback uuid for radio button form name.
let radioWagonSelectionUuid = 0;

export const WagonSelectionComponent: React.FC<WagonSelectionComponentProps> = ({
  children,
  initialValue,
  id,
  name,
  label,
  labelText,
  labelVisible,
  onChange,
}) => {
  const { t } = useTranslation();

  // use name or auto incremented generic name
  if (!name) {
    name = `wagon-selection-${radioWagonSelectionUuid}`;
    // increase value
    radioWagonSelectionUuid++;
  }

  const [selectedValue, setSelectedValue] = useState<string | undefined>(initialValue);

  useEffect(() => {
    if (initialValue) {
      setSelectedValue(initialValue);
    } else {
      setSelectedValue(undefined);
    }
  }, [initialValue]);

  /**
   * Internal change handler set selected value.
   * @param event ChangeEvent of HTMLInputElement
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeInternal: ChangeEventHandler<HTMLInputElement> = (event: any) => {
    setSelectedValue(event.currentTarget.value);

    if (onChange) {
      // notify wagon selection (group)
      onChange(event, event.target.value);
    }
  };

  // compute size in ch (characters) of price and capacity container
  let maxLengthCapacity = 0;
  let maxLengthPrice = 0;
  const peopleTranslation = t('component.wagonSelectionItem.people');
  const priceTranslation = t('component.wagonSelectionItem.from');

  let editedChildren = children;

  if (children) {
    // find max values
    React.Children.map(children, (child) => {
      const capacity = child?.props?.capacity;
      const price = child?.props.price;

      if (capacity) {
        // icon + capacity + 'people' + 2 whitespace character
        const lengthCapacity = 2 + capacity.toString().length + peopleTranslation.length + 2;
        if (lengthCapacity > maxLengthCapacity) {
          maxLengthCapacity = lengthCapacity;
        }
      }
      if (price) {
        // 'from' + price + '€' + 2 * whitespace character
        const lengthPrice = priceTranslation.length + price.toString().length + 1 + 2;
        if (lengthPrice > maxLengthPrice) {
          maxLengthPrice = lengthPrice;
        }
      }
    });
    editedChildren = React.Children.map(children, (child) => {
      // apply props to children
      return React.cloneElement(child, {
        maxLengthCapacity,
        maxLengthPrice,
        selectedValue,
        formName: name,
        onChange: onChangeInternal,
      });
    });
  }

  return (
    <div className={styles.WagonSelection} data-testid="wagon-selection">
      {labelVisible && (
        <div id={id} className={styles.WagonSelectionLabel}>
          {label}
        </div>
      )}
      <div
        className={styles.WagonSelectionItemContainer}
        role="role"
        aria-labelledby={labelVisible ? id : undefined}
        aria-label={!labelVisible ? labelText : undefined}
      >
        {editedChildren}
      </div>
    </div>
  );
};
