import React, { useRef, useState } from 'react';
import { Popover } from '@mui/material';
import { ReactComponent as IconInfo } from '../../../assets/icons/icon-info.svg';
import styles from './info-button.module.scss';
import classNames from 'classnames';

export const InfoButton: React.FC<{ text: string }> = ({ text }) => {
  const [open, setOpen] = useState<number>(0);
  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const onClick = (): void => {
    anchorRef.current?.focus();

    const buttonPosition = anchorRef.current?.getBoundingClientRect().y ?? 0;
    const upperThird = window.innerHeight / 3;
    const buttonInUpperThird = buttonPosition < upperThird;
    setOpen(buttonInUpperThird ? -1 : 1);
  };

  return (
    <>
      <button
        ref={anchorRef}
        onClick={onClick}
        className={classNames(styles.InfoButton, {
          [styles.Open]: open,
          [styles.UpperThird]: open < 0,
        })}
      >
        <IconInfo />
      </button>
      <Popover
        open={open !== 0}
        onClose={(): void => setOpen(0)}
        classes={{ paper: styles.InfoButtonPopoverPaper }}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: open >= 0 ? 'top' : 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: open >= 0 ? 'bottom' : 'top',
          horizontal: 'center',
        }}
      >
        <div
          className={classNames(styles.InfoButtonPopover, {
            [styles.UpperThird]: open < 0,
          })}
        >
          {text}
        </div>
      </Popover>
    </>
  );
};
