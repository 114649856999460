import * as Scrivito from 'scrivito';
import { TextWidget, TextWidgetClass } from './text-widget-class';
import React, { useContext } from 'react';
import { TextWidgetAttributes } from './text-widget-definitions';
import styles from './text-widget.module.scss';
import classNames from 'classnames';
import { spacingClassName } from '../../utils/scrivito/spacing-definitions';
import { TooltipAttributes, tooltipPattern } from '../../pages/home-page/home-page-tooltips';
import parse, { DOMNode } from 'html-react-parser';
import { InfoButton } from '../../components/controls/info-button/info-button';
import { TooltipObjectAttributes } from '../../objects/tooltip-object';
import { FormikContext } from 'formik';
import { removeIds } from '../../utils/string';

export const TextWidgetComponent: React.FC<{ widget: TextWidget }> = ({ widget }) => {
  const id = widget.get(TextWidgetAttributes.ID) as string;
  const grayBackground = widget.get(TextWidgetAttributes.GRAY) as boolean;

  const formikContext = useContext(FormikContext);

  if (Scrivito.isInPlaceEditingActive()) {
    return (
      <Scrivito.WidgetTag>
        <div
          id={id}
          className={classNames(styles.TextWidget, 'TextWidget', spacingClassName(widget), {
            [styles.TextWidgetSmall]: !!formikContext,
            [styles.TextWidgetGrey]: grayBackground,
          })}
        >
          <Scrivito.ContentTag content={widget} attribute={TextWidgetAttributes.TEXT} />
        </div>
      </Scrivito.WidgetTag>
    );
  }

  const root = Scrivito.Obj.root();
  const tooltipWidgets = (root?.get(TooltipAttributes.ITEMS) as Scrivito.Widget[]) ?? [];
  const tooltips = tooltipWidgets.map((widget) => ({
    name: widget.get(TooltipObjectAttributes.NAME) as string,
    text: widget.get(TooltipObjectAttributes.TEXT) as string,
  }));

  const text = removeIds(Scrivito.resolveHtmlUrls(widget.get(TextWidgetAttributes.TEXT) as string));
  const content = parse(text, {
    replace: (domNode: DOMNode): JSX.Element | undefined => {
      // HTML Element has content
      if ('data' in domNode && domNode.data !== undefined) {
        // Split the content into parts, the IDs will be kept
        // e.g. "hello [id] world" -> "hello ", "[id]", " world"
        const parts = domNode.data.split(tooltipPattern).map((part, index) => {
          const match = tooltipPattern.exec(part)?.[0];

          if (match) {
            // Search for the tooltip entry
            const matchClean = match.substring(1, match.length - 1);
            const content = tooltips.find((item) => item.name === matchClean)?.text;
            if (content) {
              return <InfoButton key={index} text={content} />;
            }
          }

          // If it is not a tooltip id, keep it as it is
          return <span key={index}>{part}</span>;
        });

        // Combine all parts into one JSX element.
        return <>{parts}</>;
      }
      return undefined;
    },
  });

  return (
    <div
      id={id}
      className={classNames(styles.TextWidget, 'TextWidget', spacingClassName(widget), {
        [styles.TextWidgetSmall]: !!formikContext,
        [styles.TextWidgetGrey]: grayBackground,
      })}
    >
      {content}
    </div>
  );
};

Scrivito.provideComponent(TextWidgetClass, TextWidgetComponent);
