/**
 * Extracts the Blob data from a Scrivito.Binary
 * @param url The url of the scrivito binary
 */
import dayjs from 'dayjs';

export const MAX_SIZE_MULTI_FILES = 7_000_000;
export const TIME_FORMAT = 'HH:mm';
export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_FORMAT_INT = 'YYYY-MM-DD';

export const APPVIEW_SEARCH_PARAM = 'appview';

export const fetchBlob = (url: string): Promise<Blob> => {
  return fetch(url, { method: 'GET' })
    .then((data) => {
      return data.blob();
    })
    .then((data) => {
      return data;
    });
};

/**
 * Downloads the given Blob as file with the given file name
 * @param blob The Blob data
 * @param fileName The file name including file extension, that will be used
 */
export const downloadFile = (blob: Blob, fileName: string): void => {
  const encodedUri = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', fileName);
  document.body.appendChild(link); // Required for FF
  link.click();
  document.body.removeChild(link);
};

/**
 * Splits the given file name to the name of the file and its extension.
 * The last dot between the name and extension is removed.
 * @param fileName The file name to split
 */
export const splitFileName = (fileName: string): { name: string; extension: string } => {
  const file = fileName.split('.');
  const extension = file[file.length - 1];
  const name = fileName.substring(0, fileName.lastIndexOf(extension) - 1);
  return { name, extension };
};

/**
 * Checks if the current date is between the given from date and until the given to date.
 * If both are null, the function returns false.
 * @param from The date from or null
 * @param until The date until or null
 */
export const isDateTimeNow = (from: Date | null, until: Date | null): boolean => {
  const now = Date.now();
  if (!from && until) {
    return until.getTime() > now;
  } else if (from && !until) {
    return from.getTime() <= now;
  } else if (from && until) {
    return from.getTime() <= now && until.getTime() > now;
  }
  return false;
};

/**
 * Adds 24 hours to the given date
 * @param d The date to add the hours
 */
export function add24Hours(d: Date): Date {
  return new Date(d.getTime() + 24 * 60 * 60 * 1000);
}

/**
 * Checks if the current date is between the given from date and until the given to date.
 * If both are null, the function returns true.
 * @param from The date from or null
 * @param until The date until or null
 */
export const isSchedulingContentVisible = (from: Date | null, until: Date | null): boolean => {
  const now = new Date();

  const tooEarly = from && from > now;
  const tooLate = until && add24Hours(until) < now;
  return !tooEarly && !tooLate;
};

/**
 * Formats the date to a string using a given format.
 * You can find more information about formatting here: https://day.js.org/docs/en/display/format
 * e.g. "DD.MM.YYYY", "HH:mm:ss" or "D. MMMM, YYYY"
 * @param date The date object
 * @param format The format how the result should look like.
 */
export const formatDate = (date: Date, format = DATE_FORMAT): string => {
  return dayjs(date).format(format);
};

/**
 * Remove html tags from string.
 */
export const removeHtmlTags = (htmlString: string): string => {
  return htmlString.replace(/<[^>]*>?/gm, '');
};

/**
 * Remove html tags from string except:
 * - <wbr>
 * - <br>
 */
export const removeHtmlTagsExceptBreaks = (htmlString: string): string => {
  return htmlString.replace(/(?!<wbr>|<br>|<sub>|<\/sub>|<sup>|<\/sup>)<[^>]*>?/gm, '');
};

/**
 * Whether string contains `&shy` character (manually placed hyphens).
 */
export const hasManualHyphens = (htmlString: string): boolean => {
  return htmlString.search(/\&shy;|<wbr>/gi) > 0;
};

/**
 * Convert sizes in bytes to string with biggest unit.
 * @param sizeInBytes
 * @returns
 */
export const getSizeWithUnit = (sizeInBytes: number): string => {
  if (sizeInBytes >= 1_000_000) {
    return `${(sizeInBytes / 1_000_000).toFixed(2)} MB`;
  } else if (sizeInBytes >= 1_000) {
    return `${(sizeInBytes / 1_000).toFixed(2)} KB`;
  } else {
    return `${sizeInBytes} Bytes`;
  }
};

/**
 * Sanitize filename
 * removes the following:
 * Control characters (0x00–0x1f and 0x80–0x9f)
 * Reserved characters (/, ?, <, >, \, :, *, |, and ")
 * Unix reserved filenames (. and ..)
 * Trailing periods and spaces (for Windows)
 * Windows reserved filenames (CON, PRN, AUX, NUL, COM1, COM2, COM3, COM4, COM5, COM6,
 * COM7, COM8, COM9, LPT1, LPT2, LPT3, LPT4, LPT5, LPT6, LPT7, LPT8, and LPT9)
 * extended by https://github.com/parshap/node-sanitize-filename (already tested)
 * @param input string to be sanitized
 * @param replacement string that replacing invalid characters
 * @returns sanitized filename
 */
export const sanitizeFileName = (input: string, replacement = '-'): string => {
  const illegalRe = /[\/\?<>\\:\*\|"]/g;
  const controlRe = /[\x00-\x1f\x80-\x9f]/g;
  const reservedRe = /^\.+$/;
  const windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;
  const windowsTrailingRe = /[\. ]+$/;

  if (typeof input !== 'string') {
    throw new Error('Input must be string');
  }
  return input
    .replace(/\s/g, replacement)
    .replace(illegalRe, replacement)
    .replace(controlRe, replacement)
    .replace(reservedRe, replacement)
    .replace(windowsReservedRe, replacement)
    .replace(windowsTrailingRe, replacement);
};

/**
 * Check if url string
 * - starts with https
 * - includes `scrvt`
 * - is not top-level (includes .../)
 * @param value
 */
export const isInternalScrivitoSubUrl = (value: string): boolean => {
  const acceptedPatterns = [/^https:\/\/[a-z0-9-]+\.scrvt\.com\//g, /^https:\/\/[a-z0-9-]+\.rheinbahn\.(de|com)\//g];

  for (const pattern of acceptedPatterns) {
    if (value.match(pattern)) {
      return true;
    }
  }

  return false;
};

export const checkAppView = (): boolean => {
  const urlParameter = new URLSearchParams(window.location.search);
  return urlParameter.get(APPVIEW_SEARCH_PARAM) !== null;
};
