import * as Scrivito from 'scrivito';
import { VideoReelSliderWidgetAttributes } from './video-reel-slider-widget-definitions';
import { VideoReelSliderWidget, VideoReelSliderWidgetClass } from './video-reel-slider-widget-class';
import { VideoReelSlider } from '../../components/controls/video-reel-slider/video-reel-slider';
import { VideoObject } from '../../objects';
import { spacingClassName } from '../../utils/scrivito/spacing-definitions';

export const VideoReelSliderWidgetComponent: React.FC<{ widget: VideoReelSliderWidget }> = ({ widget }) => {
  const title = <Scrivito.ContentTag tag="h2" content={widget} attribute={VideoReelSliderWidgetAttributes.TITLE} />;
  const elementId = (widget.get(VideoReelSliderWidgetAttributes.ELEMENT_ID) as string) || undefined;
  const text = <Scrivito.ContentTag tag="p" content={widget} attribute={VideoReelSliderWidgetAttributes.TEXT} />;
  const videos = widget.get(VideoReelSliderWidgetAttributes.VIDEOS) as VideoObject[];
  const videoUrls = videos.map((v) => v.contentUrl());

  return (
    <Scrivito.WidgetTag id={elementId} className="FullWidth">
      <div className={spacingClassName(widget)}>
        <VideoReelSlider title={title} text={text} videoUrls={videoUrls} />
      </div>
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(VideoReelSliderWidgetClass, VideoReelSliderWidgetComponent);
