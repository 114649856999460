import * as Scrivito from 'scrivito';
import { Helmet } from 'react-helmet-async';
import { BasePageAttributes } from '../../../pages/base-page';
import { ImageObject, ImageObjectAttributes } from '../../../objects';
import { stripHtml } from '../../../utils/string';
import { useMetaDataLanguages } from './page-metadata-language';
import { PageMetadataNotFound } from './page-metadata-not-found';
import { useMatomoTagManager } from './page-metadata-matomo';

function useSocialImageData(page: Scrivito.Obj | null): { url: string | null; alt: string | null } {
  const socialImage = page?.get(BasePageAttributes.SOCIAL_MEDIA_IMAGE) as ImageObject;

  let url: string | null = null;
  let alt: string | null = null;

  if (socialImage) {
    const binary = socialImage.get(ImageObjectAttributes.BLOB) as Scrivito.Binary;

    if (binary) {
      url = binary.url();
      alt = socialImage.get(ImageObjectAttributes.ALTERNATIVE_TEXT);
    }
  }

  return { url, alt };
}

export const PageMetaData: React.FC = Scrivito.connect(() => {
  const page = Scrivito.currentPage();
  const { url: socialImageUrl, alt: socialImageAltText } = useSocialImageData(page);
  const languageTags = useMetaDataLanguages();
  const matomo = useMatomoTagManager();
  const routingBasePath = process.env.REACT_APP_SCRIVITO_ROUTING_BASE_PATH || '';

  if (!page) {
    return (
      <PageMetadataNotFound>
        <link rel="apple-touch-icon" sizes="180x180" href={`${routingBasePath}/favicon/apple-touch-icon.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`${routingBasePath}/favicon/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`${routingBasePath}/favicon/favicon-16x16.png`} />
        <link rel="manifest" href={`${routingBasePath}/favicon/site.webmanifest`} />
        <link rel="mask-icon" href={`${routingBasePath}/favicon/safari-pinned-tab.svg`} color="#ffffff" />
        <link rel="shortcut icon" href={`${routingBasePath}/favicon/favicon.ico`} />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-config" content={`${routingBasePath}/favicon/browserconfig.xml`} />
        <meta name="theme-color" content="#e10025" />
      </PageMetadataNotFound>
    );
  }

  const noIndex = page.get(BasePageAttributes.NO_INDEX) as boolean;
  const language = page.language() ?? '';
  const pageUrl = Scrivito.urlFor(page);
  const pageTitle =
    (page.get(BasePageAttributes.META_TITLE) as string) ??
    (page.get(BasePageAttributes.TITLE) as string) ??
    'Rheinbahn';
  let metaDescription = (page.get(BasePageAttributes.META_DESCRIPTION) ?? '') as string;
  metaDescription = stripHtml(metaDescription);

  const canonicalOverride = page.get(BasePageAttributes.CANONICAL_URL) as Scrivito.Link;
  const canonicalUrl = canonicalOverride ? canonicalOverride.url() : pageUrl;

  let socialMediaDescription = (page.get(BasePageAttributes.SOCIAL_MEDIA_DESCRIPTION) ?? '') as string;
  socialMediaDescription = stripHtml(socialMediaDescription);

  const structuredData = page.get(BasePageAttributes.FEATURE_SNIPPETS_RAW) as string;

  return (
    <Helmet>
      <html lang={language} />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>{pageTitle}</title>
      <link rel="apple-touch-icon" sizes="180x180" href={`${routingBasePath}/favicon/apple-touch-icon.png`} />
      <link rel="icon" type="image/png" sizes="32x32" href={`${routingBasePath}/favicon/favicon-32x32.png`} />
      <link rel="icon" type="image/png" sizes="16x16" href={`${routingBasePath}/favicon/favicon-16x16.png`} />
      <link rel="manifest" href={`${routingBasePath}/favicon/site.webmanifest`} />
      <link rel="mask-icon" href={`${routingBasePath}/favicon/safari-pinned-tab.svg`} color="#ffffff" />
      <link rel="shortcut icon" href={`${routingBasePath}/favicon/favicon.ico`} />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-config" content={`${routingBasePath}/favicon/browserconfig.xml`} />
      <meta name="theme-color" content="#e10025" />
      <meta name="title" content={page?.get(BasePageAttributes.META_TITLE) as string} />
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={page?.get(BasePageAttributes.META_TAGS) as string} />
      <meta name="og:type" content="website" />
      <meta name="og:locale" content={language} />
      <meta name="og:url" content={pageUrl} />
      <meta name="og:title" content={page?.get(BasePageAttributes.SOCIAL_MEDIA_TITLE) as string} />
      <meta name="og:description" content={socialMediaDescription} />
      {socialImageUrl && <meta name="og:image" content={socialImageUrl} />}
      <meta name="twitter:title" content={page?.get(BasePageAttributes.SOCIAL_MEDIA_TITLE) as string} />
      <meta name="twitter:description" content={socialMediaDescription} />
      {socialImageUrl && <meta name="twitter:image" content={socialImageUrl} />}
      {socialImageAltText && <meta name="twitter:image:alt" content={socialImageAltText} />}
      <meta name="twitter:card" content="summary_large_image" />
      {noIndex && <meta name="robots" content="noindex" />}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {structuredData?.length > 0 ? <script type="application/ld+json">{structuredData.trim()}</script> : undefined}
      {languageTags}
      {matomo}
    </Helmet>
  );
});
