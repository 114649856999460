import * as Scrivito from 'scrivito';
import { WizardPageWidgetAttributes, WizardPageWidgetId } from './wizard-page-widget-definitions';
import { ValidationInfo } from '../../utils/type.utils';
import { Message, WidgetTitle } from '../../utils/translations';

Scrivito.provideEditingConfig(WizardPageWidgetId, {
  title: WidgetTitle.WIZARD_PAGE,
  properties: Object.values(WizardPageWidgetAttributes),
  attributes: {
    [WizardPageWidgetAttributes.TITLE]: {
      title: 'Titel',
    },
    [WizardPageWidgetAttributes.WIDGETS]: {
      title: 'Inhalt',
    },
    [WizardPageWidgetAttributes.NEXT_BUTTON_TEXT]: {
      title: 'Text für Weiter-Button',
    },
  },
  validations: [
    [
      WizardPageWidgetAttributes.TITLE,
      (value: string): ValidationInfo => {
        if (!value) {
          return {
            severity: 'error',
            message: Message.WIZARD_TITLE_MISSING,
          };
        }
        return undefined;
      },
    ],
  ],
  initialContent: {
    [WizardPageWidgetAttributes.NEXT_BUTTON_TEXT]: 'Weiter',
  },
  titleForContent: (content) => {
    const title = content.get(WizardPageWidgetAttributes.TITLE) as string;
    return 'Wizard Seite: ' + title;
  },
});
