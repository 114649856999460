import * as Scrivito from 'scrivito';
import { configureScrivitoContentBrowser } from './content-browser';
import { configureObjClassForContentType } from './content-types';
import { configureHistory } from './history';
import { RootPageAttributes } from '../pages/base-page/root-page-definitions';

const routingBasePath = process.env.REACT_APP_SCRIVITO_ROUTING_BASE_PATH ?? '';
const SCRIVITO_TENANT = process.env.REACT_APP_SCRIVITO_TENANT ?? '';

const ORIGIN_DE = process.env.REACT_APP_ORIGIN_DE;
const ORIGIN_EN = process.env.REACT_APP_ORIGIN_EN;

export const SITE_ID_DE = 'de';
export const SITE_ID_EN = 'en';

/**
 * This Scrivito callback returns the base URL of a site depending on the current
 * siteId.
 *
 * @param siteId The current site id
 *
 */
const baseUrlForSite = (siteId: string): string => {
  let url = '';
  const siteRoot = Scrivito.Obj.onSite(siteId).root();
  const baseSiteId = siteRoot?.get(RootPageAttributes.BASE_SITE_ID);
  const path = siteRoot?.get(RootPageAttributes.PATH) || '';

  switch (siteId) {
    case SITE_ID_DE:
      url = `${ORIGIN_DE}${routingBasePath}`;
      break;
    case SITE_ID_EN:
      url = `${ORIGIN_EN}${routingBasePath}`;
      break;
  }

  if (!url) {
    url = baseSiteId === SITE_ID_EN ? `${ORIGIN_EN}${routingBasePath}` : `${ORIGIN_DE}${routingBasePath}`;
  }

  url = url.endsWith('/') ? url.slice(0, -1) : url;

  return url + path;
};

/**
 * Maps the current URL to a Site ID.
 *
 * @param url The current url
 */
const siteForUrl = (url: string): { siteId: string; baseUrl: string } => {
  // construct URL object
  const urlObj = new URL(url);
  let siteId = '';

  // Get the first element of the url, so the first url matches the website site path entry.
  let pathname = urlObj.pathname;
  const publicURL = process.env.PUBLIC_URL;
  if (publicURL && publicURL !== '/') {
    pathname = pathname.replace(process.env.PUBLIC_URL, '');
  }
  const firstUrlElement = '/' + (pathname || '').split('/').find((part) => part);

  if (firstUrlElement !== '/') {
    siteId =
      Scrivito.Obj.onAllSites().where(RootPageAttributes.PATH, 'equals', firstUrlElement).first()?.siteId() || '';
  }

  // If the site id could not be found, it could be a page of the main site.
  if (!siteId) {
    switch (urlObj.origin) {
      case ORIGIN_DE:
        siteId = SITE_ID_DE;
        break;
      case ORIGIN_EN:
        siteId = SITE_ID_EN;
        break;
    }
  }

  return { siteId, baseUrl: baseUrlForSite(siteId) };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const configureScrivito = (configOverrides: any = {}): void => {
  const scrivitoConfig = {
    tenant: SCRIVITO_TENANT,
    optimizedWidgetLoading: true,
    adoptUi: true,
    baseUrlForSite,
    siteForUrl,
    ...configOverrides,
  };

  Scrivito.configure(scrivitoConfig);
  configureHistory();
  configureObjClassForContentType();
  configureScrivitoContentBrowser();
};
