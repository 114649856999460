import * as Scrivito from 'scrivito';
import { WizardWidget, WizardWidgetClass } from './wizard-widget-class';
import { WizardWidgetAttributes } from './wizard-widget-definitions';
import { WizardPageWidgetAttributes } from '../wizard-page-widget';
import { useFormContext } from '../form-widget/FormContext';
import { useFormikContext } from 'formik';
import { FormElementBaseAttributes } from '../form-widget';
import { useFormGroupContext } from '../form-group-widget/form-group-widget-context';
import { FormGroupWidgetColors } from '../form-group-widget';
import { Wizard, WizardPageProps } from '../../components/controls/wizard/wizard';
import { getFieldsFromForm } from '../form-widget/form-widget-helpers';
import { useEffect } from 'react';

export const WizardWidgetComponent: React.FC<{ widget: WizardWidget }> = ({ widget }) => {
  const pages = widget.get(WizardWidgetAttributes.PAGES) as Scrivito.Widget[];
  const formContext = useFormContext();
  const formGroupContext = useFormGroupContext();
  const formikContext = useFormikContext();

  useEffect(() => {
    formContext.setSubmitButton(false);
  }, [formContext]);

  const onButtonNext = async (index: number): Promise<boolean> => {
    const currentPage = pages[index];

    // Get the names of this page
    const widgetsInPage = getFieldsFromForm(currentPage);
    const namesInPage = widgetsInPage.map((widget) => widget.get(FormElementBaseAttributes.NAME) as string);

    // Get the errors of this page
    const errorsInForm = await formikContext.validateForm();
    const errorNames = Object.keys(errorsInForm);

    // Check, if this page has errors
    const errorsInPage = namesInPage.filter((name) => errorNames.indexOf(name) >= 0);
    const pageHasErrors = errorsInPage.length > 0;
    if (!pageHasErrors) {
      // Reset errors, so the errors won't be displayed on the next page
      formikContext.setErrors({});
      return true;
    }

    // Touch all form elements in this page
    const newTouched = namesInPage.reduce(
      (touched, name) => Object.assign(touched, { [name]: true }),
      formikContext.touched
    );
    formikContext.setTouched(newTouched);
    return false;
  };

  if (Scrivito.isInPlaceEditingActive()) {
    return (
      <Scrivito.WidgetTag>
        <Scrivito.ContentTag content={widget} attribute={WizardWidgetAttributes.PAGES} />
      </Scrivito.WidgetTag>
    );
  }

  const pageData: WizardPageProps[] = pages.map((page) => ({
    id: page.id(),
    title: page.get(WizardPageWidgetAttributes.TITLE) as string,
    nextButtonText: page.get(WizardPageWidgetAttributes.NEXT_BUTTON_TEXT) as string,
    content: <Scrivito.ContentTag content={page} attribute={WizardPageWidgetAttributes.WIDGETS} />,
  }));

  return (
    <Scrivito.WidgetTag>
      <Wizard
        grayBackground={formGroupContext.backgroundColor === FormGroupWidgetColors.GREY}
        pages={pageData}
        onButtonNext={onButtonNext}
      />
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(WizardWidgetClass, WizardWidgetComponent);
