export const FormActionId = 'FormAction';

export enum FormActionAttributes {
  TITLE = 'title',
  FORM_ACTION_TYPE = 'formActionType',
  SAVE_FORM_SUBMISSION_TO_DATABASE = 'saveFormSubmissionToDatabase',
  APPEND_REFERENCE_NUMBER = 'appendReferenceNumber',
  TARGET_EMAIL_ADDRESSES = 'targetEmailAddresses',
  EMAIL_TEMPLATE_NAME = 'emailTemplateName',
  EMAIL_SUBJECT = 'emailSubject',
  EXTRA_EMAIL_TEXT = 'extraEmailText',
  FROM_ADDRESS_NAME = 'fromAddressName',
  FROM_ADDRESS_ACCOUNT = 'fromAddressAccount',
  CONFIRMATION_EMAIL = 'confirmationEmail',
  CONFIRMATION_EMAIL_ADDRESS_REF = 'confirmationEmailAddressRef',
  CONFIRMATION_EMAIL_TEMPLATE_NAME = 'confirmationEmailTemplateName',
}

export enum emailTemplateNamesWithLabels {
  FormEmail = 'FormEmail',
}

export enum confirmationEmailTemplateNamesWithLabels {
  FormConfirmationEmail = 'FormConfirmationEmail',
}

export const formActionTypesWithLabels: { [key: string]: string } = {
  SendEmail: 'E-Mail versenden',
};
