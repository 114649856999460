import * as Scrivito from 'scrivito';
import { LandingSubPageAttributes, LandingSubPageId } from './landing-sub-page-definitions';
import { basePageEditingConfig } from '../base-page';
import { rootPageInitialContent } from '../base-page/root-page-definitions';
import { Message } from '../../utils/translations';
import { navigationAttributes } from '../landing-page/landing-page-navigation';
import { ValidationInfo } from '../../utils/type.utils';
import { scheduleAttributes, scheduleProperties } from '../properties/schedule';

Scrivito.provideEditingConfig(LandingSubPageId, {
  ...basePageEditingConfig,
  title: 'Unterseite',
  hideInSelectionDialogs: false,
  properties: [...(basePageEditingConfig.properties as string[])],
  propertiesGroups: [
    ...(basePageEditingConfig.propertiesGroups as Array<never>),
    {
      title: 'Hero',
      key: 'page-hero',
      properties: [
        LandingSubPageAttributes.HERO_MEDIA,
        LandingSubPageAttributes.HERO_MEDIA_ALT,
        LandingSubPageAttributes.HERO_MEDIA_TITLE,
      ],
    },
    ...(scheduleProperties as Array<never>),
  ],
  attributes: {
    [LandingSubPageAttributes.HERO_MEDIA]: {
      title: 'Hero (Bild oder Video)',
    },
    [LandingSubPageAttributes.HERO_MEDIA_ALT]: {
      title: 'Alternativ-Text',
    },
    [LandingSubPageAttributes.HERO_MEDIA_TITLE]: {
      title: 'Titel (optional)',
    },
    ...basePageEditingConfig.attributes,
    ...navigationAttributes,
    ...scheduleAttributes,
  },
  validations: [
    [
      LandingSubPageAttributes.HERO_MEDIA_ALT,
      (value: string, { obj }: { obj: Scrivito.Obj }): ValidationInfo => {
        const hero = obj.get(LandingSubPageAttributes.HERO_MEDIA);
        const heroIsSet = !!hero;
        const heroAltIsNotSet = !value;

        if (heroIsSet && heroAltIsNotSet) {
          return {
            severity: 'error',
            message: Message.ALT_TEXT_MISSING,
          };
        }
        return undefined;
      },
    ],
  ],
  initialContent: {
    ...rootPageInitialContent,
    ...basePageEditingConfig.initialContent,
  },
});
