import * as Scrivito from 'scrivito';
import { SchedulingWidgetAttributes, SchedulingWidgetId } from './scheduling-widget-definitions';
import { HelperText } from '../../utils/translations';
import Thumbnail from '../../assets/thumbnails/schedule.svg';

Scrivito.provideEditingConfig(SchedulingWidgetId, {
  title: HelperText.SCHEDULING_TITLE,
  thumbnail: Thumbnail,
  properties: Object.values(SchedulingWidgetAttributes),
  attributes: {
    [SchedulingWidgetAttributes.VISIBLE_FROM]: {
      title: HelperText.SCHEDULING_VISIBLE_FROM,
    },
    [SchedulingWidgetAttributes.VISIBLE_UNTIL]: {
      title: HelperText.SCHEDULING_VISIBLE_UNTIL,
    },
    [SchedulingWidgetAttributes.CHILDREN]: {
      title: HelperText.SCHEDULING_CHILDREN,
    },
  },
  validations: [],
  initialContent: {},
});
