import classNames from 'classnames';
import * as Scrivito from 'scrivito';
import { ImageObjectAttributes } from '../../objects';
import { extractResponsiveData, getAttributeOrFallback } from '../../utils/scrivito/image.utils';
import { ImageWidgetAttributes } from '../image-widget';
import { TextTeaserWidget, TextTeaserWidgetAttributes } from '../text-teaser-widget';
import { TextTeaserGroupWidget, TextTeaserGroupWidgetClass } from './text-teaser-group-widget-class';
import { TextTeaserGroupWidgetAttributes } from './text-teaser-group-widget-definitions';
import styles from './text-teaser-group-widget.module.scss';
import { spacingClassName } from '../../utils/scrivito/spacing-definitions';
import { contentTagOrHtml } from '../../utils/scrivito/widget.utils';

export const TextTeaserGroupWidgetComponent: React.FC<{ widget: TextTeaserGroupWidget }> = ({ widget }) => {
  const teasers = widget.get(TextTeaserGroupWidgetAttributes.TEXT_TEASER) || [];

  return (
    <Scrivito.WidgetTag>
      <div
        className={classNames(
          styles.TextTeaserGroup,
          styles[`text-teaser-count-${teasers.length}`],
          spacingClassName(widget)
        )}
      >
        <div className={classNames(styles.TextTeaserGroupGrid, styles[`text-teaser-count-${teasers.length}`])}>
          {teasers.map((teaser: TextTeaserWidget, index: number) => {
            const to = teaser.get(TextTeaserWidgetAttributes.LINK);
            const hoverText = to?.title() || undefined;

            const image = getAttributeOrFallback(teaser, ImageWidgetAttributes.IMAGE);

            let altText = '';
            let titleText = '';
            let smallestImage = undefined;
            let sourceSet = undefined;
            if (image) {
              altText =
                teaser.get(TextTeaserWidgetAttributes.IMAGE_ALT) || image.get(ImageObjectAttributes.ALTERNATIVE_TEXT);
              titleText = teaser.get(TextTeaserWidgetAttributes.IMAGE_TITLE) || image.get(ImageObjectAttributes.TITLE);

              const responsiveData = extractResponsiveData(image);
              if (responsiveData) {
                smallestImage = responsiveData.src;
                sourceSet = responsiveData.srcSet;
              }
            }

            return (
              <Scrivito.LinkTag
                draggable="false"
                key={index}
                to={Scrivito.isInPlaceEditingActive() ? null : to}
                className={styles.TextTeaser}
              >
                <figure className={styles.TextTeaserFigure}>
                  <div className={styles.TextTeaserImageWrapper}>
                    <Scrivito.ImageTag
                      content={teaser as unknown as Scrivito.Obj}
                      attribute={TextTeaserWidgetAttributes.IMAGE}
                      className={styles.TextTeaserImage}
                      title={titleText}
                      alt={altText}
                      loading="lazy"
                      width={image?.metadata()?.get('width')}
                      height={image?.metadata()?.get('height')}
                      srcSet={sourceSet}
                      sizes={'100vw'}
                      src={smallestImage}
                    />
                    <div className={styles.TextTeaserHoverOverlay} />
                    <div className={styles.TextTeaserHoverText}>
                      <div className={styles.HoverTag}>{hoverText}</div>
                    </div>
                  </div>
                  <figcaption className={styles.TextTeaserSubject}>
                    {contentTagOrHtml(teaser, TextTeaserWidgetAttributes.SUBJECT)}
                  </figcaption>
                </figure>
              </Scrivito.LinkTag>
            );
          })}
        </div>
      </div>
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(TextTeaserGroupWidgetClass, TextTeaserGroupWidgetComponent);
