import * as Scrivito from 'scrivito';
import { WizardWidgetAttributes, WizardWidgetId } from './wizard-widget-definitions';
import { WizardPageWidgetId } from '../wizard-page-widget';
import { FormGroupWidgetId } from '../form-group-widget';

export const WizardWidgetClass = Scrivito.provideWidgetClass(WizardWidgetId, {
  attributes: {
    [WizardWidgetAttributes.PAGES]: ['widgetlist', { only: [WizardPageWidgetId] }],
  },
  onlyInside: [FormGroupWidgetId],
});

export type WizardWidget = InstanceType<typeof WizardWidgetClass>;
