import * as Scrivito from 'scrivito';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useMatomoTagManager } from './page-metadata-matomo';

export const PageMetadataNotFound: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { t } = useTranslation();
  const matomo = useMatomoTagManager();
  const language = Scrivito.Obj.root()?.language() || 'de';

  if (language) {
    const pageTitle = t('errorPageTitle');
    // 404 error page
    return (
      <Helmet>
        <html lang={language} />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>{pageTitle}</title>
        {children}
        {matomo}
      </Helmet>
    );
  }
  return null;
};
