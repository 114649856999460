import * as Scrivito from 'scrivito';
import { FormInputElements } from '../form-widget/form-widget-types';
import { FormColumnWidgetAttributes } from './form-column-widget-definitions';
import { FormGroupWidgetId } from '../form-group-widget';
import { WizardPageWidgetId } from '../wizard-page-widget';

export const FormColumnWidgetClass = Scrivito.provideWidgetClass('FormColumnWidget', {
  attributes: {
    [FormColumnWidgetAttributes.ELEMENTS]: ['widgetlist', { only: FormInputElements }],
  },
  extractTextAttributes: [FormColumnWidgetAttributes.ELEMENTS],
  onlyInside: [FormGroupWidgetId, WizardPageWidgetId],
});

export type FormColumnWidget = InstanceType<typeof FormColumnWidgetClass>;
