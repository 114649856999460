import * as Scrivito from 'scrivito';
import { CheckboxWidgetAttributes, CheckboxWidgetId } from './checkbox-widget-definitions';
import { ValidationInfo } from '../../utils/type.utils';
import { checkValue } from '../../utils/scrivito/validation';
import { Message, WidgetTitle } from '../../utils/translations';
import { titleForContentOfHtml } from '../../utils/scrivito/title-for-content-html';

Scrivito.provideEditingConfig(CheckboxWidgetId, {
  title: WidgetTitle.CHECKBOX,
  titleForContent: (obj) => titleForContentOfHtml(obj, CheckboxWidgetAttributes.LABEL),
  properties: [
    CheckboxWidgetAttributes.LABEL,
    CheckboxWidgetAttributes.VALUE,
    CheckboxWidgetAttributes.CHECKED,
    CheckboxWidgetAttributes.DISABLED,
    CheckboxWidgetAttributes.READONLY,
    CheckboxWidgetAttributes.REQUIRED,
  ],
  attributes: {
    [CheckboxWidgetAttributes.LABEL]: {
      title: 'Label',
      options: {
        toolbar: ['bold', 'italic', 'link', 'subscript', 'superscript'],
        showHtmlSource: false,
      },
    },
    [CheckboxWidgetAttributes.VALUE]: {
      title: 'Wert',
    },
    [CheckboxWidgetAttributes.CHECKED]: {
      title: 'Abgehakt',
    },
    [CheckboxWidgetAttributes.DISABLED]: {
      title: 'Deaktiviert',
    },
    [CheckboxWidgetAttributes.READONLY]: {
      title: 'Read-only',
    },
    [CheckboxWidgetAttributes.REQUIRED]: {
      title: 'Pflichtfeld (Ja/Nein)',
    },
  },
  validations: [
    [CheckboxWidgetAttributes.LABEL, (value: string): ValidationInfo => checkValue(value, Message.LABEL_FIELD_MISSING)],
    [CheckboxWidgetAttributes.VALUE, (value: string): ValidationInfo => checkValue(value, Message.VALUE_FIELD_MISSING)],
  ],
  initialContent: {
    [CheckboxWidgetAttributes.LABEL]: 'Label',
  },
});
