export enum ScheduleAttributes {
  VISIBLE_FROM = 'visibleFrom',
  VISIBLE_UNTIL = 'visibleUntil',
}

export const scheduleAttributes = {
  [ScheduleAttributes.VISIBLE_FROM]: {
    title: 'Anzeigen von',
  },
  [ScheduleAttributes.VISIBLE_UNTIL]: {
    title: 'Anzeigen bis',
  },
};

export const scheduleClassAttributes = {
  [ScheduleAttributes.VISIBLE_FROM]: 'date',
  [ScheduleAttributes.VISIBLE_UNTIL]: 'date',
};

export const scheduleProperties = [
  {
    title: 'Zeitbasierte Einstellungen',
    key: 'page-scheduling',
    properties: Object.values(ScheduleAttributes),
  },
];
