import React from 'react';
import { ButtonComponent } from '../button/button';
import { getNextButtonText } from './wizard-helpers';
import styles from './wizard-footer.module.scss';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../loading-button';
import { useFormContext } from '../../../widgets/form-widget/FormContext';

export interface WizardFooterProps {
  currentIndex: number;
  pageCount: number;
  nextButtonText: string;
  onButtonPrev: () => void;
  onButtonNext: () => void;
}

export const WizardFooter: React.FC<WizardFooterProps> = (props) => {
  const { t } = useTranslation();
  const formContext = useFormContext();
  const isLastPage = props.currentIndex === props.pageCount - 1;
  const nextButtonText = getNextButtonText(props.nextButtonText, isLastPage, t);

  return (
    <div className={styles.WizardFooter}>
      {props.currentIndex > 0 && (
        <ButtonComponent
          className={styles.PrevButton}
          href={null}
          small={false}
          type="button"
          color="secondary"
          content={<span>Zurück</span>}
          onClick={props.onButtonPrev}
        />
      )}
      <div className={styles.FooterSpace} />
      {!isLastPage && (
        <ButtonComponent
          className={styles.NextButton}
          href={null}
          small={false}
          type="button"
          color="primary"
          content={<span>{nextButtonText}</span>}
          onClick={props.onButtonNext}
        />
      )}
      {isLastPage && (
        <LoadingButton
          buttonClassName={styles.NextButton}
          color="info"
          content={<span>{nextButtonText}</span>}
          done={formContext.isDone}
          loading={formContext.isLoading}
        />
      )}
    </div>
  );
};
