import * as Scrivito from 'scrivito';
import {
  TimelineEntryWidgetAttributes,
  TimelineEntryWidgetId,
  TimelineWidgetAttributes,
  TimelineWidgetId,
} from './timeline-widget-definitions';
import { spacingClassAttributes } from '../../utils/scrivito/spacing-definitions';

export const TimelineWidgetClass = Scrivito.provideWidgetClass(TimelineWidgetId, {
  attributes: {
    [TimelineWidgetAttributes.TITLE]: 'string',
    [TimelineWidgetAttributes.ELEMENT_ID]: 'string',
    [TimelineWidgetAttributes.ENTRIES]: ['widgetlist', { only: [TimelineEntryWidgetId] }],
    ...spacingClassAttributes(),
  },
  extractTextAttributes: [TimelineWidgetAttributes.TITLE],
});

export const TimelineEntryWidgetClass = Scrivito.provideWidgetClass(TimelineEntryWidgetId, {
  attributes: {
    [TimelineEntryWidgetAttributes.TITLE]: 'string',
    [TimelineEntryWidgetAttributes.DATE]: 'date',
    [TimelineEntryWidgetAttributes.IMAGE]: ['reference', { only: 'Image' }],
    [TimelineEntryWidgetAttributes.SHORT_TEXT]: 'html',
    [TimelineEntryWidgetAttributes.TEXT]: 'html',
  },
  extractTextAttributes: [
    TimelineEntryWidgetAttributes.TITLE,
    TimelineEntryWidgetAttributes.SHORT_TEXT,
    TimelineEntryWidgetAttributes.TEXT,
  ],
  onlyInside: [TimelineWidgetId],
});

export type TimelineWidget = InstanceType<typeof TimelineWidgetClass>;
export type TimelineEntryWidget = InstanceType<typeof TimelineEntryWidgetClass>;
