import { ReactComponent as IconFile } from '../assets/icons/icon-file.svg';
import { ReactComponent as IconFileArchive } from '../assets/icons/icon-file-archive.svg';
import { ReactComponent as IconFileImg } from '../assets/icons/icon-file-img.svg';
import { ReactComponent as IconFilePDF } from '../assets/icons/icon-file-pdf.svg';
import { ReactComponent as IconFilePPT } from '../assets/icons/icon-file-ppt.svg';
import { ReactComponent as IconFileVideo } from '../assets/icons/icon-file-video.svg';
import { ReactComponent as IconFileZip } from '../assets/icons/icon-file-zip.svg';
import { ReactComponent as IconBlog } from '../assets/icons/icon-blog.svg';
import { ReactComponent as IconFacebook } from '../assets/icons/icon-facebook.svg';
import { ReactComponent as IconInstagram } from '../assets/icons/icon-instagram.svg';
import { ReactComponent as IconLinkedIn } from '../assets/icons/icon-linkedin.svg';
import { ReactComponent as IconTwitter } from '../assets/icons/icon-twitter.svg';
import { ReactComponent as IconYoutube } from '../assets/icons/icon-youtube.svg';
import { ReactComponent as IconInfo } from '../assets/icons/icon-info.svg';
import { ReactComponent as IconMap } from '../assets/icons/icon-map.svg';
import { ReactComponent as IconTicket } from '../assets/icons/icon-ticket.svg';
import { ReactComponent as IconTip } from '../assets/icons/icon-tip.svg';
import { ReactComponent as IconShoppingCart } from '../assets/icons/icon-shopping-cart.svg';
import { ReactComponent as IconStop } from '../assets/icons/icon-stop.svg';
import { ReactComponent as IconAddress } from '../assets/icons/icon-house.svg';
import { ReactComponent as IconPoi } from '../assets/icons/icon-pin.svg';
import { ReactElement } from 'react';
import { splitFileName } from './general.utils';
import { EfaStopType } from '../components/efa/api/efa-api.utils';

export enum Icon {
  FILE = 'file',
  FILE_ARCHIVE = 'file-archive',
  FILE_PDF = 'file-pdf',
  FILE_PPT = 'file-ppt',
  FILE_VIDEO = 'file-video',
  FILE_IMAGE = 'file-img',
  FILE_ZIP = 'file-zip',
  SHOPPING_CART = 'shopping-cart',
  TICKET = 'ticket',
  INFO = 'info',
  MAP = 'map',
  TIP = 'tip',
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  TWITTER = 'twitter',
  YOUTUBE = 'youtube',
  LINKEDIN = 'linkedin',
  BLOG = 'blog',
}

/**
 * Renders the specific icon. If the icon is not available, an empty element will be returned.
 * @param icon The icon to render
 */
export const renderIcon = (icon: Icon): ReactElement => {
  switch (icon) {
    case Icon.FILE:
      return <IconFile />;
    case Icon.FILE_ARCHIVE:
      return <IconFileArchive />;
    case Icon.FILE_PDF:
      return <IconFilePDF />;
    case Icon.FILE_PPT:
      return <IconFilePPT />;
    case Icon.FILE_VIDEO:
      return <IconFileVideo />;
    case Icon.FILE_IMAGE:
      return <IconFileImg />;
    case Icon.FILE_ZIP:
      return <IconFileZip />;
    case Icon.BLOG:
      return <IconBlog />;
    case Icon.FACEBOOK:
      return <IconFacebook />;
    case Icon.INSTAGRAM:
      return <IconInstagram />;
    case Icon.LINKEDIN:
      return <IconLinkedIn />;
    case Icon.TWITTER:
      return <IconTwitter />;
    case Icon.YOUTUBE:
      return <IconYoutube />;
    case Icon.INFO:
      return <IconInfo />;
    case Icon.MAP:
      return <IconMap />;
    case Icon.SHOPPING_CART:
      return <IconShoppingCart />;
    case Icon.TICKET:
      return <IconTicket />;
    case Icon.TIP:
      return <IconTip />;
    default:
      return <></>;
  }
};

/**
 * Renders the specific file icon depending on the file name.
 * If the icon is not available, an empty file will be returned.
 * @param fileName The file name with the file extension
 */
export const renderFileIcon = (fileName: string): ReactElement => {
  const extension = splitFileName(fileName).extension.toLowerCase();
  if (['pdf'].indexOf(extension) !== -1) {
    return <IconFilePDF />;
  } else if (['ppt', 'pptx'].indexOf(extension) !== -1) {
    return <IconFilePPT />;
  } else if (['mp4', 'm4v', 'mpeg', 'mpg', 'mov', 'm2v', 'mkv', 'avi', 'webm'].indexOf(extension) !== -1) {
    return <IconFileVideo />;
  } else if (['jpg', 'jpeg', 'png'].indexOf(extension) !== -1) {
    return <IconFileImg />;
  } else if (['zip'].indexOf(extension) !== -1) {
    return <IconFileZip />;
  } else {
    return <IconFile />;
  }
};

/**
 * Renders the specific efa icon depending on the efa stop type.
 * If the icon is not available, a default will be returned.
 * @param type The efa stop type
 */
export const renderEfaIcon = (type: EfaStopType): ReactElement => {
  switch (type) {
    case EfaStopType.Stop:
      return <IconStop />;
    case EfaStopType.Street:
      return <IconAddress />;
    default:
      return <IconPoi />;
  }
};
