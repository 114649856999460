import { HelperText } from '../../utils/translations';

export enum Error404Attributes {
  TEXT = 'error404Text',
  WIDGETS = 'error404Widgets',
}

export const error404Attributes = {
  [Error404Attributes.TEXT]: {
    title: 'Text für die 404-Seite',
  },
  [Error404Attributes.WIDGETS]: {
    title: 'Weitere Elemente',
    description: HelperText.ERROR_PAGE_WIDGETS,
  },
};

export const error404ClassAttributes = {
  [Error404Attributes.TEXT]: 'string',
  [Error404Attributes.WIDGETS]: 'widgetlist',
};

export const error404InitialContent = {
  [Error404Attributes.TEXT]: 'Die Seite befindet sich auf dem Abstellgleis',
};
