import * as Scrivito from 'scrivito';
import {
  HeadlineWidgetAttributes,
  HeadlineWidgetId,
  HeadlineWidgetTag,
  HeadlineWidgetVariant,
} from './headline-widget-definitions';

export const HeadlineWidgetClass = Scrivito.provideWidgetClass(HeadlineWidgetId, {
  attributes: {
    [HeadlineWidgetAttributes.ID]: 'string',
    [HeadlineWidgetAttributes.TEXT]: 'string',
    [HeadlineWidgetAttributes.COMPONENT]: [
      'enum',
      {
        values: Object.values(HeadlineWidgetTag),
      },
    ],
    [HeadlineWidgetAttributes.VARIANT]: [
      'enum',
      {
        values: Object.values(HeadlineWidgetVariant),
      },
    ],
  },
  extractTextAttributes: [HeadlineWidgetAttributes.TEXT],
});

export type HeadlineWidget = InstanceType<typeof HeadlineWidgetClass>;
