import * as Scrivito from 'scrivito';
import { CheckboxGroupWidgetId } from '../checkbox-group-widget/checkbox-group-widget-definitions';
import { CheckboxWidgetAttributes, CheckboxWidgetId } from './checkbox-widget-definitions';

export const CheckboxWidgetClass = Scrivito.provideWidgetClass(CheckboxWidgetId, {
  attributes: {
    [CheckboxWidgetAttributes.LABEL]: 'html',
    [CheckboxWidgetAttributes.CHECKED]: 'boolean',
    [CheckboxWidgetAttributes.DISABLED]: 'boolean',
    [CheckboxWidgetAttributes.READONLY]: 'boolean',
    [CheckboxWidgetAttributes.REQUIRED]: 'boolean',
    [CheckboxWidgetAttributes.VALUE]: 'string',
  },
  onlyInside: [CheckboxGroupWidgetId],
});

export type CheckboxWidget = InstanceType<typeof CheckboxWidgetClass>;
