import styles from './landing-page-hero.module.scss';

interface LandingPageHeroProps {
  url: string;
  type: 'image' | 'video';
  alt: string;
  title: string;
}

export const LandingPageHero: React.FC<LandingPageHeroProps> = ({ url, type, alt, title }) => {
  if (type === 'image') {
    return (
      <div className={styles.LandingPageHero}>
        <img src={url} alt={alt} title={title} aria-label={alt} />
      </div>
    );
  }

  if (type === 'video') {
    return (
      <div className={styles.LandingPageHero}>
        <video src={url} title={title} aria-label={alt} autoPlay playsInline muted />
      </div>
    );
  }

  return null;
};
