import * as Scrivito from 'scrivito';
import { AppBannerComponent, AppBannerData } from './app-banner';
import { BasePageAttributes } from '../../../pages/base-page';
import { ImageObject } from '../../../objects';
import { checkAppView } from '../../../utils/general.utils';

function hashAppBanner(icon: string, title: string, text: string, urlGoogle: string, urlApple: string): string {
  let hash = 0;
  const rawData = JSON.stringify({
    icon,
    title,
    text,
    urlGoogle,
    urlApple,
  });

  for (let i = 0; i < rawData.length; i++) {
    const charCode = rawData.charCodeAt(i);
    hash = (hash << 5) - hash + charCode;
    hash &= hash;
  }

  return Math.abs(hash).toString();
}

function getDataFromApp(page: Scrivito.Obj | null): AppBannerData | undefined {
  if (page) {
    const icon = page.get(BasePageAttributes.APP_BANNER_ICON) as ImageObject | undefined;
    const title = page.get(BasePageAttributes.APP_BANNER_TITLE) as string;
    const text = page.get(BasePageAttributes.APP_BANNER_TEXT) as string;
    const urlGoogle = page.get(BasePageAttributes.APP_BANNER_URL_GOOGLE) as Scrivito.Link | undefined;
    const urlApple = page.get(BasePageAttributes.APP_BANNER_URL_APPLE) as Scrivito.Link | undefined;
    const pageId = page.contentId() as string;

    if (icon && title && text && (urlGoogle || urlApple)) {
      const hash = hashAppBanner(icon.contentId(), title, text, urlGoogle?.url() ?? '', urlApple?.url() ?? '');

      return {
        icon: <Scrivito.ImageTag content={icon} />,
        title,
        text,
        googleLink: urlGoogle,
        appleLink: urlApple,
        pageId,
        hash,
      };
    }
  }

  return undefined;
}

export const AppBanner: React.FC = Scrivito.connect(() => {
  const page = Scrivito.currentPage();
  let data: AppBannerData | undefined = getDataFromApp(page);
  if (!data) {
    const root = Scrivito.Obj.root();
    data = getDataFromApp(root);
  }

  // do not show app banner if we are within the app itself
  const isAppView = checkAppView();
  if (isAppView) {
    return null;
  }

  return data ? <AppBannerComponent data={data} /> : null;
});
