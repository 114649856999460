import * as Scrivito from 'scrivito';
import {
  IconListItemWidgetAttributes,
  IconListItemWidgetIcons,
  IconListItemWidgetId,
} from './icon-list-item-widget-definitions';
import { IconListWidgetId } from '../icon-list-widget';

export const IconListItemWidgetClass = Scrivito.provideWidgetClass(IconListItemWidgetId, {
  attributes: {
    [IconListItemWidgetAttributes.TEXT]: 'html',
    [IconListItemWidgetAttributes.ICON]: ['enum', { values: Object.values(IconListItemWidgetIcons) }],
  },
  onlyInside: [IconListWidgetId],
});

export type IconListItemWidget = InstanceType<typeof IconListItemWidgetClass>;
