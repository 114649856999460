import * as Scrivito from 'scrivito';
import { IFrameWidgetAttributes, IFrameWidgetId } from './iframe-widget-definitions';
import { ValidationInfo } from '../../utils/type.utils';
import { checkValue } from '../../utils/scrivito/validation';
import { Message, WidgetTitle } from '../../utils/translations';
import Thumbnail from '../../assets/thumbnails/i-frame.svg';

Scrivito.provideEditingConfig(IFrameWidgetId, {
  title: WidgetTitle.IFRAME,
  thumbnail: Thumbnail,
  properties: [IFrameWidgetAttributes.URL],
  attributes: {
    [IFrameWidgetAttributes.URL]: {
      title: 'URL',
    },
  },
  validations: [
    [IFrameWidgetAttributes.URL, (value: string | null): ValidationInfo => checkValue(value, Message.URL_MISSING)],
  ],
  initialContent: {},
});
