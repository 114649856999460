import * as Scrivito from 'scrivito';
import { DownloadWidget, DownloadWidgetClass } from './download-widget-class';
import React, { ElementType, useState } from 'react';
import { DownloadWidgetAttributes } from './download-widget-definitions';
import { downloadFile, fetchBlob } from '../../utils/general.utils';
import { HeadlineWidgetTag } from '../headline-widget';
import { spacingClassName } from '../../utils/scrivito/spacing-definitions';
import { DownloadBoxComponent, DownloadBoxObject } from '../../components/building-blocks/download-box/download-box';

export const DownloadWidgetComponent: React.FC<{ widget: DownloadWidget }> = Scrivito.connect(({ widget }) => {
  const headline = <Scrivito.ContentTag content={widget} attribute={DownloadWidgetAttributes.HEADLINE} />;
  const component = (widget.get(DownloadWidgetAttributes.HEADLINE_COMPONENT) ?? HeadlineWidgetTag.H2) as ElementType;
  const buttonName = <Scrivito.ContentTag content={widget} attribute={DownloadWidgetAttributes.DOWNLOAD_BUTTON_TEXT} />;
  const zipName = widget.get(DownloadWidgetAttributes.DOWNLOAD_ZIP_NAME) || 'rheinbahn.zip';
  const files = widget.get(DownloadWidgetAttributes.FILES) || [];
  const downloadObjects: DownloadBoxObject[] = [];
  const id = widget.get(DownloadWidgetAttributes.ID);
  const [loading, setLoading] = useState<boolean>(false);
  const [done, setDone] = useState<boolean>(false);

  // Add all files to the fileList and create a html <li> item
  if (files.length > 0) {
    files.forEach((file: DownloadWidget) => {
      const blob = file.get('blob') as Scrivito.Binary;
      const fileName = blob.filename();
      downloadObjects.push({
        name: fileName,
        onClick: (): Promise<void> => fetchBlob(blob.url()).then((data) => downloadFile(data, fileName)),
      });
    });
  }

  /**
   * When clicking on the button, download multiple files as zip or the single file
   */
  const onClick = (): void => {
    // if only one file is uploaded, this file will be downloaded
    if (files.length === 1) {
      const file = files[0].get('blob') as Scrivito.Binary;
      const fileName = file.filename();
      fetchBlob(file.url()).then((data) => downloadFile(data, fileName));
    } else {
      setLoading(true);
      // Zip data only on click to avoid importing the library everywhere
      import('jszip')
        .then(async (JSZip) => {
          const zip = new JSZip.default();
          for (const file of files) {
            const blob = file.get('blob') as Scrivito.Binary;
            const fileName = blob.filename();
            const data = await fetchBlob(blob.url());
            zip.file(fileName, data);
          }
          // if there are multiple files uploaded, the files will be downloaded as zip
          zip.generateAsync({ type: 'blob' }).then((content: Blob) => {
            downloadFile(content, zipName);
            setLoading(false);
            setDone(true);
          });
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  };

  return (
    <Scrivito.WidgetTag>
      <DownloadBoxComponent
        downloadObjects={downloadObjects}
        className={spacingClassName(widget)}
        headline={headline}
        headlineComponent={component as ElementType}
        buttonText={buttonName}
        onButtonClick={onClick}
        enableSingleFileDownload={false}
        id={id}
        loading={loading}
        done={done}
      />
    </Scrivito.WidgetTag>
  );
});

Scrivito.provideComponent(DownloadWidgetClass, DownloadWidgetComponent);
