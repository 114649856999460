import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const lang = process.env.REACT_APP_SITE_ID || 'de';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: lang,
    fallbackLng: 'de',
    debug: process.env.NODE_ENV === 'development',
    ns: 'website',
    defaultNS: 'website',
    returnNull: false,
    backend: {
      loadPath: (process.env.PUBLIC_URL || '') + '/locales/{{lng}}-{{ns}}.json',
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })
  .catch((e) => console.error(e));

export default i18n;
