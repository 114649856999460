import styles from './timeline-entry.module.scss';
import dayjs from 'dayjs';
import { useState } from 'react';
import classNames from 'classnames';
import * as Scrivito from 'scrivito';
import { useTranslation } from 'react-i18next';
import { Button, Collapse } from '@mui/material';
import { ResponsiveImageContextProvider } from '../../controls/image/responsive-image/responsive-image.context';

interface TimelineEntryProps {
  title: React.ReactNode;
  date: Date;
  image: React.ReactNode;
  shortText: React.ReactNode;
  text: React.ReactNode;
  hasLongText: boolean;
}

function formatDateHuman(date: Date): string {
  const d = dayjs(date);
  return d.format('D. MMMM');
}

function formatDateISO(date: Date): string {
  const d = dayjs(date);
  return d.format('YYYY-MM-DD');
}

export const TimelineEntry: React.FC<TimelineEntryProps> = ({ title, date, image, shortText, text, hasLongText }) => {
  const [isExpanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const isExpandedOrShort = isExpanded || !hasLongText;
  const editMode = Scrivito.isInPlaceEditingActive();

  return (
    <div className={styles.TimelineEntry}>
      <div className={styles.DateArea}>
        <time dateTime={formatDateISO(date)}>{formatDateHuman(date)}</time>
      </div>
      <div className={styles.ImageArea}>
        <ResponsiveImageContextProvider forceScalable>{image}</ResponsiveImageContextProvider>
      </div>
      <div className={styles.LineArea}>
        <div
          className={classNames(styles.Line, {
            [styles.Expanded]: isExpandedOrShort,
          })}
        />
      </div>
      <div className={styles.TextArea}>
        {title}
        {shortText}

        <Collapse
          className={classNames(styles.TextExpanded, {
            [styles.EditMode]: editMode,
          })}
          in={isExpandedOrShort || editMode}
        >
          {text}
        </Collapse>

        {hasLongText && !editMode && (
          <div className={styles.ExpandLink}>
            {!isExpanded && (
              <Button onClick={(): void => setExpanded(true)}>{t('component.timeline.linkShowMore')}</Button>
            )}
            {isExpanded && (
              <Button onClick={(): void => setExpanded(false)}>{t('component.timeline.linkShowLess')}</Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
