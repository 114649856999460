import * as Scrivito from 'scrivito';
import { BigTeaserWidgetAttributes, BigTeaserWidgetId, ButtonPositions } from './big-teaser-widget-definitions';
import { spacingAttributes, spacingContent, spacingGroup } from '../../utils/scrivito/spacing-definitions';
import { HelperText, WidgetTitle } from '../../utils/translations';
import { formElementIdAttributeValidation } from '../../utils/scrivito/validation';
import BigTeaserThumbnail from '../../assets/thumbnails/big-teaser.svg';

Scrivito.provideEditingConfig(BigTeaserWidgetId, {
  title: WidgetTitle.BIG_TEASER,
  thumbnail: BigTeaserThumbnail,
  properties: [
    BigTeaserWidgetAttributes.LINK,
    BigTeaserWidgetAttributes.IMAGE,
    BigTeaserWidgetAttributes.IMAGE_ALT,
    BigTeaserWidgetAttributes.IMAGE_TITLE,
    BigTeaserWidgetAttributes.HOVER_TEXT,
    BigTeaserWidgetAttributes.BUTTON_POSITION,
    BigTeaserWidgetAttributes.ID,
  ],
  propertiesGroups: [spacingGroup],
  attributes: {
    [BigTeaserWidgetAttributes.LINK]: { title: 'Teaser-Link' },
    [BigTeaserWidgetAttributes.IMAGE]: { title: 'Teaser-Bild' },
    [BigTeaserWidgetAttributes.IMAGE_ALT]: { title: 'Alternativer Teaser-Bild-Text' },
    [BigTeaserWidgetAttributes.IMAGE_TITLE]: { title: 'Teaser-Bild-Titel' },
    [BigTeaserWidgetAttributes.HOVER_TEXT]: { title: 'Hover-Text (optional)' },
    [BigTeaserWidgetAttributes.BUTTON_POSITION]: { title: 'Position des Buttons' },
    [BigTeaserWidgetAttributes.ID]: {
      title: HelperText.ELEMENT_ID,
      description: HelperText.ELEMENT_ID_DESCRIPTION,
    },
    ...spacingAttributes,
  },
  initialContent: {
    [BigTeaserWidgetAttributes.LINK]: new Scrivito.Link({
      url: '#',
      title: 'Button Text',
    }),
    [BigTeaserWidgetAttributes.HOVER_TEXT]: HelperText.TEASER_HOVER_TEXT_DEFAULT,
    [BigTeaserWidgetAttributes.BUTTON_POSITION]: ButtonPositions.RIGHT,
    ...spacingContent,
  },
  validations: [formElementIdAttributeValidation(BigTeaserWidgetAttributes.ID)],
});
