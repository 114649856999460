export const BigTeaserWidgetId = 'BigTeaserWidget';

export enum BigTeaserWidgetAttributes {
  IMAGE = 'image',
  LINK = 'link',
  HOVER_TEXT = 'hoverText',
  BUTTON_POSITION = 'buttonPosition',
  IMAGE_ALT = 'imageAltText',
  IMAGE_TITLE = 'imageTitle',
  ID = 'bigTeaserId',
}

export enum ButtonPositions {
  LEFT = 'Links',
  RIGHT = 'Rechts',
  NONE = 'Ausblenden',
}
