export const FormGroupWidgetId = 'FormGroupWidget';

export enum FormGroupWidgetAttributes {
  LABEL = 'label',
  ELEMENTS = 'elements',
  BACKGROUND_COLOR = 'backgroundColor',
  NAME = 'name',
}

export enum FormGroupWidgetColors {
  WHITE = 'white',
  GREY = 'grey',
}
