import * as Scrivito from 'scrivito';
import { SynonymObjectAttributes, SynonymObjectId } from './synonym-object-definitions';

Scrivito.provideEditingConfig(SynonymObjectId, {
  title: 'Synonyme',
  description: 'Liste mit Synonymen',
  properties: [SynonymObjectAttributes.TITLE, SynonymObjectAttributes.SYNONYMS],
  attributes: {
    [SynonymObjectAttributes.TITLE]: {
      title: 'Title',
      description: 'Bezeichnung für die Synonymliste',
    },
    [SynonymObjectAttributes.SYNONYMS]: {
      title: 'Synonymliste',
    },
  },
  hideInSelectionDialogs: true,
});
