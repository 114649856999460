import * as Scrivito from 'scrivito';
import * as Yup from 'yup';
import {
  invalidEmailMessageForWidget,
  invalidMessageForWidget,
  isFormControlRequired,
  referenceNameForWidget,
  regExpForWidget,
} from '../../shared/form/form-controls-helper-methods';
import { UploadButtonWidgetId } from '../../upload-button-widget/upload-button-widget-definitions';
import { Message } from './../../../utils/translations';
import {
  CheckboxGroupWidgetAttributes,
  CheckboxGroupWidgetId,
} from './../../checkbox-group-widget/checkbox-group-widget-definitions';
import { CheckboxWidgetAttributes } from './../../checkbox-widget/checkbox-widget-definitions';
import { DropdownWidgetId } from './../../dropdown-widget/dropdown-widget-definitions';
import { RadioGroupWidgetId } from './../../radio-group-widget/radio-group-widget-definitions';
import {
  TextInputWidgetAttributes,
  TextInputWidgetId,
  TextInputWidgetTypes,
} from './../../text-input-widget/text-input-widget-definitions';
import { ToggleButtonGroupWidgetId } from './../../toggle-button-group-widget/toggle-button-group-widget-definitions';
import { WagonSelectionWidgetId } from './../../wagon-selection-widget/wagon-selection-widget-definitions';
import { FormElementBaseAttributes } from './../form-widget-definitions';
import { FormikErrors, FormikValues } from 'formik';
import { getSizeWithUnit, MAX_SIZE_MULTI_FILES } from '../../../utils/general.utils';
import { TFunction } from 'i18next';
import { DateTimeWidgetId } from './../../date-time-widget/date-time-widget-definitions';
import { AnyObject } from 'yup';

const defaultLocalizedErrorMessage = Message.INVALID_INPUT;

interface ServerValidationFeedbackField {
  currentValue?: string;
  feedbackCode?: string;
  localizedMessage?: string;
  status?: 'ERROR' | 'WARNING' | 'VALID';
}

interface ServerValidationFeedback {
  [referenceName: string]: ServerValidationFeedbackField;
}

export interface ServerValidation {
  message?: string;
  localizedMessage?: string;
  validationFeedback?: ServerValidationFeedback;
}

/**
 * Transform the server validation object into a map of reference names and their
 * corresponding localized error messages.
 * e.g.
 *  {
 *    "email": "A user with this mail already exists.",
 *    "username": "This username is already in use."
 *  }
 *
 * @param serverValidation the validation object that was sent by the server
 * @returns a map of reference names (of the form controls) and their corresponding
 * localized error message
 */
export const getServerValidationLocalizedErrorMessages = (
  serverValidation: ServerValidation
): { [referenceName: string]: string } => {
  const validationFeedback = serverValidation?.validationFeedback;
  if (!validationFeedback) {
    return {};
  }

  return Object.keys(validationFeedback).reduce((accept, key) => {
    const errorMessage = getServerValidationLocalizedErrorMessageForField(serverValidation, key);
    if (!errorMessage) {
      return accept;
    }

    return { ...accept, [key]: errorMessage };
  }, {} as { [referenceName: string]: string });
};

/**
 * Get the localized message from a server validation object to show
 *  underneath the matching input field.
 *
 * @param serverValidation the validation object that was sent by the server
 * @param referenceName the reference name of the input
 * @param defaultErrorMessage this message is shown if the localized message is not set
 * @returns the localized message for an input field or null if the field is not invalid
 */
const getServerValidationLocalizedErrorMessageForField = (
  serverValidation: ServerValidation,
  referenceName: string,
  defaultErrorMessage: string = defaultLocalizedErrorMessage
): string | null => {
  const field = serverValidation?.validationFeedback?.[referenceName];
  if (!field) {
    return null;
  }

  if (field.status !== 'ERROR') {
    return null;
  }

  return field.localizedMessage ?? defaultErrorMessage;
};

/**
 * Validation if input field is required.
 * @param required Whether input field is required.
 * @param requiredErrorMessage Error message when error field is empty but required.
 */
const checkRequiredString = (
  required: boolean,
  requiredErrorMessage: string
): Yup.StringSchema<string | undefined, AnyObject, string | undefined> => {
  if (required) {
    return Yup.string().required(requiredErrorMessage);
  }
  return Yup.string();
};

/**
 * Generate a yup validation chain out of the properties set in the given form control.
 *
 * @param formControl the form control to generate the validation for
 * @returns a yup validation chain for the given form control
 */
const yupValidationForFormControl = (formControl: Scrivito.Widget): Yup.AnySchema => {
  const formControlType = formControl.objClass();
  const required = isFormControlRequired(formControl);
  const requiredErrorMessage = invalidMessageForWidget(formControl);

  switch (formControlType) {
    // Checkbox Group
    case CheckboxGroupWidgetId: {
      const requiredCheckboxes = (formControl.get(CheckboxGroupWidgetAttributes.ITEMS) as Scrivito.Widget[])
        .filter((checkbox) => checkbox.get(CheckboxWidgetAttributes.REQUIRED))
        .map((checkbox) => checkbox.get(CheckboxWidgetAttributes.VALUE) as string);

      // checks if all elements in target are also in arr (target is a real substring or arr then)
      const checker = (arr: (string | undefined)[], target: string[]): boolean => target.every((v) => arr.includes(v));

      return Yup.array()
        .of(Yup.string())
        .test(formControl.get(FormElementBaseAttributes.NAME) as string, Message.REQUIRED_CHECKBOXES, (value) =>
          checker(value ?? [], requiredCheckboxes)
        );
    }
    // Upload Button
    case UploadButtonWidgetId:
      if (required) {
        return Yup.array().min(1, requiredErrorMessage);
      }
      return Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required(),
          // error is handled by upload button itself
          status: Yup.string().matches(/success/),
        })
      );
    // Dropdown
    case DropdownWidgetId:
    // Radio Group
    case RadioGroupWidgetId:
    // Toggle Button
    case ToggleButtonGroupWidgetId:
    // Wagon Selection
    case WagonSelectionWidgetId: {
      return checkRequiredString(required, requiredErrorMessage);
    }
    // Date/Time Input
    case DateTimeWidgetId: {
      let schema = Yup.string();

      if (required) {
        schema = schema.required(requiredErrorMessage);
      }

      return schema;
    }
    // Text Input
    case TextInputWidgetId: {
      const regExp = regExpForWidget(formControl);
      let schema = Yup.string();

      if (regExp) {
        schema = schema.matches(regExp, requiredErrorMessage);
      }

      if (required) {
        schema = schema.required(requiredErrorMessage);
      }

      if (formControl.get(TextInputWidgetAttributes.TYPE) === TextInputWidgetTypes.EMAIL) {
        // email regex
        const emailRegex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i);
        schema = schema.matches(emailRegex, invalidEmailMessageForWidget(formControl));
      }
      return schema;
    }
    // No validation for default case
    default: {
      throw new Error(`The form control type ${formControlType} has no validation chain.`);
    }
  }
};

/**
 * Create a Yup validation schema out of all visible form control widgets.
 */
export const generateValidationSchema = (formControlWidgets: Scrivito.Widget[]): Yup.AnySchema => {
  const yupObjectSpec = formControlWidgets.reduce((accept, formControl) => {
    const referenceName = referenceNameForWidget(formControl);
    const yupValidation = yupValidationForFormControl(formControl);

    return { ...accept, [referenceName]: yupValidation };
  }, {});

  return Yup.object(yupObjectSpec);
};

/**
 * Validates the upload files if the total size is too big or if there are still pending uploads.
 * @param values The formik values
 * @param t The translation function
 */
export const validateUploadFiles = (values: FormikValues, t: TFunction): FormikErrors<{ [key: string]: string }> => {
  const errors: { [key: string]: string } = {};
  const errorKeys: string[] = [];
  const pendingKeys: string[] = [];
  let totalFileSize = 0;
  // Iterate over all form controls
  for (const key in values) {
    const result = values[key];
    // Check if there are upload buttons with the size property
    if (typeof result !== 'string' && result.length > 0 && result[0].hasOwnProperty('size')) {
      result.forEach((value: { size: number; status: 'pending' | 'success' | 'error' }) => {
        // Add file size to total size, add key to error array
        totalFileSize += value.size;
        errorKeys.push(key);
        if (value.status === 'pending') {
          pendingKeys.push(key);
        }
      });
    }
  }
  // Check if there are pending files
  if (pendingKeys.length > 0) {
    pendingKeys.forEach((key) => {
      // Set error for each upload button with pending files
      errors[key] = t('component.uploadButton.errorPending');
    });
  }

  // Check if all files are bigger than the allowed total file size
  if (totalFileSize > MAX_SIZE_MULTI_FILES) {
    errorKeys.forEach((key) => {
      // Set error for each upload button
      errors[key] = t('component.uploadButton.errorMultipleFilesTooBig') + getSizeWithUnit(MAX_SIZE_MULTI_FILES);
    });
  }
  return errors;
};
