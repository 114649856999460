import React from 'react';
import * as Scrivito from 'scrivito';
import { PageTemplate } from '../page-template/page-template';
import styles from './page-error404.module.scss';
import { ReactComponent as Rocket } from '../../../assets/img/rheinbahn-rocket.svg';
import { Error404Attributes } from '../../../pages/home-page/home-page-404';

export const PageError404: React.FC = Scrivito.connect(() => {
  const root = Scrivito.Obj.root();

  return (
    <PageTemplate hideBreadcrumb>
      <div>
        {/* Limits the width */}
        <div>
          <div className={styles.PageError404}>
            <h1>404</h1>
            <Scrivito.ContentTag tag={'p'} content={root} attribute={Error404Attributes.TEXT} />
            <Rocket />
          </div>
        </div>
      </div>
      <Scrivito.ContentTag content={root} attribute={Error404Attributes.WIDGETS} />
    </PageTemplate>
  );
});
