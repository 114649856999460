import * as Scrivito from 'scrivito';
import { SearchResult } from '../../components/search/search-function';

export const DEFAULT_RESULT_LIMIT = 10;

// main query for search with tags
export const getSearchResults = (
  path: string,
  searchableObjects: string[],
  tags: string[],
  limit = DEFAULT_RESULT_LIMIT
): SearchResult => {
  let result = Scrivito.Obj.where('_path', 'startsWith', path)
    .and('_objClass', 'equals', searchableObjects)
    .and('_restriction', 'equals', null)
    .andNot('visibleFrom', 'isGreaterThan', new Date())
    .andNot('visibleUntil', 'isLessThan', new Date())
    .and('noIndex', 'equals', false)
    .order('pressReleaseDate', 'desc');

  // search with tags
  if (tags.length > 0) {
    result = result.and('tags', 'matches', tags);

    return {
      items: result.take(limit),
      total: result.count(),
    };
  }
  // search without tags
  return {
    items: result.take(limit),
    total: result.count(),
  };
};

/**
 * Checks if any page has the given tags
 * @param path The path of the overview page
 * @param searchableObjects The pages to search in
 * @param tags Tags that should be added to the query
 */
export const hasResults = (path: string, searchableObjects: string[], tags: string[]): boolean => {
  const result = getSearchResults(path, searchableObjects, tags, DEFAULT_RESULT_LIMIT);
  return result.total > 0;
};

/**
 * Creates a filter query string separated by commas.
 * If the current filters already have the given filter, it will be removed
 * otherwise it will be added.
 * @param currentFilters The current active url filters
 * @param filter The filter to add or remove
 */
export const addOrRemoveFilter = (currentFilters: string[], filter: string): string => {
  let filterQuery = currentFilters;
  if (filterQuery.indexOf(filter) === -1) {
    filterQuery.push(filter);
  } else {
    filterQuery = filterQuery.filter((item) => item !== filter);
  }
  return filterQuery.join(',');
};
