import * as Scrivito from 'scrivito';
import {
  confirmationEmailTemplateNamesWithLabels,
  emailTemplateNamesWithLabels,
  FormActionAttributes,
  FormActionId,
  formActionTypesWithLabels,
} from './form-action-definitions';

export const FormAction = Scrivito.provideObjClass(FormActionId, {
  attributes: {
    [FormActionAttributes.TITLE]: 'string',
    [FormActionAttributes.FORM_ACTION_TYPE]: ['enum', { values: Object.keys(formActionTypesWithLabels) }],
    [FormActionAttributes.SAVE_FORM_SUBMISSION_TO_DATABASE]: 'boolean',
    [FormActionAttributes.APPEND_REFERENCE_NUMBER]: 'boolean',
    [FormActionAttributes.TARGET_EMAIL_ADDRESSES]: 'stringlist',
    [FormActionAttributes.EMAIL_TEMPLATE_NAME]: ['enum', { values: Object.keys(emailTemplateNamesWithLabels) }],
    [FormActionAttributes.EMAIL_SUBJECT]: 'string',
    [FormActionAttributes.EXTRA_EMAIL_TEXT]: 'html',
    [FormActionAttributes.FROM_ADDRESS_NAME]: 'string',
    [FormActionAttributes.FROM_ADDRESS_ACCOUNT]: 'string',
    [FormActionAttributes.CONFIRMATION_EMAIL]: 'boolean',
    [FormActionAttributes.CONFIRMATION_EMAIL_ADDRESS_REF]: 'string',
    [FormActionAttributes.CONFIRMATION_EMAIL_TEMPLATE_NAME]: [
      'enum',
      { values: Object.keys(confirmationEmailTemplateNamesWithLabels) },
    ],
  },
});
