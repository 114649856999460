import * as Scrivito from 'scrivito';
import { BreadcrumbWidgetAttributes, BreadcrumbWidgetId } from './breadcrumb-widget-definitions';
import { spacingClassAttributes } from '../../utils/scrivito/spacing-definitions';

export const BreadcrumbWidgetClass = Scrivito.provideWidgetClass(BreadcrumbWidgetId, {
  attributes: {
    [BreadcrumbWidgetAttributes.MAX_ITEMS]: 'integer',
    [BreadcrumbWidgetAttributes.ITEMS_BEFORE_COLLAPSE]: 'integer',
    [BreadcrumbWidgetAttributes.ITEMS_AFTER_COLLAPSE]: 'integer',
    ...spacingClassAttributes(),
  },
});

export type BreadcrumbWidget = InstanceType<typeof BreadcrumbWidgetClass>;
