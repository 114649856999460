import * as Scrivito from 'scrivito';
import { BasePage, BasePageAttributes } from '../base-page';
import { PressReleaseAttributes, PressReleaseId } from './press-release-definitions';
import { PressReleaseButtonWidgetId } from './button';
import { HeadlineWidgetTag } from '../../widgets/headline-widget';
import { scheduleClassAttributes } from '../properties/schedule';

export const PressReleaseObjectClass = Scrivito.provideObjClass(PressReleaseId, {
  extend: BasePage,
  attributes: {
    [PressReleaseAttributes.DATE]: 'date',
    [PressReleaseAttributes.HEADLINE]: 'string',
    [PressReleaseAttributes.HEADLINE_ELEMENT]: ['enum', { values: [HeadlineWidgetTag.H1, HeadlineWidgetTag.H2] }],
    [PressReleaseAttributes.SUBLINE]: 'string',
    [PressReleaseAttributes.BUTTONS]: ['widgetlist', { only: PressReleaseButtonWidgetId }],
    ...(scheduleClassAttributes as object),
  },
  extractTextAttributes: [PressReleaseAttributes.HEADLINE, PressReleaseAttributes.SUBLINE, BasePageAttributes.BODY],
});

export type PressRelease = InstanceType<typeof PressReleaseObjectClass>;
