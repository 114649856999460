import * as Scrivito from 'scrivito';
import { KpiTileWidget, KpiTileWidgetClass } from './kpi-tile-widget-class';

/**
 * Placeholder widget, get rendered by KpiTileGroupWidget
 */
export const KpiTileWidgetComponent: React.FC<{ widget: KpiTileWidget }> = () => {
  return <Scrivito.WidgetTag />;
};

Scrivito.provideComponent(KpiTileWidgetClass, KpiTileWidgetComponent);
