import ToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton';
import styles from './toggle-button.module.scss';

export interface ToggleButtonComponentProps extends ToggleButtonProps {
  name?: string;
  label: string;
}

export const ToggleButtonComponent: React.FC<ToggleButtonComponentProps> = (props) => {
  const { label, ...rest } = props;
  const formProps = { 'data-value-text': label };

  return (
    <ToggleButton {...rest} name={props.name} className={styles.ToggleButton} {...formProps}>
      <span className={styles.LabelPlaceholder}>{props.label}</span>
      <span className={styles.Label}>{props.label}</span>
    </ToggleButton>
  );
};
