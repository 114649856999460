import * as Scrivito from 'scrivito';
import { TooltipObjectAttributes, TooltipObjectId } from './tooltip-object-definitions';
import { HelperText } from '../../utils/translations';

Scrivito.provideEditingConfig(TooltipObjectId, {
  title: 'Tooltip',
  properties: [TooltipObjectAttributes.NAME, TooltipObjectAttributes.TEXT],
  attributes: {
    [TooltipObjectAttributes.NAME]: {
      title: 'ID',
      description: HelperText.TOOLTIP_NAME,
    },
    [TooltipObjectAttributes.TEXT]: {
      title: 'Text',
      description: HelperText.TOOLTIP_TEXT,
    },
  },
  hideInSelectionDialogs: true,
  validations: [],
  initialContent: {},
});
