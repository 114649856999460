import { RadioGroupWidgetAttributes } from './../../radio-group-widget/radio-group-widget-definitions';
import { TextInputWidgetAttributes } from './../../text-input-widget/text-input-widget-definitions';
import { WagonSelectionWidgetAttributes } from './../../wagon-selection-widget/wagon-selection-widget-definitions';
// eslint-disable-next-line max-len
import { ToggleButtonGroupWidgetAttributes } from './../../toggle-button-group-widget/toggle-button-group-widget-definitions';
import * as Scrivito from 'scrivito';
import { CheckboxGroupWidgetAttributes } from '../../checkbox-group-widget';
import { CheckboxWidget, CheckboxWidgetAttributes } from '../../checkbox-widget';
import { referenceNameForWidget } from '../../shared/form/form-controls-helper-methods';
import { DateTimeWidgetAttributes, DateTimeWidgetTypes } from './../../date-time-widget/date-time-widget-definitions';
import dayjs from 'dayjs';
import { DATE_FORMAT, TIME_FORMAT } from '../../../utils/general.utils';

/**
 * Each form control type can have a different initial value.
 *
 * @param formControl the form control to get the initial value for
 * @returns the appropriate initial value for the given form control
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialValueForFormControl = (formControl: Scrivito.Widget): any => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const initialValueForFormControlType: { [key: string]: any } = {
    ['CheckboxGroupWidget']:
      (formControl.get(CheckboxGroupWidgetAttributes.ITEMS) as CheckboxWidget[])
        ?.filter((checkbox) => !!checkbox.get(CheckboxWidgetAttributes.CHECKED))
        ?.map((checkbox) => checkbox.get(CheckboxWidgetAttributes.VALUE)) || [],
    ['DropdownWidget']: '',
    ['ToggleButtonGroupWidget']: formControl.get(ToggleButtonGroupWidgetAttributes.VALUE) || '',
    // Check if date time picker has now as initial value (set it depending on date/time picker),
    // otherwise set the given initial value
    ['DateTimeWidget']: formControl.get(DateTimeWidgetAttributes.NOW)
      ? dayjs().format(
          formControl.get(DateTimeWidgetAttributes.TYPE) === DateTimeWidgetTypes.DATE ? DATE_FORMAT : TIME_FORMAT
        )
      : formControl.get(DateTimeWidgetAttributes.VALUE) || '',
    ['TextInputWidget']: formControl.get(TextInputWidgetAttributes.VALUE) || '',
    ['RadioGroupWidget']: (formControl.get(RadioGroupWidgetAttributes.DEFAULT_SELECTED_RADIO) as string) || '',
    ['WagonSelectionWidget']: formControl.get(WagonSelectionWidgetAttributes.INITIAL_VALUE) || '',
    ['UploadButtonWidget']: [],
  };

  const objClass = formControl.objClass();

  return initialValueForFormControlType[objClass];
};

/**
 * Create the initialValues object for formik.
 * If no initial values are set, the fields are not set as "touched" when submitting.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const generateInitialValues = (formControlWidgets: Scrivito.Widget[]): { [key: string]: any } => {
  return formControlWidgets.reduce((accept, formControl) => {
    const referenceName = referenceNameForWidget(formControl);

    const initialValue = initialValueForFormControl(formControl);

    return { ...accept, [referenceName]: initialValue };
  }, {});
};
