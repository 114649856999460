import * as Scrivito from 'scrivito';
import { TextTeaserWidgetId } from '../text-teaser-widget';
import { TextTeaserGroupWidgetAttributes, TextTeaserGroupWidgetId } from './text-teaser-group-widget-definitions';
import { spacingClassAttributes } from '../../utils/scrivito/spacing-definitions';

export const TextTeaserGroupWidgetClass = Scrivito.provideWidgetClass(TextTeaserGroupWidgetId, {
  attributes: {
    [TextTeaserGroupWidgetAttributes.TEXT_TEASER]: ['widgetlist', { only: TextTeaserWidgetId }],
    ...spacingClassAttributes(),
  },
  extractTextAttributes: [TextTeaserGroupWidgetAttributes.TEXT_TEASER],
});

export type TextTeaserGroupWidget = InstanceType<typeof TextTeaserGroupWidgetClass>;
