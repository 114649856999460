import * as Scrivito from 'scrivito';
import React, { ChangeEvent, ReactNode, SyntheticEvent, useState } from 'react';
import style from './search-form.module.scss';
import classNames from 'classnames';
import { Autocomplete } from '@mui/material';
import { ButtonComponent } from '../../controls/button/button';
import { TextInputComponent } from '../../controls/text-input/text-input';
import { ReactComponent as IconClose } from '../../../assets/icons/icon-close.svg';
import styles from '../../controls/search-result/search-result.module.scss';
import Highlighter from 'react-highlight-words';
import { getAutocompleteSuggestions, MAX_SEARCH_SUGGESTIONS } from '../search-utils.scrivito';
import { useTranslation } from 'react-i18next';

export interface SearchFormProps {
  searchButtonLabel: string;
  className?: string;
  /** Query params provided by scrivito */
  params?: {
    q?: string;
  };
}

export const SearchFormComponent: React.FC<SearchFormProps> = ({ className, params, searchButtonLabel }) => {
  const [query, setQuery] = useState(params?.q || '');
  const [open, setOpen] = useState(false);
  const [suggestionQuery, setSuggestionQuery] = useState<string[]>([]);
  const { t } = useTranslation(); // useTranslation throws an error while testing

  // Sets the search parameter to the url
  const setSearchParameter = (q: string): void => {
    Scrivito.navigateTo(() => Scrivito.currentPage(), {
      params: { q },
    });
  };

  // Handles the form submit on button click
  const searchFormSubmitHandler = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setOpen(false);
    setSearchParameter(query);
  };

  // Handles the input change on the autocomplete (clear button or keyboard selection)
  const handleAutocompleteChange = (_: SyntheticEvent, value: string | null, reason: string): void => {
    if (reason === 'clear') {
      setQuery('');
    } else if (value) {
      setQuery(value);
      setSearchParameter(value);
    }
    setOpen(false);
  };

  // Handles the input change on the text field
  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setQuery(e.target.value);
    setOpen(true);
    Scrivito.load(() => {
      const newSuggestions = getAutocompleteSuggestions(e.target.value, MAX_SEARCH_SUGGESTIONS);
      setSuggestionQuery(newSuggestions);
    });
  };

  // Starts search on enter
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      setSearchParameter(query);
      setOpen(false);
    }
  };

  // Handles the click on an autocomplete option
  const handleOptionClick = (e: React.SyntheticEvent<HTMLLIElement>): void => {
    e.preventDefault();
    const child = e.currentTarget.firstElementChild;
    if (child) {
      const value = (child as HTMLElement).innerText;
      setQuery(value);
      setSearchParameter(value);
    }
    setOpen(false);
  };

  return (
    <form
      className={classNames(style.SearchForm, className)}
      onSubmit={(e): void => {
        e.preventDefault();
        searchFormSubmitHandler(e);
      }}
    >
      <Autocomplete
        id="search-autocomplete"
        freeSolo
        autoSelect
        open={open}
        onBlur={(): void => setOpen(false)}
        className={style.SearchInput}
        onChange={handleAutocompleteChange}
        value={query}
        defaultValue={query}
        ListboxProps={{
          className: style.SearchFormDropdownMenu,
        }}
        clearIcon={query.length > 0 ? <IconClose className={style.CloseIcon} /> : <></>}
        clearText={t('search.clearText') as string}
        disablePortal
        noOptionsText={t('search.noAutoCompleteSuggestions') as string}
        options={suggestionQuery}
        renderOption={(props, option): ReactNode => (
          <li {...props} onClick={handleOptionClick}>
            <Highlighter
              highlightClassName={styles.HighlightKeyword}
              searchWords={[query]}
              autoEscape={true}
              textToHighlight={option}
            />
          </li>
        )}
        renderInput={(params): JSX.Element => (
          <TextInputComponent
            {...params}
            onChange={handleChange}
            onKeyUp={handleKeyDown}
            autoFocus
            placeholder={t('search.searchInputPlaceholder')}
            required
          />
        )}
      />
      <ButtonComponent
        // onClick={searchFormSubmitHandler}
        type="submit"
        href={null}
        color={'info'}
        content={<span>{searchButtonLabel}</span>}
        small={false}
      />
    </form>
  );
};

export const SearchForm = Scrivito.connect(SearchFormComponent);
