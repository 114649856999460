export const breakpoints = {
  DESKTOP_XXL: 2400,
  DESKTOP_XL: 1920,
  DESKTOP_L: 1440,
  DESKTOP_M: 1200,
  DESKTOP_S: 1024,
  DESKTOP_XS: 900,
  DESKTOP_XXS: 720,
  MOBILE_XL: 600,
  MOBILE_L: 480,
  MOBILE_M: 375,
};
