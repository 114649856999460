import styles from './video-reel-slider.module.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as IconArrowLeft } from '../../../assets/icons/icon-arrow-left-full.svg';
import { ReactComponent as IconArrowRight } from '../../../assets/icons/icon-arrow-right-full.svg';
import variables from '../../../assets/scss/exports.module.scss';
import { useTranslation } from 'react-i18next';

function moveSlider(slider: HTMLDivElement | null, forwards: boolean): void {
  if (!slider) {
    return;
  }

  const scrolled = slider.scrollLeft;
  const itemWidth = (slider.children[0]?.clientWidth ?? 0) + 24; // 24px gap
  const currentItem = Math.floor(scrolled / itemWidth);

  if (!forwards && currentItem > 0) {
    slider.scrollTo({
      left: itemWidth * (currentItem - 1),
      behavior: 'smooth',
    });
  } else if (forwards) {
    slider.scrollTo({
      left: itemWidth * (currentItem + 1),
      behavior: 'smooth',
    });
  }
}

interface VideoReelSliderProps {
  title: React.ReactNode;
  text: React.ReactNode;
  videoUrls: string[];
}

export const VideoReelSlider: React.FC<VideoReelSliderProps> = (props) => {
  const { t } = useTranslation();
  const refTitle = useRef<HTMLDivElement | null>(null);
  const refVideos = useRef<HTMLDivElement | null>(null);
  const [videoPadding, setVideoPadding] = useState(0);

  const leftButtonText = t('component.videoReelSlider.buttonLeft');
  const rightButtonText = t('component.videoReelSlider.buttonRight');
  const onLeftButton = useCallback(() => moveSlider(refVideos.current, false), [refVideos]);
  const onRightButton = useCallback(() => moveSlider(refVideos.current, true), [refVideos]);

  const calculatePadding = useCallback(
    (windowWidth: number) => {
      const titleWidth = refTitle.current?.children[0]?.clientWidth ?? 0;
      const maxWidthPageExtract = /^(\d+)px$/.exec(variables['maxWidthPage']);
      const maxWidthPage = maxWidthPageExtract ? +maxWidthPageExtract[1] : 0;
      return (Math.min(windowWidth, maxWidthPage) - titleWidth) / 2;
    },
    [refTitle]
  );

  useEffect(() => {
    const onResize = (): void => {
      const windowWidth = document.body.clientWidth;
      setVideoPadding(calculatePadding(windowWidth));
    };

    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [calculatePadding]);

  return (
    <div className={styles.VideoReelSlider}>
      <div ref={refTitle} className={styles.Title}>
        {props.title}
      </div>
      <div className={styles.Text}>{props.text}</div>

      <div ref={refVideos} className={styles.Videos} style={{ padding: `0 ${videoPadding}px` }}>
        {props.videoUrls.map((url, index) => (
          <video key={index} src={url} controls />
        ))}
      </div>

      <div className={styles.Controls}>
        <button onClick={onLeftButton} title={leftButtonText} aria-label={leftButtonText}>
          <IconArrowLeft />
        </button>
        <button onClick={onRightButton} title={rightButtonText} aria-label={rightButtonText}>
          <IconArrowRight />
        </button>
      </div>
    </div>
  );
};
