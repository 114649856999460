import * as Scrivito from 'scrivito';
import {
  columnCountAttributes,
  ColumnCountAttributes,
  columnCountInitialContent,
} from '../../utils/scrivito/column-count-definitions';
import { HelperText, Message, WidgetTitle } from '../../utils/translations';
import {
  FormElementBaseAttributes,
  formElementBaseAttributesDefinition,
  formElementBaseAttributesValidation,
  FormElementBaseGroupLabelAttributes,
  groupLabelFormElementBaseClassAttributesDefinition,
  groupLabelFormElementBaseClassAttributesValidation,
} from '../form-widget/form-widget-definitions';
import { CheckboxGroupWidgetAttributes, CheckboxGroupWidgetId } from './checkbox-group-widget-definitions';
import CheckboxGroupThumbnail from '../../assets/thumbnails/checkbox-group.svg';
import { CheckboxWidgetAttributes, CheckboxWidgetClass } from '../checkbox-widget';

const minRequiredItems = 2;

Scrivito.provideEditingConfig(CheckboxGroupWidgetId, {
  title: WidgetTitle.CHECKBOX_GROUP,
  thumbnail: CheckboxGroupThumbnail,
  titleForContent: (obj) =>
    `${WidgetTitle.CHECKBOX_GROUP}: ${
      (obj.get(FormElementBaseAttributes.NAME) as string) || Message.FORM_INPUT_NAME_MISSING
    }`,
  properties: [
    ...Object.values(FormElementBaseGroupLabelAttributes),
    ...Object.values(CheckboxGroupWidgetAttributes),
    ColumnCountAttributes.COLUMN_COUNT,
  ],
  propertiesGroups: [
    {
      title: 'Validation',
      properties: [...Object.values(FormElementBaseAttributes)],
    },
  ],

  attributes: {
    ...(groupLabelFormElementBaseClassAttributesDefinition as object),
    [CheckboxGroupWidgetAttributes.ITEMS]: {
      title: HelperText.CHECKBOX_ITEMS,
    },
    ...columnCountAttributes,
    ...(formElementBaseAttributesDefinition as object),
  },
  validations: [
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(formElementBaseAttributesValidation as Array<any>),
    ...groupLabelFormElementBaseClassAttributesValidation,
  ],
  initialContent: {
    [CheckboxGroupWidgetAttributes.ITEMS]: [...Array(minRequiredItems)].map(
      (_, index) =>
        new CheckboxWidgetClass({
          [CheckboxWidgetAttributes.LABEL]: `${HelperText.CHECKBOX_GROUP_LABEL_INITIAL_VALUE} ${index + 1}`,
          [CheckboxWidgetAttributes.VALUE]: String(index),
        })
    ),
    ...columnCountInitialContent,
  },
});
