import * as Scrivito from 'scrivito';
import { ToggleButtonWidgetAttributes, ToggleButtonWidgetId } from './toggle-button-widget-definitions';
import { ToggleButtonGroupWidgetId } from '../toggle-button-group-widget/toggle-button-group-widget-definitions';

export const ToggleButtonWidgetClass = Scrivito.provideWidgetClass(ToggleButtonWidgetId, {
  attributes: {
    [ToggleButtonWidgetAttributes.LABEL]: 'string',
    [ToggleButtonWidgetAttributes.VALUE]: 'string',
    [ToggleButtonWidgetAttributes.DISABLED]: 'boolean',
  },
  extractTextAttributes: [ToggleButtonWidgetAttributes.LABEL],
  onlyInside: [ToggleButtonGroupWidgetId],
});

export type ToggleButtonWidget = InstanceType<typeof ToggleButtonWidgetClass>;
