import * as Scrivito from 'scrivito';
import { stripHtml } from '../string';

/**
 * Helper function to crop html scrivito fields to use as `titleForContent`.
 * @param obj - Scrivito.Obj
 * @param attr - attribute of `html` field.
 * @param maxTitleChars - length of text to be cropped. Default 50 chars.
 * @returns cropped string of html field.
 */
export const titleForContentOfHtml = (obj: Scrivito.Obj, attr: string, maxTitleChars = 50): string => {
  const raw: string = obj.get(attr) as string;
  // replace tags
  const removedTags = stripHtml(raw);
  // truncate title to maxTitleChars
  if (removedTags.length > maxTitleChars) {
    return removedTags.slice(0, maxTitleChars) + '...';
  }
  return removedTags;
};
