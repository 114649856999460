export const FormInputElements = [
  'TextInputWidget',
  'DateTimeWidget',
  'CheckboxGroupWidget',
  'RadioGroupWidget',
  'DropdownWidget',
  'ToggleButtonGroupWidget',
  'WagonSelectionWidget',
  'UploadButtonWidget',
];

// Elements that can be inside a form group
export const FormGroupWidgets = [...FormInputElements, 'TextWidget', 'FormColumnWidget', 'WizardWidget'];
