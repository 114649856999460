import * as Scrivito from 'scrivito';
import {
  IconListWidgetAttributes,
  IconListWidgetIcons,
  IconListWidgetId,
  IconListWidgetHeadlineHierarchy,
} from './icon-list-widget-definitions';
import { IconListItemWidgetId } from '../icon-list-item-widget/icon-list-item-widget-definitions';
import { spacingClassAttributes } from '../../utils/scrivito/spacing-definitions';

export const IconListWidgetClass = Scrivito.provideWidgetClass(IconListWidgetId, {
  attributes: {
    [IconListWidgetAttributes.HEADLINE]: 'string',
    [IconListWidgetAttributes.HEADLINE_HIERARCHY]: ['enum', { values: Object.values(IconListWidgetHeadlineHierarchy) }],
    [IconListWidgetAttributes.ID]: 'string',
    [IconListWidgetAttributes.ICON]: ['enum', { values: Object.values(IconListWidgetIcons) }],
    [IconListWidgetAttributes.ITEMS]: ['widgetlist', { only: [IconListItemWidgetId] }],
    ...spacingClassAttributes(),
  },
});

export type IconListWidget = InstanceType<typeof IconListWidgetClass>;
