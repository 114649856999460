import * as Scrivito from 'scrivito';
import styles from './skip-link-navigation.module.scss';
import { useTranslation } from 'react-i18next';
import { usePageBannerInfo } from '../../building-blocks/page-banner/page-banner.helper';

export const SkipLinkNavigation: React.FC = Scrivito.connect(() => {
  const { t } = useTranslation();
  const [bannerVisible] = usePageBannerInfo();

  return (
    <div className={styles.SkipLinkNavigation} role="dialog" data-test-id="skip-link-navigation">
      <div className={styles.Box}>
        <h2 className={styles.Instruction}>{t('component.skipLinkNavigation.goTo')}</h2>
        {bannerVisible && <a href="#skip-link-banner">{t('component.skipLinkNavigation.banner')}</a>}
        <a href="#skip-link-nav">{t('component.skipLinkNavigation.navigation')}</a>
        <a href="#skip-link-main">{t('component.skipLinkNavigation.content')}</a>
        <a href="#skip-link-footer">{t('component.skipLinkNavigation.footer')}</a>
      </div>
    </div>
  );
});
