import { ImageObjectId } from '../../objects';
import * as Scrivito from 'scrivito';
import { HelperText, Message } from '../../utils/translations';
import { HeadlineWidgetTag } from '../../widgets/headline-widget';
import { ValidationInfo } from '../../utils/type.utils';
import { ToolbarButton } from '../../utils/scrivito/toolbar-button';

export enum HeroAttributes {
  ACTIVE = 'active',
  TITLE = 'heroTitle',
  TITLE_ELEMENT = 'heroTitleElement',
  SUBTITLE = 'heroSubtitle',
  IMAGE = 'heroImage',
  IMAGE_HIDE_MOBILE = 'imageHideMobile',
  LINK = 'heroLink',
  COLOR = 'heroColor',
  JOURNEY_PLANNER = 'journeyPlanner',
  SMALL_FONT = 'heroSmallFont',
}

export enum HeroColor {
  GREY = 'grey',
  RED = 'red',
  BLUE = 'blue',
  VIOLET = 'violet',
}

export const heroAttributes = {
  [HeroAttributes.ACTIVE]: {
    title: HelperText.ACTIVE,
  },
  [HeroAttributes.TITLE]: {
    title: HelperText.TITLE,
    description: HelperText.HERO_SUB_TITLE_DESCRIPTION,
    options: {
      toolbar: ['subscript', 'superscript'] as ToolbarButton[],
    },
  },
  [HeroAttributes.TITLE_ELEMENT]: {
    title: HelperText.HEADLINE_HIERARCHY,
    values: [
      { value: HeadlineWidgetTag.H1, title: '<h1>' },
      { value: HeadlineWidgetTag.H2, title: '<h2>' },
    ],
  },
  [HeroAttributes.SUBTITLE]: {
    title: HelperText.SUB_TITLE,
    description: HelperText.HERO_SUB_TITLE_DESCRIPTION,
    options: {
      toolbar: ['subscript', 'superscript'] as ToolbarButton[],
    },
  },
  [HeroAttributes.IMAGE]: {
    title: HelperText.IMAGE,
  },
  [HeroAttributes.IMAGE_HIDE_MOBILE]: {
    title: HelperText.HEROE_HIDE_IMAGE_ON_MOBILE,
  },
  [HeroAttributes.LINK]: {
    title: HelperText.LINK,
  },
  [HeroAttributes.COLOR]: {
    title: HelperText.HERO_BUBBLE_COLOR,
    values: [
      { value: HeroColor.GREY, title: HelperText.GREY },
      { value: HeroColor.VIOLET, title: HelperText.PURPLE },
      { value: HeroColor.BLUE, title: HelperText.BLUE },
      { value: HeroColor.RED, title: HelperText.RED },
    ],
  },
  [HeroAttributes.JOURNEY_PLANNER]: {
    title: HelperText.HERO_SHOW_JOURNEY_PLANNER,
  },
  [HeroAttributes.SMALL_FONT]: {
    title: HelperText.HERO_SMALL_FONT,
    description: HelperText.HERO_SMALL_FONT_DESCRIPTION,
  },
};

export const heroClassAttributes = {
  [HeroAttributes.ACTIVE]: 'boolean',
  [HeroAttributes.TITLE]: 'html',
  [HeroAttributes.TITLE_ELEMENT]: ['enum', { values: [HeadlineWidgetTag.H1, HeadlineWidgetTag.H2] }],
  [HeroAttributes.SUBTITLE]: 'html',
  [HeroAttributes.SMALL_FONT]: 'boolean',
  [HeroAttributes.IMAGE]: ['reference', { only: ImageObjectId }],
  [HeroAttributes.IMAGE_HIDE_MOBILE]: 'boolean',
  [HeroAttributes.LINK]: 'link',
  [HeroAttributes.COLOR]: ['enum', { values: Object.values(HeroColor) }],
  [HeroAttributes.JOURNEY_PLANNER]: 'boolean',
};

export const heroInitialContent = {
  [HeroAttributes.TITLE_ELEMENT]: HeadlineWidgetTag.H1,
  [HeroAttributes.LINK]: new Scrivito.Link({
    url: '#',
    title: 'Button Text',
  }),
  [HeroAttributes.COLOR]: HeroColor.RED,
  [HeroAttributes.SMALL_FONT]: false,
};

export const heroValidations = [
  [
    HeroAttributes.IMAGE,
    (value: object, { obj }: { obj: Scrivito.Widget }): ValidationInfo => {
      const hasImage = !!value;
      const isActive = obj.get(HeroAttributes.ACTIVE) as boolean;

      if (isActive && !hasImage) {
        return {
          severity: 'error',
          message: Message.HERO_IMAGE_REQUIRED,
        };
      }
      return undefined;
    },
  ],
];

export const heroProperties = [
  {
    title: 'Hero',
    key: 'page-hero',
    properties: Object.values(HeroAttributes),
  },
];
