import React from 'react';
import { progressAria, progressString } from './wizard-helpers';
import styles from './wizard-header-small.module.scss';

export interface WizardHeaderSmallProps {
  title: string;
  currentIndex: number;
  pageCount: number;
}

export const WizardHeaderSmall: React.FC<WizardHeaderSmallProps> = (props) => {
  const progress = progressAria(props.currentIndex, props.pageCount);

  return (
    <div
      className={styles.WizardHeaderSmall}
      role="progressbar"
      aria-valuenow={progress}
      aria-valuemin={0}
      aria-valuemax={100}
    >
      <div className={styles.ProgressBar}>
        <div className={styles.Progress} style={{ width: progressString(props.currentIndex, props.pageCount) }} />
      </div>
      <div className={styles.Info}>
        <div className={styles.InfoLeft}>{props.title}</div>
        <div className={styles.InfoRight}>
          {props.currentIndex + 1}/{props.pageCount}
        </div>
      </div>
    </div>
  );
};
