import { createBrowserHistory } from 'history';
import { useHistory as scrivitoUseHistory } from 'scrivito';
import { scrollToFragment } from 'scroll-to-fragment';

export const browserHistory = createBrowserHistory();

export const configureHistory = (): void => {
  scrivitoUseHistory(browserHistory);

  let prevElement: Element | null = null;
  let timeout: NodeJS.Timeout | null = null;

  scrollToFragment({
    history: browserHistory,
    // workaround to smooth scroll on chrome
    // https://bugs.chromium.org/p/chromium/issues/detail?id=1364865&q=smooth%20scrolling&can=2
    scrollIntoView: (element: Element) => {
      if (prevElement !== element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
      prevElement = element;
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        prevElement = null;
        element.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    },
  });
};
