import * as Scrivito from 'scrivito';
import React from 'react';
import { NavigationEntryAttrs } from '../../../../../pages/home-page/home-page-navigation';
import styles from './navigation-flyout-section.module.scss';
import classNames from 'classnames';
import { isLinkCurrentPage } from '../../../../../utils/scrivito';
import { ScheduleAttributes } from '../../../../../pages/properties/schedule';
import { isSchedulingContentVisible } from '../../../../../utils/general.utils';

interface NavigationFlyoutSectionProps {
  nav: Scrivito.Widget;
  close: () => void;
}

function isLinkVisible(link: Scrivito.Link | null): boolean {
  if (link) {
    const page = link.obj() as Scrivito.Obj;
    if (page) {
      const visibleFrom = page.get(ScheduleAttributes.VISIBLE_FROM) as Date | null;
      const visibleUntil = page.get(ScheduleAttributes.VISIBLE_UNTIL) as Date | null;
      return isSchedulingContentVisible(visibleFrom, visibleUntil);
    }
  }
  return true;
}

export const NavigationFlyoutSection: React.FC<NavigationFlyoutSectionProps> = Scrivito.connect(({ nav, close }) => {
  const subID = nav.id();
  const subLink = nav.get(NavigationEntryAttrs.LINK) as Scrivito.Link | undefined;
  const subLabel = nav.get(NavigationEntryAttrs.LABEL) as string;
  const subTitle = subLink?.title() ?? subLabel;
  const subChildren = (nav.get(NavigationEntryAttrs.CHILDREN) ?? []) as Scrivito.Widget[];
  const currentPage = Scrivito.currentPage();

  return (
    <div className={styles.NavigationFlyoutSection}>
      {subLink && isLinkVisible(subLink) && (
        <Scrivito.LinkTag
          key={subID}
          to={subLink}
          title={subTitle}
          className={classNames(styles.SubNavLink, {
            [styles.Current]: isLinkCurrentPage(currentPage, subLink),
          })}
          onClick={close}
        >
          {subTitle}
        </Scrivito.LinkTag>
      )}
      {!subLink && (
        <span key={subID} className={styles.SubNavLink}>
          {subTitle}
        </span>
      )}
      {subChildren.map((subSubNav) => {
        const subSubID = subSubNav.id();
        const subSubLink = subSubNav.get(NavigationEntryAttrs.LINK) as Scrivito.Link;
        const subSubLabel = (subSubLink?.title() as string) || 'not_set';

        if (!isLinkVisible(subSubLink)) {
          return null;
        }

        return (
          <Scrivito.LinkTag
            key={`${subID}-${subSubID}`}
            to={subSubLink}
            title={subTitle}
            className={classNames(styles.SubSubNavLink, {
              [styles.Current]: isLinkCurrentPage(currentPage, subSubLink),
            })}
            onClick={close}
          >
            {subSubLabel}
          </Scrivito.LinkTag>
        );
      })}
    </div>
  );
});
