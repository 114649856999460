import * as Scrivito from 'scrivito';
import React, { useEffect } from 'react';
import styles from './navigation-flyout.module.scss';
import classNames from 'classnames';
import { NavigationEntryAttrs } from '../../../../../pages/home-page/home-page-navigation';
import { NavigationFlyoutColumn } from './navigation-flyout-column';

interface NavigationFlyoutProps {
  nav: Scrivito.Widget | undefined;
  small: boolean;
  close: () => void;
}

export const NavigationFlyout: React.FC<NavigationFlyoutProps> = Scrivito.connect(({ nav, small, close }) => {
  useEffect((): (() => void) | undefined => {
    // Prevent body scroll, when the flyout is open
    // It gets buggy when the flyout and the body can scroll
    if (nav) {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = '';
      };
    }
    return undefined;
  }, [nav]);

  if (!nav) {
    return null;
  }

  const label = nav.get(NavigationEntryAttrs.LABEL) as string;
  const image = nav.get(NavigationEntryAttrs.IMAGE) as Scrivito.Obj | null;
  const imagePositionH = nav.get(NavigationEntryAttrs.IMAGE_POSITION_H) as string;
  const imagePositionV = nav.get(NavigationEntryAttrs.IMAGE_POSITION_V) as string;
  const selectedChildColumns = [
    (nav.get(NavigationEntryAttrs.CHILDREN1) as Scrivito.Widget[]) ?? [],
    (nav.get(NavigationEntryAttrs.CHILDREN2) as Scrivito.Widget[]) ?? [],
    (nav.get(NavigationEntryAttrs.CHILDREN3) as Scrivito.Widget[]) ?? [],
  ];

  const imageStyle = { objectPosition: `${imagePositionH} ${imagePositionV}` };

  return (
    <div className={classNames(styles.Flyout, { [styles.Small]: small })}>
      <div className={styles.FlyoutScroll}>
        <h2>{label}</h2>
        <div className={styles.FlyoutContent}>
          {image && (
            <div className={styles.FlyoutImage}>
              <div className={classNames(styles.FlyoutImageWrapper)}>
                <Scrivito.ImageTag content={image} style={imageStyle} loading="lazy" />
              </div>
            </div>
          )}

          <nav className={styles.FlyoutNavigation}>
            {selectedChildColumns.map((selectedChildren, index) => (
              <NavigationFlyoutColumn key={`sub-nav-column-${index}`} navChildren={selectedChildren} close={close} />
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
});
