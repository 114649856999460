import * as Scrivito from 'scrivito';
import { ImageObjectAttributes } from '../../objects/image-object/image-object-definitions';
import { spacingAttributes, spacingContent, spacingGroup } from '../../utils/scrivito/spacing-definitions';
import { HelperText, Message, WidgetTitle } from '../../utils/translations';
import { ValidationInfo } from '../../utils/type.utils';
import {
  ImageWidgetAttributes,
  ImageWidgetBubbleColor,
  ImageWidgetId,
  ImageWidgetPosition,
} from './image-widget-definitions';
import Thumbnail from '../../assets/thumbnails/image.svg';

Scrivito.provideEditingConfig(ImageWidgetId, {
  title: WidgetTitle.IMAGE,
  thumbnail: Thumbnail,
  properties: Object.values(ImageWidgetAttributes),
  attributes: {
    [ImageWidgetAttributes.IMAGE]: {
      title: 'Bild',
    },
    [ImageWidgetAttributes.CAPTION]: {
      title: 'Bildunterschrift (optional)',
      description: 'Die Bildunterschrift erscheint unterhalb des Bildes.',
      options: { toolbar: ['bold', 'subscript', 'superscript'] },
    },
    [ImageWidgetAttributes.TITLE_TAG]: {
      title: 'Title-Tag',
      description: HelperText.IMAGE_TITLE_FALLBACK,
    },
    [ImageWidgetAttributes.ALT_TAG]: {
      title: 'Alt-Tag',
      description: HelperText.IMAGE_ALT_FALLBACK,
    },
    [ImageWidgetAttributes.POSITIONING]: {
      title: 'Position',
      description: HelperText.IMAGE_POSITIONING,
      values: [
        { value: ImageWidgetPosition.LEFT, title: 'Links' },
        { value: ImageWidgetPosition.CENTER, title: 'Mitte' },
        { value: ImageWidgetPosition.RIGHT, title: 'Rechts' },
        { value: ImageWidgetPosition.FULL, title: 'Volle Breite' },
      ],
    },

    [ImageWidgetAttributes.BUBBLE]: {
      title: HelperText.BUBBLE_TITLE,
      description: HelperText.BUBBLE_DESCRIPTION,
      values: [
        { value: ImageWidgetBubbleColor.NO_COLOR, title: 'Keine' },
        { value: ImageWidgetBubbleColor.LIGHT_BLUE, title: 'Hellblau' },
        { value: ImageWidgetBubbleColor.DARK_GREEN, title: 'Dunkelgrün' },
        { value: ImageWidgetBubbleColor.VIOLET, title: 'Lila' },
        { value: ImageWidgetBubbleColor.PINK, title: 'Pink' },
      ],
    },

    [ImageWidgetAttributes.WIDTH]: {
      title: HelperText.IMAGE_WIDTH,
      description: HelperText.IMAGE_WIDTH_DESCRIPTION,
    },
    [ImageWidgetAttributes.LINK]: {
      title: 'Link',
    },
    [ImageWidgetAttributes.SCALABLE]: {
      title: 'Per Klick vergrößern',
    },
    ...spacingAttributes,
  },
  propertiesGroups: [spacingGroup],
  initialContent: {
    [ImageWidgetAttributes.POSITIONING]: ImageWidgetPosition.CENTER,
    [ImageWidgetAttributes.BUBBLE]: ImageWidgetBubbleColor.NO_COLOR,
    ...spacingContent,
  },
  validations: [
    // at least one alt tag (image reference or widget) should be set
    [
      ImageWidgetAttributes.ALT_TAG,
      (altTag: string, { widget }: { widget: Scrivito.Widget }): ValidationInfo => {
        if (
          altTag.length <= 0 &&
          (
            (widget.get(ImageWidgetAttributes.IMAGE) as Scrivito.Obj)?.get(
              ImageObjectAttributes.ALTERNATIVE_TEXT
            ) as string
          )?.length <= 0
        ) {
          return {
            message: Message.ALT_TEXT_MISSING,
            severity: 'warning',
          };
        }

        return undefined;
      },
    ],
    [
      ImageWidgetAttributes.BUBBLE,
      (value: ImageWidgetBubbleColor | null): ValidationInfo => {
        if (!value) {
          return {
            severity: 'error',
            message: Message.BUBBLE_MISSING,
          };
        }

        return undefined;
      },
    ],
  ],
});
