import * as Scrivito from 'scrivito';
import { useEffect, useMemo, useState } from 'react';
import style from './search.module.scss';
import { SearchForm } from './search-form';
import { SearchBody } from './search-body';
import {
  addOrRemoveFilter,
  DEFAULT_SEARCH_RESULT_LIMIT,
  FILE_FILTER,
  hasResults,
  navigateWithFilters,
} from './search-utils.scrivito';
import { FilterTagGroupComponent } from '../controls/filter-tag-group/filter-tag-group';
import { FilterTagComponent } from '../controls/filter-tag/filter-tag';
import { useTranslation } from 'react-i18next';
import { useSearchFilters, SearchResult, useSearchFunction } from './search-function';

export interface SearchProps {
  params?: {
    q?: string;
    filterList?: string[]; // available filter
    filter?: string[]; // active filter
  };
}

const SearchFilterComponent: React.FC<SearchProps> = ({ params }) => {
  const [filterMenu, setFilterMenu] = useState<React.ReactNode[]>([]);
  const query = useMemo(() => params?.q || '', [params?.q]);
  const filterList = useMemo(() => params?.filterList ?? [], [params?.filterList]);
  const { urlFileFilter, urlFilter, tags } = useSearchFilters();
  const { t } = useTranslation();

  // Check if results with those filter exists before displaying them
  useEffect(() => {
    // Sets or removes the file filter from the url on tag click
    const onFileFilterClick = (): void => {
      navigateWithFilters(query, urlFilter, urlFileFilter !== 'true' ? 'true' : undefined);
    };

    // Sets or removes the filter from the url on tag click
    const onFilterTagClick = (_: React.MouseEvent, filter: string): void => {
      const filterQuery = addOrRemoveFilter(tags, filter);
      navigateWithFilters(query, filterQuery, urlFileFilter === 'true' ? 'true' : undefined);
    };

    const fileFilterCheck = async (): Promise<React.ReactNode> => {
      const hasFileResults = await hasResults(query, true, tags);

      if (hasFileResults) {
        return (
          <FilterTagComponent
            value={FILE_FILTER}
            label={t('search.searchFileFilter')}
            selected={urlFileFilter === 'true'}
            key={'fileFilter'}
            onClick={onFileFilterClick}
          />
        );
      }

      return null;
    };

    const filterChecks = filterList.map(async (filter, index): Promise<React.ReactNode> => {
      if (await hasResults(query, urlFileFilter === 'true', [...tags, filter])) {
        return (
          <FilterTagComponent
            value={filter}
            label={filter}
            selected={tags.indexOf(filter) !== -1}
            key={index}
            onClick={(event, filter): void => onFilterTagClick(event, filter)}
          />
        );
      }
      return null;
    });

    Promise.all([fileFilterCheck(), ...filterChecks]).then(setFilterMenu);
  }, [filterList, query, urlFileFilter, tags, t, urlFilter]);

  return <>{filterMenu}</>;
};

const SearchComponent: React.FC<SearchProps> = ({ params }) => {
  const searchFunction = useSearchFunction();
  const [result, setResult] = useState<SearchResult | null>(null);
  const [fileResult, setFileResult] = useState<SearchResult | null>(null);
  const [limit, setLimit] = useState(DEFAULT_SEARCH_RESULT_LIMIT);
  const { t } = useTranslation();
  const query = useMemo(() => params?.q || '', [params?.q]);

  useEffect(() => {
    searchFunction(query, limit, (result) => {
      setResult(result.pageSearch);
      setFileResult(result.fileSearch);
    });
  }, [query, limit, searchFunction]);

  if (!result || !fileResult) {
    return null;
  }

  const loadMoreButtonClickHandler = (): void => {
    setLimit(limit + DEFAULT_SEARCH_RESULT_LIMIT);
  };

  return (
    <div className={style.Search}>
      <div>
        <h1 className={style.Headline}>{t('search.searchPageHeadline')}</h1>
      </div>
      <div>
        <SearchForm params={params} searchButtonLabel={t('search.search')} />
      </div>
      <div>
        {result.total > 0 && (
          <FilterTagGroupComponent label={t('search.filtering')}>
            <SearchFilterComponent params={params} />
          </FilterTagGroupComponent>
        )}
      </div>
      <div>
        <SearchBody
          result={result}
          fileResult={fileResult}
          query={query}
          loadMoreButtonClickHandler={loadMoreButtonClickHandler}
        />
      </div>
    </div>
  );
};

export const Search = Scrivito.connect(SearchComponent);
