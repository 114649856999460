import * as Scrivito from 'scrivito';
import { WagonSelectionWidgetId } from '../wagon-selection-widget/wagon-selection-widget-definitions';
import {
  WagonSelectionItemWidgetAttributes,
  WagonSelectionItemWidgetId,
} from './wagon-selection-item-widget-definitions';

export const WagonSelectionItemWidgetClass = Scrivito.provideWidgetClass(WagonSelectionItemWidgetId, {
  attributes: {
    [WagonSelectionItemWidgetAttributes.NAME]: 'string',
    [WagonSelectionItemWidgetAttributes.VALUE]: 'string',
    [WagonSelectionItemWidgetAttributes.YEAR]: 'integer',
    [WagonSelectionItemWidgetAttributes.CAPACITY]: 'integer',
    [WagonSelectionItemWidgetAttributes.PRICE]: 'string',
    [WagonSelectionItemWidgetAttributes.DURATION]: 'string',
    [WagonSelectionItemWidgetAttributes.CONTENT]: 'html',
    [WagonSelectionItemWidgetAttributes.IMAGES]: ['referencelist', { only: 'Image' }],
    [WagonSelectionItemWidgetAttributes.DISABLED]: 'boolean',
  },
  onlyInside: [WagonSelectionWidgetId],
});

export type WagonSelectionItemWidget = InstanceType<typeof WagonSelectionItemWidgetClass>;
