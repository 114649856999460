import * as Scrivito from 'scrivito';
import {
  FormInteractiveLogicWidgetAttributes,
  FormInteractiveLogicWidgetId,
} from './form-interactive-logic-widget-definitions';
import { FormWidgetId } from '../form-widget/form-widget-definitions';

export const FormInteractiveLogicWidgetClass = Scrivito.provideWidgetClass(FormInteractiveLogicWidgetId, {
  attributes: {
    [FormInteractiveLogicWidgetAttributes.FORM_CONTROL_NAME]: 'string',
    [FormInteractiveLogicWidgetAttributes.FORM_CONTROL_VALUE]: 'string',
    [FormInteractiveLogicWidgetAttributes.TARGET_FORM_GROUP]: 'string',
    [FormInteractiveLogicWidgetAttributes.HIDE_FROM_GROUP_WHEN_VALUE_MATCHES]: 'boolean',
  },
  onlyInside: [FormWidgetId],
});

export type FormInteractiveLogicWidget = InstanceType<typeof FormInteractiveLogicWidgetClass>;
