import * as Scrivito from 'scrivito';
import style from './countdown.module.scss';
import dayjs from 'dayjs';
import CountUp from 'react-countup';
import { ResponsiveImageContextProvider } from '../../controls/image/responsive-image/responsive-image.context';
import classNames from 'classnames';
/**
 * Properties for the countdown component
 */
export interface CountdownProps {
  countdownDate: Date;
  countdownTitle: string;
  countdownTitleFinished: string;
  countdownUnit: string;
  buttonText: string;
  buttonToggle: boolean;
  link?: Scrivito.Link;
  id?: string;
  image: React.ReactElement | null;
  className?: string;
}

/**
 * Renders a countdown component
 */
export const CountdownComponent: React.FC<CountdownProps> = (props) => {
  // formats date for label on image
  const language = Scrivito.Obj.root()?.language() ?? '';
  const countdownDateFormatted = props.countdownDate?.toLocaleDateString(language === 'en' ? 'en-US' : 'de-DE', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  const countdownDateWithTimeAsZero = dayjs(props.countdownDate).set('hour', 2).set('minute', 0).set('second', 0);
  const currentDateWithTimeAsZero = dayjs().set('hour', 2).set('minute', 0).set('second', 0);

  const CountdownPanel = (): React.ReactElement => {
    if (!props.countdownDate) {
      return <div className={style.CountdownPanel} />;
    }

    // calculates absolute days for countdown
    const countdownDays = Math.max(
      Math.ceil(countdownDateWithTimeAsZero.diff(currentDateWithTimeAsZero, 'days', true)),
      0
    );
    const isCountdownFinished = countdownDays === 0;

    return (
      <div className={style.CountdownPanel}>
        {isCountdownFinished ? (
          <div className={style.TextFinished}>{props.countdownTitleFinished}</div>
        ) : (
          <div className={style.Text}>{props.countdownTitle}</div>
        )}
        {!isCountdownFinished && (
          <div className={style.Countdown}>
            <div className={style.Days}>
              <CountUp start={countdownDays + 30} end={countdownDays} enableScrollSpy={true} scrollSpyOnce={true} />
            </div>
            <div className={style.Unit}>{countdownDays === 1 ? 'Tag' : `${props.countdownUnit}`}</div>
          </div>
        )}
        {props.buttonToggle && props.link && (
          <Scrivito.LinkTag className={style.Link} to={props.link}>
            {props.buttonText}
          </Scrivito.LinkTag>
        )}
      </div>
    );
  };

  const ImagePanel = (): React.ReactElement => {
    return (
      <div className={style.ImageWrapper}>
        <ResponsiveImageContextProvider loading="lazy">{props.image}</ResponsiveImageContextProvider>
        <div className={style.Date}>{countdownDateFormatted}</div>
      </div>
    );
  };

  return (
    <div
      id={props.id}
      className={classNames(style.CountdownComponent, props.className)}
      data-test-id="CountdownWidgetId"
    >
      <Scrivito.LinkTag
        className={style.CountdownComponentLink}
        to={!props.buttonToggle && props.link ? props.link : null}
      >
        <CountdownPanel />
        <ImagePanel />
      </Scrivito.LinkTag>
    </div>
  );
};
