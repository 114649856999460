import * as Scrivito from 'scrivito';
import { IFrameWidgetAttributes, IFrameWidgetId } from './iframe-widget-definitions';

export const IFrameWidgetClass = Scrivito.provideWidgetClass(IFrameWidgetId, {
  attributes: {
    [IFrameWidgetAttributes.URL]: 'string',
  },
});

export type IFrameWidget = InstanceType<typeof IFrameWidgetClass>;
