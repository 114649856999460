import { useField, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Scrivito from 'scrivito';
import { TextInputComponent } from '../../components/controls/text-input/text-input';
import { getFormikErrorMessage } from '../../utils/formik/get-error';
import { FormElementBaseAttributes } from '../form-widget';
import { DateTimeWidget, DateTimeWidgetClass } from './date-time-widget-class';
import { DateTimeWidgetAttributes, DateTimeWidgetTypes } from './date-time-widget-definitions';
import dayjs from 'dayjs';
import { useFormContext } from '../form-widget/FormContext';
import { DATE_FORMAT, DATE_FORMAT_INT, TIME_FORMAT } from '../../utils/general.utils';

function getNowString(isDate: boolean): string {
  const now = dayjs();
  if (isDate) {
    return now.format(DATE_FORMAT_INT);
  }
  return now.format(TIME_FORMAT);
}

function intFormatDate(value: string, isDate: boolean): string {
  const format = dayjs(value, isDate ? DATE_FORMAT : TIME_FORMAT);
  return format.isValid() ? format.format(isDate ? DATE_FORMAT_INT : TIME_FORMAT) : '';
}

export const DateTimeWidgetComponent: React.FC<{ widget: DateTimeWidget }> = ({ widget }) => {
  const [value, setValue] = useState<string>(widget.get(DateTimeWidgetAttributes.VALUE) ?? '');

  const initValue = widget.get(DateTimeWidgetAttributes.VALUE);
  const isNow = widget.get(DateTimeWidgetAttributes.NOW) as boolean;
  const minDateNow = widget.get(DateTimeWidgetAttributes.MIN_DATE_NOW) as boolean;
  const minDate = widget.get(DateTimeWidgetAttributes.MIN_DATE) as string;
  const maxDate = widget.get(DateTimeWidgetAttributes.MAX_DATE) as string;
  const label = widget.get(DateTimeWidgetAttributes.LABEL) ?? '';
  const type = widget.get(DateTimeWidgetAttributes.TYPE) ?? DateTimeWidgetTypes.DATE;
  const optional = !!widget.get(FormElementBaseAttributes.OPTIONAL) as boolean;
  const helperText = widget.get(DateTimeWidgetAttributes.HELPER_TEXT) ?? '';
  const disabled = widget.get(DateTimeWidgetAttributes.DISABLED);
  const readOnly = widget.get(DateTimeWidgetAttributes.READ_ONLY);

  const name = widget.get(FormElementBaseAttributes.NAME);

  const formContext = useFormContext();
  const { handleBlur, setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(name);

  useEffect(() => {
    if (initValue || isNow) {
      // Set initial value to now (formatted) or init value
      const computedValue = isNow ? getNowString(type === DateTimeWidgetTypes.DATE) : initValue;
      setValue(computedValue);
      setFieldValue(name, computedValue); // set value in formik
    }
  }, [initValue, isNow, type, name, setFieldValue]);

  if (formContext.isHiddenElement(name)) {
    return null;
  }

  const onDateTimeChange = (value: string | React.ChangeEvent<HTMLInputElement>): void => {
    // value will be a string on desktop mode (material ui input) or a change event on mobile (native input)
    if (typeof value !== 'string') {
      // handle native date/time picker like text input
      formContext.setInputValue(name, value.target.value);

      setValue?.(value.target.value);
      field.onChange(value);
    } else {
      // Set formatted date/time depending on type
      // If date/time is not valid, set the string empty -> formik error for required will be triggered
      const formatted = intFormatDate(value, type === DateTimeWidgetTypes.DATE);
      setFieldValue(name, formatted);
    }
  };

  /**
   * Remove validation error when date picker dialog was used to pick a value
   */
  const onDialogClose = (): void => {
    setFieldTouched(name, false);
  };

  return (
    <Scrivito.WidgetTag>
      <TextInputComponent
        name={name}
        type={type}
        label={label}
        value={value}
        error={meta.touched && !!meta.error}
        errorMessage={getFormikErrorMessage(meta.error)}
        optional={optional}
        helperText={helperText}
        disabled={disabled}
        readOnly={readOnly}
        onChange={onDateTimeChange}
        onClose={onDialogClose}
        isNow={isNow}
        onBlur={handleBlur}
        inEditMode={Scrivito.isInPlaceEditingActive()}
        minDate={minDateNow ? new Date() : dayjs(minDate, 'YYYY').toDate() || undefined}
        maxDate={dayjs(maxDate, 'YYYY').toDate() || undefined}
      />
    </Scrivito.WidgetTag>
  );
};

Scrivito.provideComponent(DateTimeWidgetClass, DateTimeWidgetComponent);
