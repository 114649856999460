import * as Scrivito from 'scrivito';
import { ButtonWidgetIcons } from '../button-widget';
import { InfoTeaserGroupWidgetId } from '../info-teaser-group-widget/info-teaser-group-widget-definitions';
import { InfoTeaserWidgetAttributes, InfoTeaserWidgetId } from './info-teaser-widget-definitions';

export const InfoTeaserWidgetClass = Scrivito.provideWidgetClass(InfoTeaserWidgetId, {
  attributes: {
    [InfoTeaserWidgetAttributes.LINK]: 'link',
    [InfoTeaserWidgetAttributes.HEADLINE]: 'html',
    [InfoTeaserWidgetAttributes.TEXT]: 'html',
    [InfoTeaserWidgetAttributes.ICON]: [
      'enum',
      {
        values: Object.values(ButtonWidgetIcons),
      },
    ],
    [InfoTeaserWidgetAttributes.MEDIA]: ['reference', { only: 'Image' }],
  },
  onlyInside: [InfoTeaserGroupWidgetId],
});

export type InfoTeaserWidget = InstanceType<typeof InfoTeaserWidgetClass>;
